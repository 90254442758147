import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import BranchOfficeCardPreview from './BranchOfficeCardPreview';
import CreateBranchOfficeForm from './CreateBranchOfficeForm';
import BranchOfficeService from '../../../services/BranchOfficeService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreatePartnerView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [branchOffice, setBranchOffice] = useState({
    id: '',
    partner_id: '',
    brand_id: '',
    adomi_category_id: '',
    city_id: '',
    name: '',
    address: '',
    email: '',
    phone1: '',
    phone2: '',
    whatsapp1: '',
    whatsapp2: '',
    schedule: '',
    latitude: 0.0,
    longitude: 0.0,
    transaction_cost: 0.125,
    own_map: '',
    delivery_rate: '',
    supports_drivers: '',
    is_auto_accept: false,
    is_available: false,
    base_rate_id: '',
    pickup_rate_id: '',
    accept_datafono: '',
    accept_creditcard: '',
    accept_cash: '',
    has_pickup: '',
    base_km: 0,
    base_cost: '',
    cost_additional_km: '',
    max_sell_amount: '',
    min_sell_amount: '',
    min_sell_free_delivery: '',
    tip_driver_adomi: '',
    tip_driver_partner: '',
    tip_driver_friend: '',
    person1: '',
    person2: '',
    currency_id: '',
    eta: '',
    maximum_orders: '',
    cover: '',
    is_coming_soon: '',
    join_name: '',
    estimated_delivery_time: {},
    is_demo: false,
    is_develop: false,
    is_featured: false,
    tags: [],
    polygon: {},
    coverage_radio: 3500,
    commercial_zone_id: '',
    epayco_public_key: '',
    epayco_private_key: '',
    mercado_pago_public_key: '',
    mercado_pago_access_token: '',
    accept_mercado_pago: '',
  });

  const inputRef = useRef();

  const handleBranchOfficesAttributes = (values) => {
    setBranchOffice({
      ...branchOffice,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    // if (id) {
    //   (async () => {
    //     const response = await BranchOfficeService.getById(id);
    //     if (response?.code === 200) {
    //       setBranchOffice({
    //         ...response.data,
    //         latitude: Number(response.data.latitude),
    //         longitude: Number(response.data.longitude),
    //       });
    //     }
    //   })();
    // }
    if (id) {
      (async () => {
        BranchOfficeService.getById(id)
          .then((response) => {
            setBranchOffice({
              ...response.data,
              latitude: Number(response.data.latitude),
              longitude: Number(response.data.longitude),
            });
          }).catch((err) => {
            console.log(`Este es el error: ${err}`);
          });
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page
        className={classes.root}
        title="Crear Sucursal"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <BranchOfficeCardPreview
                ref={inputRef}
                branchOffice={branchOffice}
                hasImage={hasImage}
                onUpload={handleUploadImage}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <CreateBranchOfficeForm
                ref={inputRef}
                edit={edit}
                hasImage={hasImage}
                branchOffice={branchOffice}
                onChange={handleBranchOfficesAttributes}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreatePartnerView.propTypes = {
  edit: PropTypes.bool,
};

CreatePartnerView.defaultProps = {
  edit: false
};

export default CreatePartnerView;
