import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import BranchOfficeService from '../../../services/BranchOfficeService';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  },
}));

const CoverCard = ({
  className, onSuccess, branchOffice, ...rest
}) => {
  const classes = useStyles();
  const [menuIsOpen, setMenuIsOpen] = useState(null);

  const toggleMenu = (e) => setMenuIsOpen(!menuIsOpen ? e.currentTarget : null);

  const approveImage = async () => {
    await BranchOfficeService.update(branchOffice?.id, {
      ...branchOffice,
      is_cover_uploaded: false
    });
    onSuccess();
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <Avatar
              className={classes.avatar}
              alt="branch-offices-covers"
              src={branchOffice?.cover}
              variant="square"
            />
          </Grid>
          <Grid container item xs={9} alignItems="flex-start">
            <Grid container item xs={10} alignItems="flex-start">
              <Box mt={1}>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  {branchOffice?.brand?.name}
                  {' '}
                  {branchOffice?.name}
                </Typography>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                  {branchOffice?.city.name}
                </Typography>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="caption"
                >
                  {branchOffice?.address}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={2} alignItems="flex-start">
              <IconButton onClick={toggleMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuIsOpen}
                open={Boolean(menuIsOpen)}
                onClose={toggleMenu}
              >
                <MenuItem onClick={approveImage}>Aprobar cover</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CoverCard.propTypes = {
  className: PropTypes.string,
  branchOffice: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

CoverCard.propTypes = {
  className: () => {},
};

export default CoverCard;
