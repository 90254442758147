import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles, useMediaQuery } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import './WarningModal.css';

const WarningModal = ({ showWarning, text }) => {
  const matches = useMediaQuery('(min-width:520px)');

  const useStyles = makeStyles({
    body: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '20px',
      height: '100%'
    },

    warning: {
      display: 'flex',
      flexDirection: matches ? 'row' : 'column',
      alignItems: 'center',
      marginBottom: '20px',
      justifyContent: 'center'
    },

    text: {
      textAlign: 'center',
      fontSize: '15px',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      marginLeft: matches ? '7px' : '0',
      marginTop: matches ? '0' : '7px'
    },
  });

  const classes = useStyles();

  return (
    <div className="WarningModal">
      <div
        className="pane"
        onClick={() => {
          showWarning(false);
        }}
      />
      <div className="content">
        <div className={classes.body}>
          <div className={classes.warning}>
            <ReportProblemIcon color="primary" fontSize="large" />
            <p className={classes.text}>{text}</p>
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              showWarning(false);
            }}
          >
            Aceptar
          </Button>
        </div>
      </div>
    </div>
  );
};

WarningModal.propTypes = {
  showWarning: PropTypes.func,
  text: PropTypes.string
};

export default WarningModal;
