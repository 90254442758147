import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles,
  Avatar,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  message: {
    fontSize: 14,
    fontWeight: 500,
    wordBreak: 'break-word'
  },
  time: {
    marginTop: 5,
    fontWeight: 600,
    fontSize: 11
  },
  ldsRing: {
    display: 'inline-block',
    position: 'relative',
    width: 22,
    height: 22,
    '& div': {
      boxSizing: 'border-box',
      display: 'block',
      position: 'absolute',
      width: 16,
      height: 16,
      border: '2px solid #009739',
      borderRadius: '50%',
      animation: '$ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
      borderColor: '#009739 transparent transparent transparent',
    },
    '& div:nth-child(1)': {
      animationDelay: '-0.45s',
    },
    '& div:nth-child(2)': {
      animationDelay: '-0.3s',
    },
    '& div:nth-child(3)': {
      animationDelay: '-0.15s',
    },
  },
  '@keyframes ring': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}));

const Message = ({
  message,
  id,
  color,
  initials,
  rol,
  time
}) => {
  const classes = useStyles();
  return (
    <Box
      alignSelf={rol === 'user' ? 'start' : 'end'}
      display="flex"
      py={0.7}
      px={2.5}
    >
      <Box
        mt={0.5}
        display={rol === 'user' ? 'flex' : 'none'}
      >
        <Avatar
          style={{
            backgroundColor: color
          }}
        >
          {initials}
        </Avatar>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        mx={1.5}
      >
        {id.length > 0 && (
          <Typography
            variant="body2"
            className={classes.time}
            style={{ textAlign: rol === 'user' ? 'left' : 'right' }}
          >
            {time}
          </Typography>
        )}
        {id.length === 0 && rol !== 'user' && (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <div
              className={classes.ldsRing}
            >
              <div />
              <div />
              <div />
              <div />
            </div>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          maxWidth={260}
          p={2}
          pr={3}
          mt={0.5}
          bgcolor={rol === 'user' ? '#f6f6fa' : '#009739'}
          borderRadius={15}
          style={{
            borderTopLeftRadius: rol === 'user' ? 0 : 15,
            borderTopRightRadius: rol !== 'user' ? 0 : 15
          }}
        >
          <Typography
            variant="body2"
            className={classes.message}
            style={{ color: rol === 'user' ? '#000' : '#fff' }}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Message;

Message.propTypes = {
  message: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.string,
  initials: PropTypes.string,
  rol: PropTypes.string,
  time: PropTypes.string,
};
