import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import DriverService from '../../../services/DriverService';
import BranchOfficeService from '../../../services/BranchOfficeService';
import CountryService from '../../../services/CountryService';
import CityService from '../../../services/CityService';
import { selector as AppSelector } from '../../../redux/ducks/app';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateDriverForm = forwardRef(({
  className, driver, edit, onChange, hasImage, setLoading, typeForm, ...rest
}, ref) => {
  const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  const phoneRegex = RegExp(/^\d{10}$/);
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const { globalCitySelected: citySelectedApp } = useSelector(AppSelector);
  const [countrySelected, setCountrySelected] = useState('be8cc509-a489-4ec9-99d7-76b7f48258dd');
  const [countries, setCountries] = useState([]);
  const [citySelected, setCitySelected] = useState('');
  const [cities, setCities] = useState([]);
  const [driverSelected, setDriverSelected] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [branchOfficeSelected, setBranchOfficeSelected] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);

  const handleChange = (event) => {
    onChange({
      [event?.target?.name]: event?.target?.value
    });
  };

  const handleCountry = (event) => {
    setCountrySelected(event?.target?.value);
    handleChange(event);
  };

  const handleCity = (event) => {
    setCitySelected(event?.target?.value);
    handleChange(event);
  };

  const handleBranchOffice = (event) => {
    setBranchOfficeSelected(event?.target?.value);
    handleChange(event);
  };

  const handleDriver = (event) => {
    setDriverSelected(event?.target?.value);
    handleChange(event);
  };

  const getValuesForm = (type, e) => {
    if (type === 'password') {
      return {
        first_name: driver?.first_name,
        last_name: driver?.last_name,
        avatar: driver?.avatar,
        email: driver?.email,
        phone: Number(driver?.phone),
        branch_offices: driver?.branch_offices,
        country_id: driver?.country_id,
        city_id: driver?.city_id,
        whatsapp: Number(driver?.whatsapp),
        is_coordinator: driver?.is_coordinator,
        coordinator_id: driver?.coordinator_id || null,
        password: e.target?.password?.value,
        birthdate: driver?.birthdate,
        has_dataphone: driver?.has_dataphone,
        is_adomi: driver?.is_adomi,
        is_develop: driver?.is_develop,
      };
    }

    return {
      first_name: e.target?.first_name?.value,
      last_name: e.target?.last_name?.value,
      avatar: e.target?.avatar?.value,
      email: e.target?.email.value,
      phone: Number(e.target?.phone?.value),
      branch_offices: branchOfficeSelected || e.target?.branch_offices?.value,
      country_id: e.target?.country_id?.value,
      city_id: e.target?.city_id?.value,
      whatsapp: Number(e.target?.whatsapp?.value),
      is_coordinator: e.target?.is_coordinator?.value,
      coordinator_id: e.target?.coordinator_id?.value || null,
      birthdate: e.target?.birthdate?.value,
      has_dataphone: e.target?.has_dataphone?.value,
      is_adomi: e.target?.is_adomi?.value,
      is_develop: e.target?.is_develop?.value
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const file = ref.current && ref.current.files[0];
    const data = getValuesForm(typeForm, e);

    if (data?.first_name?.length === 0) {
      errors?.push('El nombre del repartidor es obligatorio');
    }
    if (data?.last_name?.length === 0) {
      errors?.push('Los apellidos del repartidor son obligatorios');
    }
    if (data?.phone?.length === 0) {
      errors?.push('El teléfono del repartidor es obligatorio');
    }
    if (phoneRegex.test(data.phone) === false) {
      errors.push('El numero de telefono esta mal escrito, no debe tener espacio o letras');
    }
    if (data?.email?.length === 0) {
      errors?.push('El email del repartidor es obligatorio');
    }
    if (emailRegex.test(data.email) === false) {
      errors.push('El email esta mal escrito');
    }
    if (data?.city_id?.length === 0) {
      errors?.push('La ciudad es obligatoria');
    }
    if (data?.whatsapp?.length === 0) {
      errors.push('El número de whatsapp es obligatorio');
    }
    if (typeForm === 'password' && data?.password?.length === 0) {
      errors.push('La nueva contraseña es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    // setLoading(true);

    const response = edit
      ? await DriverService.update(driver?.id, data)
      : await DriverService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (response?.data?.avatar !== null && file !== undefined) {
        await DriverService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await DriverService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/repartidores');
          }, 500);
        }
      }
      if (response?.data?.avatar === null && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await DriverService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/repartidores');
          }, 500);
        }
      }
      navigate('/app/repartidores');
    } else {
      setErrors(response.errors);
    }
  };

  const deleteDriver = async () => {
    if (driver?.id) {
      await DriverService.deleteImage(driver.id);
      const response = await DriverService.remove(driver.id);
      if (!response) {
        navigate('/app/repartidores', { replace: true });
      }
    }
  };

  const fetchAllCountries = async () => {
    const response = await CountryService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar los países']);
      return;
    }

    setCountries(response?.data);
  };

  const fetchAllCities = async () => {
    const response = await CityService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
      return;
    }

    setCities(response?.data.filter((city) => city?.state?.country?.id === countrySelected));
  };

  const fetchAllBranchOffices = async () => {
    const response = await BranchOfficeService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las sucursales']);
      return;
    }

    setBranchOffices(response.data.filter((branch) => branch?.city_id === citySelected));
  };

  const fetchAllDrivers = async () => {
    const response = await DriverService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar los repartidores']);
      return;
    }

    setDrivers(response.data.filter(
      (d) => d?.city?.id === citySelected && d?.is_coordinator === true
    ));
  };

  useEffect(() => {
    (async () => {
      fetchAllCountries();
    })();
  }, []);

  useEffect(() => {
    fetchAllCities();
  }, [countrySelected]);

  useEffect(() => {
    fetchAllBranchOffices();
  }, [citySelected]);

  useEffect(() => {
    fetchAllDrivers();
  }, [citySelected]);

  useEffect(() => {
    if (edit) {
      setCountrySelected(driver?.country_id);
      setCitySelected(driver?.city_id);
      setDriverSelected(driver?.coordinator_id);
      setBranchOfficeSelected(
        Array.isArray(driver?.branch_offices) ? driver?.branch_offices : []
      );
    }
  }, [edit, driver]);

  return (
    <>
      {
        typeForm === 'password'
          ? (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader
                  subheader="La información puede ser editada"
                  title="Repartidor"
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba la contraseña"
                        label="Contraseña"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={driver.password}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Errors time={0} />
                <Divider />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  {
                    edit && (
                      <Box mr={2}>
                        <Link to={`/app/repartidores/${driver?.id}/horario`}>
                          <Button
                            type="button"
                            variant="contained"
                          >
                            Horario
                          </Button>
                        </Link>
                      </Box>
                    )
                  }
                  {
                    edit && (
                      <Box mr={2}>
                        <Button
                          type="button"
                          onClick={() => toggleModal()}
                          className={classes.error}
                          variant="contained"
                        >
                          Eliminar
                        </Button>
                      </Box>
                    )
                  }
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {edit ? 'Editar' : 'Guardar'}
                  </Button>
                </Box>
              </Card>
              <ConfirmationModal onAccept={deleteDriver} />
            </form>
          )
          : (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader
                  subheader="La información puede ser editada"
                  title="Repartidor"
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl required variant="outlined" className={classes.formControl}>
                        <InputLabel id="pais">Seleccione un país</InputLabel>
                        <Select
                          required
                          name="country_id"
                          labelId="pais"
                          label="Selecciona un país"
                          value={countrySelected}
                          onChange={handleCountry}
                        >
                          <MenuItem value="0" />
                          {
                            countries.map((country) => (
                              <MenuItem key={country?.id} value={country?.id}>
                                {country?.name}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl required variant="outlined" className={classes.formControl}>
                        <InputLabel id="ciudad">Seleccione una ciudad</InputLabel>
                        <Select
                          name="city_id"
                          labelId="ciudad"
                          label="Selecciona una ciudad"
                          value={driver?.city_id || (!edit && citySelectedApp)}
                          onChange={handleCity}
                        >
                          <MenuItem value="" />
                          {
                            cities.map((city) => (
                              <MenuItem key={city?.id} value={city?.id}>
                                {city?.name}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba el nombre"
                        label="Nombre"
                        name="first_name"
                        onChange={handleChange}
                        required
                        value={driver?.first_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba los apellidos"
                        label="Apellidos"
                        name="last_name"
                        onChange={handleChange}
                        required
                        value={driver?.last_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="sucursal">Seleccione una sucursal</InputLabel>
                        <Select
                          multiple
                          name="branch_offices"
                          labelId="sucursal"
                          label="Selecciona una sucursal"
                          value={branchOfficeSelected}
                          onChange={handleBranchOffice}
                        >
                          {
                            branchOffices.map((branchOffice) => (
                              branchOffice?.join_name ? (
                                <MenuItem key={branchOffice?.id} value={branchOffice?.id}>
                                  {`${branchOffice?.brand?.name} ${branchOffice?.name}`}
                                </MenuItem>
                              ) : (
                                <MenuItem key={branchOffice?.id} value={branchOffice?.id}>
                                  {branchOffice?.brand?.name}
                                </MenuItem>
                              )
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        label="Fecha de nacimiento"
                        name="birthdate"
                        onChange={handleChange}
                        required
                        value={driver?.birthdate}
                        variant="outlined"
                        type="date"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba el teléfono"
                        label="Teléfono"
                        name="phone"
                        onChange={handleChange}
                        required
                        value={driver?.phone}
                        variant="outlined"
                        type="tel"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba el número de whatsapp"
                        label="Número de whatsapp"
                        name="whatsapp"
                        onChange={handleChange}
                        required
                        value={driver?.whatsapp}
                        variant="outlined"
                        type="whatsapp"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="is_coordinator">¿Es coordinador?</InputLabel>
                        <Select
                          name="is_coordinator"
                          labelId="is_coordinator"
                          label="¿Es coordinador?"
                          value={driver?.is_coordinator}
                          onChange={handleChange}
                        >
                          {
                            [true, false].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item?.toString() === 'true' ? 'Si' : 'No'}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="coordinator_id">Coordinador</InputLabel>
                        <Select
                          name="coordinator_id"
                          labelId="coordinator_id"
                          label="Coordinador"
                          value={driverSelected}
                          onChange={handleDriver}
                        >
                          {
                            drivers.map((d) => (
                              <MenuItem key={d?.id} value={d?.id}>
                                {d?.first_name}
                                {' '}
                                {d?.last_name}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="has_dataphone">¿Tiene datáfono?</InputLabel>
                        <Select
                          name="has_dataphone"
                          labelId="has_dataphone"
                          label="¿Tiene datáfono?"
                          value={driver?.has_dataphone}
                          onChange={handleChange}
                        >
                          {
                            [true, false].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item?.toString() === 'true' ? 'Si' : 'No'}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="is_adomi">¿Es repartidor de adomi?</InputLabel>
                        <Select
                          name="is_adomi"
                          labelId="is_adomi"
                          label="¿Es repartidor de adomi?"
                          value={driver?.is_adomi}
                          onChange={handleChange}
                        >
                          {
                            [true, false].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item?.toString() === 'true' ? 'Si' : 'No'}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="is_develop">¿Es repartidor de desarrollo?</InputLabel>
                        <Select
                          name="is_develop"
                          labelId="is_develop"
                          label="¿Es repartidor de desarrollo?"
                          value={driver?.is_develop}
                          onChange={handleChange}
                        >
                          {
                            [true, false].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item?.toString() === 'true' ? 'Si' : 'No'}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba el correo electrónico"
                        label="Correo electrónico"
                        name="email"
                        onChange={handleChange}
                        required
                        value={driver.email}
                        variant="outlined"
                        type="email"
                      />
                    </Grid>
                    {
                      !edit
                      && (
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Escriba la contraseña"
                            label="Contraseña"
                            name="password"
                            onChange={handleChange}
                            type="password"
                            value={driver.password}
                            variant="outlined"
                          />
                        </Grid>
                      )
                    }
                    {
                      hasImage && (
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            label="Perfil"
                            name="avatar"
                            onChange={handleChange}
                            required
                            value={driver.avatar}
                            variant="outlined"
                          />
                        </Grid>
                      )
                    }
                    <input name="perfil[]" type="file" ref={ref} style={{ display: 'none' }} />
                  </Grid>
                </CardContent>
                <Errors time={0} />
                <Divider />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  {
                    edit && (
                      <Box mr={2}>
                        <Link to={`/app/repartidores/${driver?.id}/horario`}>
                          <Button
                            type="button"
                            variant="contained"
                          >
                            Horario
                          </Button>
                        </Link>
                      </Box>
                    )
                  }
                  {
                    edit && (
                      <Box mr={2}>
                        <Button
                          type="button"
                          onClick={() => toggleModal()}
                          className={classes.error}
                          variant="contained"
                        >
                          Eliminar
                        </Button>
                      </Box>
                    )
                  }
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {edit ? 'Editar' : 'Guardar'}
                  </Button>
                </Box>
              </Card>
              <ConfirmationModal onAccept={deleteDriver} />
            </form>
          )
      }
    </>
  );
});

CreateDriverForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  typeForm: PropTypes.string,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  driver: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
    country_id: PropTypes.string,
    branch_offices: PropTypes.array,
    phone: PropTypes.number,
    password: PropTypes.string,
    birthdate: PropTypes.string,
    city_id: PropTypes.string,
    whatsapp: PropTypes.number,
    coordinator_id: PropTypes.string,
    is_coordinator: PropTypes.bool,
    has_dataphone: PropTypes.bool,
    is_adomi: PropTypes.bool,
    is_develop: PropTypes.bool,
  })
};

CreateDriverForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  typeForm: '',
  onChange: () => { },
  setLoading: () => { },
  driver: {
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    country_id: '',
    branch_offices: [],
    password: '',
    birthdate: '',
    city_id: '',
    whatsapp: '',
    coordinator_id: '',
    is_coordinator: false,
    is_develop: false,
    has_dataphone: false,
    is_adomi: false,
  },
};

export default CreateDriverForm;
