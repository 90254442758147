import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import TagService from '../../../services/TagService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateTagForm = ({
  className, tag, edit, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [subTags, setSubTags] = useState([]);

  const parse = (string) => {
    // Validate if the strings exists
    if (!string) return null;

    // Convert to lower case
    string = string.toLowerCase();

    // Remove accents
    const from = 'ãàáäâáèéëêìíïîõòóöôùúüûç';
    const to = 'aaaaaeeeeeiiiiooooouuuuc';
    for (let i = 0, l = from.length; i < l; i++) {
      string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return string;
  };

  const addSubTags = (event) => {
    event.preventDefault();
    if (event.target.value !== '') {
      event.target.value = parse(event.target.value);
      if (!subTags.includes(event.target.value)) {
        setSubTags([...subTags, event.target.value]);
      }
      event.target.value = '';
    }
  };
  const removeSubTags = (indexToRemove) => {
    setSubTags([...subTags.filter((_, index) => index !== indexToRemove)]);
  };

  const onKeyUpSubTags = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addSubTags(event);
    } else {
      return null;
    }
    return console.log(event);
  };

  const handleChange = (event) => {
    onChange({
      [event.target.name]: parse(event.target.value)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: e.target?.name?.value,
      sub_tags: subTags
    };

    if (data.name.length === 0) {
      errors.push('El nombre es obligatorio');
      setErrors(errors);
      return;
    }

    const response = edit
      ? await TagService.update(tag?.id, data)
      : await TagService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/etiquetas', { replace: true });
    } else {
      setErrors(response.errors);
    }
  };

  const deleteTag = async () => {
    if (tag?.id) {
      const response = await TagService.delete(tag.id);
      if (!response) {
        navigate('/app/etiquetas', { replace: true });
      }
    }
  };

  useEffect(() => {
    if (tag?.sub_tags) {
      setSubTags(tag?.sub_tags);
    } else {
      setSubTags([]);
    }
    // branchOffice?.tags ? setTags(branchOffice?.tags.split(',')) : setTags([]);
    // setTags(branchOffice?.tags.split(','));
  }, [tag]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Etiqueta"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el nombre"
                label="Nombre"
                name="name"
                onChange={handleChange}
                required
                value={tag?.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <div className="tags-input tags-container">
                <ul id="tags">
                  {subTags.map((subTag, index) => (
                    <li className="tag">
                      <span className="tag-title">{subTag}</span>
                      <span
                        className="tag-close-icon"
                        onClick={() => removeSubTags(index)}
                      >
                        x
                      </span>
                    </li>
                  ))}
                </ul>
                <input
                  type="text"
                  onKeyUp={onKeyUpSubTags}
                  onKeyPress={(e) => (e.key === 'Enter' && e.preventDefault())}
                  placeholder="Presione enter para agregar más palabras asociadas"
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteTag} />
    </form>
  );
};

CreateTagForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  tag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    sub_tags: PropTypes.array
  })
};

CreateTagForm.defaultProps = {
  className: '',
  edit: false,
  onChange: () => { },
  tag: {
    id: null,
    name: '',
    sub_tags: []
  },
};

export default CreateTagForm;
