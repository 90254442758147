import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useModal from 'src/hooks/useModal';
import {
  Box,
  Chip,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  makeStyles,
  TablePagination,
  TextField
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Check
} from '@material-ui/icons';
import PartnerLeadService from 'src/services/PartnerLeadService';
import Modal from 'src/components/Modal';
import ModalInfo from 'src/views/partner-leads/PartnerLeadListView/ModalInfo';
import TabFilters from 'src/components/TabFilters';

const useStyles = makeStyles((theme) => ({
  root: {},
  containerTabs: {
    display: 'flex'
  },
  moreVert: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd'
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  pending: {
    backgroundColor: '#FBC02D',
    color: 'white'
  },
  rejected: {
    backgroundColor: 'red',
    color: 'white'
  },
  accepted: {
    backgroundColor: '#43a047',
    color: 'white'
  },
  in_process: {
    backgroundColor: '#eee'
  },
  buttonInfo: {
    marginRight: '20px',
    color: '#fff',
    backgroundColor: '#43a047',
    borderRadius: '15px',
    width: '100%',
    height: '20px',
    fontSize: '10px'
  },
  buttonObs: {
    marginRight: '20px',
    color: '#fff',
    backgroundColor: '#43a047',
    borderRadius: '15px',
    width: '30%',
    height: '40px',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: '#ABAAAE',
      color: '#000'
    }
  },
  customRadio2: {
    '& input[type="radio"]': {
      WebkitAppearance: 'none',
      appearance: 'none'
    },
    backgroundColor: '#e8f1f6',
    borderRadius: '10px',
    border: 'none',
    display: 'flex',
    marginTop: '12px',
    marginLeft: '-11px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    height: 48,
    transition: '1s',
    '& input[type="radio"]:checked + $radioButton2': {
      '& $checked2': {
        backgroundColor: '#009739',
        border: 'none'
      },
      '& $icon2': {
        color: '#fff'
      }
    },
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;'
    }
  },
  radioButton2: {
    animation: '$scale 0.7s ease both',
    width: '220px',
    marginTop: '-4px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    cursor: 'pointer',
    borderRadius: 10,
    transition: 'border 0.5s ease'
  },
  checked2: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid #ccc'
  },
  icon2: {
    margin: 'auto',
    color: 'transparent',
    fontSize: 20
  }
}));

const Results = ({
  className,
  data,
  onRefresh,
  onChange,
  filters,
  filter,
  archived,
  setArchived,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [leads, setLeads] = useState([]);
  const [modal, setModal] = useState(false);
  const [lead, setLead] = useState(null);
  const { isOpen, toggle } = useModal();
  const [idObs, setIdObs] = useState(null);
  const [obs, setObs] = useState(null);
  const [admins, setAdmins] = useState(null);
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onClick = (e, admin) => {
    setAdmins(admin);
    setPanel(panel ? null : admin?.id);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  const updateStatus = async (status) => {
    if (!panel) return;

    const response = await PartnerLeadService.update(panel, { status });

    if (response?.code === 200 || response?.code === 201) {
      onRefresh();
    }
  };

  const handleSend = async (admin) => {
    toggle();
    setAdmins(admin);
    setIdObs(admin?.id);
  };

  const handleObs = async (e, id) => {
    e.preventDefault();
    const value = e?.target?.notes?.value;
    const update = {
      ...admins,
      notes: value
    };
    const response = await PartnerLeadService.update(id, update);
    if (response?.code === 200 || response?.code === 201) {
      onRefresh();
      toggle();
    }
  };

  const translate = (status) => {
    switch (status) {
      case 'IN_PROCESS':
        return 'En proceso';
      case 'ACCEPTED':
        return 'Aceptado';
      case 'REJECTED':
        return 'Rechazado';
      default:
        return 'Pendiente';
    }
  };

  const statusLead = (status) => {
    switch (status) {
      case 'IN_PROCESS':
        return 'in_process';
      case 'ACCEPTED':
        return 'accepted';
      case 'REJECTED':
        return 'rejected';
      default:
        return 'pending';
    }
  };

  const removeLead = async (admin) => {
    if (['in_process']?.includes(admin?.status)) {
      // eslint-disable-next-line
      alert('No es posible eliminar un registro que se encuentre en proceso');
      return;
    }

    const response = await PartnerLeadService.remove(admin?.id);

    if (!response?.code) {
      onRefresh();
    }
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setLeads(() => data);
    }
  }, [data]);
  const toggleModal = () => {
    setModal(!modal);
  };
  const handleModal = (admin) => {
    setLead(admin);
    toggleModal();
  };
  const handleArchived = () => {
    if (archived === true) {
      setArchived(!archived);
    } else if (archived === false) {
      setArchived(!archived);
    }
  };
  const optionArchived = async (e) => {
    e.preventDefault();
    const update = {
      ...admins,
      is_archived: !admins?.is_archived
    };
    const response = await PartnerLeadService.update(panel, update);
    if (response?.code === 200 || response?.code === 201) {
      onRefresh();
    }
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box height="100%" py={2} display="flex" flexDirection="column">
        <Box className={classes.containerTab}>
          <Box width="800px" display="flex" flexDirenction="row">
            <TabFilters
              filters={filters}
              selectedFilter={filter}
              haveCountData
              onChange={onChange}
            />
            <Box bgColor="red" left="-400px">
              <label className={classes.customRadio2} htmlFor="Archivado">
                <input
                  type="radio"
                  name="archived"
                  id="Archivado"
                  value="Archivado"
                  onClick={handleArchived}
                  checked={archived}
                />
                <Box
                  className={classes.radioButton2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="30%"
                  height={50}
                  pt={1}
                  px={1}
                  mt={2}
                  m={1}
                >
                  <Box
                    className={classes.checked2}
                    width={30}
                    height={30}
                    display="flex"
                    justifyContent="center"
                  >
                    <Check className={classes.icon2} />
                  </Box>
                  <Box
                    mt={-2.9}
                    ml={3}
                    display="flex"
                    flexDirection="row"
                    alignSelf="center"
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 13
                      }}
                      variant="body2"
                    >
                      Archivados
                    </Typography>
                  </Box>
                </Box>
              </label>
            </Box>
          </Box>
        </Box>
        <PerfectScrollbar>
          <Box minWidth={1000}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="150">Nombre</TableCell>
                  <TableCell width="170">Empresa</TableCell>
                  <TableCell>Tipo de empresa</TableCell>
                  <TableCell>Ciudad</TableCell>
                  <TableCell width="80">Estado</TableCell>
                  <TableCell width="80">Observaciones</TableCell>
                  <TableCell width="5" />
                  <TableCell width="5" />
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.slice(0, limit).map((admin) => (
                  <TableRow hover key={admin?.id}>
                    <TableCell width="150">
                      <Typography color="textPrimary" variant="body2">
                        {admin?.name}
                      </Typography>
                    </TableCell>
                    <TableCell width="170">
                      <Typography color="textPrimary" variant="body2">
                        {admin?.social_reason}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="body2">
                        {admin?.bussines_type}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="textPrimary" variant="body2">
                        {admin?.city2?.name}
                      </Typography>
                    </TableCell>
                    <TableCell width="80">
                      <Chip
                        label={translate(
                          admin?.status?.toString()?.toUpperCase()
                        )}
                        className={
                          classes?.[
                            statusLead(admin?.status?.toString()?.toUpperCase())
                          ]
                        }
                      />
                    </TableCell>
                    <TableCell className={classes?.statusCell} width="5">
                      <Button
                        className={classes?.buttonInfo}
                        onClick={() => handleSend(admin)}
                      >
                        Observaciones
                      </Button>
                    </TableCell>
                    <TableCell className={classes?.statusCell} width="5">
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, admin)}
                      />
                    </TableCell>
                    <TableCell className={classes?.statusCell} width="5">
                      <DeleteIcon
                        className={classes?.moreVert}
                        onClick={() => removeLead(admin)}
                      />
                    </TableCell>
                    <TableCell width="5">
                      <Button
                        className={classes?.buttonInfo}
                        onClick={() => handleModal(admin)}
                      >
                        Más info.
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={leads.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          labelRowsPerPage="Filas por página"
        />
      </Box>
      {panel !== null && (
        <div
          className={classes?.statusPanel}
          style={{
            top: panelPosition?.y,
            left: panelPosition?.x - 120
          }}
        >
          <ul className={classes?.statusPanelList}>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => updateStatus('pending')}>Pendiente</a>
            </li>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => updateStatus('in_process')}>En proceso</a>
            </li>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => updateStatus('rejected')}>Rechazado</a>
            </li>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => updateStatus('accepted')}>Aceptado</a>
            </li>
            <li className={classes?.statusPanelItem}>
              <a onClick={(e) => optionArchived(e)}>
                {admins?.is_archived ? 'Desarchivar' : 'Archivar'}
              </a>
            </li>
          </ul>
        </div>
      )}
      <Modal isOpen={isOpen} toggle={toggle} title="Agregar observaciones">
        <form onSubmit={(e) => handleObs(e, idObs)}>
          <TextField
            fullWidth
            label="Observaciones"
            margin="normal"
            value={obs}
            onchange={(e) => setObs(e.target.value)}
            name="notes"
          />
          <Button className={classes.buttonObs} type="submit">
            Guardar
          </Button>
        </form>
      </Modal>

      {modal && <ModalInfo toggleModal={toggleModal} lead={lead} />}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  onRefresh: PropTypes.func,
  onUpdate: PropTypes.func,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  filter: PropTypes.object,
  filters: PropTypes.array,
  archived: PropTypes.bool,
  setArchived: PropTypes.bool,
  optionArchived: PropTypes.func
};

Results.defaultProps = {
  className: '',
  onRefresh: () => null
};

export default Results;
