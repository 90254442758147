import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useQueryParams from 'src/hooks/useQueryParams';
import LoadingBox from 'src/components/LoadingBox';
import CreateZoneForm from './CreateZoneForm';
import ZoneService from '../../../services/ZonesService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateZoneView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zone, setZone] = useState({
    id: null,
    name: '',
    description: '',
    city_id: '',
  });
  const queryParams = useQueryParams();
  const typeForm = queryParams.get('type');

  const inputRef = useRef();

  const handleZoneAttributes = (values) => {
    setZone({
      ...zone,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await ZoneService.getById(id);
        if (response?.code === 200) {
          setZone({
            ...response.data,
            id: response.data.id,
            name: response.data.name,
            description: response.data.description,
            city_id: response.data.avatar,
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page
        className={classes.root}
        title="Crear Zona"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <CreateZoneForm
                ref={inputRef}
                edit={edit}
                hasImage={hasImage}
                zone={zone}
                onChange={handleZoneAttributes}
                setLoading={setLoading}
                typeForm={typeForm}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateZoneView.propTypes = {
  edit: PropTypes.bool,
};

CreateZoneView.defaultProps = {
  edit: false
};

export default CreateZoneView;
