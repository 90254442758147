import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import CurrencyFormat from 'react-currency-format';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Chip,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Switch,
  TableRow,
  Typography,
  Tooltip,
  makeStyles, TablePagination, IconButton, Button
} from '@material-ui/core';
import OrderService from 'src/services/OrderService';
import {
  MoreVert as MoreVertIcon,
  ListAlt as ListAltIcon,
  DirectionsBike,
  Refresh,
  SwapHoriz
} from '@material-ui/icons';
// utils
import orderBy from 'src/utils/arrays';
// import Timer from 'src/components/Timer';
// service

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  pending: {
    backgroundColor: '#FBC02D',
    color: 'white',
  },
  rejected: {
    backgroundColor: 'red',
    color: 'white'
  },
  accepted: {
    backgroundColor: '#43a047',
    color: 'white',
  },
  finished: {
    backgroundColor: '#1565C0',
    color: 'white',
  },
  in_process: {
    backgroundColor: '#eee'
  },
  sent: {
    color: 'white',
    backgroundColor: '#7B1FA2',
  },
  buttonIcon: {
    borderRadius: '50%',
    minWidth: 10,
    padding: 5,
  },
  openTab: {
    color: '#fff',
    padding: '5px 9px',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '50px'
  },
  iconSwap: {
    fontSize: 15,
    color: '#fff'
  },
  bounce: {
    animation: '$bounce 0.5s both'
  },
  rotation: {
    animationName: '$rotation',
    animationDuration: '1s',
    animationTimingFunction: 'ease-out'
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)'
    },
    '40%': {
      transform: 'translateY(-15px)'
    },
    '60%': {
      transform: 'translateY(-15px)'
    },
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}));

const Results = ({
  className,
  data,
  onRefresh,
  toggleSentModal,
  toggleDetailModal,
  toggleInProgressModal,
  setOrder,
  statusOrder,
  translate,
  ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [orders, setOrders] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(dayjs(new Date()).format('ddd MMM D YYYY hh:mm:ss a'));
  const [advice, setAdvice] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);
  const [rotation, setRotation] = useState(false);
  const [auto, setAuto] = useState(true);
  console.log(setAdvice)

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleClose = () => {
    setShowToolTip(false);
  };

  const handleOpen = () => {
    setShowToolTip(true);
  };

  const updateStatus = async (status) => {
    if (!panel) return;

    const response = await OrderService.updateStatus(panel?.id, { status });

    if (response?.success) {
      onRefresh();
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onClick = (e, order) => {
    setPanel(panel ? null : order);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  const translatePaymentMethod = (method) => {
    switch (method) {
      case 'cash':
        return 'Efectivo';
      case 'card':
        return 'Tarjeta';
      case 'datafono':
        return 'Datafono';
      default:
        return method;
    }
  };

  const getHour = (hour) => {
    const hh = dayjs(hour).get('hour');
    const mm = dayjs(hour).get('minute');

    // return `${hh}:${mm < 10 ? `0${mm}` : mm}`;
    return `${hh < 13 ? hh : hh - 12}:${mm < 10 ? `0${mm}` : mm} ${hh < 12 ? 'AM' : 'PM'}`;
  };

  const refreshTable = () => {
    setRotation(true);
    setTimeout(() => {
      setRotation(false);
    }, 1000);
    onRefresh();
    const format = dayjs(new Date()).format('ddd MMM D YYYY hh:mm:ss a');
    setLastUpdate(format);
  };

  // const startTimer = () => {
  //   setInterval(() => {
  //     setAdvice(true);
  //     setShowToolTip(true);
  //     setTimeout(() => {
  //       setAdvice(false);
  //       setShowToolTip(false);
  //     }, 5000);
  //   }, 60 * 1000);
  // };

  // useEffect(() => {
  //   startTimer();
  // }, []);

  useEffect(() => {
    if (showTooltip && auto) onRefresh();
  }, [showTooltip]);

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setOrders(() => data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box width={1400}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: 'none', paddingBottom: 0, paddingRight: 0 }}>
                    <Tooltip open={showTooltip} onClose={handleClose} onOpen={handleOpen} title={`Ultima actualización: ${lastUpdate}`} arrow placement="top">
                      <Button
                        onClick={() => refreshTable()}
                        className={clsx(
                          classes.buttonIcon,
                          { [classes.bounce]: advice, [classes.rotation]: rotation }
                        )}
                        color="primary"
                        variant="contained"
                      >
                        <Refresh style={{ fontSize: '18px' }} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell width={250} style={{ border: 'none', paddingBottom: 0, paddingRight: 0 }}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body">Actualización automatica</Typography>
                      <Switch
                        color="primary"
                        checked={auto}
                        onChange={() => setAuto(!auto)}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell width={100}>
                    Referencia
                  </TableCell>
                  <TableCell width={250}>
                    Comercio
                  </TableCell>
                  <TableCell width={150}>
                    Metodo de pago
                  </TableCell>
                  <TableCell width="110" />
                  <TableCell width="100">
                    Inicio
                  </TableCell>
                  <TableCell width="100">
                    Cierre
                  </TableCell>
                  <TableCell width="120">
                    Monto
                  </TableCell>
                  {data?.some((item) => (((item?.status === 'in_progress' || item?.status === 'pending_to_be_sent') && item?.ship_type === 'adomi')) || (item?.status === 'created')) && (
                    <TableCell width="120">
                      Temporizador
                    </TableCell>
                  )}
                  <TableCell width="120">
                    Estado
                  </TableCell>
                  <TableCell width="20" />
                  <TableCell width="20" />
                  <TableCell width="20" />
                  <TableCell width="20" />
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(orders, 'name').slice(0, limit).map((order) => (
                  <TableRow
                    hover
                    key={order?.id}
                  >
                    <TableCell style={{ paddingRight: '5px' }}>
                      <span
                        className={classes.openTab}
                        style={{
                          backgroundColor: order?.type === 'logistic' ? '#43a047' : 'none'
                        }}
                      >
                        {order?.type === 'logistic' ? 'Logistic' : ''}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Box
                          width={44}
                        >
                          <Typography
                            color="textPrimary"
                            variant="body2"
                          >
                            #
                            {order?.reference}
                          </Typography>
                        </Box>
                        {order?.roundtrip && (
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                          >
                            <Box
                              borderRadius="50%"
                              border="3px solid #97D4AE"
                              display="flex"
                              flexGrow="2"
                              justifyContent="center"
                              alignItems="center"
                              width={26}
                              height={26}
                              bgcolor="#009739"
                              ml={1.5}
                            >
                              <SwapHoriz className={classes.iconSwap} />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {order?.data_branch_office?.join_name ? `${order?.data_branch_office?.brand?.name} ${order?.data_branch_office?.name}` : order?.data_branch_office?.brand?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {translatePaymentMethod(order?.payment_method)}
                      </Typography>
                    </TableCell>
                    <TableCell width="20" />
                    <TableCell>
                      <Typography variant="body2">
                        {getHour(order?.created_at)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {order?.status === 'finished' ? getHour(order?.updated_at) : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        $
                        <CurrencyFormat value={order?.total} displayType="text" thousandSeparator="." decimalSeparator="," />
                      </Typography>
                    </TableCell>
                    {/* {data?.some((item) => (((item?.status === 'in_progress' || item?.status === 'pending_to_be_sent') && item?.ship_type === 'adomi')) || (item?.status === 'created')) && (
                      <TableCell>
                        {(((order?.status === 'in_progress' || order?.status === 'pending_to_be_sent') && order?.ship_type === 'adomi') || order?.status === 'created') && (
                          <Timer
                            order={order}
                            increase={order?.status === 'pending_to_be_sent'}
                            isPending={order?.status === 'created'}
                            refresh={onRefresh}
                          />
                        )}
                      </TableCell>
                    )} */}
                    <TableCell>
                      <Chip
                        label={translate(order?.status?.toString(), order?.ship_type)}
                        className={classes?.[
                          statusOrder(order?.status?.toString()?.toUpperCase(), order?.ship_type)
                        ]}
                      />
                    </TableCell>
                    <TableCell>
                      {order?.data_branch_office?.is_develop && (
                        <Chip label="Desarrollo" className={classes?.in_process} />
                      )}
                      {order?.data_branch_office?.is_demo && (
                        <Box ml={2}>
                          <Chip label="Demo" className={classes?.in_process} />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <IconButton
                        onClick={() => {
                          setOrder(order);
                          toggleSentModal();
                        }}
                        disabled={order?.status !== 'in_progress' && order?.status !== 'pending_to_be_sent'}
                      >
                        <DirectionsBike
                          style={{
                            color: (order?.status === 'in_progress' || order?.status === 'pending_to_be_sent') && order?.ship_type === 'adomi' ? '#000' : 'transparent'
                          }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <ListAltIcon
                        className={classes?.moreVert}
                        onClick={() => {
                          setOrder(order);
                          toggleDetailModal();
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, order)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={orders.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {
        panel !== null && (
          <div
            className={classes?.statusPanel}
            style={{
              top: panelPosition?.y,
              left: panelPosition?.x - 120
            }}
          >
            <ul className={classes?.statusPanelList}>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('created')}>
                  Pendiente
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a
                  onClick={() => {
                    setOrder(panel);
                    toggleInProgressModal();
                  }}
                >
                  En progreso
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('sent')}>
                  Enviada
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('finished')}>
                  Completar
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('rejected')}>
                  Rechazar
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => updateStatus('cancelled')}>
                  Cancelar
                </a>
              </li>
            </ul>
          </div>
        )
      }
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  onRefresh: PropTypes.func,
  data: PropTypes.array.isRequired,
  toggleSentModal: PropTypes.func,
  toggleDetailModal: PropTypes.func,
  toggleInProgressModal: PropTypes.func,
  setOrder: PropTypes.func,
  statusOrder: PropTypes.func,
  translate: PropTypes.func
};

Results.defaultProps = {
  className: '',
  onRefresh: () => null,
};

export default Results;
