import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useSearch from 'src/hooks/useSearch';
import Toolbar from './Toolbar';
import Results from './Results';
import PartnerService from '../../../services/PartnerService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  PartnerCard: {
    height: '100%'
  }
}));

const PartnerList = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [partners, setPartners] = useState([]);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['brand.name', 'first_name', 'last_name', 'phone', 'email'] });

  const foo = async () => {
    const fetchData = typeof id !== 'undefined'
      ? await PartnerService.getById(id)
      : await PartnerService.getAll();

    if (fetchData?.code === 200) {
      if (id) {
        setPartners([fetchData.data]);
        handleInitialData([fetchData?.data]);
      } else {
        setPartners(fetchData.data);
        handleInitialData(fetchData?.data);
      }
    }
  };

  useEffect(() => {
    foo();
  }, []);

  useEffect(() => {
    if (id && id?.length > 0) {
      foo();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Aliados"
    >
      <Container maxWidth={false}>
        {partners?.length > 0 && <Toolbar onSearch={handleSearch} />}
        <Box mt={3}>
          <Results data={dataFilterd} />
        </Box>
      </Container>
    </Page>
  );
};

export default PartnerList;
