import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSearch from 'src/hooks/useSearch';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import TermsAndConditionsService from 'src/services/TermsAndConditionsService';
import WarningModal from 'src/components/WarningModal';
import ConfirmationModal from 'src/components/ConfirmationModal';
import Toolbar from './Toolbar';
import TermsCard from './TermsCard';
import { selector as UserSelector } from '../../../redux/ducks/user';
import CreateTermsModal from '../CreateTermsModal/CreateTermsModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  termsCard: {
    height: '100%'
  }
}));

const TermsListView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState({ id: null, title: '', description: '' });
  const [showModal, setShowModal] = useState(false);
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [edit, setEdit] = useState(false);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['title'] });

  const toggleModalCreate = (isEdit) => {
    setShowModal(!showModal);
    setEdit(isEdit);
  };

  const showWarning = (flag) => {
    setShowModalWarning(flag);
  };

  const getTerms = async () => {
    try {
      const response = await TermsAndConditionsService.getAll();
      if (response?.code === 200 && response?.data?.length > 0) {
        setTerms(response?.data);
        handleInitialData(response?.data);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const refresh = async () => {
    if (user?.id) {
      await getTerms();
    }
  };

  const onSave = () => {
    refresh();
  };

  const onEdit = () => {
    refresh();
  };

  const handleTerm = (term) => {
    setSelectedTerm(term);
  };

  const onAccept = async () => {
    try {
      const response = await TermsAndConditionsService.remove(selectedTerm?.id);
      if (response?.data === null) {
        showWarning(true);
      } else {
        refresh();
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getTerms();
    }
  }, [user]);

  useEffect(() => {
    if (Array.isArray(dataFilterd)) {
      setTerms(dataFilterd);
    }
  }, [dataFilterd]);

  return (
    <Page
      className={classes.root}
      title="Terminos y condiciones"
    >
      <Container maxWidth={false}>
        <Toolbar toggleModal={toggleModalCreate} onSearch={handleSearch} refresh={refresh} />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            {terms.map((item, index) => (
              <Grid
                item
                key={item.id}
                lg={4}
                md={6}
                xs={12}
              >
                <TermsCard
                  className={classes.termsCard}
                  term={item}
                  index={index}
                  toggleModalCreate={toggleModalCreate}
                  setTerm={handleTerm}
                  showWarning={showWarning}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            color="primary"
            count={3}
            size="small"
          />
        </Box>
      </Container>
      {showModal && (
        <CreateTermsModal
          edit={edit}
          term={selectedTerm}
          onSave={onSave}
          onEdit={onEdit}
          toggleModal={toggleModalCreate}
        />
      )}
      {showModalWarning && (
        <WarningModal
          showWarning={showWarning}
          text="No se puede eliminar este registro debido a que esta referenciado en uno o varios cupones"
        />
      )}
      <ConfirmationModal onAccept={onAccept} />
    </Page>
  );
};

export default TermsListView;
