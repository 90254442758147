import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import CountryService from '../../../services/CountryService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateCountryForm = ({
  className, country, edit, onChange, hasImage, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();

  const handleChange = (event) => {
    onChange({
      ...country,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: e.target?.name?.value,
      flag: e.target?.flag?.value,
      code: Number(e.target?.code.value),
      acronym: e.target?.acronym?.value === '0' ? null : e.target?.acronym?.value
    };

    if (data.name.length === 0) {
      errors.push('El nombre del país es obligatorio');
    }
    if (!data.flag || data.flag.length === 0) {
      errors.push('La bandera es obligatoria');
    }

    if (data.name.length === 0
      || data.acronym === ''
      || !data.flag || data.flag.length === 0) {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await CountryService.update(country.id, data)
      : await CountryService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/paises', { replace: true });
    } else {
      setErrors(response.errors);
    }
  };

  const deleteCountry = async () => {
    if (country?.id) {
      const response = await CountryService.delete(country.id);
      if (!response) {
        navigate('/app/paises', { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="País"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el nombre"
                label="Nombre"
                name="name"
                onChange={handleChange}
                required
                value={country.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el código telefónico"
                label="Código"
                name="code"
                onChange={handleChange}
                required
                value={country.code}
                variant="outlined"
                type="Number"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el acrónimo"
                label="Acrónimo"
                name="acronym"
                onChange={handleChange}
                required
                value={country.acronym}
                variant="outlined"
              />
            </Grid>
            {
              hasImage && (
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Bandera"
                    name="flag"
                    onChange={handleChange}
                    required
                    value={country.flag}
                    variant="outlined"
                  />
                </Grid>
              )
            }
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            { edit ? 'Editar' : 'Guardar' }
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteCountry} />
    </form>
  );
};

CreateCountryForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  country: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    flag: PropTypes.string,
    code: PropTypes.number,
    acronym: PropTypes.string
  })
};

CreateCountryForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => {},
  country: {
    id: null,
    name: '',
    flag: '',
    code: '',
    acronym: ''
  },
};

export default CreateCountryForm;
