import HttpRequest from './HttpRequest';

export default class NotificationService extends HttpRequest {
  static endpoint = 'notifications';

  static async sendToAppInGeneral(app, data) {
    this.endpoint = `notifications/general?app=${app}`;
    const response = await this.post(data);
    return response;
  }

  static async sendByTopic(data) {
    this.endpoint = 'notifications/topic';
    const response = await this.post(data);
    return response;
  }
}
