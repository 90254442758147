import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import CancellationReasonsService from '../../../services/CancellationReasonsService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  },
  menuItem: {
    display: 'flex',
    flexFlow: 'column',
    whiteSpace: 'normal',
    paddingBottom: '.5rem',
    '& p': {
      width: '100%',
      display: 'block'
    }
  }
}));

const CreateCancellationReasonsForm = ({
  className, CancellationReasons, edit, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      title: e.target?.title?.value,
      description: e.target?.description?.value,
    };

    if (data?.title.length === 0) {
      errors.push('El titulo es obligatorio');
    }

    if (data?.description.length === 0) {
      errors.push('La descripción es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await CancellationReasonsService.update(CancellationReasons?.id, data)
      : await CancellationReasonsService.create(data);

    console.log(response);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/razones-cancelacion', { replace: true });
    } else {
      setErrors(response.errors);
    }
  };

  const deleteCancellationReasons = async () => {
    if (CancellationReasons?.id) {
      const response = await CancellationReasonsService.remove(CancellationReasons?.id);
      if (!response) {
        navigate('/app/razones-cancelacion', { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Razón de cancelación"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={2}
                variant="outlined"
                autoComplete="off"
                placeholder="Escriba el titulo"
                label="Titulo"
                name="title"
                onChange={handleChange}
                required
                value={CancellationReasons?.title}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={3}
                variant="outlined"
                autoComplete="off"
                placeholder="Escriba la descripción"
                label="Descripción"
                name="description"
                onChange={handleChange}
                required
                value={CancellationReasons?.description}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteCancellationReasons} />
    </form>
  );
};

CreateCancellationReasonsForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  CancellationReasons: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  })
};

CreateCancellationReasonsForm.defaultProps = {
  className: '',
  edit: false,
  onChange: () => { },
  CancellationReasons: {
    id: null,
    title: '',
    description: ''
  },
};

export default CreateCancellationReasonsForm;
