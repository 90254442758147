import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import FaqCardPreview from './FaqCardPreview';
import CreateFaqForm from './CreateFaqForm';
import FAQService from '../../../services/FAQService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateFaqView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [faq, setFaq] = useState({
    id: null,
    title: '',
    description: '',
    faq_category: '',
    city_id: '',
    order: '',
  });

  const handleFaqAttributes = (values) => {
    setFaq({
      ...faq,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await FAQService.getById(id);
        if (response?.code === 200) {
          setFaq({
            ...response.data
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Pregunta frecuente"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <FaqCardPreview
              FAQ={faq}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateFaqForm
              edit={edit}
              FAQ={faq}
              onChange={handleFaqAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateFaqView.propTypes = {
  edit: PropTypes.bool,
};

CreateFaqView.defaultProps = {
  edit: false
};

export default CreateFaqView;
