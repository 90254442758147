import { Box, Card, Typography, makeStyles, Checkbox } from '@material-ui/core';
import LoadingBox from 'src/components/LoadingBox';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from 'src/components/Modal';
import TemplateService from 'src/services/TemplateService';
import useTemplateListContext from 'src/hooks/useTemplateListContext';
import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%'
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const ModalTemplates = ({ isOpen, toggle }) => {
  const classes = useStyles();
  const { token } = useSelector(UserSelector);
  const [templates, setTemplates] = useState([]);
  const {
    selectedTemplates,
    onChangeSelectedTemplates
  } = useTemplateListContext();

  const handleChange = (event, template) => {
    const { checked } = event.target;
    if (checked) {
      const aux = selectedTemplates.slice();
      aux.push({
        id: template.id,
        name: template.name,
        is_active: false
      });
      onChangeSelectedTemplates(aux);
      return;
    }
    onChangeSelectedTemplates(
      selectedTemplates.filter(
        (selectTemplate) => selectTemplate.id !== template.id
      )
    );
  };

  const getTemplates = async () => {
    const response = await TemplateService.getAll();
    if (response.code === 200) {
      setTemplates(response.data);
    }
  };

  useEffect(() => {
    if (token !== null) {
      getTemplates();
    }
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} title="Asociar templates">
      <>
        <LoadingBox loading={templates.length === 0}>
          <Box
            display="flex"
            gridGap={10}
            p={1}
            className={classes.scroll}
            flexDirection="column"
            width="100%"
          >
            <Box display="flex" gridGap={8} flexDirection="column" width="100%">
              {templates.map((template) => (
                <Card className={classes.card} key={template.id}>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    px={2}
                    justifyContent="space-between"
                  >
                    <Typography>{template.name}</Typography>
                    <Checkbox
                      color="primary"
                      onChange={(e) => handleChange(e, template)}
                      checked={selectedTemplates.some(
                        (selectedTemplate) =>
                          selectedTemplate.id === template.id
                      )}
                    />
                  </Box>
                </Card>
              ))}
            </Box>
          </Box>
        </LoadingBox>
      </>
    </Modal>
  );
};

ModalTemplates.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default ModalTemplates;
