import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  Card,
  Collapse,
  makeStyles,
  Typography
} from '@material-ui/core';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import { HiChatAlt2 } from 'react-icons/hi';
import { selector as ChatSelector } from '../../../redux/ducks/chat';
import Profile from './components/Profile';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '40%',
    boxShadow: 'none',
    borderRadius: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflow: 'scroll',
  },
  arrowIcon: {
    fontSize: 23,
    transition: 'transform 0.3s ease 0s',
    transform: 'rotate(0deg)'
  },
  titleFilter: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 5,
  },
  time: {
    fontWeight: 600,
    fontSize: 11
  },
  dropdown: {
    transform: 'rotate(180deg)',
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const ListChats = ({
  setChat,
}) => {
  const classes = useStyles();
  const { pendingChats, activeChats, finishedChats } = useSelector(ChatSelector);
  const [showChatsPending, setShowChatsPending] = useState(true);
  const [showChatsActive, setShowChatsActive] = useState(true);
  const [showChatsFinish, setShowChatsFinish] = useState(false);

  return (
    <Card
      className={clsx(classes.root, classes.scroll)}
    >
      <Box
        display="flex"
        alignItems="center"
        px={2}
      >
        <HiChatAlt2
          size={30}
          color="009739"
        />
        <Typography
          variant="h4"
          style={{ marginLeft: 5, fontWeight: 600 }}
        >
          Chats
        </Typography>
      </Box>
      <Box
        mt={2}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
          style={{ cursor: 'pointer' }}
          width="fit-content"
          onClick={() => setShowChatsPending((prev) => !prev)}
          px={2}
          mb={1}
        >
          <Box
            mt={0.3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#009739"
            color="#fff"
            borderRadius="50%"
            width={23}
            height={23}
            p={1}
          >
            <Typography
              variant="body2"
              className={classes.time}
            >
              {pendingChats.length}
            </Typography>
          </Box>
          <Typography
            className={classes.titleFilter}
            variant="body2"
          >
            Pendientes
          </Typography>
          <KeyboardArrowDownRounded
            className={clsx(
              classes.arrowIcon,
              {
                [classes.dropdown]: showChatsPending
              }
            )}
          />
        </Box>
        <Collapse in={showChatsPending} mountOnEnter unmountOnExit>
          {pendingChats.map((chat) => (
            <Profile
              key={chat?.id}
              chat={chat}
              setChat={setChat}
            />
          ))}
        </Collapse>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
          my={1}
          px={2}
          style={{ cursor: 'pointer' }}
          width="fit-content"
          onClick={() => setShowChatsActive((prev) => !prev)}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#009739"
            color="#fff"
            borderRadius="50%"
            width={23}
            height={23}
            p={1}
          >
            <Typography
              variant="body2"
              className={classes.time}
            >
              {activeChats.length}
            </Typography>
          </Box>
          <Typography
            className={classes.titleFilter}
            variant="body2"
          >
            Activos
          </Typography>
          <KeyboardArrowDownRounded
            className={clsx(
              classes.arrowIcon,
              {
                [classes.dropdown]: showChatsActive
              }
            )}
          />
        </Box>
        <Collapse style={{ width: '100%' }} in={showChatsActive}>
          {activeChats.map((chat) => (
            <Profile
              key={chat?.id}
              chat={chat}
              setChat={setChat}
            />
          ))}
        </Collapse>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
          my={1}
          px={2}
          style={{ cursor: 'pointer' }}
          width="fit-content"
          onClick={() => setShowChatsFinish((prev) => !prev)}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#009739"
            color="#fff"
            borderRadius="50%"
            width={23}
            height={23}
            p={1}
          >
            <Typography
              variant="body2"
              className={classes.time}
            >
              {finishedChats.length}
            </Typography>
          </Box>
          <Typography
            className={classes.titleFilter}
            variant="body2"
          >
            Finalizados
          </Typography>
          <KeyboardArrowDownRounded
            className={clsx(
              classes.arrowIcon,
              {
                [classes.dropdown]: showChatsFinish
              }
            )}
          />
        </Box>
        <Collapse style={{ width: '100%' }} in={showChatsFinish}>
          {finishedChats.map((chat) => (
            <Profile
              key={chat?.id}
              chat={chat}
              setChat={setChat}
            />
          ))}
        </Collapse>
      </Box>
    </Card>
  );
};

export default ListChats;

ListChats.propTypes = {
  setChat: PropTypes.func,
};
