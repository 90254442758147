import HttpRequest from './HttpRequest';

export default class TutorialsService extends HttpRequest {
  static endpoint = 'tutorials';

  static async getAll() {
    this.endpoint = 'tutorials';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'tutorials';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'tutorials';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'tutorials';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'tutorials';
    const response = await this.delete(id);
    return response;
  }

  static async uploadImage(data) {
    this.endpoint = 'tutorials/upload-preview-image';
    const response = await this.post(data);
    return response;
  }

  static async deleteImage(data) {
    this.endpoint = 'tutorials/delete-preview-image';
    const response = await this.delete(data);
    return response;
  }
}
