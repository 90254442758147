import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  Grid,
  CardContent,
  Typography,
  makeStyles, Avatar, Divider, CardActions, Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  }
}));

const TutorialCardPreview = forwardRef(({
  className, tutorial, onUpload, hasImage, ...rest
}, ref) => {
  const classes = useStyles();
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    ref?.current?.addEventListener('change', () => {
      setPreviewImage(URL.createObjectURL(ref?.current?.files[0]));
    });
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <a
              href={tutorial?.url}
              target="_blank"
              rel="noreferrer"
            >
              <Avatar
                className={classes.avatar}
                alt="tutorial"
                src={previewImage || tutorial?.preview_image}
                variant="square"
              />
            </a>
          </Grid>
          <Grid item xs={9}>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {tutorial?.title}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="caption"
            >
              {tutorial?.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {
        !hasImage && (
          <>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                fullWidth
                variant="text"
                onClick={onUpload}
              >
                Subir Imagen
              </Button>
            </CardActions>
          </>
        )
      }
    </Card>
  );
});

TutorialCardPreview.propTypes = {
  className: PropTypes.string,
  tutorial: PropTypes.object,
  hasImage: PropTypes.bool,
  onUpload: PropTypes.func,
};

TutorialCardPreview.defaultProps = {
  className: '',
  tutorial: {},
  hasImage: false,
  onUpload: () => {},
};

export default TutorialCardPreview;
