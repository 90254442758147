import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import LoadingBox from 'src/components/LoadingBox';
import CreateTemplateForm from './CreatePageTemplateForm';
import TemplateCardPreview from './PageTemplateCardPreview';
import PageTemplateService from '../../../services/PageTemplateService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreatePageTemplateView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [pageTemplate, setPageTemplate] = useState({});
  const [loading, setLoading] = useState(false);

  const handleTemplateAttributes = (values) => {
    setPageTemplate({
      ...pageTemplate,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await PageTemplateService.getById(id);
        if (response.code === 200) {
          setPageTemplate({
            id: response.data.id,
            title: response.data.title,
            path_name: response.data.path_name,
            name: response.data.name
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page className={classes.root} title="Crear pagina">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <TemplateCardPreview pageTemplate={pageTemplate} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <CreateTemplateForm
                edit={edit}
                pageTemplate={pageTemplate}
                onChange={handleTemplateAttributes}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreatePageTemplateView.propTypes = {
  edit: PropTypes.bool
};

CreatePageTemplateView.defaultProps = {
  edit: false
};

export default CreatePageTemplateView;
