import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CouponCardPreview from './CouponCardPreview';
import CreateCouponForm from './CreateCouponForm';
import CouponService from '../../../services/CouponService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateCouponView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [coupon, setCoupons] = useState({
    id: null,
    branch_office_id: '',
    brand_id: '',
    client_id: '',
    description: '',
    key: '',
    title: '',
    type: '',
    value: '',
    started_at: '',
    expired_at: '',
    term_and_conditions_id: '',
    city_id: ''
  });

  const handleBannerAttributes = (values) => {
    setCoupons({
      ...coupon,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await CouponService.getById(id);
        if (response?.code === 200) {
          setCoupons({
            ...response.data
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Cupón"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <CouponCardPreview
              coupon={coupon}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateCouponForm
              edit={edit}
              coupon={coupon}
              onChange={handleBannerAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateCouponView.propTypes = {
  edit: PropTypes.bool,
};

CreateCouponView.defaultProps = {
  edit: false
};

export default CreateCouponView;
