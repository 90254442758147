import React, { useState, useRef, useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import LoadingBox from 'src/components/LoadingBox';
import TemplateListProvider from 'src/context/TemplateListContext/TemplateListProvider';
import BrandCardPreview from './BrandCardPreview';
import CreateBrandForm from './CreateBrandForm';
import BrandService from '../../../services/BrandService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateBrandView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [brand, setBrand] = useState({});
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();

  const handleBrandAttributes = (values) => {
    setBrand({
      ...brand,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await BrandService.getById(id);
        if (response?.code === 200) {
          setBrand({
            id: response.data.id,
            name: response.data.name,
            logo: response.data.logo,
            description: response.data.description,
            slug: response?.data?.slug,
            google_analytics_id: response.data.google_analytics_id,
            facebook_pixel_id: response.data.facebook_pixel_id,
            hotjar_id: response.data.hotjar_id
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page className={classes.root} title="Crear marca">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <BrandCardPreview
                ref={inputRef}
                brand={brand}
                hasImage={hasImage}
                onUpload={handleUploadImage}
              />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <TemplateListProvider>
                <CreateBrandForm
                  ref={inputRef}
                  edit={edit}
                  brand={brand}
                  hasImage={hasImage}
                  onChange={handleBrandAttributes}
                  setLoading={setLoading}
                />
              </TemplateListProvider>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateBrandView.propTypes = {
  edit: PropTypes.bool
};

CreateBrandView.defaultProps = {
  edit: false
};

export default CreateBrandView;
