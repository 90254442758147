import React, { useState, useEffect, useRef } from 'react';
import pusher from 'src/utils/pusher';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getHour } from 'src/utils/formatDate';
import {
  Avatar,
  Box,
  Button,
  Card,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ArrowDownwardRounded } from '@material-ui/icons';
import ChatSupportService from 'src/services/ChatSupportService';
import getInitials from 'src/utils/getInitials';
import Message from './components/Message';
import InputText from './components/InputText';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '60%',
    boxShadow: 'none',
    borderRadius: 10,
    marginLeft: theme.spacing(2),
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backdropFilter: 'blur(5px)',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    wordBreak: 'break-word',
    marginLeft: theme.spacing(1)
  },
  button: {
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: 12,
    borderRadius: 50
  },
  buttonBottom: {
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: 17,
    padding: theme.spacing(0.7),
    width: '100%',
    height: 'fit-content',
    borderRadius: 100
  },
  buttonFinish: {
    backgroundColor: '#dc3545',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#c9303f',
      color: 'white',
    }
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  arrowIcon: {
    animation: '$point 1.25s infinite alternate',
    color: '#FFFFFF',
    fontSize: 22,
  },
  '@keyframes point': {
    '0%': {
      transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(.125em)'
    }
  },
  time: {
    fontWeight: 600,
    fontSize: 12
  },
}));

const Chat = ({
  selectedChat,
  setChat,
  messages,
  addMessage
}) => {
  const classes = useStyles();
  const refChannel = useRef('');
  const refScroll = useRef(null);
  const refMessages = useRef(null);
  const [messagesReverse, setMessagesReverse] = useState([]);
  const [newUserMessage, setNewUserMessage] = useState(0);

  const scrollToBottom = () => {
    if (refScroll.current) {
      refScroll.current.scrollTop = refScroll.current.scrollHeight;
    }
  };

  const changeStatus = async (id, status) => {
    const { code } = await ChatSupportService.changeStatusById(id, status);
    if (code === 200) {
      if (status === 'start') setChat(id, selectedChat?.color);
      else setChat(null);
    }
  };

  const isScrollBottom = (e) => {
    const scrollHeight = e?.target?.scrollHeight;
    const clientHeight = e?.target?.clientHeight;
    if (e?.target?.scrollTop === (scrollHeight - clientHeight)) {
      setNewUserMessage(0);
    }
  };

  useEffect(() => {
    scrollToBottom();
    if (selectedChat?.id) {
      refChannel.current = pusher.subscribe(`SUPPORT_CHAT_${selectedChat.id}`);
      ['changes-support-messages']
        .map((item) => {
          refChannel.current.bind(item, (data) => {
            if (['changes-support-messages']?.some((event) => event === item)) {
              setChat(selectedChat?.id, selectedChat?.color, 'new');
              const messagesHeight = refMessages.current.offsetHeight;
              const scrollHeight = refScroll.current.offsetHeight;
              if (data?.message?.support_message?.sent_by === 'user' && (messagesHeight - scrollHeight) >= 0) {
                setNewUserMessage((prev) => prev + 1);
              }
            }
          });
          return item;
        });
    } else {
      pusher.unsubscribe(refChannel.current?.name);
    }
    return () => {
      pusher.unsubscribe(refChannel.current?.name);
    };
  }, [selectedChat?.id]);

  useEffect(() => {
    if (messagesReverse) {
      if (messagesReverse[messagesReverse.length - 1]?.sent_by !== 'user') {
        scrollToBottom();
      }
    }
  }, [messagesReverse?.length]);

  useEffect(() => {
    scrollToBottom();
  }, [refScroll?.current]);

  useEffect(() => {
    if (messages) setMessagesReverse(messages.reverse());
  }, [messages]);

  return messagesReverse && (
    <Card
      className={clsx(classes.root, classes.scroll)}
      ref={refScroll}
      onScroll={isScrollBottom}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderTop="1px solid rgba(255, 255, 255, 0.5)"
        borderBottom="1px solid rgba(255, 255, 255, 0.5)"
        bgcolor="rgba(238,243,249,0.3)"
        boxShadow="rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
        style={{ backdropFilter: 'blur(5px)' }}
        px={2}
        py={1.2}
        position="sticky"
        zIndex={1}
        top="0"
        width="100%"
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Avatar
            style={{
              backgroundColor: selectedChat?.color
            }}
          >
            {getInitials(selectedChat?.user?.first_name)}
          </Avatar>
          <Typography
            variant="body2"
            className={classes.name}
          >
            {`${selectedChat?.user?.first_name} ${selectedChat?.user?.last_name}`}
          </Typography>
        </Box>
        {selectedChat?.status !== 'finished' && (
          <Button
            className={clsx(
              classes.button,
              {
                [classes.buttonFinish]: selectedChat?.status === 'in_progress'
              },
            )}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => changeStatus(selectedChat?.id, selectedChat?.status === 'pending' ? 'start' : 'finish')}
          >
            {selectedChat?.status === 'pending' ? 'Aceptar' : 'Finalizar'}
          </Button>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        ref={refMessages}
      >
        {messagesReverse.map((msg, index) => (
          <Message
            key={msg?.id || index}
            message={msg?.message}
            id={msg?.id || ''}
            color={selectedChat?.color}
            initials={getInitials(selectedChat?.user?.first_name)}
            rol={msg?.sent_by}
            time={getHour(msg?.created_at)}
          />
        ))}
      </Box>
      <Box
        position="sticky"
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        bottom={0}
        py={2}
        px={2.5}
      >
        {newUserMessage > 0 && (
          <Box
            display="flex"
            position="absolute"
            top={-23}
            right={20}
            mb={1}
            mr={0.5}
            bgcolor="#009739"
            borderRadius="50%"
            justifyContent="center"
            alignItems="center"
            p={0.6}
            style={{ cursor: 'pointer' }}
            onClick={() => scrollToBottom()}
          >
            <Box
              width={25}
              height={25}
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="absolute"
              bgcolor="rgb(224, 51, 109)"
              top={-10}
              right={-9}
              color="#fff"
              borderRadius="50%"
              p={1}
              boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
            >
              <Typography
                variant="body2"
                className={classes.time}
              >
                {newUserMessage}
              </Typography>
            </Box>
            <ArrowDownwardRounded className={classes.arrowIcon} />
          </Box>
        )}
        {selectedChat?.status === 'pending' && (
          <Button
            className={classes.buttonBottom}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => changeStatus(selectedChat?.id, 'start')}
          >
            Aceptar
          </Button>
        )}
        {selectedChat?.status === 'in_progress' && (
          <InputText
            id={selectedChat?.id}
            addMessage={addMessage}
          />
        )}
      </Box>
    </Card>
  );
};

export default Chat;

Chat.propTypes = {
  selectedChat: PropTypes.object,
  setChat: PropTypes.func,
  messages: PropTypes.array,
  addMessage: PropTypes.func
};
