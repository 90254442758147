import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  Typography,
  Container,
  makeStyles,
  Grid,
  // Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import AdomiBiService from '../../../services/AdomiBIService';

import { selector as UserSelector } from '../../../redux/ducks/user';

import chart from '../../../utils/chart';
import isArray from '../../../utils/is_array';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ReportCountComponentView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [showData, setShowData] = useState([]);

  const fetchData = async () => {
    const response = await AdomiBiService.countRegisteredUsers();
    if (!response?.data) return;
    const { labels, datasets } = response.data;
    setShowData(chart(isArray(labels), isArray(datasets)));
  };

  useEffect(() => {
    if (user?.id) {
      fetchData();
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Reporte de usuarios registrados"
    >
      <Container maxWidth={false}>
        <Card>
          <Box p={3}>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Typography variant="h4">Reporte demográfico de clientes</Typography>
              </Grid>
              {/* <Grid container xs={6} justify="flex-end" alignItems="center">
                <Button variant="outlined" color="primary">Descargar reporte</Button>
              </Grid> */}
            </Grid>
            <Box m={1} mt={5}>
              <Line
                data={showData}
                options={{ responsive: true }}
              />
            </Box>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default ReportCountComponentView;
