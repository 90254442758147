import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const LoadingBox = ({ children, loading = false }) => {
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <div className={classes.container}>
          <CircularProgress>
            {children}
          </CircularProgress>
        </div>
      ) : children}
    </>
  );
};

LoadingBox.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool
};

export default LoadingBox;
