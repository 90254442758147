import React, { useEffect, useState, useCallback } from 'react';
import mapStyles from 'src/utils/mapStyles';
import useConfirmation from 'src/hooks/useConfirmation';
import { makeStyles, Button } from '@material-ui/core';
import { Add, Clear } from '@material-ui/icons';
import WarningModal from 'src/components/WarningModal';
import PolygonDetailModal from 'src/components/PolygonDetailModal';
import GoogleMapsWithPolygons from 'src/components/GoogleMapsWithPolygons';
import EnvironmentService from 'src/services/EnvironmentService';
import PolygonService from 'src/services/PolygonService';
import CenterCityMaps from 'src/utils/CenterCityMaps';
import store from 'src/redux/store';
import ConfirmationModal from 'src/components/ConfirmationModal';
import ListPolygons from './ListPolygons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 64px)',
    position: 'relative'
  },

  Button: {
    position: 'fixed',
    zIndex: 1,
    top: '74px',
    right: '35%',
    borderRadius: '50px',
    fontSize: '13px'
  }
}));

const monitorMapStyles = null;

const MapPolygonsView = () => {
  const classes = useStyles();
  const [center, setCenter] = useState({
    lat: 4.5319550262015955,
    lng: -76.10316395139068
  });
  const { toggleModal } = useConfirmation();
  const [showModal, setShowModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [textWarning, setTextWarning] = useState('');
  const [polygons, setPolygons] = useState([]);
  const [googleMapStyles, setGoogleMapStyles] = useState(monitorMapStyles);
  const [draw, setDraw] = useState(false);
  const [idEdit, setIdEdit] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [indexSelectedPoly, setIndexSelectedPoly] = useState(null);
  const [polygonDelete, setPolygonDelete] = useState([]);

  const toogleModalInfo = () => {
    setShowModal(!showModal);
  };

  const toogleBtn = () => {
    setDraw(!draw);
  };

  const showWarning = (flag, text = '') => {
    setWarning(flag);
    setTextWarning(text);
  };

  const getPointsPolygon = (paths) => {
    const coors = [];
    for (let i = 0; i < paths.getLength(); i++) {
      coors.push([paths.getAt(i).lat(), paths.getAt(i).lng()]);
    }
    return coors;
  };

  const onChangeInfo = (value) => {
    setSelectedPolygon({
      ...selectedPolygon,
      ...value
    });
  };

  const onChangeSchedule = (value, index) => {
    selectedPolygon.schedule[index].increment = value;
    setSelectedPolygon({
      ...selectedPolygon
    });
  };

  const handleSelectedPolygon = (poly, index) => {
    setSelectedPolygon(poly);
    setIndexSelectedPoly(index);
    toogleModalInfo();
  };

  const onChange = (polygon, data) => {
    const aux = polygons.slice();
    const paths = data.points?.map((point) => {
      return { lat: point[0], lng: point[1] };
    });

    aux.push({ ...data, paths, polygonRef: polygon });
    setPolygons(aux);
    polygon.setMap(null);
  };

  const fetchData = async () => {
    try {
      let response = null;
      const cityId = store.getState()?.app?.city;

      if (cityId === 'general') {
        response = await PolygonService.getAll();
      } else {
        response = await PolygonService.getByCityId(cityId);
      }
      const cityCenter = await CenterCityMaps();
      setCenter(cityCenter);

      if (response?.data?.length > 0) {
        setPolygons(
          response?.data?.map((item) => {
            const id = item?.id;
            const paths = item?.points?.map((point) => {
              return { lat: point[0], lng: point[1] };
            });

            return {
              ...item,
              id,
              schedule: item.schedule,
              paths
            };
          }) || []
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const savePolygon = async (polygon) => {
    polygon.setEditable(false);
    toogleBtn();
    const cityId = store.getState()?.app?.city;
    const prefix = `Polygon ${polygons.length + 1}`;
    const points = getPointsPolygon(polygon.getPath());
    const schedule = [
      {
        start: '00:00:00',
        end: '01:00:00',
        increment: '0'
      },
      {
        start: '01:00:00',
        end: '03:00:00',
        increment: '0'
      },
      {
        start: '03:00:00',
        end: '12:00:00',
        increment: '0'
      },
      {
        start: '12:00:00',
        end: '18:00:00',
        increment: '0'
      },
      {
        start: '18:00:00',
        end: '24:00:00',
        increment: '0'
      }
    ];

    const data = {
      city_id: cityId,
      base_cost: 0,
      prefix,
      alias: '1',
      points,
      schedule,
      is_available: true
    };

    try {
      await PolygonService.create(data);
      const res = await PolygonService.getByCityId(cityId);
      const { id } = res.data.filter((item) => item.prefix === prefix)[0];
      onChange(polygon, { id, ...data });
    } catch (e) {
      console.log(e.message);
    }
  };

  const onEdit = useCallback(async (poly, ref) => {
    poly.points = ref.getArray().map((item) => {
      return [item.lat(), item.lng()];
    });

    const data = {
      city_id: poly.city_id,
      base_cost: poly.base_cost,
      prefix: poly.prefix,
      alias: poly.alias,
      points: poly.points,
      schedule: JSON.stringify(poly.schedule),
      is_available: poly.is_available
    };
    try {
      await PolygonService.update(poly.id, data);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const onEditInfo = (data) => {
    polygons[indexSelectedPoly] = data;
    setPolygons(polygons.slice());
  };

  const handleDelete = (id, index) => {
    toggleModal();
    setPolygonDelete([id, index]);
  };

  const deletePolygon = async (id, index) => {
    polygons.splice(index, 1);
    setPolygons(polygons.slice());

    try {
      await PolygonService.remove(id);
    } catch (e) {
      console.log(e.message);
    }
  };

  const onAccept = () => {
    const id = polygonDelete[0];
    const index = polygonDelete[1];
    deletePolygon(id, index);
  };

  const dragEnd = (poly, ref) => {
    setIsDragging(false);
    onEdit(poly, ref.getPath());
  };

  const dragStart = () => {
    setIsDragging(true);
  };

  const onMouseDown = (e, poly, ref) => {
    const polygon = ref.getPath();
    window.google.maps.event.addListener(polygon, 'set_at', (event) => {
      if (event !== null && !isDragging && e.vertex !== undefined) {
        onEdit(poly, polygon);
        e.vertex = undefined;
      }
    });

    window.google.maps.event.addListener(polygon, 'insert_at', (event) => {
      if (event !== null) {
        onEdit(poly, polygon);
      }
    });
  };

  const setEditable = (id, point) => {
    setIdEdit(id);
    setCenter({ lat: point[0], lng: point[1] });
  };

  const getMonitorGoogleMapStyles = async () => {
    const response = await EnvironmentService.getMonitorGoogleMapStyles();

    if (response?.code === 200) {
      setGoogleMapStyles(JSON.parse(response?.data?.value));
    }
  };

  useEffect(() => {
    getMonitorGoogleMapStyles();
    fetchData();
  }, []);
  return (
    <div className={classes?.root}>
      {googleMapStyles !== null && (
        <>
          <Button
            color="primary"
            onClick={() => {
              if (store.getState()?.app?.city === 'general') {
                showWarning(true, 'Debe seleccionar una ciudad para poder crear un poligono');
                return;
              }
              toogleBtn();
            }}
            startIcon={!draw ? <Add /> : <Clear />}
            variant="contained"
            className={classes.Button}
          >
            {!draw ? 'Crear poligono' : 'Cancelar poligono'}
          </Button>
          <GoogleMapsWithPolygons
            center={center}
            polygons={polygons}
            styles={googleMapStyles}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=drawing`}
            loadingElement={<div style={mapStyles} />}
            containerElement={<div style={mapStyles} />}
            mapElement={<div style={mapStyles} />}
            isDrawing={draw}
            savePolygon={savePolygon}
            onMouseDown={onMouseDown}
            idEdit={idEdit}
            dragEnd={dragEnd}
            dragStart={dragStart}
            handleSelectedPolygon={handleSelectedPolygon}
            setEditable={setEditable}
          />
          <ListPolygons
            polygons={polygons}
            setEditable={setEditable}
            showWarning={showWarning}
            handleDelete={handleDelete}
          />
          { showModal && (
            <PolygonDetailModal
              polygon={selectedPolygon}
              toogleModal={toogleModalInfo}
              onChange={onChangeInfo}
              onChangeSchedule={onChangeSchedule}
              onEditInfo={onEditInfo}
              showWarning={showWarning}
            />
          )}
          { warning && (
            <WarningModal showWarning={showWarning} text={textWarning} />
          )}
          <ConfirmationModal onAccept={onAccept} />
        </>
      )}
    </div>
  );
};
export default MapPolygonsView;
