import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles
} from '@material-ui/core';
import { RiSendPlaneFill } from 'react-icons/ri';
import ChatSupportService from 'src/services/ChatSupportService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  input: {
    outline: 'none',
    border: 'none',
    background: '#f6f6fa',
    padding: theme.spacing(2),
    borderRadius: 50,
    width: '100%',
    color: '#717375',
    fontWeight: 500,
    fontSize: 14,
    fontFamily: '"Poppins", sans-serif',
  },
  sendButton: {
    borderRadius: '50%',
    minWidth: 10,
    padding: theme.spacing(1.3),
    marginLeft: theme.spacing(1),
  },
}));

const InputText = ({
  id,
  addMessage,
}) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim().length === 0) {
      return;
    }
    addMessage({
      message,
      sent_by: 'admin',
    });
    setMessage('');
    await ChatSupportService.sendMessage(id, {
      message,
      sent_by: 'admin'
    });
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  return (
    <form
      className={classes.root}
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
      >
        <input
          className={classes.input}
          type="text"
          value={message || ''}
          onChange={handleMessage}
          placeholder="Escribe tu mensaje aquí"
        />
        <Button
          type="submit"
          color="primary"
          size="small"
          variant="contained"
          className={classes.sendButton}
        >
          <RiSendPlaneFill size={27} />
        </Button>
      </Box>
    </form>
  );
};

export default InputText;

InputText.propTypes = {
  id: PropTypes.string,
  addMessage: PropTypes.func,
};
