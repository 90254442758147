import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import StateCardPreview from './StateCardPreview';
import CreateStateForm from './CreateStateForm';
import StateService from '../../../services/StateService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateStateView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [state, setState] = useState({
    id: null,
    first_name: '',
    country_id: '',
    is_available: false
  });

  const handleStateAttributes = (values) => {
    setState({
      ...state,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await StateService.getById(id);
        if (response?.code === 200) {
          setState({
            ...response.data,
            id: response.data.id,
            name: response.data.name,
            country_id: response.data.country_id,
            is_available: response.data.is_available,
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Estado"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <StateCardPreview
              state={state}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateStateForm
              edit={edit}
              state={state}
              onChange={handleStateAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateStateView.propTypes = {
  edit: PropTypes.bool,
};

CreateStateView.defaultProps = {
  edit: false
};

export default CreateStateView;
