import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  }
}));

const Results = ({
  className, data, onRefresh, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [cancellationReasons, setCancellationReasons] = useState([]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleMenu = (e) => setCancellationReasons(!cancellationReasons ? e.currentTarget : null);

  const redirect = () => {
    navigate(`/app/razones-cancelacion/${panel}/editar`, { replace: true });
    toggleMenu();
  };

  const onClick = (e, cancellationReason) => {
    setPanel(panel ? null : cancellationReason?.id);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setCancellationReasons(data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Titulo
                  </TableCell>
                  <TableCell>
                    Descripción
                  </TableCell>
                  <TableCell width="5" />
                </TableRow>
              </TableHead>
              <TableBody>
                {/* eslint-disable-next-line max-len */}
                {cancellationReasons.slice(page * limit, (limit * (page + 1))).map((cancellationReason) => (
                  <TableRow
                    hover
                    key={cancellationReason?.id}
                  >
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {cancellationReason?.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {cancellationReason?.description}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes?.statusCell} width="5">
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, cancellationReason)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={cancellationReasons.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {
      panel !== null && (
        <div
          className={classes?.statusPanel}
          style={{
            top: panelPosition?.y,
            left: panelPosition?.x - 120
          }}
        >
          <ul className={classes?.statusPanelList}>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => redirect('edit')}>
                Editar
              </a>
            </li>
          </ul>
        </div>
      )
    }
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  onRefresh: PropTypes.func,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  className: '',
  onRefresh: () => null,
};

export default Results;
