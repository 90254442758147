import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ImageCardPreview from './ImageCardPreview';
import CreateImageForm from './CreateImageForm';
import ImageService from '../../../services/ImageService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateImageView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [image, setImage] = useState({
    id: null,
    key: '',
    image: '',
  });

  const inputRef = useRef();

  const handleImageAttributes = (values) => {
    setImage({
      ...image,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await ImageService.getById(id);
        if (response?.code === 200) {
          setImage({
            ...response.data,
            id: response.data.id,
            key: response.data.key,
            image: response.data.image,
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Imagen"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <ImageCardPreview
              ref={inputRef}
              image={image}
              hasImage={hasImage}
              onUpload={handleUploadImage}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateImageForm
              ref={inputRef}
              edit={edit}
              hasImage={hasImage}
              image={image}
              onChange={handleImageAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateImageView.propTypes = {
  edit: PropTypes.bool,
};

CreateImageView.defaultProps = {
  edit: false
};

export default CreateImageView;
