import React, { useEffect, useState } from 'react';
import mapStyles from 'src/utils/mapStyles';
import { makeStyles } from '@material-ui/core';
import GoogleMapDrivers from 'src/components/GoogleMapDrivers';
import MarkerPNG from 'src/assets/images/marker.png';
import MarkerWarningPNG from 'src/assets/images/marker_warning.png';
import MarkerGrayPNG from 'src/assets/images/marker_gray.png';
import EnvironmentService from 'src/services/EnvironmentService';
import FirebaseApp from 'src/utils/firebase';
import CenterCityMaps from 'src/utils/CenterCityMaps';
import {
  getFirestore, collection, onSnapshot, query, where
} from 'firebase/firestore';
import dayjs from 'dayjs';
import store from '../../redux/store';

const Firestone = getFirestore(FirebaseApp);

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 64px)'
  }
}));

const monitorMapStyles = null;

const GodEyeView = () => {
  const classes = useStyles();
  const [center, setCenter] = useState({ lat: 4.5319550262015955, lng: -76.10316395139068 });
  const [markers, setMarkers] = useState([]);
  const [googleMapStyles, setGoogleMapStyles] = useState(monitorMapStyles);

  const toggleInfoWindow = (id, _markers) => {
    if (_markers && Array.isArray(_markers) && _markers?.length > 0) {
      setMarkers(_markers?.reduce((array, marker) => {
        if (marker?.uuid === id) {
          return array.concat({
            ...marker,
            infoWindow: !marker?.infoWindow
          });
        }

        return array.concat({
          ...marker,
          infoWindow: false
        });
      }, []));
    }
  };

  const fetchData = async () => {
    try {
      const cityCenter = await CenterCityMaps();
      setCenter(cityCenter);

      const cityId = store.getState()?.app?.city === 'general' ? 'c1e4bcc9-eb84-4653-872c-e38f8de4bf79' : store.getState()?.app?.city;
      const q = query(collection(Firestone, 'driverslocation'), where('city_id', '==', cityId));

      onSnapshot(q, (querySnapshot) => {
        const drivers = [];
        querySnapshot.forEach((doc) => {
          drivers.push(doc.data());
        });

        setMarkers(
          drivers?.map((driver) => {
            const uuid = driver?.driver_id;
            const position = { lat: driver?.position?.latitude, lng: driver?.position?.longitude };
            let icon = driver?.orders.length === 0 ? MarkerPNG : MarkerWarningPNG;
            const lastUpdate = driver?.position?.last_update.toDate();
            const currentDate = dayjs();
            const diff = currentDate.diff(lastUpdate, 'hour');
            icon = diff > 5 ? MarkerGrayPNG : icon;

            return ({
              ...driver,
              uuid,
              icon,
              position,
              last_update: lastUpdate.toString(),
              toggle: toggleInfoWindow,
              infoWindow: false
            });
          }) || []
        );
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const getMonitorGoogleMapStyles = async () => {
    const response = await EnvironmentService.getMonitorGoogleMapStyles();

    if (response?.code === 200) {
      setGoogleMapStyles(JSON.parse(response?.data?.value));
    }
  };

  useEffect(() => {
    getMonitorGoogleMapStyles();
    fetchData();
  }, []);

  return (
    <div className={classes?.root}>
      {
        googleMapStyles !== null && (
          <GoogleMapDrivers
            center={center}
            markers={markers}
            centerMarker={false}
            styles={googleMapStyles}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp`}
            loadingElement={<div style={mapStyles} />}
            containerElement={<div style={mapStyles} />}
            mapElement={<div style={mapStyles} />}
          />
        )
      }
    </div>
  );
};

export default GodEyeView;
