import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {
  Box,
  Card,
  Grid,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreIcon: {
    cursor: 'pointer',
    marginLeft: '1rem'
  },
  categoryButton: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    flex: 1,
    width: '100%',
  },
  calendarContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    border: '1px solid #bbb',
    padding: '.85rem',
    borderRadius: '4px',
  },
  calendar: {
    position: 'absolute',
    zIndex: 10000000,
  },
  calendarIcon: {
    cursor: 'pointer'
  },
  devInput: {
    display: 'inline-block'
  },
  devText: {
    display: 'inline-block'
  }
}));

const Toolbar = ({
  className, onChange, onChangeDate, onSearch, onDev, date, ...rest
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(e?.target?.value);
  };

  const handleSearch = (e) => {
    onSearch(e?.target?.value);
  };

  return (
    <>
      <div
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item xs={12} md={6}>
                  <Box maxWidth={500} mr="1rem">
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Buscar orden"
                      variant="outlined"
                      onChange={handleSearch}
                    />
                  </Box>
                </Grid>
                <Grid container item xs={12} md={6} justify="flex-end" alignItems="center">

                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="status">Estado</InputLabel>
                    <Select
                      required
                      name="status"
                      labelId="Status"
                      label="Selecciona una estado"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        Todos
                      </MenuItem>
                      <MenuItem value="created">
                        Pendiente
                      </MenuItem>
                      <MenuItem value="in_progress">
                        En proceso
                      </MenuItem>
                      <MenuItem value="sent">
                        Enviada
                      </MenuItem>
                      <MenuItem value="finished">
                        Completada
                      </MenuItem>
                      <MenuItem value="rejected">
                        Rechazada
                      </MenuItem>
                      <MenuItem value="cancelled">
                        Cancelada
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <Box ml={2}>
                    <label htmlFor="dev">
                      <input
                        type="checkbox"
                        name="dev"
                        id="dev"
                        className={classes?.devInput}
                        onChange={onDev}
                      />
                      {' '}
                      <Typography variant="caption" className={classes?.devText}>
                        <small>
                          Desarrollo
                        </small>
                      </Typography>
                    </label>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </div>
    </>
  );
};

Toolbar.propTypes = {
  date: PropTypes.string,
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onDev: PropTypes.func,
};

Toolbar.defaultProps = {
  date: dayjs(new Date()).format('YYYY-MM-DD'),
  className: '',
  onDev: () => null,
  onSearch: () => null,
  onChange: () => null,
  onChangeDate: () => null,
};

export default Toolbar;
