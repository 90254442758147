import React from 'react';
import {
  Box,
  Card,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DiCreativecommons } from 'react-icons/di';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '60%',
    boxShadow: 'none',
    borderRadius: 10,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginLeft: theme.spacing(2),
    '& a': {
      textDecoration: 'none',
      color: '#009739'
    }
  },
  title: {
    fontSize: 27,
    fontWeight: 600,
    color: '#fff'
  },
  supportImg: {
    width: 300,
    height: 300
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  copyright: {
    fontSize: 11
  }
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img
          className={classes.supportImg}
          src="/static/images/support.svg"
          alt="support chat"
        />
        <Box
          bgcolor="#009739"
          textAlign="center"
          py={1}
          px={2.5}
          borderRadius={10}
        >
          <Typography
            variant="h2"
            className={classes.title}
          >
            Chat de soporte
          </Typography>
        </Box>
        <Typography
          variant="body2"
          className={classes.description}
        >
          Envía y recibé mensajes para realizar soporte a las inquietudes de los usuarios. 💚
        </Typography>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
      >
        <Typography
          variant="body2"
          className={classes.copyright}
        >
          <a
            href="https://iconscout.com/illustration/support-2103598"
            rel="noreferrer"
            target="_blank"
          >
            Ilustración
          </a>
          {' '}
          descargada de
          {' '}
          <a
            href="https://iconscout.com"
            rel="noreferrer"
            target="_blank"
          >
            iconscout
          </a>
          {' '}
          y realizada por
          {' '}
          <a
            href="https://iconscout.com/contributors/pixel-true-designs"
            rel="noreferrer"
            target="_blank"
          >
            Pixel True
          </a>
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <DiCreativecommons size={22} />
          <Typography
            variant="body2"
            className={classes.copyright}
          >
            Creative Commons 4 Attribution (Downloaded)
            {' '}
            <a
              href="https://creativecommons.org/licenses/by/4.0"
              rel="noreferrer"
              target="_blank"
            >
              Leer más
            </a>
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default Welcome;
