import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import LoginConfirmationView from 'src/views/auth/LoginConfirmationView';
import NotFoundView from 'src/views/errors/NotFoundView';
import BrandListView from 'src/views/brand/BrandListView';
import CreateBrandView from 'src/views/brand/CreateBrandView';
import AdomiCategoryListView from 'src/views/adomi-category/AdomiCategoryListView';
import CreateAdomiCategoryView from 'src/views/adomi-category/CreateAdomiCategoryView';
import CountryListView from 'src/views/countries/CountryListView';
import CreateCountryView from 'src/views/countries/CreateCountryView';
import StateListView from 'src/views/states/StateListView';
import TutorialListView from 'src/views/Tutorials/TutorialListView';
import CreateTutorialView from 'src/views/Tutorials/CreateTutorialView';
import CreateStateView from 'src/views/states/CreateStateView';
import CityListView from 'src/views/cities/CityListView';
import CreateCityView from 'src/views/cities/CreateCityView';
import PartnerListView from 'src/views/partners/PartnerListView';
import CreatePartnerView from 'src/views/partners/CreatePartnerView';
import DriverListView from 'src/views/drivers/DriverListView';
import CreateDriverView from 'src/views/drivers/CreateDriverView';
import CreateDriverScheduleView from 'src/views/drivers/CreateScheduleView';
import AdminListView from 'src/views/admins/AdminListView';
import CreateAdminView from 'src/views/admins/CreateAdminView';
import ZonesListView from 'src/views/zones/ZonasListView';
import CreateZoneView from 'src/views/zones/CreateZoneView';
import AdomiComponentsListView from 'src/views/adomi-components/AdomiComponentsListView';
import CreateAdomiComponentsView from 'src/views/adomi-components/CreateAdomiComponentsView';
import BannersListView from 'src/views/banners/BannersListView';
import CreateBannersView from 'src/views/banners/CreateBannersView';
import CouponsListView from 'src/views/coupons/CouponsListView';
import CreateCouponsView from 'src/views/coupons/CreateCouponsView';
import FAQListView from 'src/views/faq/FaqListView';
import CreateFAQView from 'src/views/faq/CreateFaqView';
import ImagesListView from 'src/views/images/ImagesListView';
import CreateImagesView from 'src/views/images/CreateImagesView';
import BranchOfficeListView from 'src/views/branch_offices/BranchOfficesListView';
import CreateBranchOfficeView from 'src/views/branch_offices/CreateBranchOfficesView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import ProductListView from 'src/views/product/ProductListView';
import CoverListView from 'src/views/branch_offices/CoverListView';
import GodEyeView from 'src/views/god-eye/GodEyeView';
import MapSucursalView from 'src/views/map-sucursal/MapSucursalView';
import MapDriverView from 'src/views/map-driver/MapDriverView';
import MapPolygonsView from 'src/views/map-polygons/MapPolygonsView';
import LogoutView from 'src/views/auth/LogoutView';
import PartnerLeadListView from 'src/views/partner-leads/PartnerLeadListView';
import DriverLeadListView from 'src/views/driver-leads/DriverLeadListView';
import ReportHubView from 'src/views/reports/ReportHubView';
import ZonaReportView from 'src/views/reports/ZoneReportView';
import DemographicReportView from 'src/views/reports/DemographicReportView';
import CountAllRegisterUserView from 'src/views/reports/CountAllRegisterUsersView';
import OrderListView from 'src/views/orders/OrderListView';
import ClientListView from 'src/views/clients/ClientsListView';
import CreateClientView from 'src/views/clients/CreateClientView';
import AddressesListView from 'src/views/clients/AddressesListView';
import CreateAddressView from 'src/views/clients/CreateAddressView';
import CreateNotificationView from 'src/views/notifications/CreateNotificationsView';
import CancellationReasonsListView from 'src/views/cancellation-reasons/CancellationReasonsListView';
import CreateCancellationReasonsView from 'src/views/cancellation-reasons/CreateCancellationReasonsView';
import TagsListView from 'src/views/tags/TagListView';
import CreateTagsView from 'src/views/tags/CreateTagView';
import TermsListView from './views/terms-and-conditions/TermsListView';
import ChatSupportView from './views/chatSupport/ChatSupportView';
import TemplateListView from './views/templates/TemplateListView';
import CreateTemplateView from './views/templates/CreateTemplateView';
import PageTemplateView from './views/page-template/PageTemplateView';
import CreatePageTemplateView from './views/page-template/CreatePageTemplateView';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'products', element: <ProductListView /> },

      { path: 'chat-soporte', element: <ChatSupportView /> },

      { path: 'notificaciones/enviar', element: <CreateNotificationView /> },

      { path: 'marcas', element: <BrandListView /> },
      { path: 'marcas/crear', element: <CreateBrandView /> },
      { path: 'marcas/:id/editar', element: <CreateBrandView edit /> },

      { path: 'adomi-categorias', element: <AdomiCategoryListView /> },
      {
        path: 'adomi-categorias/:id/subcategorias',
        element: <AdomiCategoryListView />
      },
      { path: 'adomi-categorias/crear', element: <CreateAdomiCategoryView /> },
      {
        path: 'adomi-categorias/:id/editar',
        element: <CreateAdomiCategoryView edit />
      },

      { path: 'paises', element: <CountryListView /> },
      { path: 'paises/crear', element: <CreateCountryView /> },
      { path: 'paises/:id/editar', element: <CreateCountryView edit /> },

      { path: 'estados', element: <StateListView /> },
      { path: 'estados/crear', element: <CreateStateView /> },
      { path: 'estados/:id/editar', element: <CreateStateView edit /> },

      { path: 'tutoriales', element: <TutorialListView /> },
      { path: 'tutoriales/crear', element: <CreateTutorialView /> },
      { path: 'tutoriales/:id/editar', element: <CreateTutorialView edit /> },

      { path: 'etiquetas', element: <TagsListView /> },
      { path: 'etiquetas/crear', element: <CreateTagsView /> },
      { path: 'etiquetas/:id/editar', element: <CreateTagsView edit /> },

      { path: 'ciudades', element: <CityListView /> },
      { path: 'ciudades/crear', element: <CreateCityView /> },
      { path: 'ciudades/:id/editar', element: <CreateCityView edit /> },

      { path: 'aliados', element: <PartnerListView /> },
      { path: 'aliados/crear', element: <CreatePartnerView /> },
      { path: 'aliados/:id/editar', element: <CreatePartnerView edit /> },

      { path: 'aliados-potenciales', element: <PartnerLeadListView /> },

      { path: 'sucursales', element: <BranchOfficeListView /> },
      { path: 'sucursales/crear', element: <CreateBranchOfficeView /> },
      {
        path: 'sucursales/:id/editar',
        element: <CreateBranchOfficeView edit />
      },

      { path: 'ordenes/en-progreso', element: <OrderListView /> },

      { path: 'clientes', element: <ClientListView /> },
      { path: 'clientes/crear', element: <CreateClientView /> },
      { path: 'clientes/:id/direcciones', element: <AddressesListView /> },
      {
        path: 'clientes/:id/direcciones/crear',
        element: <CreateAddressView />
      },
      {
        path: 'clientes/direcciones/:id/editar',
        element: <CreateAddressView edit />
      },

      { path: 'repartidores', element: <DriverListView /> },
      { path: 'repartidores/crear', element: <CreateDriverView /> },
      { path: 'repartidores/:id/editar', element: <CreateDriverView edit /> },
      {
        path: 'repartidores/:id/horario',
        element: <CreateDriverScheduleView />
      },

      { path: 'repartidores-potenciales', element: <DriverLeadListView /> },

      { path: 'administradores', element: <AdminListView /> },
      { path: 'administradores/crear', element: <CreateAdminView /> },
      { path: 'administradores/:id/editar', element: <CreateAdminView edit /> },

      { path: 'zonas', element: <ZonesListView /> },
      { path: 'zonas/crear', element: <CreateZoneView /> },
      { path: 'zonas/:id/editar', element: <CreateZoneView edit /> },

      { path: 'adomi-componentes', element: <AdomiComponentsListView /> },
      {
        path: 'adomi-componentes/crear',
        element: <CreateAdomiComponentsView />
      },
      {
        path: 'adomi-componentes/:id/editar',
        element: <CreateAdomiComponentsView edit />
      },

      { path: 'anuncios', element: <BannersListView /> },
      { path: 'anuncios/crear', element: <CreateBannersView /> },
      { path: 'anuncios/:id/editar', element: <CreateBannersView edit /> },

      { path: 'cupones', element: <CouponsListView /> },
      { path: 'cupones/crear', element: <CreateCouponsView /> },
      { path: 'cupones/:id/editar', element: <CreateCouponsView edit /> },

      { path: 'terminos-y-condiciones', element: <TermsListView /> },

      { path: 'preguntas-frecuentes', element: <FAQListView /> },
      { path: 'preguntas-frecuentes/crear', element: <CreateFAQView /> },
      {
        path: 'preguntas-frecuentes/:id/editar',
        element: <CreateFAQView edit />
      },

      { path: 'razones-cancelacion', element: <CancellationReasonsListView /> },
      {
        path: 'razones-cancelacion/crear',
        element: <CreateCancellationReasonsView />
      },
      {
        path: 'razones-cancelacion/:id/editar',
        element: <CreateCancellationReasonsView edit />
      },

      { path: 'imagenes', element: <ImagesListView /> },
      { path: 'imagenes/crear', element: <CreateImagesView /> },
      { path: 'imagenes/:id/editar', element: <CreateImagesView edit /> },

      { path: 'imagenes-productos', element: <ProductListView /> },

      { path: 'covers-sucursales', element: <CoverListView /> },

      { path: 'reporte/zonas-comerciales', element: <ZonaReportView /> },
      { path: 'reporte/demografico', element: <DemographicReportView /> },
      {
        path: 'reporte/usuarios-registrados',
        element: <CountAllRegisterUserView />
      },
      { path: 'reporte/operaciones', element: <ReportHubView /> },

      { path: 'god-eye', element: <GodEyeView /> },
      { path: 'map-polygons', element: <MapPolygonsView /> },
      { path: 'map-sucursal', element: <MapSucursalView /> },
      { path: 'map-driver', element: <MapDriverView /> },

      { path: 'templates', element: <TemplateListView /> },
      { path: 'templates/crear', element: <CreateTemplateView /> },
      {
        path: 'templates/:id/editar',
        element: <CreateTemplateView edit />
      },

      { path: 'page-templates/:templateId', element: <PageTemplateView /> },
      {
        path: 'page-templates/:templateId/crear',
        element: <CreatePageTemplateView />
      },
      {
        path: 'page-templates/:templateId/:id/editar',
        element: <CreatePageTemplateView edit />
      },

      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'confirmation', element: <LoginConfirmationView /> },
      { path: 'logout', element: <LogoutView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/adomi-categorias" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
