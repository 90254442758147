import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Card,
  Grid,
  Box,
  Divider,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  },
}));

const TutorialCard = ({ className, tutorial, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(null);

  const toggleMenu = (e) => setMenuIsOpen(!menuIsOpen ? e.currentTarget : null);

  const redirect = () => {
    navigate(`/app/tutoriales/${tutorial.id}/editar`, { replace: true });
    toggleMenu();
  };

  const translateApp = (app) => {
    switch (app) {
      case 'orders':
        return 'Pedidos';
      case 'partners':
        return 'Aliados';
      case 'drivers':
        return 'Repartidores';
      default: return '';
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <a
              href={tutorial?.url}
              target="_blank"
              rel="noreferrer"
            >
              <Avatar
                className={classes.avatar}
                alt="tutorial"
                src={tutorial?.preview_image}
                variant="square"
              />
            </a>
          </Grid>
          <Grid container item xs={9} alignItems="flex-start">
            <Grid container item xs={10} alignItems="flex-start">
              <Box mt={1}>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  {tutorial?.title}
                </Typography>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                  {tutorial?.description}
                </Typography>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="caption"
                >
                  {`App: ${translateApp(tutorial?.app)}`}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={2} alignItems="flex-start">
              <IconButton onClick={toggleMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuIsOpen}
                open={Boolean(menuIsOpen)}
                onClose={toggleMenu}
              >
                <MenuItem onClick={() => redirect('edit')}>Editar</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
};

TutorialCard.propTypes = {
  className: PropTypes.func,
  tutorial: PropTypes.object.isRequired
};

TutorialCard.propTypes = {
  className: () => { },
};

export default TutorialCard;
