import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import BannerService from '../../../services/BannerService';
import BranchOfficeService from '../../../services/BranchOfficeService';
import CityService from '../../../services/CityService';
import BrandService from '../../../services/BrandService';
import AdomiCategoryService from '../../../services/AdomiCategoryService';
import { selector as AppSelector } from '../../../redux/ducks/app';
import Selector from '../../../components/Selector';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateBannerForm = forwardRef(({
  className, banner, edit, onChange, hasImage, ...rest
}, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const { globalCitySelected } = useSelector(AppSelector);
  const [cities, setCities] = useState([]);
  const [adomiCategorySelected, setAdomiCategorySelected] = useState('');
  const [adomiCategories, setAdomiCategories] = useState([]);
  const [brandSelected, setBrandSelected] = useState('');
  const [brands, setBrands] = useState([]);
  const [branchOfficeSelected, setBranchOfficeSelected] = useState('');
  const [branchOffices, setBranchOffices] = useState([]);

  const deleteBanner = async () => {
    if (banner?.id) {
      await BannerService.deleteImage(banner.id);
      const response = await BannerService.remove(banner.id);
      if (!response) {
        navigate('/app/anuncios', { replace: true });
      }
    }
  };

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const handleBrand = (value) => {
    setBrandSelected(value?.value);
    handleChange({
      target: {
        name: 'brand_id',
        value: value?.value
      }
    });
    setBranchOffices(value?.branch_offices);
  };

  const handleBranchOffice = (event) => {
    setBranchOfficeSelected(event.target.value);
    handleChange(event);
  };

  const handleAdomiCategory = (event) => {
    setAdomiCategorySelected(event.target.value);
    handleChange(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const file = ref.current.files[0];
    const data = {
      image: e.target?.image?.value,
      branch_office_id: e.target?.branch_office_id?.value || null,
      brand_id: e.target?.brand_id?.value || null,
      description: e.target?.description?.value,
      route: e.target?.route.value,
      order: e.target?.order?.value,
      is_available: e.target?.is_available?.value,
      city_id: e.target?.city_id?.value === 'general' ? null : e.target?.city_id?.value,
      adomi_category_id: e.target?.adomi_category_id?.value === 'general' ? null : e.target?.adomi_category_id?.value,
    };

    if (data?.description?.length === 0) {
      errors.push('La descripción es obligatoria');
    }

    if (data?.order?.length === 0) {
      errors.push('El orden es obligatorio');
    }

    if (!edit && file === undefined) {
      errors.push('La imagen es obligatoria');
    }

    if (data?.route?.length === 0) {
      errors.push('La pantalla en la app es obligatoria');
    }

    if (data?.adomi_category_id?.length === 0) {
      errors.push('La categoría de Adomi es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await BannerService.update(banner.id, data)
      : await BannerService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (response?.data?.image !== null && file !== undefined) {
        await BannerService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await BannerService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/anuncios');
          }, 500);
        }
      }
      if (response?.data?.image === null && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await BannerService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/anuncios');
          }, 500);
        }
      }
      navigate('/app/anuncios');
    } else {
      setErrors(response.errors);
    }
  };

  const fetchAllCities = async () => {
    const response = await CityService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
      return;
    }

    setCities([{ id: 'general', name: 'General' }, ...response?.data]);
  };

  const fetchAllAdomiCategories = async () => {
    const response = await AdomiCategoryService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las categorías de Adomi']);
      return;
    }

    setAdomiCategories(
      [
        { id: 'general', name: 'General' },
        ...response?.data.filter((adomiCategory) => adomiCategory.is_main === true),
      ]
    );
  };

  const translateRoute = (route) => {
    switch (route) {
      case 'None':
        return 'Ninguna';
      case 'Branch office':
        return 'Sucursal';
      case 'Discounts':
        return 'Promociones';
      default:
        return route;
    }
  };

  useEffect(() => {
    (async () => {
      const response = await BrandService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las marcas']);
        return;
      }

      setBrands(response.data);
    })();
    (async () => {
      const response = await BranchOfficeService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las sucursales']);
        return;
      }

      setBranchOffices(response.data);
    })();
  }, []);

  useEffect(async () => {
    await fetchAllCities();
    await fetchAllAdomiCategories();
  }, []);

  useEffect(() => {
    setBrandSelected(banner?.brand_id);
    setBranchOfficeSelected(banner?.branch_office_id);
    setAdomiCategorySelected(banner?.adomi_category_id);
  }, [banner?.brand_id, banner?.branch_office_id, banner?.adomi_category_id]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Anuncio"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba la descripción"
                label="Descripción"
                name="description"
                onChange={handleChange}
                required
                value={banner?.description}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el orden"
                label="Orden"
                name="order"
                onChange={handleChange}
                required
                value={banner?.order}
                variant="outlined"
                type="Number"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="is_available">Está Disponible</InputLabel>
                <Select
                  required
                  name="is_available"
                  labelId="Está Disponible"
                  label="Está Disponible?"
                  value={banner?.is_available}
                  onChange={handleChange}
                >
                  {
                    [true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="ciudad">Seleccione una ciudad</InputLabel>
                <Select
                  required
                  name="city_id"
                  labelId="ciudad"
                  label="Selecciona una ciudad *"
                  value={banner?.city_id || globalCitySelected}
                  onChange={handleChange}
                >
                  <MenuItem value="" />
                  {
                    cities.map((city) => (
                      <MenuItem key={city?.id} value={city?.id}>
                        {city?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="adomiCategory">Selecciona una categoría de Adomi</InputLabel>
                <Select
                  required
                  name="adomi_category_id"
                  labelId="categoría de Adomi"
                  label="Selecciona una categoría de Adomi"
                  value={adomiCategorySelected}
                  onChange={handleAdomiCategory}
                >
                  <MenuItem value="0" />
                  {
                    adomiCategories.map((adomiCategory) => (
                      <MenuItem key={adomiCategory.id} value={adomiCategory.id}>
                        { adomiCategory.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="route">Seleccione una pantalla de la app</InputLabel>
                <Select
                  name="route"
                  labelId="ruta"
                  label="Seleccione una pantalla de la app *"
                  value={banner?.route}
                  onChange={handleChange}
                >
                  <MenuItem value="0" />
                  {
                    ['None', 'Branch office', 'Discounts'].map((route) => (
                      <MenuItem key={route} value={route}>
                        {translateRoute(route)}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl
                // error={localErrors.some((e) => e.field === 'brand_id')}
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel
                  id="marca"
                >
                  Seleccione una marca
                </InputLabel>
                <Selector
                  required
                  options={brands?.map((brand) => (
                    { value: brand?.id, label: brand?.name, branch_offices: brand?.branch_offices }
                  ))}
                  name="brand_id"
                  labelId="marca"
                  label="Selecciona una marca *"
                  placeholder="Selecciona una marca"
                  value={brands
                    ?.filter((brand) => brand?.id === brandSelected)
                    ?.map((brand) => ({
                      value: brand?.id,
                      label: brand?.name,
                      branch_offices: brand?.branch_offices
                    }))
                    ?.find((brand) => brand?.value === brandSelected)}
                  onChange={handleBrand}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl disabled={banner?.brand_id?.length === 0} variant="outlined" className={classes.formControl}>
                <InputLabel id="sucursal">Seleccione una sucursal</InputLabel>
                <Select
                  name="branch_office_id"
                  labelId="sucursal"
                  label="Selecciona una sucursal *"
                  value={branchOfficeSelected}
                  onChange={handleBranchOffice}
                >
                  <MenuItem value="0" />
                  {
                    branchOffices.map((branchOffice) => (
                      <MenuItem key={branchOffice?.id} value={branchOffice?.id}>
                        {branchOffice?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              hasImage && (
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Image"
                    name="image"
                    onChange={handleChange}
                    required
                    value={banner.image}
                    variant="outlined"
                  />
                </Grid>
              )
            }
            <input name="image[]" type="file" ref={ref} style={{ display: 'none' }} />
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteBanner} />
    </form>
  );
});

CreateBannerForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  banner: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    branch_office_id: PropTypes.string,
    brand_id: PropTypes.string,
    description: PropTypes.string,
    route: PropTypes.string,
    order: PropTypes.number,
    is_available: PropTypes.bool,
    city_id: PropTypes.string,
    adomi_category_id: PropTypes.string
  })
};

CreateBannerForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => { },
  banner: {
    id: null,
    image: '',
    branch_office_id: '',
    brand_id: '',
    description: '',
    route: '',
    order: '',
    is_available: false,
    city_id: '',
    adomi_category_id: ''
  },
};

export default CreateBannerForm;
