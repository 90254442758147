import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles, Card
} from '@material-ui/core';
import { Edit as EditIcon, CheckCircle as ActiveIcon, HighlightOff as InactiveIcon } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer',
    color: 'black'
  },
  active: {
    color: 'green'
  },
  inactive: {
    color: 'red'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  }
}));

const Results = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);

  const goToEdit = (addressId) => {
    navigate(`/app/clientes/direcciones/${addressId}/editar`, { replace: true });
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setAddresses(data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1000}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Dirección
                  </TableCell>
                  <TableCell>
                    Tipo de dirección
                  </TableCell>
                  <TableCell>
                    Referencia
                  </TableCell>
                  <TableCell>
                    Ciudad
                  </TableCell>
                  <TableCell>
                    Activa
                  </TableCell>
                  <TableCell>
                    Editar
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addresses.map((address) => (
                  <TableRow
                    hover
                    key={address?.id}
                  >
                    <TableCell>
                      <Typography
                        component="span"
                        color="textPrimary"
                        variant="body2"
                      >
                        {address?.address1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {address?.address_type }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        { address?.reference || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {address?.city?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {
                        address?.is_active
                          ? (
                            <ActiveIcon className={classes.active} />
                          ) : (
                            <InactiveIcon className={classes.inactive} />
                          )
                      }
                    </TableCell>
                    <TableCell>
                      <EditIcon
                        className={classes.moreVert}
                        onClick={() => goToEdit(address?.id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  className: '',
};

export default Results;
