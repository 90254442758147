import React, { useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import Toolbar from './Toolbar';
import Results from './Results';
import TagService from '../../../services/TagService';
import { selector as UserSelector } from '../../../redux/ducks/user';
import useSearch from '../../../hooks/useSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TagsList = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['name', 'sub_tags'] });

  const getTags = async () => {
    const fetchData = await TagService.getAll();

    if (fetchData?.code === 200) {
      handleInitialData(fetchData?.data);
    }
  };

  const refresh = async () => {
    if (user?.id) {
      await getTags();
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Etiquetas"
    >
      <Container maxWidth={false}>
        <Toolbar refresh={refresh} onSearch={handleSearch} />
        <Box mt={3}>
          <Results data={dataFilterd} onRefresh={refresh} />
        </Box>
      </Container>
    </Page>
  );
};

export default TagsList;
