import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CityCardPreview from './CityCardPreview';
import CreateCityForm from './CreateCityForm';
import CityService from '../../../services/CityService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateCityView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [city, setCity] = useState({
    id: null,
    first_name: '',
    state_id: '0',
    latitude: '',
    longitude: '',
    cost_additional_km: '',
    base_km: '',
    is_available: false,
    is_coming_soon: false,
    slug: '',
  });

  const handleCityAttributes = (values) => {
    setCity({
      ...city,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await CityService.getById(id);
        if (response?.code === 200) {
          setCity({
            ...response.data,
            id: response.data.id,
            name: response.data.name,
            state_id: response.data.state_id || '0',
            latitude: response.data.latitude,
            longitude: response.data.longitude,
            cost_additional_km: response.data.cost_additional_km,
            base_km: response.data.base_km,
            is_available: response.data.is_available,
            is_coming_soon: response?.data?.is_coming_soon,
            slug: response?.data?.slug || '',
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Ciudad"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <CityCardPreview
              city={city}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateCityForm
              edit={edit}
              city={city}
              onChange={handleCityAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateCityView.propTypes = {
  edit: PropTypes.bool,
};

CreateCityView.defaultProps = {
  edit: false
};

export default CreateCityView;
