import React, { forwardRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import ComponentBrandService from 'src/services/ComponentBrandService';
import ComponentPackingService from 'src/services/ComponentPackingService';
import ComponentSizeUnitService from 'src/services/ComponentSizeUnitService';
import ComponentCategoryService from 'src/services/ComponentCategoryService';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import AdomiComponentsService from '../../../services/AdomiComponentsService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateAdomiComponentsForm = forwardRef(({
  className, adomiComponent, edit, onChange, hasImage, setLoading, ...rest
}, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [componentBrandSelected, setComponentBrandSelected] = useState('');
  const [componentBrands, setComponentBrands] = useState([]);
  const [componentPackingSelected, setComponentPackingSelected] = useState('');
  const [componentPacking, setComponentPacking] = useState([]);
  const [componentSizeUnitsSelected, setComponentSizeUnitsSelected] = useState('');
  const [componentSizeUnits, setComponentSizeUnits] = useState([]);
  const [componentCategoriesSelected, setComponentCategoriesSelected] = useState('');
  const [componentCategories, setComponentCategories] = useState([]);

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const handleComponentBrand = (event) => {
    setComponentBrandSelected(event.target.value);
    handleChange(event);
  };

  const handleComponentPacking = (event) => {
    setComponentPackingSelected(event.target.value);
    handleChange(event);
  };

  const handleComponentSizeUnits = (event) => {
    setComponentSizeUnitsSelected(event.target.value);
    handleChange(event);
  };

  const handleComponentCategories = (event) => {
    setComponentCategoriesSelected(event.target.value);
    handleChange(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const file = ref.current.files[0];
    const data = {
      name: e.target?.name?.value,
      image: e.target?.image?.value,
      component_category_id: e?.target?.component_category_id?.value || null,
      component_brand_id: e?.target?.component_brand_id?.value || null,
      component_packaging_type_id: e?.target?.component_packaging_type_id?.value || null,
      component_size_unit_id: e?.target?.component_size_unit_id?.value || null,
    };

    if (data.name.length === 0) {
      errors.push('El nombre del componente de Adomi es obligatorio');
    }

    if (!edit && file === undefined) {
      errors.push('La imagen es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    const response = edit
      ? await AdomiComponentsService.update(adomiComponent?.id, data)
      : await AdomiComponentsService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (response?.data?.image !== null && file !== undefined) {
        await AdomiComponentsService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await AdomiComponentsService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/adomi-componentes');
          }, 500);
        }
      }
      if (response?.data?.image === null && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await AdomiComponentsService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/adomi-componentes');
          }, 500);
        }
      }
      navigate('/app/adomi-componentes');
    } else {
      setErrors(response.errors);
    }
  };

  const deleteAdomiComponent = async () => {
    if (adomiComponent?.id) {
      await AdomiComponentsService.deleteImage(adomiComponent?.id);
      const response = await AdomiComponentsService.remove(adomiComponent?.id);
      if (!response) {
        navigate('/app/adomi-componentes', { replace: true });
      }
    }
  };

  const fetchComponentCategories = async () => {
    const response = await ComponentCategoryService.getAll();
    if (response?.code === 200) {
      setComponentCategories(response?.data);
    }
  };

  const fetchComponentBrands = async () => {
    const response = await ComponentBrandService.getAll();
    if (response?.code === 200) {
      setComponentBrands(response?.data);
    }
  };

  const fetchComponentPacking = async () => {
    const response = await ComponentPackingService.getAll();
    if (response?.code === 200) {
      setComponentPacking(response?.data);
    }
  };

  const fetchComponentSizeUnit = async () => {
    const response = await ComponentSizeUnitService.getAll();
    if (response?.code === 200) {
      setComponentSizeUnits(response?.data);
    }
  };

  const fetchComponentFeatures = async () => {
    await fetchComponentBrands();
    await fetchComponentCategories();
    await fetchComponentPacking();
    await fetchComponentSizeUnit();
  };
  useEffect(() => {
    fetchComponentFeatures();
  }, []);

  useEffect(() => {
    setComponentBrandSelected(adomiComponent?.component_brand_id);
  }, [adomiComponent?.component_brand_id]);

  useEffect(() => {
    setComponentSizeUnitsSelected(adomiComponent?.component_size_unit_id);
  }, [adomiComponent?.component_size_unit_id]);

  useEffect(() => {
    setComponentCategoriesSelected(adomiComponent?.component_category_id);
  }, [adomiComponent?.component_category_id]);

  useEffect(() => {
    setComponentPackingSelected(adomiComponent?.component_packaging_type_id);
  }, [adomiComponent?.component_packaging_type_id]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Componente de Adomi"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el nombre"
                label="Nombre"
                name="name"
                onChange={handleChange}
                required
                value={adomiComponent?.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Selecciona una categoría</InputLabel>
                <Select
                  name="component_category_id"
                  labelId="component_category"
                  label="Selecciona una categoría"
                  value={componentCategoriesSelected}
                  onChange={handleComponentCategories}
                >
                  <MenuItem value="0">Selecciona una categoría</MenuItem>
                  {
                    componentCategories.map((componentCategory) => (
                      <MenuItem key={componentCategory.id} value={componentCategory.id}>
                        { componentCategory.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Selecciona una marca</InputLabel>
                <Select
                  name="component_brand_id"
                  labelId="component_brand"
                  label="Selecciona una marca"
                  value={componentBrandSelected}
                  onChange={handleComponentBrand}
                >
                  <MenuItem value="0">Selecciona una marca</MenuItem>
                  {
                    componentBrands.map((componentBrand) => (
                      <MenuItem key={componentBrand.id} value={componentBrand.id}>
                        { componentBrand.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Selecciona un empaque</InputLabel>
                <Select
                  name="component_packaging_type_id"
                  labelId="component_packaging_type"
                  label="Selecciona un empaque"
                  value={componentPackingSelected}
                  onChange={handleComponentPacking}
                >
                  <MenuItem value="0">Selecciona un empaque</MenuItem>
                  {
                    componentPacking.map((componentPackingType) => (
                      <MenuItem key={componentPackingType.id} value={componentPackingType.id}>
                        { componentPackingType.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="category">Selecciona la unidad</InputLabel>
                <Select
                  name="component_size_unit_id"
                  labelId="component_size_unit"
                  label="Selecciona la unidad"
                  value={componentSizeUnitsSelected}
                  onChange={handleComponentSizeUnits}
                >
                  <MenuItem value="0">Selecciona la unidad</MenuItem>
                  {
                    componentSizeUnits.map((componentSizeUnit) => (
                      <MenuItem key={componentSizeUnit.id} value={componentSizeUnit.id}>
                        { componentSizeUnit.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <input name="image[]" type="file" ref={ref} style={{ display: 'none' }} />
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            { edit ? 'Editar' : 'Guardar' }
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteAdomiComponent} />
    </form>
  );
});

CreateAdomiComponentsForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  adomiComponent: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    component_brand_id: PropTypes.string,
    component_category_id: PropTypes.string,
    component_packaging_type_id: PropTypes.string,
    component_size_unit_id: PropTypes.string,
  })
};

CreateAdomiComponentsForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => {},
  setLoading: () => {},
};

export default CreateAdomiComponentsForm;
