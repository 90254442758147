import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  Grid,
  Button,
  Divider,
  CardActions,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  }
}));

const BrandCardPreview = forwardRef(
  ({ className, brand, onUpload, hasImage, ...rest }, ref) => {
    const classes = useStyles();
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
      ref?.current?.addEventListener('change', () => {
        setPreviewImage(URL.createObjectURL(ref?.current?.files[0]));
      });
    }, []);

    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid
              container
              item
              xs={3}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Avatar
                className={classes.avatar}
                alt="brand"
                src={previewImage || brand?.logo}
                variant="square"
              />
            </Grid>
            <Grid item xs={9}>
              <Typography
                align="left"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                {brand?.name}
              </Typography>
              <Typography align="left" color="textPrimary" variant="body1">
                {brand?.description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        {!hasImage && (
          <>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                fullWidth
                variant="text"
                onClick={onUpload}
              >
                Subir Logo
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    );
  }
);

BrandCardPreview.propTypes = {
  className: PropTypes.string,
  brand: PropTypes.object,
  hasImage: PropTypes.bool,
  onUpload: PropTypes.func
};

BrandCardPreview.defaultProps = {
  className: '',
  brand: {},
  hasImage: false,
  onUpload: () => {}
};

export default BrandCardPreview;
