import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useSearch from 'src/hooks/useSearch';
import isArray from 'src/utils/is_array';
import PartnerLeadService from '../../../services/PartnerLeadService';
import Results from './Results';
import Toolbar from './Toolbar';

import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const tabsFilters = [
  {
    key: 0,
    value: 'pending',
    title: 'Pendiente',
    total: 0
  },
  {
    key: 1,
    value: 'in_process',
    title: 'En proceso',
    total: 0
  },
  {
    key: 2,
    value: 'accepted',
    title: 'Aceptado',
    total: 0
  },
  {
    key: 3,
    value: 'rejected',
    title: 'Rechazado',
    total: 0
  },
];

const PartnerLeadView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [filter, setFilter] = useState(tabsFilters[0]);
  const [filters, setFilters] = useState(tabsFilters);
  const [archived, setArchived] = useState(false);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['name', 'city2.name', 'email', 'adress', 'phone', 'bussines_type', 'social_reason'] });
  const getCount = (lds) => {
    filters.forEach((item) => {
      const count = lds?.filter((lead) => lead?.status === item.value).length;
      setFilters((prev) => {
        if (!isArray(prev)) return [];
        return prev.map((tab) => {
          if (tab.value === item.value) {
            return {
              ...tab,
              total: count,
            };
          }
          return tab;
        });
      });
    });
  };
  const getLeads = async () => {
    const response = await PartnerLeadService.getAll();
    if (response?.code === 200 && response?.data?.length > 0) {
      handleInitialData(response?.data);
      getCount(response?.data);
    }
  };
  const refresh = async () => {
    if (user?.id) {
      getLeads();
    }
  };
  const handleFilter = (value) => setFilter(value);

  const filterData = (lds) => {
    if (!filter){
      return lds
    } if (archived === true) {
      return lds?.filter((lead) => lead?.is_archived === archived)
    }
    return (
      lds?.filter((lead) => lead?.status === filter.value && lead?.is_archived === false)
    );
  };
  useEffect(() => {
    if (user?.id) {
      getLeads();
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Aliados prospectos"
    >
      <Container maxWidth={false}>
        <Toolbar
          onChange={handleFilter}
          refresh={refresh}
          handleSearch={handleSearch}
        />
        <Box mt={3}>
          <Results
            data={filterData(dataFilterd)}
            onRefresh={refresh}
            onChange={handleFilter}
            filters={filters}
            filter={filter}
            archived={archived}
            setArchived={setArchived}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default PartnerLeadView;
