import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import ImageService from '../../../services/ImageService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateImageForm = forwardRef(({
  className, image, edit, onChange, hasImage, ...rest
}, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const file = ref.current.files[0];
    const data = {
      image: e.target?.image?.value,
      ...(edit ? { key: image?.key } : { key: e.target?.key?.value }),
    };

    if (data.key.length === 0) {
      errors.push('La llave es obligatoria');
    }

    if (!edit && file === undefined) {
      errors.push('La imagen es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await ImageService.update(image?.id, data)
      : await ImageService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (response?.data?.image !== null && file !== undefined) {
        await ImageService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await ImageService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/imagenes');
          }, 500);
        }
      }
      if (response?.data?.image === null && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await ImageService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/imagenes');
          }, 500);
        }
      }
      navigate('/app/imagenes');
    } else {
      setErrors(response.errors);
    }
  };

  const deleteImage = async () => {
    if (image?.id) {
      await ImageService.deleteImage(image?.id);
      const response = await ImageService.remove(image?.id);
      if (!response) {
        navigate('/app/imagenes', { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Imagen"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba la llave"
                label="Llave"
                name="key"
                onChange={handleChange}
                required
                value={image?.key}
                variant="outlined"
                disabled={edit}
              />
            </Grid>
            {
              hasImage && (
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Image"
                    name="image"
                    onChange={handleChange}
                    required
                    value={image?.image}
                    variant="outlined"
                  />
                </Grid>
              )
            }
            <input name="image[]" type="file" ref={ref} style={{ display: 'none' }} />
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            { edit ? 'Editar' : 'Guardar' }
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteImage} />
    </form>
  );
});

CreateImageForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  image: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    image: PropTypes.string
  })
};

CreateImageForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => {},
  image: {
    id: null,
    key: '',
    image: ''
  },
};

export default CreateImageForm;
