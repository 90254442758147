export const roles = {
  ADMIN: 'ADMINISTRATOR',
  SELLER: 'SELLER'
};

export const rolesTranslates = (role) => {
  switch (role) {
    case roles.SELLER:
      return 'Asesor Comercial';
    default:
      return 'Adminitrador';
  }
};

export const isAdmin = (user) => user?.role === roles?.ADMIN;

export const isSeller = (user) => user?.role === roles?.SELLER;

export const getUserRole = (user) => {
  switch (user?.role) {
    case 'SELLER':
      return roles.SELLER;
    default:
      return roles.ADMIN;
  }
};

export default null;
