import React, { useState, useEffect } from 'react';
import {
  Box,
  Container, Grid,
  makeStyles, Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import useSearch from 'src/hooks/useSearch';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Toolbar from './Toolbar';
import Results from './Results';
import DriverService from '../../../services/DriverService';
import { selector as UserSelector } from '../../../redux/ducks/user';
import BranchOfficeService from '../../../services/BranchOfficeService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DriverList = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { user } = useSelector(UserSelector);
  const queryParams = useQuery();
  const branchOfficeId = queryParams?.get('sucursal');
  const [drivers, setDrivers] = useState([]);
  const [branchOffice, setBranchOffice] = useState([]);
  const [filterByIsDevelop, setFilterByIsDevelop] = useState(null);
  const [filterByIsAdomi, setFilterByIsAdomi] = useState(null);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['phone', 'first_name', 'last_name'] });

  const getDrivers = async () => {
    let fetchData;

    if (id) {
      fetchData = await DriverService.getById(id);
    } else if (branchOfficeId) {
      fetchData = await DriverService.getByBranchOffice(branchOfficeId);
      const response = await BranchOfficeService.getById(branchOfficeId);
      if (response?.code === 200) {
        setBranchOffice(response?.data);
      }
    } else {
      fetchData = await DriverService.getAll();
    }

    if (fetchData?.code === 200) {
      setDrivers(fetchData.data);
      handleInitialData(fetchData.data);
    }
  };

  const refresh = async () => {
    if (user?.id) {
      await getDrivers();
    }
  };

  const handleFilterByIsDevelop = (value) => setFilterByIsDevelop(value);

  const handleFilterByIsAdomi = (value) => setFilterByIsAdomi(value);

  const filterData = (data) => {
    if (!filterByIsDevelop && !filterByIsAdomi) return data;

    if (filterByIsDevelop) {
      data = data?.filter((driver) => driver?.is_develop.toString() === filterByIsDevelop);
      if (filterByIsAdomi) {
        data = data?.filter((driver) => driver?.is_adomi.toString() === filterByIsAdomi);
      }
    }

    if (filterByIsAdomi) {
      data = data?.filter((driver) => driver?.is_adomi.toString() === filterByIsAdomi);
      if (filterByIsDevelop) {
        data = data?.filter((driver) => driver?.is_develop.toString() === filterByIsDevelop);
      }
    }

    return data;
  };

  useEffect(() => {
    getDrivers();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Repartidores"
    >
      <Container maxWidth={false}>
        {
          branchOfficeId ? (
            <Box p={2}>
              <Grid item container alignItems="center">
                <Typography variant="h4" component="h2" className={classes?.categoryTitle}>
                  {`Repartidores asociados a la sucursal ${branchOffice?.join_name ? `${branchOffice?.brand?.name} ${branchOffice?.name}` : branchOffice?.name}`}
                </Typography>
              </Grid>
            </Box>
          ) : (
            <>
              {drivers?.length > 0
                && (
                <Toolbar
                  onFilterByIsDevelop={handleFilterByIsDevelop}
                  onFilterByIsAdomi={handleFilterByIsAdomi}
                  onSearch={handleSearch}
                />
                )}
            </>
          )
        }
        <Box mt={3}>
          <Results data={filterData(dataFilterd)} onRefresh={refresh} />
        </Box>
      </Container>
    </Page>
  );
};

export default DriverList;
