import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import ClientService from '../../../services/ClientService';
import CountryService from '../../../services/CountryService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateClientForm = ({
  className, client, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const [countrySelected, setCountrySelected] = useState('');
  const [countries, setCountries] = useState([]);

  const handleChange = (event) => {
    onChange({
      [event?.target?.name]: event?.target?.value
    });
  };

  const handleCountry = (event) => {
    setCountrySelected(event?.target?.value);
    handleChange(event);
  };

  const translateGender = (gender) => {
    switch (gender) {
      case 'other':
        return 'Otro';
      case 'female':
        return 'Femenino';
      case 'male':
        return 'Masculino';
      default: return 'Other';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      id: e.target?.id?.value,
      first_name: e.target?.first_name?.value,
      last_name: e.target?.last_name?.value,
      birthdate: e.target?.birthdate?.value || null,
      gender: e.target?.gender?.value || null,
      country_id: e.target?.country_id?.value,
      phone: Number(e.target?.phone?.value),
      email: e.target?.email?.value || null,
      is_develop: e.target?.is_develop?.value,
    };

    if (data?.first_name?.length === 0) {
      errors?.push('El nombre del cliente es obligatorio');
    }
    if (data?.last_name?.length === 0) {
      errors?.push('Los apellidos del cliente son obligatorios');
    }
    if (data?.phone?.length === 0) {
      errors?.push('El teléfono del repartidor es obligatorio');
    }

    if (data?.country_id?.length === 0) {
      errors?.push('El país del cliente es obligatorio');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const response = await ClientService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/clientes');
    } else {
      setErrors(response?.errors);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await CountryService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar los países']);
        return;
      }
      setCountries(response?.data);
    })();
  }, []);

  useEffect(() => {
    setCountrySelected(client?.country_id);
  }, [client?.country_id]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Cliente"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el nombre"
                label="Nombre"
                name="first_name"
                onChange={handleChange}
                required
                value={client?.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba los apellidos"
                label="Apellidos"
                name="last_name"
                onChange={handleChange}
                required
                value={client?.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="Fecha de nacimiento"
                name="birthdate"
                onChange={handleChange}
                value={client?.birthdate || null}
                variant="outlined"
                type="date"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="gender">Género</InputLabel>
                <Select
                  name="gender"
                  labelId="gender"
                  label="Género"
                  value={client?.gender?.toString() || null}
                  onChange={handleChange}
                >
                  {
                    ['male', 'female', 'other'].map((item) => (
                      <MenuItem key={item} value={item}>
                        { translateGender(item) }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="pais">Seleccione un país</InputLabel>
                <Select
                  required
                  name="country_id"
                  labelId="pais"
                  label="Selecciona un país"
                  value={countrySelected}
                  onChange={handleCountry}
                >
                  <MenuItem value="0" />
                  {
                    countries.map((country) => (
                      <MenuItem key={country?.id} value={country?.id}>
                        { country?.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el teléfono"
                label="Teléfono"
                name="phone"
                onChange={handleChange}
                required
                value={client?.phone}
                variant="outlined"
                type="tel"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el correo electrónico"
                label="Correo electrónico"
                name="email"
                onChange={handleChange}
                value={client?.email || null}
                variant="outlined"
                type="email"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="is_develop">¿Es de desarrollo?</InputLabel>
                <Select
                  name="is_develop"
                  labelId="is_develop"
                  label="¿Es de desarrollo?"
                  value={client?.is_develop?.toString() || 'false'}
                  onChange={handleChange}
                >
                  {
                    [true, false].map((item) => (
                      <MenuItem key={item?.toString()} value={item?.toString()}>
                        { item?.toString() === 'true' ? 'Si' : 'No' }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            Guardar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CreateClientForm.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  client: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    birthdate: PropTypes.string,
    gender: PropTypes.string,
    country_id: PropTypes.string,
    phone: PropTypes.number,
    email: PropTypes.string,
    is_develop: PropTypes.bool,
  })
};

CreateClientForm.defaultProps = {
  className: '',
  onChange: () => {},
  client: {
    id: null,
    first_name: '',
    last_name: '',
    gender: '',
    birthdate: '',
    country_id: '',
    email: '',
    phone: '',
    is_develop: false,
  },
};

export default CreateClientForm;
