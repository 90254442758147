import axios from 'axios';
import cookie from 'js-cookie';

export default class HttpRequest {
  static endpoint = '';

  static API_URI = process.env.REACT_APP_API_URI;

  static setMicroservice(microservice) {
    switch (microservice) {
      case 'polygons':
        this.API_URI = process.env.REACT_APP_API_POLYGONS;
        return;
      case 'orders':
        this.API_URI = process.env.REACT_APP_API_ORDERS;
        return;
      case 'adomi-bi':
        this.API_URI = process.env.REACT_APP_API_ADOMI_BI;
        return;
      case 'adomi-wallet':
        this.API_URI = process.env.REACT_APP_API_WALLET;
        return;
      default:
        this.API_URI = process.env.REACT_APP_API_URI;
    }
  }

  static async get(id = null) {
    try {
      let response = null;
      if (id) {
        response = await axios.get(`${this.API_URI}/${this.endpoint}/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookie.get('token')}`
          },
        });
      } else {
        response = await axios.get(`${this.API_URI}/${this.endpoint}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookie.get('token')}`
          },
        });
      }

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }

  static async post(data) {
    try {
      const response = await axios.post(`${this.API_URI}/${this.endpoint}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.get('token')}`
        },
      });

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }

  static async put(id, data) {
    try {
      const response = await axios.put(`${this.API_URI}/${this.endpoint}/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.get('token')}`
        },
      });

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }

  static async putNew(data) {
    try {
      const response = await axios.put(`${this.API_URI}/${this.endpoint}`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.get('token')}`
        },
      });

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }

  static async delete(id) {
    try {
      const response = await axios.delete(`${this.API_URI}/${this.endpoint}/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookie.get('token')}`
        },
      });

      return response.data;
    } catch (err) {
      console.error(err.response ? err.response?.data?.errors : err.message);
      return err?.response?.data;
    }
  }
}
