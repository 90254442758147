import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TemplateListContext } from '.';

const TemplateListProvider = ({ children }) => {
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const onChangeSelectedTemplates = (newTemplates) => {
    setSelectedTemplates(newTemplates);
  };

  return (
    <TemplateListContext.Provider
      value={{
        selectedTemplates,
        onChangeSelectedTemplates
      }}
    >
      {children}
    </TemplateListContext.Provider>
  );
};

TemplateListProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TemplateListProvider;
