import dayjs from 'dayjs';
import HttpRequest from './HttpRequest';
import store from '../redux/store';

export default class OrderService extends HttpRequest {
  static endpoint = 'me/admin';

  static async getAll() {
    this.setMicroservice('orders');
    const cityId = store.getState()?.app?.city;
    if (cityId === 'general') {
      this.endpoint = 'order?page=1&perPage=1000&today=true';
    } else {
      this.endpoint = `order?page=1&perPage=1000&today=true&cityId=${cityId}`;
    }
    const response = await this.get();
    return response;
  }

  static async getAllByDate(date = dayjs(new Date()).format('YYYY-MM-DD')) {
    this.setMicroservice('orders');
    const cityId = store.getState()?.app?.city;
    if (cityId === 'general') {
      this.endpoint = `order?page=1&perPage=1000&date=${date}`;
    } else {
      this.endpoint = `order?page=1&perPage=1000&date=${date}&cityId=${cityId}`;
    }
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.setMicroservice('orders');
    this.endpoint = 'order';
    const response = await this.get(id);
    return response;
  }

  static async getOrdersInProgress() {
    this.setMicroservice('orders');
    this.endpoint = 'me/admin/order-in-progress';
    const response = await this.get();
    return response;
  }

  static async updateStatus(id, data) {
    this.setMicroservice('orders');
    this.endpoint = 'me/common/update-status';
    const response = await this.put(id, data);
    return response;
  }

  static async commandOrder(id, data) {
    this.setMicroservice('orders');
    this.endpoint = `me/common/order/${id}/new-command`;
    const response = await this.putNew(data);
    return response;
  }

  static async assignDriver(data) {
    this.setMicroservice('orders');
    this.endpoint = 'me/common/assign-local-driver';
    const response = await this.post(data);
    return response;
  }

  static async getByBranchOffice(id = null, date = new Date()) {
    const today = dayjs(date.toLocaleString('en-US', { timeZone: 'America/Bogota' })).format('YYYY-MM-DD');
    this.setMicroservice('orders');
    this.endpoint = 'order/by-branch-office';
    const response = await this.get(`${id}?page=1&perPage=10&hasAll=true&date=${today}`);
    return response;
  }

  static async deleteByClient(id) {
    this.setMicroservice('orders');
    this.endpoint = 'me/admin/order/delete-by-client';
    const response = await this.delete(id);
    return response;
  }

  static async generalReport(body) {
    this.setMicroservice('orders');
    this.endpoint = 'me/common/general-report-csv';
    const response = await this.post(body);
    return response;
  }
}
