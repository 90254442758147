import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import {
  Box,
  Container, Grid,
  makeStyles, Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import useSearch from 'src/hooks/useSearch';
import FiltersAside from 'src/components/FiltersAside';
import BranchOfficeService from '../../../services/BranchOfficeService';
import Results from './Results';
import Toolbar from './Toolbar';
import { selector as UserSelector } from '../../../redux/ducks/user';
import DriverService from '../../../services/DriverService';
import AdminService from '../../../services/AdminService';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const BranchOfficeView = () => {
  const classes = useStyles();
  const [showAside, setShowAside] = useState(false);
  const { user } = useSelector(UserSelector);
  const [branchOffices, setBranchOffices] = useState([]);
  const [driver, setDriver] = useState([]);
  const [admin, setAdmin] = useState([]);
  const { id } = useParams();
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['brand.name', 'city.name', 'whatsapp1', 'email', 'adress', 'phone1'] });
  const [filter, setFilter] = useState(null);
  const [isDev, setIsDev] = useState(null);
  const [isDemo, setIsdemo] = useState(null);
  const [isWorking, setIsWorking] = useState(null);
  const [isAvailable, setIsAvailable] = useState(null);
  const [zone, setZone] = useState(null);
  const queryParams = useQuery();
  const driverId = queryParams?.get('driver');
  const adminId = queryParams?.get('asesor');
  const [totalBranchOffices, setTotalBrancOffices] = useState(0);

  console.log(dataFilterd);

  const toggleAside = () => {
    setShowAside(!showAside);
  };

  const getBranchOffices = async () => {
    let fetchData;

    if (id) {
      fetchData = await BranchOfficeService.getById(id);
    } else if (driverId) {
      fetchData = await DriverService.getBranchOffices(driverId);
      const response = await DriverService.getById(driverId);
      if (response?.code === 200) {
        await setDriver(response?.data);
      }
    } else if (adminId) {
      fetchData = await AdminService.getBranchOfficesReport(adminId);
      const response = await AdminService.getById(adminId);
      if (response?.code === 200) {
        await setAdmin(response?.data);
      }
    } else {
      fetchData = await BranchOfficeService.getAll();
    }
    console.log(fetchData.data);

    if (fetchData?.code === 200) {
      if (id) {
        setBranchOffices([fetchData.data]);
        handleInitialData([fetchData.data]);
      } else if (queryParams?.get('brand')) {
        setBranchOffices(fetchData.data.filter((b) => b?.brand_id === queryParams?.get('brand')));
        handleInitialData(fetchData.data.filter((b) => b?.brand_id === queryParams?.get('brand')));
      } else if (queryParams?.get('branch_office')) {
        setBranchOffices(fetchData.data.filter((b) => b?.id === queryParams?.get('branch_office')));
        handleInitialData(fetchData.data.filter((b) => b?.id === queryParams?.get('branch_office')));
      } else {
        setBranchOffices(fetchData.data);
        handleInitialData(fetchData.data);
      }
    }
  };

  const refresh = async () => {
    if (user?.id) {
      await getBranchOffices();
    }
  };

  const handleFilter = (value) => setFilter(value);
  const handleDev = (value) => setIsDev(value);
  const handleDemo = (value) => setIsdemo(value);
  const handleWorking = (value) => setIsWorking(value);
  const handleAvailable = (value) => setIsAvailable(value);
  const handleZone = (value) => setZone(value);

  const filterData = (branchOfficesToFilter) => {
    if (!filter) return branchOfficesToFilter;

    const filterDataResult = branchOfficesToFilter?.filter(
      (branchOffice) => branchOffice?.is_featured.toString() === filter
    );

    return filterDataResult;
  };

  const filterDev = (ors) => {
    if (!isDev) return ors;

    return ors?.filter((branchOffice) => (
      branchOffice?.is_develop.toString() === isDev
    ));
  };

  const filterDemo = (ors) => {
    if (!isDemo) return ors;

    return ors?.filter((branchOffice) => (
      branchOffice?.is_demo.toString() === isDemo
    ));
  };
  const filterWorking = (ors) => {
    if (!isWorking) return ors;

    return ors?.filter((branchOffice) => (
      branchOffice?.is_working.toString() === isWorking
    ));
  };
  const filterAvailable = (ors) => {
    if (!isAvailable) return ors;

    return ors?.filter((branchOffice) => (
      branchOffice?.is_available.toString() === isAvailable
    ));
  };

  const filterZones = (ors) => {
    if (!zone) return ors;

    return ors?.filter((branchOffice) => (
      branchOffice?.commercial_zone_id === zone
    ));
  };

  useEffect(() => {
    if (user?.id) {
      getBranchOffices();
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Sucursales"
    >
      <Container maxWidth={false}>
        {
          driverId || adminId ? (
            <Box p={2}>
              <Grid item container alignItems="center">
                <Typography variant="h4" component="h2" className={classes?.categoryTitle}>
                  {
                    driverId ? (
                      `Sucursales asociadas al driver ${driver?.first_name} ${driver?.last_name}`
                    ) : (
                      `Sucursales asesoradas por el asesor ${admin?.first_name} ${admin?.last_name}`
                    )
                  }
                </Typography>
              </Grid>
            </Box>
          ) : (
            <>
              {branchOffices?.length > 0
                && (
                  <Toolbar
                    totalBranchOffices={totalBranchOffices}
                    refresh={refresh}
                    data={branchOffices}
                    handleSearch={handleSearch}
                    toggleAside={toggleAside}
                  />
                )}
            </>
          )
        }
        <Results
          data={
            filterData(
              filterAvailable(
                filterWorking(
                  filterDemo(
                    filterDev(
                      filterZones(
                        dataFilterd
                      )
                    )
                  )
                )
              )
            )
          }
          onRefresh={refresh}
          setTotalBrancOffices={setTotalBrancOffices}
        />
      </Container>
      <FiltersAside
        onChange={handleFilter}
        onDev={handleDev}
        onDemo={handleDemo}
        onWorking={handleWorking}
        toggleAside={toggleAside}
        onAvailable={handleAvailable}
        onZone={handleZone}
        show={showAside}
      />
    </Page>
  );
};

export default BranchOfficeView;
