import React from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow, Circle
} from 'react-google-maps';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

const InfoWindowContent = ({ data }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell align="center"><strong>ID</strong></TableCell>
            <TableCell align="center">{data?.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Nombre</strong></TableCell>
            <TableCell align="center">{data?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Estado</strong></TableCell>
            <TableCell align="center">{data?.is_available}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

InfoWindowContent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_available: PropTypes.bool,
  })
};

const GoogleMapsOrderModal = withScriptjs(withGoogleMap(({
  showCircles = true, center, setPosition, draggable, markers, centerMarker, styles, markerSucursal
}) => {
  const handleDragged = (marker) => {
    setPosition(marker.latLng.lat(), marker.latLng.lng());
  };

  return (
    <GoogleMap
      on
      defaultZoom={15}
      center={center}
      defaultCenter={center}
      defaultOptions={{
        styles
      }}
    >
      {
        centerMarker && <Marker draggable={draggable} onDragEnd={handleDragged} position={center} />
      }
      {
        markers?.length > 0 && markers?.map(
          (marker) => (
            <>
              <Marker
                label={marker?.name}
                // onClick={() => marker?.toggle(marker?.uuid, markers)}
                position={marker?.position}
                icon={{
                  url: marker?.brand?.logo ?? marker?.icon,
                  scaledSize: new window.google.maps.Size(35, 35),
                }}
              >
                {
                  marker?.infoWindow && (
                    <InfoWindow onCloseClick={() => marker?.toggle(marker?.uuid)}>
                      <InfoWindowContent data={marker} />
                    </InfoWindow>
                  )
                }
              </Marker>
              {
                showCircles && (
                  <Circle
                    defaultCenter={marker?.position}
                    defaultRadius={marker?.radius}
                    options={{
                      strokeColor: '#156e2b',
                      fillColor: '#12b93c',
                      fillOpacity: 0.03,
                      clickable: true,
                    }}
                  />
                )
              }
            </>
          )
        )
      }
      {
        markerSucursal?.length > 0 && markerSucursal?.map(
          (marker) => (
            <>
              <Marker
                label={marker?.name}
                // onClick={() => marker?.toggle(marker?.uuid, markers)}
                position={marker?.position}
                icon={{
                  url: marker?.brand?.logo ?? marker?.icon,
                  scaledSize: new window.google.maps.Size(35, 35),
                }}
              >
                {
                  marker?.infoWindow && (
                    <InfoWindow onCloseClick={() => marker?.toggle(marker?.uuid)}>
                      <InfoWindowContent data={marker} />
                    </InfoWindow>
                  )
                }
              </Marker>
              {
                showCircles && (
                  <Circle
                    defaultCenter={marker?.position}
                    defaultRadius={marker?.radius}
                    options={{
                      strokeColor: '#156e2b',
                      fillColor: '#12b93c',
                      fillOpacity: 0.03,
                      clickable: true,
                    }}
                  />
                )
              }
            </>
          )
        )
      }
    </GoogleMap>
  );
}));

GoogleMapsOrderModal.defaultProps = {
  showCircles: true,
  center: { lat: 0, lng: 0 },
  setPosition: () => { },
  draggable: false,
  markers: [],
  centerMarker: true,
  styles: [],
};

GoogleMapsOrderModal.propTypes = {
  showCircles: PropTypes.bool,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  setPosition: PropTypes.func,
  draggable: PropTypes.bool,
  centerMarker: PropTypes.bool,
  markers: PropTypes.array,
  styles: PropTypes.array,
};

export default GoogleMapsOrderModal;
