import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useQueryParams from 'src/hooks/useQueryParams';
import LoadingBox from 'src/components/LoadingBox';
import PartnerCardPreview from './PartnerCardPreview';
import CreatePartnerForm from './CreatePartnerForm';
import PartnerService from '../../../services/PartnerService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreatePartnerView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState({
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    password: '',
    bank_account_details: {
      bank_name: '',
      account_number: '',
      account_type: ''
    }
  });
  const queryParams = useQueryParams();
  const typeForm = queryParams.get('type');

  const inputRef = useRef();

  const handlePartnerAttributes = (values) => {
    setPartner({
      ...partner,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await PartnerService.getById(id);
        if (response?.code === 200) {
          setPartner({
            ...response.data,
            id: response.data.id,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            avatar: response.data.avatar,
            password: response.data.password
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page
        className={classes.root}
        title="Crear Aliado"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            {
              typeForm !== 'password'
              && (
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <PartnerCardPreview
                    ref={inputRef}
                    partner={partner}
                    hasImage={hasImage}
                    onUpload={handleUploadImage}
                  />
                </Grid>
              )
            }
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <CreatePartnerForm
                ref={inputRef}
                edit={edit}
                hasImage={hasImage}
                partner={partner}
                onChange={handlePartnerAttributes}
                typeForm={typeForm}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreatePartnerView.propTypes = {
  edit: PropTypes.bool,
};

CreatePartnerView.defaultProps = {
  edit: false
};

export default CreatePartnerView;
