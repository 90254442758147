import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import AdomiCategoryCardPreview from './AdomiCategoryCardPreview';
import CreateAdomiCategoryForm from './CreateAdomiCategoryForm';
import AdomiCategoryService from '../../../services/AdomiCategoryService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateAdomiCategoryView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [category, setCategory] = useState({ // estado que inicializa los valores de la categoria
    is_main: false,
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    country_id: '',
    branch_office_id: '',
    password: '',
    birthdate: '',
    is_develop: false,
    platform: '',
    cities: [],
    sub_categories: [],
  });

  const inputRef = useRef();

  const handleAdomiCategoryAttributes = (values) => {
    setCategory({
      ...category,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await AdomiCategoryService.getById(id);
        if (response?.code === 200) {
          setCategory({
            ...response.data,
            id: response.data.id,
            name: response.data.name,
            image: response.data.image,
            is_main: response.data.is_main,
            parent_id: response.data.parent_id || '0',
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Categoría de Adomi"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <AdomiCategoryCardPreview
              ref={inputRef}
              category={category}
              hasImage={hasImage}
              onUpload={handleUploadImage}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateAdomiCategoryForm
              ref={inputRef}
              edit={edit}
              hasImage={hasImage}
              category={category}
              onChange={handleAdomiCategoryAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateAdomiCategoryView.propTypes = {
  edit: PropTypes.bool,
};

CreateAdomiCategoryView.defaultProps = {
  edit: false
};

export default CreateAdomiCategoryView;
