import React, { useEffect, useState, forwardRef } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  // ListSubheader,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Input,
  Chip,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import { isSeller } from 'src/helpers/roles';
import Selector from 'src/components/Selector';
// import Multiselect from 'multiselect-react-dropdown';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import GoogleMapsModal from 'src/components/GoogleMapsModal';
import GoogleMapsPolygonModal from 'src/components/GoogleMapsPolygonModal';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
// import useConfirmation from '../../../hooks/useConfirmation';
import BranchOfficeService from '../../../services/BranchOfficeService';
import BrandService from '../../../services/BrandService';
import AdminService from '../../../services/AdminService';
import CityService from '../../../services/CityService';
import AdomiCategoryService from '../../../services/AdomiCategoryService';
import PartnerService from '../../../services/PartnerService';
import { selector as AppSelector } from '../../../redux/ducks/app';
import { selector as UserSelector } from '../../../redux/ducks/user';
import './CreateBranchOfficeForm.css';
import ZonesService from '../../../services/ZonesService';
import TagService from '../../../services/TagService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%'
  },
  listSubheader: {
    fontSize: '20px',
    color: 'black'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'rgba(67, 160, 72, 0.9)',
      color: '#fff'
    }
  }
}));

const selectorStyles = {
  container: {
    height: '100%',
    minHeight: '56px'
  },
  control: {
    height: '100%',
    padding: '9px 0'
  }
};

const CreateBranchOfficesForm = forwardRef(
  (
    { className, branchOffice, edit, onChange, hasImage, setLoading, ...rest },
    ref
  ) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { setErrors } = useErrors();
    // const { toggleModal } = useConfirmation();
    const { user } = useSelector(UserSelector);
    const { globalCitySelected: citySelectedApp } = useSelector(AppSelector);
    const [localErrors, setLocalErrors] = useState([]);
    const [brandSelected, setBrandSelected] = useState('');
    const [brands, setBrands] = useState([]);
    const [assessorSelected, setAssessorSelected] = useState('');
    const [assessors, setAssessors] = useState([]);
    const [adomiCategorySelected, setAdomiCategorySelected] = useState([]);
    const [adomiCategories, setAdomiCategories] = useState([]);
    const [partnerSelected, setPartnerSelected] = useState('');
    const [partners, setPartners] = useState([]);
    const [citySelected, setCitySelected] = useState('');
    const [cities, setCity] = useState([]);
    const [googleMapsOpen, setGoogleMapsOpen] = useState(false);
    const [googleMapsPolygonOpen, setGoogleMapsPolygonOpen] = useState(false);
    const [googleMapCenter, setGoogleMapCenter] = useState({ lat: 0, lng: 0 });
    const [zonaSelected, setZonaSelected] = useState('');
    const [zonas, setZonas] = useState([]);
    const [etiquetasSelected, setEtiquetasSelected] = useState([]);
    const [etiquetas, setEtiquetas] = useState([]);

    const handleChange = (event) => {
      onChange({
        [event.target.name]: event.target.value
      });
    };

    const handleMercadoPago = (event) => {
      onChange({
        [event.target.name]: event.target.value
      });
    }

    console.log(branchOffice);

    const handleEtiquetas = (e) => {
      setEtiquetasSelected(e.target.value);
      onChange({
        [e.target.name]: e.target.value.map((item) => {
          const tag = JSON.parse(item);
          return {
            id: tag.id,
            name: tag.name
          };
        })
      });
    };

    const handleBrand = (value) => {
      setBrandSelected(value?.value);
      handleChange({
        target: {
          name: 'brand_id',
          value: value?.value
        }
      });
    };

    const handleAssessor = (value) => {
      setAssessorSelected(value?.value);
      handleChange({
        target: {
          name: 'assessor_id',
          value: value?.value
        }
      });
    };

    const handleAdomiCategory = (event) => {
      setAdomiCategorySelected(event.target.value);
      handleChange(event);
    };

    const handlePartner = (event) => {
      setPartnerSelected(event.target.value);
      handleChange(event);
    };

    const handleZone = (event) => {
      setZonaSelected(event.target.value);
      handleChange(event);
    };

    const handleCity = (event) => {
      const cityId = event.target.value;
      const city = cities.find((c) => c?.id === cityId);
      setCitySelected(cityId);
      if (!edit) {
        setGoogleMapCenter({ lat: city?.latitude, lng: city?.longitude });
      }
      handleChange(event);
      // Rellena automáticamente la latitud y longitud de la sucursal con ls datos de la ciudad
      if (!edit) {
        onChange({
          latitude: city?.latitude,
          longitude: city?.longitude
        });
      }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      const errors = [];
      const file = ref.current.files[0];
      const data = {
        partner_id: e.target?.partner_id?.value,
        brand_id: e.target?.brand_id?.value,
        adomi_categories:
          adomiCategorySelected || e.target?.adomi_categories?.value,
        city_id: e.target?.city_id?.value,
        name: e.target?.name?.value,
        address: e.target?.address?.value,
        email: e.target?.email?.value,
        phone1: e.target?.phone1?.value,
        phone2: e.target?.phone2?.value,
        whatsapp1: e.target?.whatsapp1?.value,
        whatsapp2: e.target?.whatsapp2?.value,
        transaction_cost: e.target?.transaction_cost?.value,
        latitude: Number(e.target?.latitude?.value),
        longitude: Number(e.target?.longitude?.value),
        own_map: e.target?.own_map?.value,
        delivery_rate: e.target?.delivery_rate?.value,
        supports_drivers: e.target?.supports_drivers?.value,
        is_auto_accept: e.target?.is_auto_accept?.value,
        is_available: e.target?.is_available?.value,
        accept_datafono: e.target?.accept_datafono?.value,
        accept_creditcard: e.target?.accept_creditcard?.value,
        accept_cash: e.target?.accept_cash?.value,
        accept_mercado_pago: e.target?.accept_mercado_pago?.value,
        epayco_public_key: e.target?.epayco_public_key?.value,
        epayco_private_key: e.target?.epayco_private_key?.value,
        mercado_pago_public_key: e.target?.mercado_pago_public_key?.value,
        mercado_pago_private_key: e.target?.mercado_pago_private_key?.value,
        has_pickup: e.target?.has_pickup?.value,
        base_km:
          e.target?.base_km === undefined ? 0 : Number(e.target?.base_km.value),
        base_cost:
          e.target?.base_cost === undefined
            ? 0
            : Number((e.target?.base_cost.value).replace('.', '')),
        cost_additional_km:
          e.target?.cost_additional_km === undefined
            ? 0
            : Number((e.target?.cost_additional_km.value).replace('.', '')),
        max_sell_amount: Number(
          (e.target?.max_sell_amount?.value).replace('.', '')
        ),
        min_sell_amount: Number(
          (e.target?.min_sell_amount?.value).replace('.', '')
        ),
        min_sell_free_delivery: Number(
          (e.target?.min_sell_free_delivery?.value).replace('.', '')
        ),
        tip_driver_adomi: Number(
          (e.target?.tip_driver_adomi?.value).replace('.', '')
        ),
        tip_driver_partner: Number(
          (e.target?.tip_driver_partner?.value).replace('.', '')
        ),
        tip_driver_friend: Number(
          (e.target?.tip_driver_friend?.value).replace('.', '')
        ),
        join_name: e.target?.join_name?.value,
        currency_id: 'cf6fe1d0-08ff-4c6b-9315-e49f56a88911',
        eta: Number(e.target?.eta?.value),
        maximum_orders: Number(e.target?.maximum_orders?.value),
        cover: e.target?.cover?.value,
        is_coming_soon: e.target?.is_coming_soon?.value,
        base_rate_id: e.target?.base_rate_id?.value || 0,
        pickup_rate_id: e.target?.pickup_rate_id?.value || 0,
        order_type: e.target?.order_type.value || 'standard',
        order: e.target?.order.value || 0,
        ...(!edit
          ? {
              schedule: {
                Holidays: [], // Personalizado para días festivos
                Sunday: [],
                Monday: [],
                Tuesday: [],
                Wednesday: [],
                Thursday: [],
                Friday: [],
                Saturday: []
              }
            }
          : {}),
        is_demo: e?.target?.is_demo?.value,
        is_develop: e?.target?.is_develop?.value,
        is_featured: e?.target?.is_featured?.value,
        branchoffice_type: e.target?.branchoffice_type?.value,
        coverage_radio: e.target?.coverage_radio?.value,
        commercial_zone_id: e.target?.commercial_zone_id?.value,
        tags:
          etiquetasSelected.map((item) => {
            const tag = JSON.parse(item);
            return tag.id;
          }) || [],
        polygon: branchOffice?.polygon,
        assessor_id: e.target?.assessor_id?.value || null
      };
      if (data.brand_id.length === 0) {
        errors.push({ field: 'brand_id', error: 'La marca es obligatoria' });
      }
      if (data.adomi_categories.length === 0) {
        errors.push({
          field: 'brand_id',
          error: 'Las categorías de Adomi son obligatorias'
        });
      }
      if (data.partner_id.length === 0) {
        errors.push({ field: 'partner_id', error: 'El aliado es obligatorio' });
      }
      if (data.city_id.length === 0) {
        errors.push({ field: 'city_id', error: 'La ciudad es obligatoria' });
      }
      if (data.name.length === 0) {
        errors.push({ field: 'name', error: 'El nombre es obligatorio' });
      }
      if (data.address.length === 0) {
        errors.push({ field: 'address', error: 'La dirección es obligatoria' });
      }
      if (data.email.length === 0) {
        errors.push({ field: 'email', error: 'El email es obligatorio' });
      }
      if (data.phone1.length === 0) {
        errors.push({ field: 'phone1', error: 'El teléfono 1 es obligatorio' });
      }
      if (data.whatsapp1.length === 0) {
        errors.push({
          field: 'whatsapp1',
          error: 'El whatsapp 1 es obligatorio'
        });
      }
      if (
        data.latitude.toString() === '0' ||
        data.longitude.toString() === '0'
      ) {
        errors.push({
          field: 'latitude',
          error: 'La latitud es obligatoria y no puede ser 0'
        });
        errors.push({
          field: 'longitude',
          error: 'La longitud es obligatoria y no puede ser 0'
        });
      }
      if (data.supports_drivers.length === 0) {
        errors.push({
          field: 'supports_drivers',
          error: 'El campo "Soporta repartidores" es obligatorio'
        });
      }
      if (data.accept_datafono.length === 0) {
        errors.push({
          field: 'accept_datafono',
          error: 'El campo "Acepta datáfono" es obligatorio'
        });
      }
      if (data.accept_datafono.length === 0) {
        errors.push({
          field: 'accept_datafono',
          error: 'El campo "Acepta datáfono" es obligatorio'
        });
      }
      if (data.accept_creditcard.length === 0) {
        errors.push({
          field: 'accept_creditcard',
          error: 'El campo "Acepta tarjeta de crédito" es obligatorio'
        });
      }
      if (data.accept_cash.length === 0) {
        errors.push({
          field: 'accept_cash',
          error: 'El campo "Acepta efectivo" es obligatorio'
        });
      }
      if (data.has_pickup.length === 0) {
        errors.push({
          field: 'has_pickup',
          error: 'El campo "Tiene recogida" es obligatorio'
        });
      }
      if (data.supports_drivers === 'own' && data.base_km === 0) {
        errors.push({
          field: 'base_km',
          error: 'El campo "Kilometro base" es obligatorio y no puede ser 0'
        });
      }
      if (data.supports_drivers === 'own' && data.base_cost === 0) {
        errors.push({
          field: 'base_cost',
          error: 'El campo "Costo base" es obligatorio y no puede ser 0'
        });
      }
      if (data.supports_drivers === 'own' && data.cost_additional_km === 0) {
        errors.push({
          field: 'cost_additional_km',
          error:
            'El campo "Costo adicional por kilometro" es obligatorio y no puede ser 0'
        });
      }
      if (data.max_sell_amount.length === 0) {
        console.log('no funciona');
        errors.push({
          field: 'max_sell_amount',
          error: 'El campo "Monto de venta máximo" es obligatorio'
        });
      }
      if (data.max_sell_amount < data.min_sell_amount) {
        errors.push({
          field: 'max_sell_amount',
          error:
            'El monto maximo de venta no puede ser menor al monto minimo de venta'
        });
      }
      if (data.min_sell_amount.length === 0) {
        errors.push({
          field: 'min_sell_amount',
          error: 'El campo "Monto de venta mínimo" es obligatorio'
        });
      }
      if (data.min_sell_free_delivery.length === 0) {
        errors.push({
          field: 'min_sell_free_delivery',
          error: 'El campo "Monto de venta para entrega gratis" es obligatorio'
        });
      }
      if (data.maximum_orders.length === 0) {
        errors.push({
          field: 'maximum_orders',
          error: 'El campo "Máximo de ordenes" es obligatorio'
        });
      }
      if (data.tip_driver_adomi.length === 0) {
        errors.push({
          field: 'tip_driver_adomi',
          error: 'El campo "Propina al driver de Adomi" es obligatorio'
        });
      }
      if (data.tip_driver_partner.length === 0) {
        errors.push({
          field: 'tip_driver_partner',
          error: 'El campo "Propina al driver del aliado" es obligatorio'
        });
      }
      if (data.tip_driver_friend.length === 0) {
        errors.push({
          field: 'tip_driver_friend',
          error: 'El campo "Propina al driver amigo" es obligatorio'
        });
      }
      if (data.is_available.length === 0) {
        errors.push({
          field: 'is_available',
          error: 'El campo "Está disponible" es obligatorio'
        });
      }
      if (data.is_coming_soon.length === 0) {
        errors.push({
          field: 'is_coming_soon',
          error: 'El campo "Próximamente" es obligatorio'
        });
      }
      if (data.join_name.length === 0) {
        errors.push({
          field: 'join_name',
          error:
            'El campo "Unir nombre de sucursal con la marca" es obligatorio'
        });
      }
      if (data.order_type.length === 0) {
        errors.push({
          field: 'order_type',
          error: 'El campo "Tipo de ordenes" es obligatorio'
        });
      }
      if (data.branchoffice_type.length === 0) {
        errors.push({
          field: 'branchoffice_type',
          error: 'El campo "Tipo de sucursal" es obligatorio'
        });
      }
      if (data.is_demo.length === 0) {
        errors.push({
          field: 'is_demo',
          error: 'El campo "Sucursal demo" es obligatorio'
        });
      }
      if (data.is_develop.length === 0) {
        errors.push({
          field: 'is_develop',
          error: 'El campo "Sucursal de desarrollo" es obligatorio'
        });
      }
      if (data.order.length === 0) {
        errors.push({
          field: 'order',
          error: 'El campo "ordenamiento" es obligatorio'
        });
      }
      if (data.coverage_radio.length === 0) {
        errors.push({
          field: 'coverage_radio',
          error: 'El campo "Radio de cobertura" es obligatorio'
        });
      }
      if (data.transaction_cost.length === 0) {
        errors.push({
          field: 'transaction_cost',
          error: 'El campo "Costo de transacción" es obligatorio'
        });
      }
      if (data.commercial_zone_id.length === 0) {
        errors.push({
          field: 'commercial_zone_id',
          error: 'El campo "Zona" es obligatorio'
        });
      }

      if (errors.length > 0) {
        setErrors(errors.map((err) => err.error));
        setLocalErrors(errors);
        return;
      }

      // setLoading(true);

      const response = edit
        ? await BranchOfficeService.update(branchOffice.id, data)
        : await BranchOfficeService.create(data);
      console.log(response);
      if (response?.code === 201 || response?.code === 200) {
        setErrors([]);
        if (response?.data?.cover !== null && file !== undefined) {
          await BranchOfficeService.deleteImage(response?.data?.id);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('id', response.data.id);
          const r = await BranchOfficeService.uploadImage(formData);

          if (r?.code === 200 || r?.code === 201) {
            setTimeout(() => {
              return navigate('/app/sucursales');
            }, 500);
          }
        }
        if (response?.data?.cover === null && file !== undefined) {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('id', response.data.id);
          const r = await BranchOfficeService.uploadImage(formData);

          if (r?.code === 200 || r?.code === 201) {
            setTimeout(() => {
              return navigate('/app/sucursales');
            }, 500);
          }
        }
        navigate('/app/sucursales');
      } else {
        setErrors(Array.isArray(response.errors) ? response.errors : []);
      }
    };

    const deleteBranchOffice = async () => {
      if (branchOffice?.id) {
        await BranchOfficeService.deleteImage(branchOffice.id);
        const response = await BranchOfficeService.remove(branchOffice.id);
        if (!response) {
          navigate('/app/sucursales', { replace: true });
        }
      }
    };

    const fetchAllBrands = async () => {
      const response = await BrandService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las marcas']);
        return;
      }

      setBrands(response.data);
    };

    const fetchAllAssesors = async () => {
      const response = await AdminService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar los asesores']);
        return;
      }

      setAssessors(response?.data);
    };

    const getZones = async (cityId) => {
      const fetchData = await ZonesService.getAllId(cityId);

      if (fetchData?.code === 200) {
        setZonas(fetchData.data);
      }
    };

    const getEtiquetas = async () => {
      TagService.getAll()
        .then((response) => {
          setEtiquetas(response.data);
        })
        .catch((err) => {
          console.log(`Este es el error: ${err}`);
        });
    };

    const fetchAllAdomiCategories = async () => {
      const response = await AdomiCategoryService.getAll();

      if (response?.code !== 200) {
        setErrors([
          'Ocurrió un error al intentar mostrar las categorías de Adomi'
        ]);
        return;
      }
      const categoriesResult = [];
      response?.data?.map((category) =>
        category?.sub_categories?.map((subCategory) =>
          categoriesResult.push({
            id: subCategory?.id,
            category: category?.name,
            name: subCategory?.name
          })
        )
      );

      setAdomiCategories(categoriesResult);
    };

    const fetchAllPartners = async () => {
      const response = await PartnerService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar los aliados']);
        return;
      }

      setPartners(
        response.data.filter((partner) => partner?.brand_id === brandSelected)
      );
    };

    const saveCoverageRadio = (position, radius) => {
      onChange({
        latitude: position.lat,
        longitude: position.lng,
        coverage_radio: radius
      });
    };

    const savePolygon = (position, polygon) => {
      onChange({
        latitude: position.lat,
        longitude: position.lng,
        polygon
      });
    };

    const handleMarkerPosition = (latitude, longitude) => {
      onChange({
        latitude,
        longitude
      });
    };

    const handleUserLocation = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        handleMarkerPosition(
          position.coords.latitude,
          position.coords.longitude
        );
        setGoogleMapCenter(position.coords.latitude, position.coords.longitude);
      });
    };

    const translateBranchOfficeType = (branchOfficeType) => {
      switch (branchOfficeType) {
        case 'RESTAURANTS':
          return 'Restaurante';
        case 'LIQUOR':
          return 'Licores';
        case 'RETAIL':
          return 'Retail';
        default:
          return 'Restaurante';
      }
    };

    const getCommissionType = (transactionCost) => {
      if (transactionCost) {
        if (transactionCost < 1) {
          branchOffice.commission_type = 'Porcentual';
          return 'Porcentual';
        }
        branchOffice.commission_type = 'Monto fijo';
        return 'Monto fijo';
      }
      return '';
    };

    const setValue = (commisionType, transactionCost) => {
      if (commisionType === 'Porcentual') {
        if (transactionCost < 1) {
          return transactionCost;
        }
        return 0;
      }
      return Number.parseInt(transactionCost, 10);
    };

    useEffect(() => {
      getZones(citySelected);
    }, [citySelected]);

    useEffect(() => {
      fetchAllAssesors();
    }, []);

    useEffect(() => {
      getEtiquetas();
    }, []);

    useEffect(() => {
      (async () => {
        fetchAllBrands();
        fetchAllAdomiCategories();
      })();
    }, []);

    useEffect(() => {
      fetchAllPartners();
    }, [brandSelected]);

    useEffect(() => {
      (async () => {
        const response = await CityService.getAll();

        if (response?.code !== 200) {
          setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
          return;
        }

        setCity(response.data);
      })();
    }, []);

    useEffect(() => {
      if (edit) {
        // console.log(branchOffice?.adomi_categories);
        // const adomiCategorySelectedTotal = branchOffice?.adomi_categories?.map((subCategory) => (
        //   adomiCategories?.find((value) => (
        //     value?.id === subCategory
        //   ))
        // ));
        // console.log(adomiCategorySelectedTotal);
        setBrandSelected(branchOffice?.brand_id);
        setAssessorSelected(branchOffice?.assessor_id);
        setCitySelected(branchOffice?.city_id);
        setPartnerSelected(branchOffice?.partner_id);
        setAdomiCategorySelected(
          Array.isArray(branchOffice?.adomi_categories)
            ? branchOffice?.adomi_categories
            : []
        );
        setZonaSelected(branchOffice?.commercial_zone_id);
        setEtiquetasSelected(
          branchOffice?.tags.map((tag) => {
            return JSON.stringify({
              id: tag.id,
              name: tag.name
            });
          })
        );
      }
    }, [edit, branchOffice, adomiCategories]);

    useEffect(() => {
      setGoogleMapCenter({
        lat: branchOffice?.latitude,
        lng: branchOffice?.longitude
      });
    }, [branchOffice]);

    return (
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Card>
          <CardHeader
            subheader="La información puede ser editada"
            title="Sucursal"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl
                  error={localErrors.some((e) => e.field === 'city_id')}
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="city">Seleccione una ciudad</InputLabel>
                  <Select
                    required
                    name="city_id"
                    labelId="ciudad"
                    label="Selecciona una ciudad"
                    value={citySelected || (!edit && citySelectedApp)}
                    onChange={handleCity}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="commercialZone">
                    Seleccione una Zona
                  </InputLabel>
                  <Select
                    name="commercial_zone_id"
                    labelId="zone"
                    label="Seleccione una Zona"
                    value={zonaSelected}
                    onChange={handleZone}
                  >
                    {zonas.map((zona) => (
                      <MenuItem key={zona?.id} value={zona?.id}>
                        {zona?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="grouped-select" id="adomiCategory">
                    Seleccione una categoría de Adomi
                  </InputLabel>
                  <Select
                    // native
                    required
                    multiple
                    name="adomi_categories"
                    labelId="adomiCategory"
                    label="Selecciona una categoría de Adomi"
                    value={adomiCategorySelected}
                    defaultValue={adomiCategorySelected}
                    onChange={handleAdomiCategory}
                  >
                    {adomiCategories.map((subCategory) => (
                      <MenuItem value={subCategory?.id}>
                        {`${subCategory?.category} - ${subCategory?.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  error={localErrors.some((e) => e.field === 'assessor_id')}
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Selector
                    options={assessors?.map((a) => ({
                      value: a?.id,
                      label: a?.first_name?.concat(' ', a?.last_name)
                    }))}
                    name="assessor_id"
                    labelId="asesor"
                    label="Selecciona una asesor"
                    placeholder="Selecciona un asesor"
                    value={assessors
                      ?.filter((a) => a?.id === assessorSelected)
                      ?.map((a) => ({
                        value: a?.id,
                        label: a?.first_name?.concat(' ', a?.last_name)
                      }))
                      ?.find((a) => a?.value === assessorSelected)}
                    onChange={handleAssessor}
                    styles={selectorStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="brand">
                  <FormControl
                    error={localErrors.some((e) => e.field === 'brand_id')}
                    required
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Selector
                      required
                      options={brands?.map((brand) => ({
                        value: brand?.id,
                        label: brand?.name
                      }))}
                      name="brand_id"
                      labelId="marca"
                      label="Selecciona una marca"
                      placeholder="Selecciona una marca"
                      value={brands
                        ?.filter((brand) => brand?.id === brandSelected)
                        ?.map((brand) => ({
                          value: brand?.id,
                          label: brand?.name
                        }))
                        ?.find((brand) => brand?.value === brandSelected)}
                      onChange={handleBrand}
                      styles={selectorStyles}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  error={localErrors.some((e) => e.field === 'partner_id')}
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="partner">Seleccione un aliado</InputLabel>
                  <Select
                    required
                    name="partner_id"
                    labelId="aliado"
                    label="Selecciona un aliado"
                    value={partnerSelected}
                    onChange={handlePartner}
                  >
                    {partners.map((partner) => (
                      <MenuItem key={partner.id} value={partner.id}>
                        {`${partner.first_name} ${partner.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={localErrors.some((e) => e.field === 'name')}
                  helperText={
                    localErrors?.find((e) => e.field === 'name')?.error || ''
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el nombre"
                  label="Nombre de la sucursal"
                  name="name"
                  onChange={handleChange}
                  required
                  value={branchOffice.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={localErrors.some((e) => e.field === 'address')}
                  helperText={
                    localErrors?.find((e) => e.field === 'address')?.error || ''
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba la dirección"
                  label="Dirección"
                  name="address"
                  onChange={handleChange}
                  required
                  value={branchOffice.address}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={localErrors.some((e) => e.field === 'email')}
                  helperText={
                    localErrors?.find((e) => e.field === 'email')?.error || ''
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el correo electrónico"
                  label="Correo electrónico"
                  name="email"
                  onChange={handleChange}
                  required
                  value={branchOffice.email}
                  variant="outlined"
                  type="email"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  error={localErrors.some((e) => e.field === 'phone1')}
                  helperText={
                    localErrors?.find((e) => e.field === 'phone1')?.error || ''
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el teléfono 1"
                  label="Teléfono 1"
                  name="phone1"
                  onChange={handleChange}
                  required
                  value={branchOffice.phone1}
                  variant="outlined"
                  type="tel"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el teléfono 2"
                  label="Teléfono 2"
                  name="phone2"
                  onChange={handleChange}
                  value={branchOffice.phone2}
                  variant="outlined"
                  type="tel"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el whatsapp 1"
                  label="Whatsapp 1"
                  name="whatsapp1"
                  onChange={handleChange}
                  required
                  value={
                    branchOffice.whatsapp1 ||
                    (isSeller(user) ? user?.phone : '')
                  }
                  variant="outlined"
                  type="tel"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el whatsapp 2"
                  label="Whatsapp 2"
                  name="whatsapp2"
                  onChange={handleChange}
                  value={branchOffice.whatsapp2}
                  variant="outlined"
                  type="tel"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Vaya al mapa"
                  label="Latitud"
                  name="latitude"
                  onChange={handleChange}
                  required
                  disabled
                  value={branchOffice.latitude}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Vaya al mapa"
                  label="Longitud"
                  name="longitude"
                  onChange={handleChange}
                  required
                  disabled
                  value={branchOffice.longitude}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Código de facturación"
                  label="Código de facturación"
                  name="billing_code"
                  onChange={handleChange}
                  disabled
                  value={branchOffice.billing_code}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="supports_drivers">
                    Soporta repartidores
                  </InputLabel>
                  <Select
                    required
                    name="supports_drivers"
                    labelId="Soporta repartidores"
                    label="Selecciona una opción"
                    value={branchOffice.supports_drivers}
                    onChange={handleChange}
                  >
                    <MenuItem value="adomi">Red adomi</MenuItem>
                    <MenuItem value="own">Propios</MenuItem>
                    <MenuItem value="both">Ambos</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="has_pickup">Tiene recogida</InputLabel>
                  <Select
                    required
                    name="has_pickup"
                    labelId="Tiene recogida"
                    label="Selecciona una opción"
                    value={branchOffice.has_pickup}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="accept_cash">Acepta efectivo</InputLabel>
                  <Select
                    required
                    name="accept_cash"
                    labelId="Acepta efectivo"
                    label="Selecciona una opción"
                    value={branchOffice.accept_cash}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="accept_datafono">Acepta datáfono</InputLabel>
                  <Select
                    required
                    name="accept_datafono"
                    labelId="Acepta datáfono"
                    label="Selecciona una opción"
                    value={branchOffice.accept_datafono}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Epayco public key"
                  label="Epayco public key"
                  name="epayco_public_key"
                  onChange={handleChange}
                  value={branchOffice.epayco_public_key}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Epayco private key"
                  label="Epayco private key"
                  name="epayco_private_key"
                  onChange={handleChange}
                  value={branchOffice.epayco_private_key}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="accept_creditcard">Acepta tarjeta</InputLabel>
                  <Select
                    required
                    name="accept_creditcard"
                    labelId="Acepta tarjeta"
                    label="Selecciona una opción"
                    value={branchOffice.accept_creditcard}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {branchOffice.supports_drivers === 'own' && (
                <>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba el Kilometro base"
                      label="Kilometro base"
                      name="base_km"
                      error={localErrors.some((e) => e.field === 'base_km')}
                      helperText={
                        localErrors?.find((e) => e.field === 'base_km')
                          ?.error || ''
                      }
                      onChange={handleChange}
                      required
                      type="number"
                      value={branchOffice.base_km}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="accept_mercado_pago">
                        Acepta mercado pago
                      </InputLabel>
                      <Select
                        required
                        name="accept_mercado_pago"
                        labelId="accept_mercado_pago"
                        label="Selecciona una opción"
                        value={branchOffice.accept_mercado_pago}
                        onChange={handleMercadoPago}
                      >
                        {[true, false].map((answer) => (
                          <MenuItem key={answer} value={answer}>
                            {answer.toString() === 'true' ? 'Si' : 'No'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {branchOffice.accept_mercado_pago && (
                    <>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder="mercado_pago_public_key"
                          label="mercado_pago_public_key"
                          name="mercado_pago_public_key"
                          onChange={handleChange}
                          value={branchOffice.mercado_pago_public_key}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder="mercado_pago_access_token"
                          label="mercado_pago_access_token"
                          name="mercado_pago_access_token"
                          onChange={handleChange}
                          value={branchOffice.mercado_pago_access_token}
                          variant="outlined"
                        />
                      </Grid>
                    </>
                  )
                  }
                  <Grid item md={6} xs={12}>
                    <CurrencyFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      customInput={TextField}
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba el costo base"
                      label="Costo base"
                      name="base_cost"
                      error={localErrors.some((e) => e.field === 'base_cost')}
                      helperText={
                        localErrors?.find((e) => e.field === 'base_cost')
                          ?.error || ''
                      }
                      onChange={handleChange}
                      required
                      value={
                        branchOffice.base_cost === null
                          ? ''
                          : branchOffice.base_cost
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CurrencyFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      customInput={TextField}
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba el costo adicional por kilometro"
                      label="Costo adicional por kilometro"
                      name="cost_additional_km"
                      error={localErrors.some(
                        (e) => e.field === 'cost_additional_km'
                      )}
                      helperText={
                        localErrors?.find(
                          (e) => e.field === 'cost_additional_km'
                        )?.error || ''
                      }
                      onChange={handleChange}
                      required
                      value={
                        branchOffice.cost_additional_km === null
                          ? ''
                          : branchOffice.cost_additional_km
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12} />
                </>
              )}
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="commission_type">Tipo de comisión</InputLabel>
                  <Select
                    required
                    name="commission_type"
                    labelId="Tipo de comisión"
                    label="Selecciona una opción"
                    value={
                      branchOffice?.commission_type ||
                      getCommissionType(branchOffice?.transaction_cost)
                    }
                    onChange={handleChange}
                  >
                    {['Porcentual', 'Monto fijo'].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Costo de transacción"
                  label="Costo de transacción"
                  name="transaction_cost"
                  onChange={handleChange}
                  required
                  type="number"
                  value={setValue(
                    branchOffice?.commission_type,
                    branchOffice?.transaction_cost
                  )}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CurrencyFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  customInput={TextField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el monto de venta máximo"
                  label="Monto de venta máximo"
                  name="max_sell_amount"
                  onChange={handleChange}
                  required
                  value={branchOffice.max_sell_amount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CurrencyFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  customInput={TextField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el monto de venta mínimo"
                  label="Monto de venta mínimo"
                  name="min_sell_amount"
                  onChange={handleChange}
                  required
                  value={branchOffice.min_sell_amount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CurrencyFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  customInput={TextField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Monto de venta para entrega gratis"
                  label="Monto de venta para entrega gratis"
                  name="min_sell_free_delivery"
                  onChange={handleChange}
                  required
                  value={branchOffice.min_sell_free_delivery}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el máximo de ordenes"
                  label="Máximo de ordenes"
                  name="maximum_orders"
                  onChange={handleChange}
                  required
                  value={branchOffice.maximum_orders}
                  variant="outlined"
                  type="Number"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CurrencyFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  customInput={TextField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba la propina al driver de Adomi"
                  label="Propina al driver de Adomi"
                  name="tip_driver_adomi"
                  onChange={handleChange}
                  required
                  value={branchOffice.tip_driver_adomi}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CurrencyFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  customInput={TextField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba la propina al driver del aliado"
                  label="Propina al driver del aliado"
                  name="tip_driver_partner"
                  onChange={handleChange}
                  required
                  value={branchOffice.tip_driver_partner}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CurrencyFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  customInput={TextField}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba la propina al driver amigo"
                  label="Propina al driver amigo"
                  name="tip_driver_friend"
                  onChange={handleChange}
                  required
                  value={branchOffice.tip_driver_friend}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="is_available">Está disponible</InputLabel>
                  <Select
                    required
                    name="is_available"
                    labelId="Está disponible"
                    label="Selecciona una opción"
                    value={branchOffice?.is_available}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="is_coming_soon">Próximamente</InputLabel>
                  <Select
                    required
                    name="is_coming_soon"
                    labelId="Próximamente"
                    label="Selecciona una opción"
                    value={branchOffice?.is_coming_soon}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="join_name">
                    Unir nombre de sucursal con la marca
                  </InputLabel>
                  <Select
                    required
                    name="join_name"
                    labelId="Unir nombre de sucursal con la marca"
                    label="Selecciona una opción"
                    value={branchOffice?.join_name}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="join_branch_office_name">
                    Tipo de ordenes
                  </InputLabel>
                  <Select
                    required
                    name="order_type"
                    labelId="Tipo de ordenes"
                    label="Selecciona una opción"
                    value={branchOffice?.order_type || 'standard'}
                    onChange={handleChange}
                  >
                    {['standard', 'assisted'].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer === 'standard' ? 'Estandar' : 'Asistido'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="join_branch_office_name">
                    Autoaceptación de ordenes
                  </InputLabel>
                  <Select
                    required
                    name="is_auto_accept"
                    labelId="Autoaceptación de ordenes"
                    label="Selecciona una opción"
                    value={branchOffice?.is_auto_accept}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="branchoffice_type">
                    Tipo de sucursal
                  </InputLabel>
                  <Select
                    required
                    name="branchoffice_type"
                    labelId="Tipo de sucursal"
                    label="Selecciona una opción"
                    value={branchOffice?.branchoffice_type || 'RESTAURANTS'}
                    onChange={handleChange}
                  >
                    {['RESTAURANTS', 'LIQUOR', 'RETAIL'].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {translateBranchOfficeType(answer)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Radio de covertura (Metros)"
                  label="Radio de covertura (Metros)"
                  name="coverage_radio"
                  onChange={handleChange}
                  required
                  type="number"
                  value={branchOffice?.coverage_radio}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Ordenamiento"
                  label="Ordenamiento"
                  name="order"
                  onChange={handleChange}
                  required
                  value={branchOffice?.order || 0}
                  variant="outlined"
                  type="Number"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="is_demo">Sucursal demo</InputLabel>
                  <Select
                    required
                    name="is_demo"
                    labelId="Sucursal demo"
                    label="Selecciona una opción"
                    value={branchOffice?.is_demo === true}
                    onChange={handleChange}
                    disabled={branchOffice?.is_develop}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="is_develop">
                    Sucursal en desarrollo
                  </InputLabel>
                  <Select
                    required
                    name="is_develop"
                    labelId="Sucursal en desarrollo"
                    label="Selecciona una opción"
                    value={branchOffice?.is_develop === true}
                    onChange={handleChange}
                    disabled={branchOffice?.is_demo}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="is_featured">Sucursal destacada</InputLabel>
                  <Select
                    required
                    name="is_featured"
                    labelId="Sucursal destacada"
                    label="Selecciona una opción"
                    value={branchOffice?.is_featured === true}
                    onChange={handleChange}
                  >
                    {[true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer ? 'Si' : 'No'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="grouped-select" id="tags">
                    Seleccione las etiquetas
                  </InputLabel>
                  <Select
                    multiple
                    name="tags"
                    labelId="tags"
                    label="Selecciona alguna etiqueta"
                    value={etiquetasSelected || ''}
                    defaultValue=""
                    onChange={handleEtiquetas}
                    input={
                      <Input
                        style={{ padding: '5px' }}
                        id="select-multiple-chip"
                      />
                    }
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={JSON.parse(value).id}
                            label={JSON.parse(value).name}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {etiquetas.map((tag) => (
                      <MenuItem
                        className={classes.menuItem}
                        value={JSON.stringify({
                          id: tag.id,
                          name: tag.name
                        })}
                      >
                        {tag?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {hasImage && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Cover"
                    name="cover"
                    onChange={handleChange}
                    required
                    value={branchOffice.cover}
                    variant="outlined"
                  />
                </Grid>
              )}
              <input
                name="cover[]"
                type="file"
                ref={ref}
                style={{ display: 'none' }}
              />
            </Grid>
          </CardContent>
          <Errors time={0} />
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Box mr={2}>
              <Button
                disabled={citySelected === ''}
                onClick={() => {
                  if (Object.keys(branchOffice?.polygon).length === 0) {
                    setGoogleMapsOpen(true);
                    return;
                  }
                  setGoogleMapsPolygonOpen(true);
                }}
              >
                Ver mapa
              </Button>
            </Box>
            {/* {
            edit && !isSeller(user) && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          } */}
            <Button type="submit" color="primary" variant="contained">
              {edit ? 'Editar' : 'Guardar'}
            </Button>
          </Box>
        </Card>
        <ConfirmationModal onAccept={deleteBranchOffice} />
        {googleMapsOpen && (
          <GoogleMapsModal
            position={googleMapCenter}
            isOpen={googleMapsOpen}
            onLocate={handleUserLocation}
            onAccept={() => setGoogleMapsOpen(false)}
            coverageRadio={branchOffice?.coverage_radio || 0}
            openModalPolygon={() => {
              setGoogleMapsOpen(false);
              setGoogleMapsPolygonOpen(true);
            }}
            saveCoverageRadio={saveCoverageRadio}
            isBranchOffice
          />
        )}
        {googleMapsPolygonOpen && (
          <GoogleMapsPolygonModal
            position={googleMapCenter}
            isOpen={googleMapsPolygonOpen}
            onLocate={handleUserLocation}
            onAccept={() => setGoogleMapsPolygonOpen(false)}
            polygon={branchOffice?.polygon}
            savePolygon={savePolygon}
          />
        )}
      </form>
    );
  }
);

CreateBranchOfficesForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  branchOffice: PropTypes.shape({
    id: PropTypes.string,
    partner_id: PropTypes.string,
    brand_id: PropTypes.string,
    assessor_id: PropTypes.string,
    adomi_categories: PropTypes.string,
    city_id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    whatsapp1: PropTypes.string,
    whatsapp2: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    transaction_cost: PropTypes.number,
    billing_code: PropTypes.string,
    own_map: PropTypes.string,
    delivery_rate: PropTypes.string,
    supports_drivers: PropTypes.string,
    is_auto_accept: PropTypes.bool,
    is_available: PropTypes.bool,
    accept_datafono: PropTypes.bool,
    accept_creditcard: PropTypes.bool,
    accept_mercado_pago: PropTypes.bool,
    accept_cash: PropTypes.bool,
    has_pickup: PropTypes.bool,
    base_km: PropTypes.number,
    base_cost: PropTypes.string,
    cost_additional_km: PropTypes.string,
    max_sell_amount: PropTypes.string,
    min_sell_amount: PropTypes.string,
    min_sell_free_delivery: PropTypes.string,
    tip_driver_adomi: PropTypes.string,
    tip_driver_partner: PropTypes.string,
    tip_driver_friend: PropTypes.string,
    person1: PropTypes.string,
    person2: PropTypes.string,
    currency_id: PropTypes.string,
    eta: PropTypes.string,
    maximum_orders: PropTypes.number,
    cover: PropTypes.string,
    is_coming_soon: PropTypes.bool,
    join_name: PropTypes.bool,
    base_rate_id: PropTypes.number,
    pickup_rate_id: PropTypes.number,
    order_type: PropTypes.string,
    order: PropTypes.number,
    is_demo: PropTypes.bool,
    is_develop: PropTypes.bool,
    is_featured: PropTypes.bool,
    branchoffice_type: PropTypes.string,
    coverage_radio: PropTypes.number,
    commercial_zone_id: PropTypes.string,
    commission_type: PropTypes.string,
    epayco_public_key: PropTypes.string,
    epayco_private_key: PropTypes.string,
    mercado_pago_public_key: PropTypes.string,
    mercado_pago_access_token: PropTypes.string,
    tags: PropTypes.array,
    polygon: PropTypes.shape({
      points: PropTypes.array
    })
  })
};

CreateBranchOfficesForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => {},
  setLoading: () => {},
  branchOffice: {
    id: null,
    partner_id: '',
    brand_id: '',
    adomi_categories: [],
    city_id: '',
    name: '',
    address: '',
    email: '',
    phone1: '',
    phone2: '',
    whatsapp1: '',
    whatsapp2: '',
    latitude: 0.0,
    longitude: 0.0,
    transaction_cost: 0.125,
    billing_code: '',
    own_map: '',
    delivery_rate: '',
    supports_drivers: '',
    is_auto_accept: false,
    is_available: '',
    base_rate_id: '',
    pickup_rate_id: '',
    accept_datafono: '',
    accept_creditcard: '',
    accept_mercado_pago: '',
    accept_cash: '',
    has_pickup: '',
    base_km: 0,
    base_cost: '',
    cost_additional_km: '',
    max_sell_amount: '',
    min_sell_amount: '',
    min_sell_free_delivery: '',
    tip_driver_adomi: '',
    tip_driver_partner: '',
    tip_driver_friend: '',
    currency_id: '',
    eta: '',
    maximum_orders: '',
    cover: '',
    is_coming_soon: '',
    join_name: '',
    order_type: 'standard',
    order: 0,
    is_demo: false,
    is_develop: false,
    is_featured: false,
    branchoffice_type: 'RESTAURANTS',
    coverage_radio: '',
    commercial_zone_id: '',
    commission_type: '',
    tags: [],
    polygon: {},
    epayco_public_key: '',
    epayco_private_key: '',
    mercado_pago_public_key: '',
    mercado_pago_access_token: ''
  }
};

export default CreateBranchOfficesForm;
