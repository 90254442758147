import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import AddressCardPreview from './AddressCardPreview';
import CreateAddressForm from './CreateAddressForm';
import AddressService from '../../../services/AddressService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateAddressView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [address, setAddress] = useState({
    id: null,
    address1: '',
    city_id: '0',
    latitude: 0,
    longitude: 0,
    reference: '',
    address_type: '',
  });

  const handleAddressAttributes = (values) => {
    setAddress({
      ...address,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await AddressService.getById(id);
        if (response?.code === 200) {
          setAddress({
            ...response.data,
            latitude: Number(response.data.latitude),
            longitude: Number(response.data.longitude),
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Dirección"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <AddressCardPreview
              address={address}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateAddressForm
              id={id}
              edit={edit}
              address={address}
              onChange={handleAddressAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateAddressView.propTypes = {
  edit: PropTypes.bool,
};

CreateAddressView.defaultProps = {
  edit: false
};

export default CreateAddressView;
