import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  Grid,
  CardContent,
  Avatar,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  }
}));

const FaqCardPreview = ({
  className, FAQ, ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Avatar
              className={classes.avatar}
              alt="faq"
              src={FAQ?.image || 'https://storage.googleapis.com/bucket-adomi-dev/images/FAQ.png'}
              variant="square"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

FaqCardPreview.propTypes = {
  className: PropTypes.string,
  FAQ: PropTypes.object,
};

FaqCardPreview.defaultProps = {
  className: '',
  FAQ: {},
};

export default FaqCardPreview;
