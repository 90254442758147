import HttpRequest from './HttpRequest';

export default class AuthService extends HttpRequest {
  static endpoint = 'auth/admin';

  static async authenticate({ email, password }) {
    this.endpoint = 'auth/admin/request';
    const response = await this.post({ email, password });
    return response;
  }
  /*eslint-disable */
  static async twoAuthenticate({ phone, sms_token }) {
    this.endpoint = 'auth/admin/two-factor-login';
    const response = await this.post({ phone, sms_token });
    return response;
  }

  static async validate() {
    this.endpoint = 'auth/admin/validate';
    const response = await this.get();
    return response;
  }

  static async refresh() {
    this.endpoint = 'auth/admin/refresh';
    const response = await this.get();
    return response;
  }
}
