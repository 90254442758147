import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  Grid,
  Box,
  CardContent,
  Divider,
  Typography,
  CardActions,
  Button,
  IconButton,
  makeStyles
} from '@material-ui/core';
import {
  Assignment,
  Delete
} from '@material-ui/icons';
import useConfirmation from 'src/hooks/useConfirmation';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnEdit: {
    border: '1px solid rgb(38, 50, 56, 0.12)',
    borderRadius: '50px',
    fontSize: '12px',
    padding: '2px 15px',
    textTransform: 'capitalize'
  },
  btnDelete: {
    border: '1px solid rgb(38, 50, 56, 0.12)',
    borderRadius: '50px',
    padding: '8px'
  }
}));

const TermsCard = ({
  className,
  term,
  index,
  toggleModalCreate,
  setTerm,
  ...rest
}) => {
  const classes = useStyles();
  const { toggleModal } = useConfirmation();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={12} justifyContent="center" alignItems="center">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={1}>
              <Box mb={1}>
                <Assignment color="primary" fontSize="large" />
              </Box>
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="h5"
                style={{ textTransform: 'uppercase' }}
              >
                {term.title}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Box paddingX={1} sx={{ width: '100%' }} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Button
              size="small"
              color="primary"
              className={classes.btnEdit}
              onClick={() => {
                setTerm(term, index);
                toggleModalCreate(true);
              }}
            >
              Editar
            </Button>
          </Box>
          <Box>
            <IconButton
              className={classes.btnDelete}
              size="medium"
              color="primary"
              aria-label="delete"
              onClick={() => {
                setTerm(term, index);
                toggleModal();
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

TermsCard.propTypes = {
  className: PropTypes.func,
  term: PropTypes.object.isRequired,
  index: PropTypes.number,
  toggleModalCreate: PropTypes.func,
  setTerm: PropTypes.func,
};

TermsCard.propTypes = {
  className: () => {},
};

export default TermsCard;
