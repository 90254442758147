import app from './ducks/app';
import user from './ducks/user';
import modal from './ducks/modal';
import alert from './ducks/alert';
import errors from './ducks/errors';
import chat from './ducks/chat';

export default {
  app,
  user,
  modal,
  alert,
  errors,
  chat
};
