import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  Grid,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { FilterList, ArrowForwardIosRounded } from '@material-ui/icons';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    flex: 1,
    width: '100%'
  },
  button: {
    margin: theme.spacing(1),
  },
  total: {
    color: '#757575',
    marginLeft: '6px',
  }
}));

const Toolbar = ({
  onAvailable,
  onWorking,
  onDemo,
  onDev,
  totalBranchOffices,
  className,
  handleSearch,
  onChange,
  toggleAside,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Link to="/app/sucursales/crear">
          <Button
            color="primary"
            variant="contained"
          >
            Agregar Sucursal
          </Button>
        </Link>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6">
                  Total de sucursales:
                  <span className={classes.total}>255</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box marginTop={1.5}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Buscar Sucursal"
                    variant="outlined"
                    onChange={(event) => handleSearch(event?.target?.value)}
                  />
                </Box>
              </Grid>
              <Grid style={{ textAlign: 'right' }} item xs={12} md={6}>
                <Box marginTop={1.5}>
                  <Button
                    onClick={() => toggleAside()}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<FilterList />}
                    endIcon={<ArrowForwardIosRounded style={{ fontSize: '15px' }} />}
                  >
                    {'Filtros (3) '}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  totalBranchOffices: PropTypes.number,
  className: PropTypes.string,
  handleSearch: PropTypes.func,
  onChange: PropTypes.func,
  onDev: PropTypes.func,
  onDemo: PropTypes.func,
  onWorking: PropTypes.func,
  onAvailable: PropTypes.func,
  toggleAside: PropTypes.func
};

export default Toolbar;
