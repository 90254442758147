import HttpRequest from './HttpRequest';
import generateEndpoint from '../utils/generateEndpoint';

export default class BranchOfficeService extends HttpRequest {
  static endpoint = 'branch-offices';

  static async getAll() {
    this.setMicroservice('core');
    this.endpoint = generateEndpoint('branch-offices/get-for-dashboard');
    const response = await this.get();
    return response;
  }

  static async getAllCovers() {
    this.setMicroservice('core');
    this.endpoint = generateEndpoint('branch-offices/get-for-dashboard/covers');
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.setMicroservice('core');
    this.endpoint = 'branch-offices/get-for-dashboard';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.setMicroservice('core');
    this.endpoint = 'branch-offices';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.setMicroservice('core');
    this.endpoint = 'branch-offices';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.setMicroservice('core');
    this.endpoint = 'branch-offices';
    const response = await this.delete(id);
    return response;
  }

  static async uploadImage(data) {
    this.setMicroservice('core');
    this.endpoint = 'branch-offices/create-cover';
    const response = await this.post(data);
    return response;
  }

  static async deleteImage(data) {
    this.setMicroservice('core');
    this.endpoint = 'branch-offices/delete-cover';
    const response = await this.delete(data);
    return response;
  }

  static async downloadReport(id) {
    this.setMicroservice('orders');
    this.endpoint = `me/common/download-csv/${id}`;
    const response = await this.post({});
    return response;
  }
}
