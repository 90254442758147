import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { getDate, getHour } from 'src/utils/formatDate';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles(() => ({
  container: {
    fontFamily: '"Courier New", Courier, monospace',
    margin: '40px',
    padding: '20px',
    width: '90%',
    height: '70%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    border: '1px solid #999'
  },
  p: {
    marginTop: '10px'
  }
}));

const TicketDebt = forwardRef(({
  driver,
  saldoAct,
  valorPag,
  user,
  fecha,
}, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref}>
      <div className={classes?.container}>
        <h1>Comprobante de Pago</h1>
        <br />
        <p>
          Nombre:
          {' '}
          {driver?.first_name}
          {' '}
          {driver?.last_name}
          <br />
          Telefono:
          {' '}
          {driver?.phone}
          <br />
          Recibido:
          {' '}
          {user?.first_name}
          {' '}
          {user?.last_name}
        </p>
        <p className={classes?.p}>
          Pago en Efectivo
          <br />
          Valor pagado:
          {' $ '}
          <CurrencyFormat
            value={valorPag}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
          <br />
          Deuda actual:
          {' $ '}
          <CurrencyFormat
            value={saldoAct}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
          />
          <br />
          Fecha:
          {' '}
          {getDate(fecha)}
          <br />
          Hora:
          {' '}
          {getHour(fecha)}
        </p>
      </div>
    </div>
  );
});

TicketDebt.propTypes = {
  driver: PropTypes.object,
  saldoAct: PropTypes.number,
  valorPag: PropTypes.number,
  user: PropTypes.object,
  fecha: PropTypes.string,
};

export default TicketDebt;
