import CityService from 'src/services/CityService';
import store from '../redux/store';

const CenterCityMaps = async () => {
  try {
    const cityId = store.getState()?.app?.city;
    if (cityId === 'general') {
      return { lat: 4.5319550262015955, lng: -76.10316395139068 };
    }

    const response = await CityService.getById(cityId);

    return { lat: response.data?.latitude, lng: response.data?.longitude };
  } catch (error) {
    console.error(error);
    return { lat: 4.5319550262015955, lng: -76.10316395139068 };
  }
};

export default CenterCityMaps;
