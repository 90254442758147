import HttpRequest from './HttpRequest';
import generateEndpoint from '../utils/generateEndpoint';

export default class PartnerLeadService extends HttpRequest {
  static endpoint = 'partner-leads';

  static async getAll() {
    this.endpoint = generateEndpoint('partner-leads');
    const response = await this.get();
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'partner-leads';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'partner-leads';
    const response = await this.delete(id);
    return response;
  }
}
