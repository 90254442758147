import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow, Circle
} from 'react-google-maps';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

const InfoWindowContent = ({ data }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell align="center"><strong>ID</strong></TableCell>
            <TableCell align="center">{data?.uuid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Nombre</strong></TableCell>
            <TableCell align="center">{data?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Última actualización</strong></TableCell>
            <TableCell align="center">{data?.last_update}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Ordenes</strong></TableCell>
            <TableCell align="center">{JSON.stringify(data?.orders)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

InfoWindowContent.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    is_available: PropTypes.bool,
    orders: PropTypes.object,
    last_update: PropTypes.string,
  })
};

const GoogleMapDrivers = withScriptjs(withGoogleMap(({
  center,
  setPosition,
  setRadius,
  draggable,
  markers,
  centerMarker,
  styles,
  coverageRadio = 0
}) => {
  const [circleCenter, setCircleCenter] = useState(center);

  const handleDragged = (marker) => {
    setPosition(marker.latLng.lat(), marker.latLng.lng());
    setCircleCenter({ lat: marker.latLng.lat(), lng: marker.latLng.lng() });
  };

  const radiusRef = useRef(null);

  const updateRadius = () => {
    if (radiusRef.current === null) { return; }
    // eslint-disable-next-line max-len
    const newRadius = radiusRef.current.state.__SECRET_CIRCLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.radius;
    setRadius(newRadius);
  };

  return (
    <GoogleMap
      on
      defaultZoom={15}
      center={center}
      defaultCenter={center}
      defaultOptions={{
        styles
      }}
    >
      {
        centerMarker && (
          <>
            <Marker draggable={draggable} onDragEnd={handleDragged} position={center} />
            <Circle
              ref={radiusRef}
              editable
              onRadiusChanged={updateRadius}
              center={circleCenter || center}
              radius={coverageRadio}
              options={{
                strokeColor: '#156e2b',
                fillColor: '#12b93c',
                fillOpacity: 0.5,
                clickable: true,
              }}
            />
          </>
        )
      }
      {
        markers?.length > 0 && markers?.map(
          (marker) => (
            <>
              <Marker
                label={marker?.name}
                onClick={() => marker?.toggle(marker?.uuid, markers)}
                position={marker?.position}
                icon={marker?.icon ?? ''}
              >
                {
                  marker?.infoWindow && (
                    <InfoWindow onCloseClick={() => marker?.toggle(marker?.uuid)}>
                      <InfoWindowContent data={marker} />
                    </InfoWindow>
                  )
                }
              </Marker>
            </>
          )
        )
      }
    </GoogleMap>
  );
}));

GoogleMapDrivers.defaultProps = {
  center: { lat: 0, lng: 0 },
  setPosition: () => { },
  draggable: false,
  markers: [],
  centerMarker: true,
  styles: [],
};

GoogleMapDrivers.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  setPosition: PropTypes.func,
  draggable: PropTypes.bool,
  centerMarker: PropTypes.bool,
  markers: PropTypes.array,
  styles: PropTypes.array,
};

export default GoogleMapDrivers;
