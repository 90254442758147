import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import StateService from '../../../services/StateService';
import CountryService from '../../../services/CountryService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateStateForm = ({
  className, state, edit, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [countrySelected, setCountrySelected] = useState('');
  const [countries, setCountries] = useState([]);

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const handleCountry = (event) => {
    setCountrySelected(event.target.value);
    handleChange(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: e.target?.name?.value,
      country_id: e.target?.country_id?.value === '0' ? null : e.target?.country_id?.value,
      is_available: e.target?.is_available?.value
    };

    if (data.name.length === 0) {
      errors.push('El nombre del estado es obligatorio');
    }

    if (data.country_id.value === '') {
      errors.push('El campo país es obligatorio');
    }

    if (data.name.length === 0 || data.country_id.value === '') {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await StateService.update(state.id, data)
      : await StateService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/estados', { replace: true });
    } else {
      setErrors(response.errors);
    }
  };

  const deleteState = async () => {
    if (state?.id) {
      const response = await StateService.delete(state.id);
      if (!response) {
        navigate('/app/estados', { replace: true });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await CountryService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar los países']);
        return;
      }

      setCountries(response.data);
    })();
  }, []);

  useEffect(() => {
    setCountrySelected(state.country_id);
  }, [state.country_id]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Estado"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="pais">Seleccione un país</InputLabel>
                <Select
                  required
                  name="country_id"
                  labelId="pais"
                  label="Selecciona un país"
                  value={countrySelected}
                  onChange={handleCountry}
                >
                  <MenuItem value="0" />
                  {
                    countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        { country.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el nombre"
                label="Nombre"
                name="name"
                onChange={handleChange}
                required
                value={state?.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="is_available">Está disponible</InputLabel>
                <Select
                  required
                  name="is_available"
                  labelId="Está disponible"
                  label="Selecciona una opción"
                  value={state?.is_available}
                  onChange={handleChange}
                >
                  {
                    [true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        { answer.toString() === 'true' ? 'Si' : 'No' }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            { edit ? 'Editar' : 'Guardar' }
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteState} />
    </form>
  );
};

CreateStateForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  state: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    country_id: PropTypes.string,
    is_available: PropTypes.bool
  })
};

CreateStateForm.defaultProps = {
  className: '',
  edit: false,
  onChange: () => {},
  state: {
    id: null,
    name: '',
    country_id: '',
    is_available: false
  },
};

export default CreateStateForm;
