import HttpRequest from './HttpRequest';

export default class ChatSupportService extends HttpRequest {
  static endpoint = 'support-chats';

  static async getAll() {
    this.endpoint = 'support-chats';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'support-chats';
    const response = await this.get(id);
    return response;
  }

  static async changeStatusById(id, status) {
    this.endpoint = `support-chats/${id}/${status}`;
    const response = await this.putNew({ status });
    return response;
  }

  static async sendMessage(id, message) {
    this.endpoint = `support-chats/${id}/create-message`;
    const response = await this.post(message);
    return response;
  }
}
