import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

const customStyles = (styles) => {
  return {
    container: (containerStyles) => ({
      ...containerStyles,
      fontFamily: '"Poppins", sans-serif',
      ...styles?.container
    }),
    control: (controlStyles, { isFocused }) => ({
      ...controlStyles,
      borderColor: isFocused ? '#009739' : '#ccc',
      boxShadow: isFocused ? '#009739 0 0 0 1px' : undefined,
      '&:hover': {
        borderColor: isFocused && '#009739'
      },
      cursor: 'pointer',
      ...styles?.control
    }),
    menu: (menuStyles) => ({
      ...menuStyles,
      zIndex: 2,
      '::-webkit-scrollbar': {
        width: '4px'
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '20px',
        backgroundColor: '#009739'
      },
      ...styles?.menu
    }),
    menuList: (menuListStyles) => ({
      ...menuListStyles,
      '::-webkit-scrollbar': {
        width: '4px'
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '20px',
        backgroundColor: '#009739'
      },
      ...styles?.menuList
    }),
    option: (optionStyles, { isDisabled, isFocused, isSelected }) => {
      let backgroundColor = '';
      let bgColorActive = '';

      if (isDisabled) backgroundColor = undefined;
      else if (isSelected) backgroundColor = '#009739';
      else if (isFocused) backgroundColor = '#97D4AE';
      else backgroundColor = undefined;

      if (!isDisabled) {
        if (isSelected) bgColorActive = '#009739';
        else bgColorActive = '#97D4AE';
      } else bgColorActive = undefined;

      return {
        ...optionStyles,
        backgroundColor,
        color: isSelected ? '#fff' : '#000',
        cursor: 'pointer',

        ':active': {
          ...optionStyles[':active'],
          backgroundColor: bgColorActive
        },
        ...styles?.option
      };
    },
    input: (inputStyles) => ({
      ...inputStyles,
      ...styles?.input
    }),
    placeholder: (placeholderStyles) => ({
      ...placeholderStyles,
      fontSize: 15,
      ...styles?.placeholder
    }),
    singleValue: (valueStyles) => ({
      ...valueStyles,
      fontSize: 14,
      ...styles?.singleValue
    })
  };
};

const Selector = ({ type, styles, ...rest }) => {
  switch (type) {
    case 'async-creatable':
      return <AsyncCreatableSelect styles={customStyles(styles)} {...rest} />;
    case 'creatable':
      return <CreatableSelect styles={customStyles(styles)} {...rest} />;
    default:
      return <Select styles={customStyles(styles)} {...rest} />;
  }
};

Selector.propTypes = {
  styles: PropTypes.object,
  type: PropTypes.oneOf(['creatable', 'async-creatable', 'default'])
};

Selector.defaultProps = {
  styles: {},
  type: 'default'
};

export default Selector;
