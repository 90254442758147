import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import BannerCard from './BannerCard';
import BannerService from '../../../services/BannerService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  BannerCard: {
    height: '100%'
  }
}));

const BannerList = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [banners, setBanners] = useState([]);

  const foo = async () => {
    const fetchData = typeof id !== 'undefined'
      ? await BannerService.getById(id)
      : await BannerService.getAll();

    if (fetchData?.code === 200) {
      if (id) {
        setBanners([fetchData.data]);
      } else {
        setBanners(fetchData.data);
      }
    }
  };

  useEffect(() => {
    foo();
  }, []);

  useEffect(() => {
    if (id && id?.length > 0) {
      foo();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Anuncios"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            {banners.map((banner) => (
              <Grid
                item
                key={banner.id}
                lg={4}
                md={6}
                xs={12}
              >
                <BannerCard
                  className={classes.PartnerCard}
                  banner={banner}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            color="primary"
            count={3}
            size="small"
          />
        </Box>
      </Container>
    </Page>
  );
};

export default BannerList;
