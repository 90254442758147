import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import reducers from './reducers';

export const reduxStore = () => {
  // Combined Reducers
  const combinedReducers = combineReducers(reducers);

  // Reducers
  const reducer = (state = {}, action) => {
    return combinedReducers(state, action);
  };

  // Create global state using reducers, middlewares and redux-dev-tools
  const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunk)),
  );

  return store;
};

export default reduxStore();
