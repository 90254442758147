import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  }
}));

const Results = ({
  activeView,
  foods,
  zonaNames,
  city,
  className,
  data,
  ...rest
}) => {
  const classes = useStyles();

  // const [limit, setLimit] = useState(100);
  // const [page, setPage] = useState(0);

  // const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });

  // const handleLimitChange = (event) => {
  //   setLimit(event.target.value);
  // };

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  // };
  const foodsList = () => {
    return (
      <TableBody>
        <TableRow
          hover
        >
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Arroces
            </Typography>
          </TableCell>

          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Arroces}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Asiática
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Asiática}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Bebidas
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Bebidas}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              C.. Típica
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.['C.. Típica']}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              C.. de Mar
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.['C.. de Mar']}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Comida Saludable
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.['Comida Saludable']}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Comida rápida
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.['Comida rápida']}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Helados y Postres
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.['Helados y Postres']}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Mexicana
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Mexicana}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Panadería
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Panadería}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Parrilla
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Parrilla}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Pastas
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Pastas}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Pizzas
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Pizzas}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Pollo
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Pollo}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              Sushi
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="body2"
            >
              {foods?.Sushi}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1000}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Categoria
                  </TableCell>
                  <TableCell>
                    Conteo de sucursales
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              {activeView ? foodsList() : <div />}
            </Table>
          </Box>
        </PerfectScrollbar>
        {/* <TablePagination
          component="div"
          count={zones.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 100]}
          labelRowsPerPage="Filas por página"
        /> */}
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  city: PropTypes.string,
  zonaNames: PropTypes.array,
  foods: PropTypes.object,
  activeView: PropTypes.bool
};

export default Results;
