import HttpRequest from './HttpRequest';

export default class TermsAndConditionsService extends HttpRequest {
  static endpoint = 'term-and-conditions';

  static async getAll() {
    this.endpoint = 'term-and-conditions';
    const response = await this.get();
    return response;
  }

  static async create(data) {
    this.endpoint = 'term-and-conditions';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'term-and-conditions';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'term-and-conditions';
    const response = await this.delete(id);
    return response;
  }
}
