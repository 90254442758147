import React, { useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useSearch from 'src/hooks/useSearch';
import { useSelector } from 'react-redux';
import Toolbar from './Toolbar';
import Results from './Results';
import AdminService from '../../../services/AdminService';
import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AdminList = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['role', 'email', 'phone', 'last_name', 'first_name'] });

  const getAdmins = async () => {
    const fetchData = await AdminService.getAll();
    console.log(fetchData.data);

    if (fetchData?.code === 200) {
      handleInitialData(fetchData.data);
    }
  };

  const refresh = async () => {
    if (user?.id) {
      await getAdmins();
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Administradores"
    >
      <Container maxWidth={false}>
        <Toolbar refresh={refresh} handleSearch={handleSearch} />
        <Box mt={3}>
          <Results data={dataFilterd} onRefresh={refresh} />
        </Box>
      </Container>
    </Page>
  );
};

export default AdminList;
