import React, { useState, useEffect } from 'react';
import mapStyles from 'src/utils/mapStyles';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  Button,
  Modal,
  makeStyles
} from '@material-ui/core';
import GoogleMap from 'src/components/GoogleMap';
import CityService from 'src/services/CityService';
import StateService from 'src/services/StateService';

const useStyles = makeStyles(() => ({
  modalContent: {
    minWidth: '100%',
    width: '100%'
  },
  buttons: {
    display: 'inline-block'
  },
  ml: {
    marginLeft: '5px'
  },
  box: {
    display: 'flex',
    width: '100%'
  }
}));

const GoogleMapsModal = ({
  cityId,
  address,
  position,
  isOpen,
  onAccept,
  coverageRadio = 0,
  openModalPolygon,
  saveCoverageRadio,
  isBranchOffice,
  savePosition
  // onLocate
}) => {
  const classes = useStyles();
  const [center, setCenter] = useState(null);
  const [radius, setRadius] = useState(0);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const confirm = () => {
    if (isBranchOffice) saveCoverageRadio(center, radius);
    else savePosition(center);
    onAccept();
  };

  const handleMarkerPosition = (latitude, longitude) => {
    setCenter({ lat: latitude, lng: longitude });
  };

  const handleCirclePosition = (newRadius) => {
    setRadius(newRadius);
  };

  const getCity = async () => {
    try {
      const response = await CityService.getById(cityId);
      if (response.code === 200) {
        setCity(response?.data?.name);
        return response?.data?.state_id;
      }
    } catch (err) {
      console.error(err.message);
    }
    return '';
  };

  const getState = async (stateId) => {
    try {
      const response = await StateService.getById(stateId);
      if (response?.code === 200) {
        setState(response.data.name);
      }
    } catch (err) {
      console.error(err.message);
    }
    return '';
  };

  useEffect(() => {
    (async () => {
      const stateId = await getCity();
      getState(stateId);
    })();

    setCenter(position);
    setRadius(coverageRadio);
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onAccept}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Dialog
        open={isOpen}
        onClose={onAccept}
        aria-labelledby="simple-dialog-title"
      >
        <Box display="flex" justifyContent="space-between">
          <DialogTitle id="simple-dialog-title">
            {isBranchOffice ? 'Radio de cobertura' : ''}
          </DialogTitle>
          {isBranchOffice && (
            <Box
              pr={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => openModalPolygon()}
              >
                Crear poligono
              </Button>
            </Box>
          )}
        </Box>
        <Box p={2} pt={0} className={classes?.modalContent}>
          <Grid container>
            <Grid className={classes.box}>
              <GoogleMap
                draggable
                address={address}
                city={city}
                state={state}
                center={center}
                setPosition={handleMarkerPosition}
                setRadius={handleCirclePosition}
                coverageRadio={radius}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={mapStyles} />}
                containerElement={
                  <div style={{ width: '568px', height: '400px' }} />
                }
                mapElement={<div style={mapStyles} />}
              />
            </Grid>
            <Grid item container justify="flex-end">
              <Box mt={1} className={classes.buttons}>
                {/*
                    <Button onClick={onLocate}>
                      Mi Ubicacion
                    </Button>
                  */}
                <Button onClick={onAccept}>Cancelar</Button>
                <Button
                  className={classes.ml}
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={confirm}
                >
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Modal>
  );
};

GoogleMapsModal.propTypes = {
  address: PropTypes.string,
  cityId: PropTypes.string,
  isOpen: PropTypes.bool,
  onAccept: PropTypes.func,
  // onLocate: PropTypes.func,
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  coverageRadio: PropTypes.number,
  openModalPolygon: PropTypes.func,
  saveCoverageRadio: PropTypes.func,
  isBranchOffice: PropTypes.bool,
  savePosition: PropTypes.func
};

GoogleMapsModal.defaultProps = {
  isBranchOffice: false
};

export default GoogleMapsModal;
