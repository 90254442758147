import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Card,
  Grid,
  Box,
  Divider,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { Landscape, MoreVert as MoreVertIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  }
}));

const CountryCard = ({ className, country, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(null);

  const toggleMenu = (e) => setMenuIsOpen(!menuIsOpen ? e.currentTarget : null);

  const redirect = () => {
    navigate(`/app/paises/${country.id}/editar`, { replace: true });
    toggleMenu();
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <Avatar
              className={classes.avatar}
              alt="country"
              src={country.flag}
              variant="square"
            />
          </Grid>
          <Grid container item xs={9} alignItems="flex-start">
            <Grid container item xs={10} alignItems="flex-start">
              <Box mt={1}>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  {country.name}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={2} alignItems="flex-start">
              <IconButton onClick={toggleMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuIsOpen}
                open={Boolean(menuIsOpen)}
                onClose={toggleMenu}
              >
                <MenuItem onClick={() => redirect('edit')}>Editar</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2}>
        <Grid item container justify="flex-end" alignItems="center">
          <Box mr={1}>
            <Landscape color="action" />
          </Box>
          <Typography color="textSecondary" display="inline" variant="body2">
            {country?.states?.length || 0}
            {' '}
            {country?.states?.length === 1 ? 'estado' : 'estados'}
          </Typography>
        </Grid>
      </Box>
    </Card>
  );
};

CountryCard.propTypes = {
  className: PropTypes.func,
  country: PropTypes.object.isRequired
};

CountryCard.propTypes = {
  className: () => {},
};

export default CountryCard;
