import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { getHour } from 'src/utils/formatDate';

const useStyles = makeStyles(() => ({
  profile: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(38, 50, 56, 0.12)'
    }
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    wordBreak: 'break-word'
  },
  time: {
    fontWeight: 600,
    fontSize: 12
  },
  message: {
    fontSize: 13,
    color: '#8696a0',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  userMessage: {
    fontWeight: 600,
    color: '#009739'
  }
}));

const Profile = ({
  chat,
  setChat
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      width="100%"
      pt={2}
      px={2}
      className={classes.profile}
      onClick={() => setChat(chat?.id, chat?.color)}
    >
      <Box width="fit-content" height={58}>
        <Avatar
          style={{
            backgroundColor: chat?.color,
          }}
        >
          {getInitials(chat?.user?.first_name)}
        </Avatar>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="87%"
        borderBottom="1px solid rgba(38, 50, 56, 0.12)"
        pb={2}
        ml={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="70%"
        >
          <Typography
            variant="body2"
            className={classes.name}
          >
            {`${chat?.user?.first_name} ${chat?.user?.last_name}`}
          </Typography>
          <Typography
            variant="body2"
            className={clsx(
              classes.message,
              {
                [classes.userMessage]: chat?.last_message?.sent_by === 'user' && chat?.status !== 'finished'
              }
            )}
            style={{
              marginTop: 3,
            }}
          >
            {chat?.last_message?.message}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="end"
          width="30%"
          ml={3}
        >
          <Typography
            variant="body2"
            className={classes.time}
          >
            {getHour(chat?.last_message?.created_at)}
          </Typography>
          {chat?.unanswered_messages_by_admin > 0 && chat?.status !== 'finished' && (
            <Box
              mt={0.3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="#009739"
              color="#fff"
              borderRadius="50%"
              width={23}
              height={23}
              p={1}
            >
              <Typography
                variant="body2"
                className={classes.time}
              >
                {chat?.unanswered_messages_by_admin}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;

Profile.propTypes = {
  chat: PropTypes.object,
  setChat: PropTypes.func,
};
