import axios from 'axios';
import HttpRequest from './HttpRequest';

export default class EnvironmentService extends HttpRequest {
  static endpoint = 'environment';

  static async getAll() {
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    const response = await this.put(id, data);
    return response;
  }

  static async getMonitorGoogleMapStyles() {
    try {
      const uri = 'https://us-east1-datoxco.cloudfunctions.net/InitHandler/environment/find?key=monitor_google_maps_style';
      const response = await axios.get(uri);

      if (response?.status === 200) {
        return {
          data: response?.data,
          code: 200,
          message: 'Estilo del mapa',
          errors: [],
        };
      }

      return {
        data: null,
        code: 404,
        message: '',
        errors: ['No se encontró un registro con esa key'],
      };
    } catch (e) {
      console.log(e);
      return {
        data: null,
        code: 500,
        message: '',
        errors: [e.message]
      };
    }
  }
}
