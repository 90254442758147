import { React, useState, useEffect } from 'react';
import { Box, makeStyles, Typography, Card, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CancelRounded } from '@material-ui/icons';
import { getDate } from 'src/utils/formatDate';

const useStyles = makeStyles((theme) => ({
  scroll: {
    overflow: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  },
  overlay: {
    backgroundColor: 'rgba(46, 44, 54, 0.4)',
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    zIndex: 4,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  modal: {
    borderRadius: '8px',
    width: 500,
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    animation: 'showPopUp 0.5s',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      height: 'fit-content'
    }
  },
  panel: {
    height: '100%',
    width: '100%',
    position: 'absolute'
  },
  header: {
    width: '100%',
    padding: '10px 15px',
    borderBottom: '1px solid #e6ebf1',
    zIndex: '1000'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h3': {
      fontWeight: 'bold',
      fontSize: '22px'
    }
  },
  card: {
    overflow: 'unset',
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(2.5),
    '&:last-child': {
      marginBottom: 0
    },
    borderRadius: 12,
    boxShadow:
      'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '15%'
  }
}));

const ModalInfo = ({ toggleModal, lead }) => {
  const classes = useStyles();
  const [state, setState] = useState('');
  useEffect(() => {
    if (lead.status === 'pending') {
      setState('Pendiente');
    } else if (lead.status === 'in_process') {
      setState('En proceso');
    } else if (lead.status === 'accepted') {
      setState('Aceptado');
    } else {
      setState('Rechazado');
    }
  }, []);

  return (
    <Box className={classes?.overlay}>
      <Box className={classes.panel} onClick={toggleModal} />
      <Box className={classes.modal}>
        <Box className={classes.header}>
          <Box className={classes.title}>
            <Typography variant="h3">Más información</Typography>
            <CancelRounded
              onClick={() => toggleModal()}
              style={{ cursor: 'pointer' }}
              color="primary"
            />
          </Box>
        </Box>
        <Box
          className={classes.scroll}
          bgColor="#000"
          maxHeight="450px"
          mt={2}
          m={2}
          mb={2}
          pb={1}
          pr={1}
        >
          <Card className={classes.card}>
            <Box
              mt={0.5}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                style={{ fontWeight: 600, fontSize: '16px' }}
                variant="body2"
              >
                Datos personales
              </Typography>
            </Box>
            <Typography>
              Nombre: {lead.name}
              <br />
              Correo: {lead.email}
              <br />
              Telefono: {lead.phone}
              <br />
              Dirección: {lead.address}
              <br />
            </Typography>
          </Card>
          <Card className={classes.card}>
            <Box
              mt={0.5}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                style={{ fontWeight: 600, fontSize: '16px' }}
                variant="body2"
              >
                Datos de la empresa
              </Typography>
            </Box>
            <Typography>
              Empresa: {lead.social_reason}
              <br />
              Tipo de empresa: {lead.bussines_type}
              <br />
              Ciudad: {lead.city}
              <br />
              Fecha de creación: {getDate(lead.created_at)}
              <br />
              Estado: {state}
              <br />
              {lead.how_find?.length !== 0 && (
                <Typography>
                  Como se entero de nosotros: {lead.how_find}
                </Typography>
              )}
              {lead.notes?.length !== 0 && lead.notes?.trim() && (
                <Typography>Observaciones: {lead.notes}</Typography>
              )}
            </Typography>
          </Card>
          {lead.images?.length !== 0 && (
            <Card className={classes.card}>
              <Box
                mt={0.5}
                mb={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  style={{ fontWeight: 600, fontSize: '16px' }}
                  variant="body2"
                >
                  Imagenes
                </Typography>
              </Box>
              <Grid container>
                {lead.images.map((img) => (
                  <Grid item xs={4}>
                    <Box m={1}>
                      <img className={classes.img} src={img} alt="imagen" />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ModalInfo.propTypes = {
  toggleModal: PropTypes.func,
  lead: PropTypes.object
};

export default ModalInfo;
