import {
  useEffect,
  useState,
  React,
  useRef,
  useCallback
} from 'react';
import {
  Box,
  TextField,
  Button,
  makeStyles,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
import { CancelRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { selector as UserSelector } from 'src/redux/ducks/user';
import { useSelector } from 'react-redux';
import LoadingBox from 'src/components/LoadingBox';
import TransferService from '../services/TransferSevice';
import useError from '../hooks/useErrors';
import TicketDebt from './TicketDebt';

const useStyles = makeStyles(() => ({
  overlay: {
    backgroundColor: 'rgba(46, 44, 54, 0.4)',
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    zIndex: '3',
  },
  modal: {
    border: '1px solid #e6ebf1',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    bottom: '0',
    animation: 'showPopUp 0.5s',
  },
  pane: {
    height: '100%',
    width: '100%',
    position: 'fixed'
  },
  header: {
    padding: '10px 15px',
    borderBottom: '1px solid #e6ebf1',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h3': {
      fontWeight: 'bold',
      fontSize: '22px'
    }
  },
  valor: {
    '& [type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&[type=number]': {
      MozAppearance: 'textfield'
    }
  }
}));

const DriverModal = ({ toggleModal, driver }) => {
  const { user } = useSelector(UserSelector);
  const componentRef = useRef(null);
  const classes = useStyles();
  const matches = useMediaQuery('screen and (min-width: 769px)');
  const [val, setVal] = useState('');
  const [totalRes, setTotalRes] = useState(0);
  const { setErrors } = useError();
  const [fecha, setFecha] = useState('');
  const [loading, setLoading] = useState(false);
  const [localErrors, setLocalErrors] = useState([]);
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const print = useReactToPrint({
    content: reactToPrintContent
  });
  const totalDeuda = (trans) => {
    let total = 0;
    trans.forEach((item) => {
      const deuda = item.value;
      total += deuda;
    });
    setTotalRes(total);
  };
  const fetchTransfers = async () => {
    const response = await TransferService.getAll(driver?.id);
    if (response?.success) {
      totalDeuda(response.data.transfers);
    }
  };
  useEffect(() => {
    fetchTransfers();
  }, []);
  const handleChange = (e) => {
    setVal(e?.target?.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      account: {
        user_id: driver?.id,
        user_type: 'DRIVER'
      },
      value: Number(val),
      concept_id: '362ed203-2606-422f-a0c9-38bbe5129630',
      detail: 'xd'
    };

    if (val.length === 0) {
      errors.push({ field: 'valor', error: 'El valor es obligatorio' });
    }
    if (data.value <= 0) {
      errors.push({ field: 'valor', error: 'El valor es invalido' });
    }
    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalErrors(errors);
      return;
    }
    const response = await TransferService.create(data);
    if (response?.success) {
      setLoading(true);
      setErrors([]);
      setTotalRes(totalRes + data.value);
      setFecha(response.data.created_at);
      print();
      setTimeout(() => {
        setLoading(false);
        toggleModal();
      }, 1500);
    }
  };
  return (
    <Box
      className={classes?.overlay}
      display={matches ? 'flex' : null}
      justifyContent={matches ? 'center' : null}
      alignItems={matches ? 'center' : null}
    >
      <Box onClick={() => toggleModal()} className={classes.pane} />
      <Box
        className={classes?.modal}
        width={matches ? 450 : '100%'}
        height={matches ? 'fit-content' : '100%'}
        position={matches ? 'relative' : 'absolute'}
      >
        <Box className={classes.header}>
          <Box className={classes.title}>
            <Typography variant="h3">
              Pago deuda
            </Typography>
            <CancelRounded onClick={() => toggleModal()} style={{ cursor: 'pointer' }} color="primary" />
          </Box>
        </Box>
        <Box
          p={2}
        >
          <LoadingBox loading={loading}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={2}
            >
              <Typography variant="h5">
                La deuda total es:
                {' $ '}
                <CurrencyFormat
                  value={totalRes}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Typography>
            </Box>
            <Box p={2.5} display="flex" flexDirection="column" justifyContent="space-between" textAlign="center" alignItems="center">
              <form noValidate autoComplete="off">
                <TextField
                  className={classes.valor}
                  type="number"
                  error={localErrors.some((e) => e.field === 'valor')}
                  helperText={localErrors?.find((e) => e.field === 'valor')?.error || ''}
                  label="Valor"
                  placeholder="Valor a pagar"
                  name="valor"
                  value={val}
                  onChange={handleChange}
                />
              </form>
              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Box
                  m={2}
                >
                  <Button
                    onClick={() => toggleModal()}
                    variant="contained"
                  >
                    Cancelar
                  </Button>
                </Box>
                <Box
                  m={2}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Pagar
                  </Button>
                </Box>
              </Box>
            </Box>
          </LoadingBox>
        </Box>
      </Box>
      <Box
        display="none"
      >
        <TicketDebt
          ref={componentRef}
          driver={driver}
          saldoAct={totalRes}
          valorPag={val}
          user={user}
          fecha={fecha}
        />
      </Box>
    </Box>
  );
};

DriverModal.propTypes = {
  toggleModal: PropTypes.func,
  driver: PropTypes.object,
};
export default DriverModal;
