import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  Input,
  Chip,
  makeStyles
} from '@material-ui/core';
import adminsPhone from 'src/utils/adminsPhone';
import { selector as UserSelector } from 'src/redux/ducks/user';
import CityService from 'src/services/CityService';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import AdomiCategoryService from '../../../services/AdomiCategoryService';
// import data from 'src/views/customer/CustomerListView/data';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  }
}));

const getStyles = (city, citiesSelected) => {
  return {
    backgroundColor:
      citiesSelected.indexOf(city) === -1
        ? 'transparent'
        : 'rgba(67, 160, 72, 0.9)',
    color: citiesSelected.indexOf(city) === -1 ? '#000' : '#fff'
  };
};

const CreateBrandForm = forwardRef(({
  className,
  category,
  edit,
  onChange,
  hasImage,
  ...rest
}, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useSelector(UserSelector);
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [categorySelected, setCategorySelected] = useState('');
  const [mainCategories, setMainCategories] = useState([]);
  const [citiesSelected, setCitiesSelected] = useState([]);
  const [cities, setCities] = useState([]);

  const handleChange = (event) => {
    onChange({
      ...category,
      [event.target.name]: event.target.value
    });
  };

  const handleCities = (e) => {
    console.log(e.target.value);
    setCitiesSelected(e.target.value);
    onChange({
      ...category,
      [e.target.name]: e.target.value.map((item) => {
        const city = JSON.parse(item);
        return {
          id: city.id,
          name: city.name
        };
      })
    });
  };
  const handleCategory = (event) => {
    setCategorySelected(event.target.value);
    handleChange(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const file = ref.current.files[0];
    const data = {
      name: e.target?.name?.value,
      image: e.target?.image?.value,
      is_main: e.target?.parent_id?.value === '0',
      parent_id:
        e.target?.parent_id?.value === '0'
          ? null
          : e.target?.parent_id?.value,
      order: e.target?.order?.value,
      is_develop: e.target?.is_develop?.value,
      platform: e.target?.platform?.value, //
      cities:
        categorySelected === '0'
          ? citiesSelected.map((item) => {
            const city = JSON.parse(item);
            return city.id;
          })
          : [] || [],
    };

    if (data.parent_id === '') {
      errors.push('El campo categoría es obligatorio');
    }
    if (data.name.length === 0) {
      errors.push('El nombre de la categoría es obligatoria');
    }

    if (!edit && file === undefined) {
      errors.push('La imagen es obligatoria');
    }

    if (data.platform.length === 0) {
      errors.push('El compo es obligatorio');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    const response = edit
      ? await AdomiCategoryService.update(category.id, data)
      : await AdomiCategoryService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (response?.data?.image !== null && file !== undefined) {
        await AdomiCategoryService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await AdomiCategoryService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/adomi-categorias');
          }, 500);
        }
      }
      if (response?.data?.image === null && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await AdomiCategoryService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/adomi-categorias');
          }, 500);
        }
      }
      navigate('/app/adomi-categorias');
    } else {
      setErrors(response.errors);
    }
  };

  const deleteAdomiCategory = async () => {
    if (category?.id) {
      await AdomiCategoryService.deleteImage(category.id);
      const response = await AdomiCategoryService.remove(category.id);
      if (!response) {
        navigate('/app/adomi-categorias', { replace: true });
      }
    }
  };

  const fetchCities = async () => {
    const response = await CityService.getAll();
    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
      return;
    }
    setCities(response.data);
  };

  useEffect(() => {
    (async () => {
      const response = await AdomiCategoryService.getAll();

      if (response?.code !== 200) {
        setErrors([
          'Ocurrió un error al intentar mostrar las categorías principales'
        ]);
        return;
      }

      setMainCategories(response.data);
    })();
    fetchCities();
  }, []);

  useEffect(() => {
    setCategorySelected(category.parent_id);
  }, [category.parent_id]);

  useEffect(() => {
    if (edit) {
      setCitiesSelected(
        category?.cities.map((city) => {
          return JSON.stringify({
            id: city.id,
            name: city.name
          });
        })
      );
    }
  }, [edit, category]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card style={{ overflow: 'inherit' }}>
        <CardHeader
          subheader="La información puede ser editada"
          title="Categoría"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={5} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="category">
                  Selecciona una categoría
                </InputLabel>
                <Select
                  required
                  name="parent_id"
                  labelId="category"
                  label="Selecciona una categoría"
                  value={categorySelected}
                  onChange={handleCategory}
                >
                  <MenuItem value="0">Categoría Principal</MenuItem>
                  {mainCategories.map((mainCategory) => (
                    <MenuItem key={mainCategory.id} value={mainCategory.id}>
                      {mainCategory.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Nombre"
                placeholder="Escriba el nombre"
                name="name"
                onChange={handleChange}
                required
                value={category.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Orden"
                placeholder="1"
                name="order"
                onChange={handleChange}
                required
                value={category?.order}
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="is_develop">Es de desarrollo</InputLabel>
                <Select
                  required
                  name="is_develop"
                  labelId="Categoría de desarrollo"
                  label="Selecciona una opción"
                  value={category?.is_develop === true}
                  onChange={handleChange}
                >
                  {[true, false].map((answer) => (
                    <MenuItem key={answer} value={answer}>
                      {answer ? 'Si' : 'No'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={4} xs={12}>
              <FormControl
                required
                variant="outlined"
                className={classes.formControl}
                disabled={!category?.is_main}
              >
                <InputLabel id="category">Plataforma</InputLabel>
                <Select
                  required
                  name="platform"
                  labelId="Categoría de plataforma"
                  label="Selecciona una opción"
                  value={category?.platform}
                  onChange={handleChange}
                >
                  {['adomi', 'salsamentaria'].map((answer) => (
                    <MenuItem key={answer} value={answer}>
                      {answer}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {categorySelected === '0' && (
              <Grid item md={8} xs={12}>
                <FormControl
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="grouped-select" id="cities">
                    Seleccione una ciudad
                  </InputLabel>
                  <Select
                    // native
                    required
                    multiple
                    name="cities"
                    labelId="cities"
                    label="Selecciona alguna ciudad"
                    value={citiesSelected || ''}
                    defaultValue=""
                    onChange={handleCities}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={JSON.parse(value).id}
                            label={JSON.parse(value).name}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {cities.map((city) => (
                      <MenuItem
                        style={getStyles(
                          JSON.stringify({
                            id: city.id,
                            name: city.name
                          }),
                          citiesSelected
                        )}
                        value={JSON.stringify({
                          id: city.id,
                          name: city.name
                        })}
                      >
                        {city?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <input
              name="logo[]"
              type="file"
              ref={ref}
              style={{ display: 'none' }}
            />
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {edit && adminsPhone.includes(user?.phone.toString()) && (
            <Box mr={2}>
              <Button
                type="button"
                onClick={() => toggleModal()}
                className={classes.error}
                variant="contained"
              >
                Eliminar
              </Button>
            </Box>
          )}
          <Button type="submit" color="primary" variant="contained">
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteAdomiCategory} />
    </form>
  );
});

CreateBrandForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    is_main: PropTypes.bool,
    parent_id: PropTypes.string,
    order: PropTypes.number,
    is_develop: PropTypes.bool,
    platform: PropTypes.string,
    cities: PropTypes.array,
    sub_categories: PropTypes.array
  })
};

CreateBrandForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => {},
  category: {
    id: null,
    name: '',
    image: '',
    is_main: true,
    parent_id: '0',
    order: '0',
    is_develop: false,
    platform: '',
    cities: []
  }
};

export default CreateBrandForm;
