import React, { useEffect, useState } from 'react';
import mapStyles from 'src/utils/mapStyles';
import clsx from 'clsx';
import Pusher from 'pusher-js';
import GoogleMapsOrderModal from 'src/components/GoogleMapOrderModal';
import MarkerPNG from 'src/assets/images/marker.png';
import MarkerWarningPNG from 'src/assets/images/marker_warning.png';
import MarketShop from 'src/assets/images/marker_shop.png';
import EnvironmentService from 'src/services/EnvironmentService';
import PerfectScrollbar from 'react-perfect-scrollbar';
import dayjs from 'dayjs';
import orderBy from 'src/utils/arrays';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Card,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // Typography,
  makeStyles,
  TablePagination,
  Tooltip
} from '@material-ui/core';
import { IoPaperPlaneSharp } from 'react-icons/io5';
import FirebaseApp from 'src/utils/firebase';
import CenterCityMaps from 'src/utils/CenterCityMaps';
import {
  getFirestore, collection, onSnapshot, query, where
} from 'firebase/firestore';
import store from '../../redux/store';
import './MapDriverView.css';
import { selector as UserSelector } from '../../redux/ducks/user';
import OrderService from '../../services/OrderService';
import Toolbar from './Toolbar';
import { actions as AlertActions } from '../../redux/ducks/alert';
import OrderDetailModal from '../../components/OrderDetailModal';

const Firestone = getFirestore(FirebaseApp);

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 64px)',
    position: 'relative',
  },
  statusCell: {
    position: 'relative'
  },
  moreVert: {
    cursor: 'pointer'
  },
  container: {
    height: '360px',
    // overflow: 'scroll'
  }
}));

const monitorMapStyles = null;

const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER
});

const MapDriverView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(UserSelector);
  const [center, setCenter] = useState({ lat: 4.5319550262015955, lng: -76.10316395139068 });
  const [markersDrivers, setMarkersDrivers] = useState([]);
  const [markersSelected, setMarkersSelected] = useState([]);
  const [limit, setLimit] = useState(100);
  const [googleMapStyles, setGoogleMapStyles] = useState(monitorMapStyles);
  const [orders, setOrders] = useState([]);
  const [detail, setDetail] = useState(null);
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState(null);
  const [isDev, setIsDev] = useState(false);
  const [page, setPage] = useState(0);

  // const handleLimitChange = (event) => {
  //   setLimit(event.target.value);
  // };

  const markerSelect = (order) => {
    const markerSelectData = {
      uuid: order?.data_branch_office?.id,
      is_available: order?.data_branch_office?.is_available,
      position: {
        lat: order?.data_branch_office?.latitude,
        lng: order?.data_branch_office?.longitude
      },
      icon: order?.data_branch_office?.brand?.logo || MarketShop,
      name: order?.data_branch_office?.brand?.name
    };
    setCenter(markerSelectData.position);
    setMarkersSelected([markerSelectData]);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleOrderDetailClose = () => {
    setDetail(null);
  };

  const handleFilter = (value) => setFilter(value);

  const handleSearch = (value) => setSearch(value);

  const handleDev = (e) => {
    setIsDev(e?.target?.checked);
  };

  const toggleInfoWindow = (id, _markers) => {
    if (_markers && Array.isArray(_markers) && _markers?.length > 0) {
      setMarkersDrivers(_markers?.reduce((array, marker) => {
        if (marker?.uuid === id) {
          return array.concat({
            ...marker,
            infoWindow: !marker?.infoWindow
          });
        }

        return array.concat({
          ...marker,
          infoWindow: false
        });
      }, []));
    }
  };

  const date = dayjs(new Date()).format('YYYY-MM-DD');

  const getOrders = async () => {
    const response = await OrderService.getAllByDate(date || null);

    if (response?.data) {
      const data = response?.data?.orders.reduce((array, item) => {
        let statusOrder = 0;
        switch (item?.status) {
          case 'in_progress':
            statusOrder = 1;
            break;
          case 'finished':
            statusOrder = 2;
            break;
          case 'rejected':
            statusOrder = 3;
            break;
          case 'cancelled':
            statusOrder = 4;
            break;
          default:
            statusOrder = 0;
        }

        return array.concat({ ...item, statusOrder });
      }, []);

      setOrders(orderBy(data, 'statusOrder'));
    }
  };

  const refresh = async () => {
    if (user?.id) {
      getOrders();
    }
  };

  useEffect(() => {
    if (user?.id) {
      getOrders();

      const channel = pusher.subscribe('adomi-dashboard');

      ['new-order', 'create-order-product', 'create-order-status', 'command-order', 'finish-order', 'client-cancel-order', 'manager-cancel-order']
        .map((item) => {
          channel.bind(item, () => {
            refresh();
            if (['new-order', 'create-order-product']?.some((event) => event === item)) {
              dispatch(AlertActions.set());
            }
          });
          return item;
        });
    }
  }, [user]);

  useEffect(() => {
    refresh();
  }, [date]);

  const fetchData = async () => {
    try {
      const cityCenter = await CenterCityMaps();
      setCenter(cityCenter);

      const cityId = store.getState()?.app?.city === 'general' ? 'c1e4bcc9-eb84-4653-872c-e38f8de4bf79' : store.getState()?.app?.city;
      const q = query(collection(Firestone, 'driverslocation'), where('city_id', '==', cityId));

      onSnapshot(q, (querySnapshot) => {
        const drivers = [];
        querySnapshot.forEach((doc) => {
          drivers.push(doc.data());
        });

        setMarkersDrivers(
          drivers?.map((driver) => {
            const uuid = driver?.driver_id;
            const position = { lat: driver?.position?.latitude, lng: driver?.position?.longitude };
            const icon = driver?.orders.length === 0 ? MarkerPNG : MarkerWarningPNG;

            return ({
              ...driver,
              uuid,
              icon,
              position,
              toggle: toggleInfoWindow,
              infoWindow: false
            });
          }) || []
        );
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const getHour = (hour) => {
    const hh = dayjs(hour).get('hour');
    const mm = dayjs(hour).get('minute');

    // return `${hh}:${mm < 10 ? `0${mm}` : mm}`;
    return `${hh < 13 ? hh : hh - 12}:${mm < 10 ? `0${mm}` : mm} ${hh < 12 ? 'AM' : 'PM'}`;
  };

  const getMonitorGoogleMapStyles = async () => {
    const response = await EnvironmentService.getMonitorGoogleMapStyles();

    if (response?.code === 200) {
      setGoogleMapStyles(JSON.parse(response?.data?.value));
    }
  };

  useEffect(() => {
    const channel = pusher.subscribe('MONITOR');
    channel.bind('driver', async () => {
      await fetchData();
    });

    getMonitorGoogleMapStyles();
    fetchData();
  }, []);

  const filterDev = (ors) => {
    if (isDev) return ors;

    return ors?.filter((order) => !order?.data_branch_office?.is_develop);
  };

  const filterData = (ors) => {
    if (!filter) return ors;

    return ors?.filter((order) => order?.status === filter);
  };

  const searchData = (ors) => {
    if (!search) return ors;

    return ors?.reduce((array, order) => {
      if (order?.reference?.toString()?.search(search) !== -1) {
        return array.concat(order);
      }
      return array;
    }, []);
  };

  const ordersData = searchData(filterData(filterDev(orders)));
  console.log(ordersData);

  return (
    <div className={classes?.root}>
      {
        googleMapStyles !== null && (
          <GoogleMapsOrderModal
            center={center}
            markers={markersDrivers}
            markerSucursal={markersSelected}
            centerMarker={false}
            styles={googleMapStyles}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp`}
            loadingElement={<div style={mapStyles} />}
            containerElement={<div style={mapStyles} />}
            mapElement={<div style={mapStyles} />}
          />
        )
      }
      <div className="card__orders">
        <div className="card__title">
          <h3>Ordenes sin asignar</h3>
        </div>
        <Box>
          <Toolbar
            refresh={refresh}
            onSearch={handleSearch}
            onChange={handleFilter}
            onDev={handleDev}
          />
        </Box>
        <Card
          className={clsx(classes?.container)}
        >
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="100">
                      Referencia
                    </TableCell>
                    <TableCell>
                      Comercio
                    </TableCell>
                    <TableCell width="100">
                      Inicio
                    </TableCell>
                    <TableCell width="110">
                      Detalle
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderBy(ordersData, 'name').slice(0, limit).map((order) => (
                    <TableRow
                      hover
                      key={order?.id}
                    >
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          #
                          {order?.reference}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          <Tooltip title="Buscar marcador" arrow>
                            <span
                              className="card__marker-selected"
                              onClick={() => markerSelect(order)}
                            >
                              {order?.data_branch_office?.join_name ? `${order?.data_branch_office?.brand?.name} ${order?.data_branch_office?.name}` : order?.data_branch_office?.brand?.name}
                            </span>
                          </Tooltip>

                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                      {order?.data_branch_office?.is_develop && (
                        <Chip label="Desarrollo" className={classes?.in_process} />
                      )}
                      {order?.data_branch_office?.is_demo && (
                        <Box ml={2}>
                          <Chip label="Demo" className={classes?.in_process} />
                        </Box>
                      )}
                    </TableCell> */}
                      <TableCell>
                        <Typography variant="body2">
                          {getHour(order?.created_at)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IoPaperPlaneSharp
                          className={classes?.moreVert}
                          onClick={() => setDetail(order)}
                          size={30}
                          color="#F15A39"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={orders.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPageOptions={[100]}
            rowsPerPage={4}
            labelRowsPerPage="Filas por página"
          />
        </Card>
        {
          detail && (
            <OrderDetailModal detail={detail} onClose={handleOrderDetailClose} />
          )
        }

      </div>
    </div>
  );
};

export default MapDriverView;
