import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useSearch from 'src/hooks/useSearch';
import DriverLeadService from '../../../services/DriverLeadService';
import Results from './Results';
import Toolbar from './Toolbar';

import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DriverLeadView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  // const [leads, setLeads] = useState([]);
  const [filter, setFilter] = useState(null);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['phone', 'name', 'email', 'adress'] });

  const getLeads = async () => {
    const response = await DriverLeadService.getAll();

    if (response?.code === 200 && response?.data?.length > 0) {
      handleInitialData(response?.data);
    }
  };

  const refresh = async () => {
    if (user?.id) {
      getLeads();
    }
  };

  const handleFilter = (value) => setFilter(value);

  const filterData = (lds) => {
    if (!filter) return lds;

    return lds?.filter((lead) => lead?.status === filter);
  };

  useEffect(() => {
    if (user?.id) {
      getLeads();
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Drivers prospectos"
    >
      <Container maxWidth={false}>
        <Toolbar
          onChange={handleFilter}
          refresh={refresh}
          handleSearch={handleSearch}
        />
        <Box mt={3}>
          <Results
            data={filterData(dataFilterd)}
            onRefresh={refresh}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DriverLeadView;
