import { createStructuredSelector } from 'reselect';
import ChatSupportService from 'src/services/ChatSupportService';
import colors from 'src/utils/colorsProfile';

// Define initial state
const initialState = {
  chatsSupports: []
};

// Define types
const GET_CHATS = '@chat/get_chats';
const NEW_CHAT = '@chat/new_chat';
const UPDATE_CHAT = '@chat/update_chat';
const ADD_UNANSWERED_MESSAGE = '@chat/add_unanswered_message';

// Define actions
export const getChats = () => {
  return async (dispatch) => {
    const response = await ChatSupportService.getAll();
    let chats = [];
    if (response.code === 200) {
      chats = response.data.map((chat) => {
        const color = colors[Math.floor(Math.random() * colors.length)];
        return {
          ...chat,
          color
        };
      });
    }
    dispatch({
      type: GET_CHATS,
      payload: chats
    });
  };
};

export const newChat = (chat) => {
  return {
    type: NEW_CHAT,
    payload: chat
  };
};

export const updateChat = (data) => {
  return {
    type: UPDATE_CHAT,
    payload: data
  };
};

export const addUnansweredMsg = (id) => {
  return {
    type: ADD_UNANSWERED_MESSAGE,
    payload: {
      id
    }
  };
};

// Define selector
export const selector = createStructuredSelector({
  pendingChats: (state) => state.chat.chatsSupports.filter((chat) => chat?.status === 'pending'),
  activeChats: (state) => state.chat.chatsSupports.filter((chat) => chat?.status === 'in_progress'),
  finishedChats: (state) => state.chat.chatsSupports.filter((chat) => chat?.status === 'finished'),
});

// Define reducer
export default function chatReducer(state = initialState, action) {
  if (action.type === GET_CHATS) {
    return {
      ...state,
      chatsSupports: action.payload
    };
  }

  if (action.type === NEW_CHAT) {
    return {
      ...state,
      chatsSupports: [...state.chatsSupports, action.payload]
    };
  }

  if (action.type === UPDATE_CHAT) {
    return {
      ...state,
      chatsSupports: state.chatsSupports.map((chat) => {
        if (chat?.id === action.payload?.id) {
          return {
            ...chat,
            ...action.payload,
          };
        }
        return chat;
      })
    };
  }

  if (action.type === ADD_UNANSWERED_MESSAGE) {
    return {
      ...state,
      chatsSupports: state.chatsSupports.map((chat) => {
        if (chat?.id === action.payload?.id) {
          return {
            ...chat,
            unanswered_messages_by_admin: chat?.unanswered_messages_by_admin + 1
          };
        }
        return chat;
      })
    };
  }

  return state;
}
