import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  makeStyles, Divider
} from '@material-ui/core';
import {
  LocationCity, AddToHomeScreen, MoreVert as MoreVertIcon, QuestionAnswer,
  Sort as SortIcon, Category
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 297.5,
    height: 115,
  }
}));

const BannerCard = ({ className, banner, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(null);

  const toggleMenu = (e) => setMenuIsOpen(!menuIsOpen ? e.currentTarget : null);

  const redirect = () => {
    navigate(`/app/anuncios/${banner.id}/editar`, { replace: true });
    toggleMenu();
  };

  const translateRoute = (route) => {
    switch (route) {
      case 'None':
        return 'Ninguna';
      case 'Branch office':
        return 'Sucursal';
      case 'Discounts':
        return 'Promociones';
      default:
        return route;
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <Avatar
              className={classes.avatar}
              alt="banner"
              src={banner?.image}
              variant="square"
            />
          </Grid>
          <Grid container item xs={9} justify="flex-end" alignItems="flex-start">
            <IconButton onClick={toggleMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuIsOpen}
              open={Boolean(menuIsOpen)}
              onClose={toggleMenu}
            >
              <MenuItem onClick={() => redirect('edit')}>Editar</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box p={2}>
        <Grid item container alignItems="center">
          <Typography color="textPrimary" variant="h4" align="left">
            {banner?.description}
          </Typography>
        </Grid>
      </Box>
      <>
        <Divider />
        <Box p={2}>
          <Grid item container alignItems="center">
            <Box mr={1}>
              <SortIcon color="action" />
            </Box>
            <Typography color="textSecondary" display="inline" variant="body2" align="left">
              Orden
              {': '}
              {banner?.order || 0}
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <Box mr={1}>
              <QuestionAnswer color="action" />
            </Box>
            <Typography color="textSecondary" display="inline" variant="body2" align="left">
              Disponible
              {': '}
              {banner?.is_available ? 'Si' : 'No' }
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <Box mr={1}>
              <AddToHomeScreen color="action" />
            </Box>
            <Typography color="textSecondary" display="inline" variant="body2" align="left">
              Pantalla en la App
              {': '}
              {translateRoute(banner?.route)}
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <Box mr={1}>
              <LocationCity color="action" />
            </Box>
            <Typography color="textSecondary" display="inline" variant="body2" align="left">
              Ciudad
              {': '}
              {banner?.city?.name || 'General'}
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <Box mr={1}>
              <Category color="action" />
            </Box>
            <Typography color="textSecondary" display="inline" variant="body2" align="left">
              Categoría de Adomi
              {': '}
              {banner?.adomi_category?.name || 'General'}
            </Typography>
          </Grid>
        </Box>
      </>
    </Card>
  );
};

BannerCard.propTypes = {
  className: PropTypes.func,
  banner: PropTypes.object.isRequired
};

BannerCard.propTypes = {
  className: () => {},
};

export default BannerCard;
