import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import {
  Box,
  Card,
  Grid,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon, Calendar as CaldendarIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreIcon: {
    cursor: 'pointer',
    marginLeft: '1rem'
  },
  categoryButton: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    flex: 1,
    width: '100%',
  },
  calendarContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    border: '1px solid #bbb',
    padding: '.85rem',
    borderRadius: '4px',
  },
  calendar: {
    position: 'absolute',
    zIndex: 10000000,
  },
  calendarIcon: {
    cursor: 'pointer'
  },
  devInput: {
    display: 'inline-block'
  },
  devText: {
    display: 'inline-block'
  }
}));

const Toolbar = ({
  className, onChange, onChangeDate, onSearch, onDev, date, ...rest
}) => {
  const classes = useStyles();
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState({ x: 0, y: 0 });

  const handleChange = (e) => {
    onChange(e?.target?.value);
  };

  const toggleCalendar = (e) => {
    setCalendarPosition(
      !calendarPosition ? { x: 0, y: 0 } : { x: e.clientX - 200, y: e.clientY + 20 }
    );
    setCalendarIsOpen(!calendarIsOpen);
  };

  const handleDate = (d) => {
    onChangeDate(dayjs(d).format('YYYY-MM-DD'));
    setCalendarIsOpen(false);
  };

  const handleSearch = (e) => {
    onSearch(e?.target?.value);
  };

  return (
    <>
      <div
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item xs={12} md={6}>
                  <Box maxWidth={500}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Buscar orden"
                      variant="outlined"
                      onChange={handleSearch}
                    />
                  </Box>
                </Grid>
                <Grid container item xs={12} md={6} justify="flex-end" alignItems="center">
                  <Box mr={2}>
                    <Grid container className={classes?.calendarContainer} alignItems="center">
                      <Box mr={2}>
                        <Typography variant="body2">
                          {dayjs(date).format('DD/MM/YYYY')}
                        </Typography>
                      </Box>
                      <CaldendarIcon
                        className={classes?.calendarIcon}
                        onClick={toggleCalendar}
                      />
                    </Grid>
                  </Box>
                  <Box mr={4} width="30%">
                    <FormControl variant="outlined" className={classes.formControl} mr={2}>
                      <InputLabel id="status">Estado</InputLabel>
                      <Select
                        required
                        name="status"
                        labelId="Status"
                        label="Selecciona una estado"
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          Todos
                        </MenuItem>
                        <MenuItem value="created">
                          Pendiente
                        </MenuItem>
                        <MenuItem value="in_progress">
                          En proceso
                        </MenuItem>
                        <MenuItem value="pending_to_be_sent">
                          Pendiente por asignar
                        </MenuItem>
                        <MenuItem value="sent">
                          Enviada
                        </MenuItem>
                        <MenuItem value="finished">
                          Completada
                        </MenuItem>
                        <MenuItem value="rejected">
                          Rechazada
                        </MenuItem>
                        <MenuItem value="cancelled">
                          Cancelada
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box width="30%">
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="status">Metodo de pago</InputLabel>
                      <Select
                        required
                        name="status"
                        labelId="Status"
                        label="Selecciona una metodo de pago"
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          Todos
                        </MenuItem>
                        <MenuItem value="cash">
                          Efectivo
                        </MenuItem>
                        <MenuItem value="card">
                          Tarjeta
                        </MenuItem>
                        <MenuItem value="datafono">
                          Datafono
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box ml={2}>
                    <label htmlFor="dev">
                      <input
                        type="checkbox"
                        name="dev"
                        id="dev"
                        className={classes?.devInput}
                        onChange={onDev}
                      />
                      {' '}
                      <Typography variant="caption" className={classes?.devText}>
                        <small>
                          Desarrollo
                        </small>
                      </Typography>
                    </label>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </div>
      {
        calendarIsOpen && (
          <div
            className={classes?.calendar}
            style={{ top: calendarPosition?.y, left: calendarPosition?.x }}
          >
            <Calendar onChange={handleDate} maxDate={new Date()} />
          </div>
        )
      }
    </>
  );
};

Toolbar.propTypes = {
  date: PropTypes.string,
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  onChangeDate: PropTypes.func,
  onDev: PropTypes.func,
};

Toolbar.defaultProps = {
  date: dayjs(new Date()).format('YYYY-MM-DD'),
  className: '',
  onDev: () => null,
  onSearch: () => null,
  onChange: () => null,
  onChangeDate: () => null,
};

export default Toolbar;
