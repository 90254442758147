import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Card,
  Grid,
  Box,
  Divider,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  makeStyles
} from '@material-ui/core';
import {
  AppsOutlined as AppsIcon,
  MoreVert as MoreVertIcon,
  Sort as SortIcon
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  },
  description: {
    height: '3rem',
    minHeight: '3rem',
    maxHeight: '3rem',
    overflow: 'hidden',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkitLineClamp': 2,
    '-webkitBoxOrient': 'vertical'
  }
}));

const AdomiCategoryCard = ({ className, category, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(null);

  const toggleMenu = (e) => setMenuIsOpen(!menuIsOpen ? e.currentTarget : null);

  const redirect = (to) => {
    switch (to) {
      case 'subcategories':
        navigate(`/app/adomi-categorias/${category.id}/subcategorias`, {
          replace: true
        });
        break;
      default:
        navigate(`/app/adomi-categorias/${category.id}/editar`, {
          replace: true
        });
    }

    toggleMenu();
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid
            container
            item
            xs={3}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              className={classes.avatar}
              alt="category"
              src={category.image}
              variant="square"
            />
          </Grid>
          <Grid container item xs={9} alignItems="flex-start">
            <Grid container item xs={10} alignItems="flex-start">
              <Box mt={1}>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  {category.name}
                </Typography>
                <Typography
                  align="left"
                  color="textPrimary"
                  variant="body2"
                >
                  {`De desarrollo: ${category?.is_develop ? 'Si' : 'No'}`}
                </Typography>
                <Typography
                  align="left"
                  color="textPrimary"
                  variant="body2"
                >
                  {`Orden: ${category?.order}`}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={2} alignItems="flex-start">
              <IconButton onClick={toggleMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuIsOpen}
                open={Boolean(menuIsOpen)}
                onClose={toggleMenu}
              >
                <MenuItem onClick={() => redirect('edit')}>Editar</MenuItem>
                {category.is_main && (
                  <MenuItem onClick={() => redirect('subcategories')}>
                    Ver sub categorías
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {category.is_main ? (
        <>
          <Divider />
          <Box p={2}>
            <Grid item container justifyContent="flex-end" alignItems="center">
              <Box mr={1}>
                <AppsIcon color="action" />
              </Box>
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                {category?.sub_categories?.length || 0}
                {' '}
                {category?.sub_categories?.length === 1
                  ? 'subcategoría'
                  : 'subcategorías'}
              </Typography>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Divider />
          <Box p={2}>
            <Grid item container justify="flex-end" alignItems="center">
              <Box mr={1}>
                <SortIcon color="action" />
              </Box>
              <Typography
                color="textSecondary"
                display="inline"
                variant="body2"
              >
                Orden
                {category?.order || 0}
              </Typography>
            </Grid>
          </Box>
        </>
      )}
    </Card>
  );
};

AdomiCategoryCard.propTypes = {
  className: PropTypes.func,
  category: PropTypes.object.isRequired
};

AdomiCategoryCard.propTypes = {
  className: () => {}
};

export default AdomiCategoryCard;
