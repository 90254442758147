import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  Grid,
  Button,
  Divider,
  CardActions,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  }
}));

const CountryCardPreview = ({
  className, country, onUpload, hasImage, ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <Avatar
              className={classes.avatar}
              alt="country"
              src={country?.flag}
              variant="square"
            />
          </Grid>
          <Grid item xs={9}>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {country?.name}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
            >
              {country?.code}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
            >
              {country?.acronym}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      {
        !hasImage && (
          <>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                fullWidth
                variant="text"
                onClick={onUpload}
              >
                Subir Imagen
              </Button>
            </CardActions>
          </>
        )
      }
    </Card>
  );
};

CountryCardPreview.propTypes = {
  className: PropTypes.string,
  country: PropTypes.object,
  hasImage: PropTypes.bool,
  onUpload: PropTypes.func,
};

CountryCardPreview.defaultProps = {
  className: '',
  country: {},
  hasImage: false,
  onUpload: () => {},
};

export default CountryCardPreview;
