import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import FAQService from '../../../services/FAQService';
import CityService from '../../../services/CityService';
import { selector as AppSelector } from '../../../redux/ducks/app';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  },
  menuItem: {
    display: 'flex',
    flexFlow: 'column',
    whiteSpace: 'normal',
    paddingBottom: '.5rem',
    '& p': {
      width: '100%',
      display: 'block'
    }
  }
}));

const CreateFaqForm = ({
  className, FAQ, edit, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const { globalCitySelected: citySelectedApp } = useSelector(AppSelector);
  const [citySelected, setCitySelected] = useState('');
  const [cities, setCities] = useState([]);

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const handleCity = (event) => {
    setCitySelected(event.target.value);
    handleChange(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      title: e.target?.title?.value,
      description: e.target?.description?.value,
      faq_category: e.target?.faq_category?.value,
      city_id: e.target?.city_id?.value || null,
      order: Number(e.target?.order?.value),
    };

    if (data?.title.length === 0) {
      errors.push('La pregunta es obligatoria');
    }

    if (data?.description.length === 0) {
      errors.push('La respuesta es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await FAQService.update(FAQ?.id, data)
      : await FAQService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/preguntas-frecuentes');
    } else {
      setErrors(response.errors);
    }
  };

  const deleteFAQ = async () => {
    if (FAQ?.id) {
      const response = await FAQService.remove(FAQ?.id);
      if (!response) {
        navigate('/app/preguntas-frecuentes', { replace: true });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await CityService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
        return;
      }

      setCities(response?.data);
    })();
  }, []);

  useEffect(() => {
    setCitySelected(FAQ?.city_id);
  }, [FAQ?.city_id]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Pregunta Frecuente"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={3}
                variant="outlined"
                autoComplete="off"
                placeholder="Escriba la pregunta"
                label="Pregunta"
                name="title"
                onChange={handleChange}
                required
                value={FAQ?.title}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={4}
                variant="outlined"
                autoComplete="off"
                placeholder="Escriba la respuesta"
                label="Respuesta"
                name="description"
                onChange={handleChange}
                required
                value={FAQ?.description}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba la categoría"
                label="Categoría"
                name="faq_category"
                onChange={handleChange}
                value={FAQ?.faq_category}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="ciudad">Seleccione una ciudad</InputLabel>
                <Select
                  name="city_id"
                  labelId="ciudad"
                  label="Selecciona una ciudad"
                  value={citySelected || citySelectedApp}
                  onChange={handleCity}
                >
                  {
                    cities.map((city) => (
                      <MenuItem key={city?.id} value={city?.id} className={classes?.menuItem}>
                        { city?.name }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el orden"
                label="Orden"
                name="order"
                onChange={handleChange}
                required
                value={FAQ?.order}
                variant="outlined"
                type="Number"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            { edit ? 'Editar' : 'Guardar' }
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteFAQ} />
    </form>
  );
};

CreateFaqForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  FAQ: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    city_id: PropTypes.string,
    faq_category: PropTypes.string,
    order: PropTypes.number,
  })
};

CreateFaqForm.defaultProps = {
  className: '',
  edit: false,
  onChange: () => {},
  FAQ: {
    id: null,
    title: '',
    description: '',
    city_id: '',
    faq_category: '',
    order: 0,
  },
};

export default CreateFaqForm;
