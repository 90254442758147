import dayjs from 'dayjs';

const getHour = (date) => {
  return dayjs(date).format('h:mm a');
};

const getDate = (date) => {
  return dayjs(date).format('DD/MM/YYYY');
};

export {
  getHour,
  getDate
};
