import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  FormControl,
  makeStyles,
  Button
} from '@material-ui/core';
import BrandService from 'src/services/BrandService';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import CityService from 'src/services/CityService';
import Selector from 'src/components/Selector';
import { actions as UserActions } from '../../redux/ducks/user';
import {
  actions as AppActions,
  selector as AppSelector
} from '../../redux/ducks/app';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 3,
    background: '#fffefe',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;'
  },
  avatar: {
    width: 60,
    height: 60
  },
  citySelector: {
    '&:before': {
      borderColor: 'white'
    }
  },
  appBarShift: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 256px)',
      marginLeft: 256
    }
  },
  citySelectorRoot: {
    minWidth: '150px',
    color: 'white',
    '& *': {
      backgroundColor: 'transparent'
    }
  },
  citySelectorIcon: {
    color: 'white'
  },
  select: {
    fontSize: 12,
    fontFamily: '"Poppins", sans-serif'
  },
  formControl: {
    width: '100%'
  },
  button: {
    backgroundColor: '#43a047',
    border: 'none',
    display: 'flex',
    padding: '.5rem 2rem',
    color: 'white',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#226125'
    }
  }
}));

const selectorStyles = {
  control: {
    backgroundColor: '#F5F7FB'
  },
  placeholder: {
    fontSize: 11
  },
  singleValue: {
    fontSize: 14
  }
};

const TopBar = ({ className, onMobileNavOpen, open, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector(AppSelector);
  const classes = useStyles();
  const [notifications] = useState([]);
  // const [active, setActive] = useState(false);
  const [cities, setCities] = useState([]);
  const { globalCitySelected } = useSelector(AppSelector);
  console.log(token);
  const getCities = async () => {
    const response = await CityService.getAll();
    if (response?.code === 200) {
      response.data.unshift({
        id: 'general',
        name: 'General'
      });
      setCities(response?.data);
      dispatch(AppActions.setCity(response?.data?.[0]?.id));
    }
  };

  const closeSession = () => {
    dispatch(UserActions.clear());
    navigate('/login', { replace: true });
  };

  /**
   * Esta función me permite obtener la información de la ciudad seleccionada
   * @return Object:
   * city: {
   *   value: id ciudad,
   *   label: nombre ciudad
   * }
   */
  const getCitySelected = () => {
    const city = cities.find((item) => item?.id === globalCitySelected);
    return {
      value: city?.id,
      label: city?.name
    };
  };

  const handleCity = (city) => {
    dispatch(AppActions.setCity(city?.value));
    navigate('/app');
    setTimeout(() => navigate(location?.pathname), 50);
  };

  useEffect(() => {
    getCities();
  }, []);

  const handleDisable = async () => {
    const data = {
      active: false
    };
    await BrandService.toggleMaintenance(data);
  };

  const handleActive = async () => {
    const data = {
      active: true
    };
    await BrandService.toggleMaintenance(data);
  };

  return (
    <AppBar
      className={clsx(classes.root, className, {
        [classes.appBarShift]: !open
      })}
      position="fixed"
      {...rest}
    >
      <Toolbar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <IconButton
            color="primary"
            onClick={() => onMobileNavOpen((prev) => !prev)}
          >
            <MenuIcon />
          </IconButton>
          <Logo />
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center" marginRight={1}>
              <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={() => handleDisable()}
              >
                Desactivar mantenimiento
              </Button>
            </Box>
            <Box display="flex" alignItems="center" marginRight={1}>
              <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={() => handleActive()}
              >
                Activar mantenimiento
              </Button>
            </Box>
            <Box width={200}>
              <FormControl required className={classes.formControl}>
                <Selector
                  options={cities?.map((item) => ({
                    value: item?.id,
                    label: item?.name
                  }))}
                  className={classes.select}
                  value={getCitySelected()}
                  onChange={handleCity}
                  name="city_id"
                  labelId="city"
                  placeholder="Selecciona una ciudad"
                  styles={selectorStyles}
                />
              </FormControl>
            </Box>
            <Hidden mdDown>
              <IconButton color="primary">
                <Badge
                  badgeContent={notifications.length}
                  color="primary"
                  variant="dot"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton color="primary" onClick={closeSession}>
                <InputIcon />
              </IconButton>
            </Hidden>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  open: PropTypes.bool
};

export default TopBar;
