import HttpRequest from './HttpRequest';

export default class PolygonService extends HttpRequest {
  static endpoint = 'city-polygons';

  static async getAll() {
    this.setMicroservice('polygons');
    this.endpoint = 'city-polygons';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.setMicroservice('polygons');
    this.endpoint = 'city-polygons';
    const response = await this.get(id);
    return response;
  }

  static async getByCityId(id) {
    this.setMicroservice('polygons');
    this.endpoint = 'city-polygons/by-city-id';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.setMicroservice('polygons');
    this.endpoint = 'city-polygons';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.setMicroservice('polygons');
    this.endpoint = 'city-polygons';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.setMicroservice('polygons');
    this.endpoint = 'city-polygons';
    const response = await this.delete(id);
    return response;
  }
}
