import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { actions as UserActions, selector as UserSelector } from '../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LogoutView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useSelector(UserSelector);

  useEffect(() => {
    if (user?.id) {
      dispatch(UserActions.clear());

      setTimeout(() => navigate('/login', { replace: true }), 100);
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Logout"
    />
  );
};

export default LogoutView;
