import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  },
  description: {
    height: '3rem',
    minHeight: '3rem',
    maxHeight: '3rem',
    overflow: 'hidden',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkitLineClamp': 2,
    '-webkitBoxOrient': 'vertical',
  }
}));

const CityCard = ({ className, city, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(null);

  const toggleMenu = (e) => setMenuIsOpen(!menuIsOpen ? e.currentTarget : null);

  const redirect = () => {
    navigate(`/app/ciudades/${city.id}/editar`, { replace: true });
    toggleMenu();
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <Avatar
              className={classes.avatar}
              alt="city"
              src={city?.state?.country?.flag}
              variant="square"
            />
          </Grid>
          <Grid container item xs={9} alignItems="flex-start">
            <Grid container item xs={10} alignItems="flex-start">
              <Box mt={1}>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  {city.name}
                </Typography>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                >
                  {city?.state?.name}
                </Typography>
                <Typography
                  align="left"
                  color="textPrimary"
                  gutterBottom
                  variant="caption"
                >
                  {city?.state?.country?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={2} alignItems="flex-start">
              <IconButton onClick={toggleMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuIsOpen}
                open={Boolean(menuIsOpen)}
                onClose={toggleMenu}
              >
                <MenuItem onClick={() => redirect('edit')}>Editar</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CityCard.propTypes = {
  className: PropTypes.func,
  city: PropTypes.object.isRequired
};

export default CityCard;
