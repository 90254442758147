import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  Switch,
  Tooltip,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles, Grid, Avatar
} from '@material-ui/core';
import { ViewList as ViewListIcon } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import ClientService from '../../../services/ClientService';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer',
    color: 'black'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  }
}));

const Results = ({
  className, data, onRefresh, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState('');
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [clients, setClients] = useState([]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleIsDevelopClient = async (client) => {
    const response = await ClientService.toggleIsDevelop(client?.id);
    setTooltipOpen('');
    setTooltipTitle('');

    if (response?.code === 200) {
      const newClients = clients.reduce((result, item) => {
        if (item?.id === client?.id) {
          return result.concat({ ...item, is_develop: !item?.is_develop });
        }
        return result.concat(item);
      }, []);

      setClients(newClients);
    }

    if (response?.code === 409) {
      setTooltipTitle(response?.errors[0]);
      setTooltipOpen(client?.id);

      setTimeout(() => {
        setTooltipOpen('');
        setTooltipTitle('');
      }, 5000);
    }

    onRefresh();
  };

  const redirect = (id) => {
    navigate(`/app/clientes/${id}/direcciones`, { replace: true });
  };

  const translateGender = (gender) => {
    switch (gender) {
      case 'other':
        return 'Otro';
      case 'female':
        return 'Femenino';
      case 'male':
        return 'Masculino';
      default: return 'Other';
    }
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setClients(data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1000}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nombre
                  </TableCell>
                  <TableCell>
                    Género
                  </TableCell>
                  <TableCell>
                    Celular
                  </TableCell>
                  <TableCell>
                    Email
                  </TableCell>
                  <TableCell>
                    Desarrollo
                  </TableCell>
                  <TableCell>
                    Direcciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.slice(page * limit, (limit * (page + 1))).map((client) => (
                  <TableRow
                    hover
                    key={client?.id}
                  >
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={client?.avatar}
                        />
                        <Typography
                          component="span"
                          color="textPrimary"
                          variant="body2"
                        >
                          {client?.first_name}
                          <br />
                          {client?.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {client?.gender !== null ? translateGender(client?.gender) : 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {client?.phone}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        { client?.email || 'Sin registrar' }
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={tooltipTitle} open={tooltipOpen === client?.id}>
                        <Switch
                          color="primary"
                          checked={client.is_develop}
                          onChange={() => client && toggleIsDevelopClient(client)}
                          name="client_is_develop"
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Grid item container justify="center" alignItems="center">
                        <ViewListIcon
                          className={classes?.moreVert}
                          onClick={() => redirect(client?.id)}
                        />
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={clients.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  onRefresh: PropTypes.func,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  className: '',
  onRefresh: () => null,
};

export default Results;
