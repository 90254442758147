import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import DriverService from 'src/services/DriverService';
import DriverCardPreview from './DriverCardPreview';
import CreateScheduleForm from './CreateScheduleForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateScheduleView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [driver, setDriver] = useState({
    id: '',
    name: '',
    description: '',
    schedule: null,
    brand: {
      name: '',
      logo: ''
    },
  });

  const handleDriverAttributes = (values) => {
    setDriver(values);
  };

  const fetchDriverData = async () => {
    const response = await DriverService.getById(id);
    if (response?.code === 200) {
      setDriver({
        id: response?.data?.id,
        first_name: response?.data?.first_name,
        last_name: response?.data?.last_name,
        schedule: response?.data?.schedule,
        avatar: response?.data?.avatar,
        email: response?.data?.email,
        phone: response?.data?.phone,
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchDriverData();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="CreateScheduleView"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <DriverCardPreview driver={driver} />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateScheduleForm
              driver={driver}
              onChange={handleDriverAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateScheduleView;
