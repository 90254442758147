import HttpRequest from './HttpRequest';
import generateEndpoint from '../utils/generateEndpoint';

export default class ClientService extends HttpRequest {
  static endpoint = 'clients';

  static async getAll() {
    this.endpoint = generateEndpoint('clients');
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'clients';
    const response = await this.get(id);
    return response;
  }

  static async getByPhone(phone) {
    this.endpoint = 'clients/by-phone';
    const response = await this.get(phone);
    return response;
  }

  static async create(data) {
    this.endpoint = 'clients';
    const response = await this.post(data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'clients/delete-information';
    const response = await this.delete(id);
    return response;
  }

  static async toggleIsDevelop(id) {
    this.endpoint = 'clients/toggle/is-develop';
    const response = await this.get(id);
    return response;
  }

  // demographic
  static async demographic() {
    this.endpoint = 'clients/demographic-report';
    const response = await this.get();
    return response;
  }
}
