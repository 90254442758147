import { createDuck } from 'redux-duck';
import { createStructuredSelector } from 'reselect';
import AlertSound from '../../assets/sound/adomi.mp3';
import AlertNewChat from '../../assets/sound/new_chat.mp3';
import AlertNewMessage from '../../assets/sound/new_message.mp3';

const duck = createDuck('app/alert');

// Define initial state
const initialState = null;

// Define constants
export const SET_ALERT = duck.defineType('SET_ALERT');
export const SET_ALERT_CHAT = duck.defineType('SET_ALERT_CHAT');
export const SET_ALERT_MESSAGE = duck.defineType('SET_ALERT_MESSAGE');

// Define actions
export const actions = ({
  set: duck.createAction(SET_ALERT),
  setNewChat: duck.createAction(SET_ALERT_CHAT),
  setNewMessage: duck.createAction(SET_ALERT_MESSAGE),
});

// Define selector
export const selector = createStructuredSelector({
  alert: (state) => state.alert,
});

// Define reducer
export default duck.createReducer({
  [SET_ALERT]: (state) => {
    const audio = new Audio(AlertSound);
    audio.play();
    audio.remove();
    return state;
  },
  [SET_ALERT_CHAT]: (state) => {
    const audio = new Audio(AlertNewChat);
    audio.play();
    audio.remove();
    return state;
  },
  [SET_ALERT_MESSAGE]: (state) => {
    const audio = new Audio(AlertNewMessage);
    audio.play();
    audio.remove();
    return state;
  },
}, initialState);
