import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import CityService from '../../../services/CityService';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
// import useConfirmation from '../../../hooks/useConfirmation';
import ZonesService from '../../../services/ZonesService';
import { selector as AppSelector } from '../../../redux/ducks/app';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateZoneForm = ({
  className, zone, edit, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  // const { toggleModal } = useConfirmation();
  const { globalCitySelected } = useSelector(AppSelector);
  const [cities, setCities] = useState([]);

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: e.target?.name?.value,
      description: e.target?.description?.value,
      city_id: e.target?.city_id?.value,
    };

    if (data.name.length === 0) {
      errors.push('La zona es obligatoria');
    }

    if (data.description.value === '') {
      errors.push('La de descripción es obligatoria');
    }

    if (data.name.length === 0 || data.description.value === '') {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await ZonesService.update(zone.id, data)
      : await ZonesService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/zonas', { replace: true });
    } else {
      setErrors(response.errors);
    }
  };

  const deleteState = async () => {
    if (zone?.id) {
      const response = await ZonesService.delete(zone.id);
      if (!response) {
        navigate('/app/zonas', { replace: true });
      }
    }
  };
  const fetchAllCities = async () => {
    const response = await CityService.getAll();

    if (response?.code !== 200) {
      setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
      return;
    }

    setCities(response?.data);
  };

  useEffect(() => {
    fetchAllCities();
  }, []);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Zona"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba la Zona"
                label="Zona"
                name="name"
                onChange={handleChange}
                required
                value={zone?.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba Descripción"
                label="Descripción"
                name="description"
                onChange={handleChange}
                required
                value={zone?.description}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="ciudad">Seleccione una ciudad</InputLabel>
                <Select
                  name="city_id"
                  labelId="ciudad"
                  label="Selecciona una ciudad"
                  value={zone?.city_id || globalCitySelected}
                  onChange={handleChange}
                >
                  <MenuItem value="" />
                  {
                    cities.map((city) => (
                      <MenuItem key={city?.id} value={city?.id}>
                        {city?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {/* {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          } */}
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteState} />
    </form>
  );
};

CreateZoneForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  zone: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    city_id: PropTypes.string,
  })
};

CreateZoneForm.defaultProps = {
  className: '',
  edit: false,
  onChange: () => { },
  zone: {
    id: null,
    name: '',
    description: '',
    city_id: '',
  },
};

export default CreateZoneForm;
