import HttpRequest from './HttpRequest';

export default class TemplateService extends HttpRequest {
  static endpoint = 'template';

  static async getAll() {
    this.endpoint = 'template';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'template';
    const response = await this.get(id);
    return response;
  }

  static async getByBrand(brandId) {
    this.endpoint = 'template/by-brand';
    const response = await this.get(brandId);
    return response;
  }

  static async addTemplates(brandId, templates) {
    this.endpoint = 'template/add-templates';
    const response = await this.put(brandId, { templates });
    return response;
  }

  static async create(data) {
    this.endpoint = 'template';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'template';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'template';
    const response = await this.delete(id);
    return response;
  }
}