import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CreateTagForm from './CreateTagForm';
import TagService from '../../../services/TagService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateTagView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [tag, setTag] = useState({
    id: null,
    name: '',
    sub_tags: [],
  });

  const handleTagAttributes = (values) => {
    setTag({
      ...tag,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await TagService.getById(id);
        if (response?.code === 200) {
          setTag({
            ...response.data
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Etiqueta"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateTagForm
              edit={edit}
              tag={tag}
              onChange={handleTagAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateTagView.propTypes = {
  edit: PropTypes.bool,
};

CreateTagView.defaultProps = {
  edit: false
};

export default CreateTagView;
