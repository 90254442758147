import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import store from 'src/redux/store';
import {
  Box,
  Grid,
  Typography,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import {
  CancelRounded,
} from '@material-ui/icons';
import ZonesService from 'src/services/ZonesService';

export const FiltersAside = ({
  show,
  toggleAside,
  onChange,
  onDev,
  onDemo,
  onWorking,
  onAvailable,
  onZone
}) => {
  const matchesSm = useMediaQuery('screen and (min-width: 600px)');
  const matches = useMediaQuery('screen and (min-width: 750px)');
  const [zones, setZones] = useState([]);
  const [zoneSelected, setZoneSelected] = useState('');

  const useStyles = makeStyles((theme) => ({
    aside: {
      position: 'fixed',
      zIndex: 3,
      top: matchesSm ? '64px' : '57px',
      backgroundColor: '#fff',
      width: matches ? '450px' : '100%',
      right: 0,
      height: matchesSm ? 'calc(100% - 64px)' : 'calc(100% - 57px)',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    pane: {
      backgroundColor: 'rgba(46, 44, 54, 0.2)',
      bottom: 0,
      height: '100%',
      width: '100%',
      position: 'fixed',
      zIndex: 2,
    },
    header: {
      padding: '15px',
      borderBottom: '1px solid #e6ebf1',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    title: {
      fontWeight: 'bold',
      fontSize: '20px'
    },
    formControl: {
      flex: 1,
      width: '100%'
    },
    scroll: {
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '20px',
        backgroundColor: theme.palette.primary.main
      }
    }
  }));

  const classes = useStyles();

  const handleChange = (e) => {
    onChange(e?.target?.value);
  };

  const handleDev = (e) => {
    onDev(e?.target?.value);
  };

  const handleDemo = (e) => {
    onDemo(e?.target?.value);
  };

  const handleWorking = (e) => {
    onWorking(e?.target?.value);
  };

  const handleAvailable = (e) => {
    onAvailable(e?.target?.value);
  };

  const handleZone = (e) => {
    setZoneSelected(e.target.value);
    onZone(e.target.value);
  };

  const getZones = async () => {
    const cityId = store.getState()?.app?.city;
    try {
      const response = await ZonesService.getAllId(cityId);
      setZones(response.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (store.getState()?.app?.city !== 'general') getZones();
  }, [store.getState()?.app?.city]);

  return (
    <>
      {show && (
        <Box className={classes.pane} onClick={() => toggleAside()} />
      )}
      <Slide timeout={450} direction="left" in={show}>
        <Box className={classes.aside}>
          <Box className={classes.header}>
            <Typography className={classes.title} variant="h3">Filtros</Typography>
            <CancelRounded
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => toggleAside()}
            />
          </Box>
          <Box className={classes.scroll} p={2.5} overflow="auto" maxHeight={matchesSm ? 'calc(100% - 64px)' : 'calc(100% - 57px)'}>
            <Grid container spacing={3} justify="space-between" alignItems="center">
              <Grid item xs={12} justify="flex-end" alignItems="center">
                <Box>
                  <Box mb={1.2}>
                    <Typography variant="h5">Destacadas</Typography>
                  </Box>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="is_featured">Destacadas</InputLabel>
                    <Select
                      required
                      name="is_featured"
                      labelId="is_featured"
                      label="Destacadas"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        Todas
                      </MenuItem>
                      <MenuItem value="true">
                        Si
                      </MenuItem>
                      <MenuItem value="false">
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} justify="flex-end" alignItems="center">
                <Box>
                  <Box mb={1.2}>
                    <Typography variant="h5">Desarrollo</Typography>
                  </Box>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="is_develop">Desarrollo</InputLabel>
                    <Select
                      required
                      name="is_develop"
                      labelId="is_develop"
                      label="Desarrollo"
                      onChange={handleDev}
                    >
                      <MenuItem value="">
                        Todas
                      </MenuItem>
                      <MenuItem value="true">
                        Si
                      </MenuItem>
                      <MenuItem value="false">
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} justify="flex-end" alignItems="center">
                <Box>
                  <Box mb={1.2}>
                    <Typography variant="h5">Demo</Typography>
                  </Box>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="is_demo">Demo</InputLabel>
                    <Select
                      required
                      name="is_demo"
                      labelId="is_demo"
                      label="Demo"
                      onChange={handleDemo}
                    >
                      <MenuItem value="">
                        Todas
                      </MenuItem>
                      <MenuItem value="true">
                        Si
                      </MenuItem>
                      <MenuItem value="false">
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} justify="flex-end" alignItems="center">
                <Box>
                  <Box mb={1.2}>
                    <Typography variant="h5">Activas</Typography>
                  </Box>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="is_demo">Activas</InputLabel>
                    <Select
                      required
                      name="is_working"
                      labelId="is_working"
                      label="Producción"
                      onChange={handleWorking}
                    >
                      <MenuItem value="">
                        Todas
                      </MenuItem>
                      <MenuItem value="true">
                        Si
                      </MenuItem>
                      <MenuItem value="false">
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} justify="flex-end" alignItems="center">
                <Box>
                  <Box mb={1.2}>
                    <Typography variant="h5">Disponibles</Typography>
                  </Box>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="is_demo">Disponibles</InputLabel>
                    <Select
                      required
                      name="is_available"
                      labelId="is_available"
                      label="Disponible"
                      onChange={handleAvailable}
                    >
                      <MenuItem value="">
                        Todas
                      </MenuItem>
                      <MenuItem value="true">
                        Si
                      </MenuItem>
                      <MenuItem value="false">
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              {store.getState()?.app?.city !== 'general' && (
                <Grid item xs={12} justify="flex-end" alignItems="center">
                  <Box>
                    <Box mb={1.2}>
                      <Typography variant="h5">Zonas</Typography>
                    </Box>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="is_demo">Zonas</InputLabel>
                      <Select
                        required
                        name="zones"
                        labelId="zones"
                        label="Zonas"
                        value={zoneSelected}
                        onChange={handleZone}
                      >
                        <MenuItem value="">
                          Todas
                        </MenuItem>
                        {
                          zones.map((zone) => (
                            <MenuItem key={zone?.id} value={zone?.id}>
                              {zone?.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Slide>
    </>
  );
};

FiltersAside.propTypes = {
  show: PropTypes.bool,
  toggleAside: PropTypes.func,
  onChange: PropTypes.func,
  onDev: PropTypes.func,
  onDemo: PropTypes.func,
  onWorking: PropTypes.func,
  onAvailable: PropTypes.func,
  onZone: PropTypes.func
};

export default FiltersAside;
