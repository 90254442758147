import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Toolbar from './Toolbar';
import Results from './Results';
import { selector as UserSelector } from '../../../redux/ducks/user';
import PageTemplateService from '../../../services/PageTemplateService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  brandCard: {
    height: '100%'
  }
}));

const PageTemplateView = () => {
  const classes = useStyles();
  const [pageTemplates, setPageTemplates] = useState([]);
  const { templateId } = useParams();
  const { token } = useSelector(UserSelector);

  const getPageTemplates = async () => {
    const response = await PageTemplateService.getByTemplate(templateId);
    if (response.code === 200) {
      setPageTemplates(response.data);
    }
  };

  useEffect(() => {
    if (token !== null) {
      getPageTemplates();
    }
  }, [templateId]);

  return (
    <Page className={classes.root} title="Paginas">
      <Container maxWidth={false}>
        {pageTemplates.length > 0 && <Toolbar />}
        <Box mt={3}>
          <Results data={pageTemplates} />
        </Box>
      </Container>
    </Page>
  );
};

export default PageTemplateView;
