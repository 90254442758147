import store from '../redux/store';

function generateEndpoint(endpoint, cityIdFormat = 'city_id') {
  const cityId = store.getState()?.app?.city;
  if (cityId === 'general') {
    return `${endpoint}?all=true`;
  }

  if (endpoint.includes('?')) {
    return `${endpoint}&${cityIdFormat}=${cityId}&all=true`;
  }

  return `${endpoint}?${cityIdFormat}=${cityId}&all=true`;
}

export function generateEndpointBtCity(endpoint) {
  const cityId = store.getState()?.app?.city;
  if (cityId === 'general') {
    return endpoint;
  }

  if (endpoint.includes('?')) {
    return `${endpoint}/${cityId}`;
  }

  return `${endpoint}/${cityId}`;
}

export default generateEndpoint;
