import React from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  Polygon,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager';
import store from 'src/redux/store';

const GoogleMapsWithPolygons = withScriptjs(
  withGoogleMap(({
    center,
    polygons,
    styles,
    isDrawing,
    savePolygon,
    idEdit,
    onMouseDown,
    dragEnd,
    dragStart,
    handleSelectedPolygon,
    setEditable,
  }) => {
    const options = {
      fillColor: 'lightgreen',
      fillOpacity: 0.5,
      strokeColor: 'green',
      strokeOpacity: 1,
      strokeWeight: 2,
      clickable: true,
      zIndex: 1
    };

    const drawingOptions = {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
    };

    const refs = [];

    return (
      <>
        <GoogleMap
          on
          defaultZoom={15}
          center={center}
          defaultCenter={center}
          defaultOptions={{
            styles
          }}
        >
          <DrawingManager
            drawingMode={!isDrawing ? null : window.google.maps.drawing.OverlayType.POLYGON}
            defaultOptions={drawingOptions}
            options={{
              drawingControl: false,
              drawingControlOptions: drawingOptions,
              polygonOptions: { editable: true, draggable: false, ...options }
            }}
            onPolygonComplete={(polygon) => savePolygon(polygon)}
          />
          {polygons?.length > 0
            && polygons?.map((poly, index) => (
              <>
                <Polygon
                  ref={(ref) => {
                    refs.push(ref);
                  }}
                  onDragStart={() => dragStart()}
                  onDrag={() => dragStart()}
                  onDragEnd={() => dragEnd(poly, refs[index])}
                  onMouseDown={(e) => onMouseDown(e, poly, refs[index])}
                  editable={idEdit === poly.id}
                  draggable={idEdit === poly.id}
                  key={poly?.id}
                  paths={poly?.paths}
                  onClick={() => {
                    handleSelectedPolygon(poly, index);
                    if (store.getState()?.app?.city !== 'general') {
                      setEditable(poly.id, poly.points[0]);
                    }
                  }}
                  options={options}
                />
              </>
            ))}
        </GoogleMap>
      </>
    );
  })
);

GoogleMapsWithPolygons.defaultProps = {
  center: { lat: 0, lng: 0 },
  setPosition: () => {},
  draggable: false,
  markers: [],
  centerMarker: true,
  styles: [],
  isDrawing: false,
};

GoogleMapsWithPolygons.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  setPosition: PropTypes.func,
  draggable: PropTypes.bool,
  centerMarker: PropTypes.bool,
  markers: PropTypes.array,
  styles: PropTypes.array,
  isDrawing: PropTypes.bool,
  onMouseDown: PropTypes.func,
  dragEnd: PropTypes.func,
  dragStart: PropTypes.func,
  handleSelectedPolygon: PropTypes.func,
  setEditable: PropTypes.func,
};

export default GoogleMapsWithPolygons;
