import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow, Circle, Polygon
} from 'react-google-maps';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Box
} from '@material-ui/core';

const InfoWindowContent = ({ data }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell align="center"><strong>ID</strong></TableCell>
            <TableCell align="center">{data?.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Nombre</strong></TableCell>
            <TableCell align="center">{data?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Estado</strong></TableCell>
            <TableCell align="center">{data?.is_available}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

InfoWindowContent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_available: PropTypes.bool,
  })
};

const GoogleMaps = withScriptjs(withGoogleMap(({
  center,
  address,
  city,
  state,
  lockDrag,
  setPosition,
  setRadius,
  draggable,
  markers,
  centerMarker,
  styles,
  coverageRadio = 0,
  drawPolygon,
  polygon,
  setPolygon,
  isBranchOffice
}) => {
  const [circleCenter, setCircleCenter] = useState(center);
  const radiusRef = useRef(null);
  const polygonRef = useRef(null);
  const markerRef = useRef(null);

  const getPoints = (paths) => {
    const points = paths.getArray().map((point) => {
      return [point.lat(), point.lng()];
    });
    return points;
  };

  const validateAddress = async () => {
    const results = await geocodeByAddress(`${city} ${state} ${address}`);
    const { lat, lng } = await getLatLng(results[0]);
    const x = Math.abs(center.lat - lat);
    const y = Math.abs(center.lng - lng);
    const difference = x + y;
    if (difference > 0.0015) {
      setPosition(lat, lng);
      setCircleCenter({ lat, lng });
    }
  };

  const getPaths = (points) => {
    const paths = points.map((item) => {
      return { lat: item[0], lng: item[1] };
    });
    return paths;
  };

  const onDragEnd = () => {
    setPolygon(getPoints(polygonRef.current.getPath()));
  };

  const onMouseDown = (e) => {
    const paths = polygonRef.current.getPath();
    window.google.maps.event.addListener(paths, 'set_at', (event) => {
      if (event !== null && e.vertex !== undefined) {
        setPolygon(getPoints(paths));
      }
    });

    window.google.maps.event.addListener(paths, 'insert_at', (event) => {
      if (event !== null) {
        setPolygon(getPoints(paths));
      }
    });
  };

  const handleDragged = (marker) => {
    setPosition(marker.latLng.lat(), marker.latLng.lng());
    setCircleCenter({ lat: marker.latLng.lat(), lng: marker.latLng.lng() });
  };

  const updateRadius = () => {
    if (radiusRef.current === null) { return; }
    // eslint-disable-next-line max-len
    const newRadius = radiusRef.current.state.__SECRET_CIRCLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.radius;
    setRadius(newRadius);
  };

  return (
    <>
      {!isBranchOffice && (
        <Box mt={1}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => validateAddress()}
          >
            validar
          </Button>
        </Box>
      )}
      <GoogleMap
        on
        defaultZoom={15}
        center={center}
        defaultOptions={{
          styles
        }}
      >
        {
          drawPolygon && (
            <>
              <DrawingManager
                drawingMode={
                  Object.keys(polygon).length === 0
                    ? window.google.maps.drawing.OverlayType.POLYGON : null
                }
                options={{
                  drawingControl: false,
                  drawingControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_CENTER,
                    drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
                  },
                  polygonOptions: {
                    editable: true,
                    draggable: true,
                    fillColor: 'lightgreen',
                    fillOpacity: 0.5,
                    strokeColor: 'green',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    zIndex: 1
                  }
                }}
                onPolygonComplete={(poly) => {
                  setPolygon(getPoints(poly.getPath()));
                  poly.setMap(null);
                }}
              />
              {Object.keys(polygon).length !== 0 && (
                <Polygon
                  ref={polygonRef}
                  draggable={!lockDrag}
                  editable
                  onDragEnd={() => onDragEnd()}
                  onMouseDown={(e) => onMouseDown(e)}
                  paths={getPaths(polygon)}
                  options={{
                    fillColor: 'lightgreen',
                    fillOpacity: 0.5,
                    strokeColor: 'green',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    zIndex: 1
                  }}
                />
              )}
            </>
          )
        }
        {
          centerMarker && (
            <>
              <Marker
                ref={markerRef}
                draggable={draggable}
                onDragEnd={handleDragged}
                position={center}
              />
              {!drawPolygon && (
                <Circle
                  ref={radiusRef}
                  editable
                  onRadiusChanged={updateRadius}
                  center={circleCenter || center}
                  radius={coverageRadio}
                  options={{
                    strokeColor: '#156e2b',
                    fillColor: '#12b93c',
                    fillOpacity: 0.5,
                    clickable: true,
                  }}
                />
              )}
            </>
          )
        }
        {
          markers?.length > 0 && markers?.map(
            (marker) => (
              <>
                <Marker
                  label={marker?.name}
                  onClick={() => marker?.toggle(marker?.uuid, markers)}
                  position={marker?.position}
                  icon={marker?.icon ?? ''}
                >
                  {
                    marker?.infoWindow && (
                      <InfoWindow onCloseClick={() => marker?.toggle(marker?.uuid)}>
                        <InfoWindowContent data={marker} />
                      </InfoWindow>
                    )
                  }
                </Marker>
              </>
            )
          )
        }
      </GoogleMap>
    </>
  );
}));

GoogleMaps.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  lockDrag: PropTypes.bool,
  setPosition: PropTypes.func,
  draggable: PropTypes.bool,
  centerMarker: PropTypes.bool,
  markers: PropTypes.array,
  styles: PropTypes.array,
  drawPolygon: PropTypes.bool,
  polygon: PropTypes.array,
  setPolygon: PropTypes.func,
  isBranchOffice: PropTypes.bool,
};

GoogleMaps.defaultProps = {
  center: { lat: 0, lng: 0 },
  lockDrag: false,
  setPosition: () => { },
  draggable: false,
  markers: [],
  centerMarker: true,
  styles: [],
  drawPolygon: false,
  polygon: [],
  setPolygon: () => { },
  isBranchOffice: false
};

export default GoogleMaps;
