import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import {
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';

const useStyles = makeStyles({
  removeIcon: {
    marginTop: '1rem',
    cursor: 'pointer'
  }
});

const HourForm = ({
  hours, onChange, onRemove
}) => {
  const classes = useStyles();
  const [state, setState] = useState(hours);

  const handleChange = (e) => {
    const value = e?.target?.value;
    const data = {
      ...state,
      [e?.target?.name]: `${value}:00`
    };

    setState(data);
    onChange(data);
  };

  const handleRemove = () => {
    onRemove(hours);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={5}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          helperText="Hora de apertura"
          label="Inicio"
          name="start"
          onChange={handleChange}
          required
          type="time"
          value={hours?.start}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          helperText="Hora de cierre"
          label="Cierre"
          name="end"
          onChange={handleChange}
          required
          type="time"
          value={hours?.end}
          variant="outlined"
        />
      </Grid>
      <Grid container item xs={2} alignItems="flex-start" justify="center">
        <DeleteOutlineIcon className={classes?.removeIcon} onClick={handleRemove} />
      </Grid>
    </Grid>
  );
};

HourForm.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  hours: PropTypes.shape({
    id: PropTypes.string,
    end: PropTypes.string,
    start: PropTypes.string,
  })
};

HourForm.defaultProps = {
  onChange: () => null,
  onRemove: () => null,
  hours: {
    id: '',
    end: '',
    start: '',
  }
};

export default HourForm;
