import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  Grid,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles, InputLabel, Select, MenuItem, FormControl
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  },
  formControl: {
    flex: 1,
    width: '100%',
  }
}));

const Toolbar = ({
  className, onChange, onSearch, toggleModal, ...rest
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(e?.target?.value);
  };

  const handleSearch = (e) => {
    onSearch(e?.target?.value);
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Box mr={2}>
          <Button
            type="button"
            onClick={() => toggleModal()}
            className={classes.error}
            variant="contained"
          >
            Eliminar cliente
          </Button>
        </Box>
        <Link to="/app/clientes/crear">
          <Button
            color="primary"
            variant="contained"
          >
            Agregar Cliente
          </Button>
        </Link>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container justify="space-between">
              <Grid item xs={12} md={6}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Buscar cliente"
                    variant="outlined"
                    onChange={handleSearch}
                  />
                </Box>
              </Grid>
              <Grid container item xs={12} md={5} justify="flex-end" alignItems="center">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="is_develop">Filtrar</InputLabel>
                  <Select
                    required
                    name="is_develop"
                    labelId="is_develop"
                    label="Filtrar"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      Todos
                    </MenuItem>
                    <MenuItem value="false">
                      Comunes
                    </MenuItem>
                    <MenuItem value="true">
                      De desarrollo
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  toggleModal: PropTypes.func
};

Toolbar.defaultProps = {
  className: '',
  onChange: () => null,
  onSearch: () => null,
  toggleModal: () => { },
};

export default Toolbar;
