import { generateEndpointBtCity } from 'src/utils/generateEndpoint';
import HttpRequest from './HttpRequest';

export default class ZonesService extends HttpRequest {
  static endpoint = 'commercial-zones';

  static async getAll() {
    this.endpoint = generateEndpointBtCity('commercial-zones/by-city');
    const response = await this.get();
    return response;
  }

  static async getAllId(id) {
    this.endpoint = `commercial-zones/by-city/${id}`;
    const response = await this.get();
    return response;
  }

  static async getReport(id) {
    this.endpoint = `commercial-zones/report?city_id=${id}`;
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'commercial-zones';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'commercial-zones';
    const response = await this.post(data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'commercial-zones';
    const response = await this.delete(id);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'commercial-zones';
    const response = await this.put(id, data);
    return response;
  }
}
