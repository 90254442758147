import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow, Circle
} from 'react-google-maps';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

const InfoWindowContent = ({ data }) => {
  console.table(data);
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell align="center"><strong>ID</strong></TableCell>
            <TableCell align="center">
              <a
                target="_blank"
                href={`https://admin.adomi.app/app/sucursales/${data?.id}/editar`}
                rel="noreferrer"
              >
                <strong>{data?.id}</strong>
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Nombre</strong></TableCell>
            <TableCell align="center">{data?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Dirección</strong></TableCell>
            <TableCell align="center">{data?.address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><strong>Radio de servicio</strong></TableCell>
            <TableCell align="center">
              {`${data?.coverage_radio} mts`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

InfoWindowContent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    coverage_radio: PropTypes.number,
    address: PropTypes.string,
  })
};

const GoogleMapWithToggle = withScriptjs(withGoogleMap(({
  center, setPosition, draggable, markers, centerMarker, styles
}) => {
  const handleDragged = (marker) => {
    setPosition(marker.latLng.lat(), marker.latLng.lng());
  };

  const [circles, setCircles] = useState([]);

  return (
    <GoogleMap
      on
      defaultZoom={15}
      center={center}
      defaultCenter={center}
      defaultOptions={{
        styles
      }}
    >
      {
        centerMarker && <Marker draggable={draggable} onDragEnd={handleDragged} position={center} />
      }
      {
        markers?.length > 0 && markers?.map(
          (marker) => (
            <>
              <Marker
                onClick={() => {
                  marker?.toggle(marker?.uuid, markers);
                  setCircles([...circles, {
                    id: marker?.uuid,
                    center: marker?.position,
                    radius: marker?.radius
                  }]);
                }}
                position={marker?.position}
                icon={{
                  url: marker?.brand?.logo ?? marker?.icon,
                  scaledSize: new window.google.maps.Size(35, 35)
                }}
              >
                {
                  marker?.infoWindow && (
                    <InfoWindow onCloseClick={() => marker?.toggle(marker?.uuid)}>
                      <InfoWindowContent data={marker} />
                    </InfoWindow>
                  )
                }
              </Marker>
              {
                circles?.map((circle) => {
                  return (
                    <Circle
                      onClick={() => {
                        setCircles(circles.filter((item) => item.id !== circle.id));
                      }}
                      key={circle.id}
                      center={circle.center}
                      radius={circle.radius}
                      options={{
                        strokeColor: '#156e2b',
                        fillColor: '#12b93c',
                        fillOpacity: 0.003,
                        clickable: true,
                      }}
                    />
                  );
                })
              }
            </>
          )
        )
      }
    </GoogleMap>
  );
}));

GoogleMapWithToggle.defaultProps = {
  center: { lat: 0, lng: 0 },
  setPosition: () => { },
  draggable: false,
  markers: [],
  centerMarker: true,
  styles: [],
};

GoogleMapWithToggle.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  setPosition: PropTypes.func,
  draggable: PropTypes.bool,
  centerMarker: PropTypes.bool,
  markers: PropTypes.array,
  styles: PropTypes.array,
};

export default GoogleMapWithToggle;
