import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import getInitials from 'src/utils/getInitials';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Drawer,
  Hidden,
  List,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles
} from '@material-ui/core';
import {
  // AlertCircle as AlertCircleIcon,
  // Lock as LockIcon,
  // Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  // Users as UsersIcon,
} from 'react-feather';
import {
  AppsOutlined as AppsIcon,
  Public as PublicIcon,
  Landscape,
  LocationCity,
  Group,
  ShoppingCart,
  TransferWithinAStation,
  Store,
  Announcement,
  LocalOffer,
  TrackChanges,
  AddAPhoto,
  Photo,
  ExitToApp as ExitToAppIcon,
  BarChart,
  LocalShipping as LocalShippingIcon,
  LiveHelp,
  NotificationsActive,
  CancelPresentation,
  Movie,
  AddBox,
  Bookmarks,
  Assignment,
  ChatRounded,
  Dashboard,
  Web
} from '@material-ui/icons';
import { roles, getUserRole, rolesTranslates } from '../../../helpers/roles';
import NavItem from './NavItem';
import { selector as ChatSelector } from '../../../redux/ducks/chat';
import {
  selector as UserSelector,
  actions as UserActions
} from '../../../redux/ducks/user';

// Utils
import isArray from '../../../utils/is_array';

const items = [
  {
    href: '/app/ordenes/en-progreso',
    icon: LocalShippingIcon,
    title: 'Ordenes en Progreso',
    roles: [roles.ADMIN],
    notifications: false,
    active: true
  },
  {
    href: '/app/chat-soporte',
    icon: ChatRounded,
    title: 'Chat soporte',
    roles: [roles.ADMIN],
    notifications: true,
    active: false
  },
  {
    href: '/app/notificaciones/enviar',
    icon: NotificationsActive,
    title: 'Enviar Notificación',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/adomi-categorias',
    icon: AppsIcon,
    title: 'Adomi Categorías',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/adomi-componentes',
    icon: ShoppingCart,
    title: 'Adomi Componentes',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/zonas',
    icon: AddBox,
    title: 'Zonas Comerciales',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/templates',
    icon: Web,
    title: 'Templates',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/marcas',
    icon: ShoppingBagIcon,
    title: 'Marcas',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/aliados',
    icon: Group,
    title: 'Aliados',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/sucursales',
    icon: Store,
    title: 'Sucursales',
    roles: [roles.ADMIN, roles.SELLER],
    notifications: false,
    active: false
  },
  {
    href: '/app/repartidores',
    icon: TransferWithinAStation,
    title: 'Repartidores',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/clientes',
    icon: Group,
    title: 'Clientes',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/administradores',
    icon: Group,
    title: 'Administradores',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/repartidores-potenciales',
    icon: TransferWithinAStation,
    title: 'Repartidores Potenciales',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/aliados-potenciales',
    icon: Group,
    title: 'Aliados Potenciales',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/cupones',
    icon: LocalOffer,
    title: 'Cupones',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/terminos-y-condiciones',
    icon: Assignment,
    title: 'Terminos y condiciones',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/anuncios',
    icon: Announcement,
    title: 'Anuncios',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/preguntas-frecuentes',
    icon: LiveHelp,
    title: 'Preguntas Frecuentes',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/tutoriales',
    icon: Movie,
    title: 'Tutoriales',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/razones-cancelacion',
    icon: CancelPresentation,
    title: 'Razones de cancelación',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/etiquetas',
    icon: Bookmarks,
    title: 'Etiquetas',
    roles: [roles.ADMIN, roles.SELLER],
    notifications: false,
    active: false
  },
  {
    href: '/app/imagenes',
    icon: AddAPhoto,
    title: 'Imágenes',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/covers-sucursales',
    icon: Photo,
    title: 'Covers de sucursales',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/paises',
    icon: PublicIcon,
    title: 'Países',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/estados',
    icon: Landscape,
    title: 'Estados',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/ciudades',
    icon: LocationCity,
    title: 'Ciudades',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/god-eye',
    icon: TrackChanges,
    title: 'God eye',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/map-polygons',
    icon: TrackChanges,
    title: 'Mapa Polygons',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/map-sucursal',
    icon: TrackChanges,
    title: 'Mapa Surcursales',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/map-driver',
    icon: TrackChanges,
    title: 'Mapa Driver',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/reporte/usuarios-registrados',
    icon: BarChart,
    title: 'Reporte de usuarios',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/reporte/zonas-comerciales',
    icon: BarChart,
    title: 'Reporte de zonas comerciales',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/reporte/demografico',
    icon: BarChart,
    title: 'Reporte demografico',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  },
  {
    href: '/app/reporte/operaciones',
    icon: BarChart,
    title: 'Reportes operacionales',
    roles: [roles.ADMIN],
    notifications: false,
    active: false
  }
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    zIndex: 4
  },
  drawer: {
    width: 256,
    flexShrink: 0,
    border: 'none',
    '& .MuiDrawer-paperAnchorDockedLeft': {
      border: 'none'
    },
    '& .MuiDrawer-paper': {
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
    }
  },
  avatar: {
    cursor: 'pointer',
    width: 45,
    height: 45,
    marginBottom: theme.spacing(0.5),
    backgroundColor: '#009739'
  },
  scroll: {
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.main
    }
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pendingChats, activeChats } = useSelector(ChatSelector);
  const { user } = useSelector(UserSelector);
  // const [showInstallButton, setShowInstallButton] = useState(false);
  const [tabs, setTabs] = useState(items);

  const navigateToTab = (tabActive, href) => {
    if (href !== '/auth') {
      const actTabActive = tabs.findIndex((item) => item?.active);
      tabs[actTabActive].active = false;
      tabs[tabActive].active = true;
      setTabs(tabs.slice());
    } else dispatch(UserActions.clear());
    navigate(href, { replace: true });
  };

  useEffect(() => {
    if (openMobile && onMobileClose && !matchesLg) {
      onMobileClose((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    // Se activa el tab que coincida con la url actual
    setTabs((prev) => {
      if (!isArray(prev)) return [];
      return prev.map((tab) => {
        return {
          ...tab,
          active: location.pathname.includes(tab?.href)
        };
      });
    });
  }, []);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      bgcolor="#fffefe"
      border="none"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={3}
        px={3}
      >
        <Avatar className={classes.avatar} src={user?.avatar}>
          {getInitials(user?.first_name)}
        </Avatar>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            color="textPrimary"
            variant="h6"
            style={{
              fontSize: 15,
              textAlign: 'center'
            }}
          >
            {` ${user?.first_name} ${user?.last_name}`}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: 13,
              fontWeight: 500,
              color: '#bdbccf'
            }}
          >
            {rolesTranslates(getUserRole(user))}
          </Typography>
        </Box>
      </Box>
      <Box overflow="auto" mt={3.5} className={classes.scroll}>
        <Box px={1.5} display="flex" alignItems="center">
          <Dashboard
            style={{
              color: '#009739',
              fontSize: 21
            }}
          />
          <Typography
            color="primary"
            variant="body2"
            style={{
              fontSize: 14,
              fontWeight: 500,
              marginLeft: 5
            }}
          >
            Dashboard
          </Typography>
        </Box>
        <Box>
          <List>
            {tabs
              .filter((item) => item?.roles.includes(getUserRole(user)))
              .map((item, index) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  active={item?.active}
                  index={index}
                  navigateToTab={navigateToTab}
                  notifications={
                    item?.notifications
                      ? pendingChats.length + activeChats.length
                      : 0
                  }
                />
              ))}
            <NavItem
              href="/auth"
              title="Cerrar sesión"
              icon={ExitToAppIcon}
              navigateToTab={navigateToTab}
            />
          </List>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={() => onMobileClose((prev) => !prev)}
          open={openMobile}
          variant="temporary"
        >
          {content}
          {/* {showInstallButton && (
            <Box m={4}>
              <Button variant="outlined" onClick={installPWA}>
                Instalar APP
              </Button>
            </Box>
          )} */}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          elevation={0}
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.desktopDrawer }}
          open={!openMobile}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
