import HttpRequest from './HttpRequest';

export default class ProductService extends HttpRequest {
  static endpoint = 'products';

  static async getAll() {
    this.endpoint = 'products';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'products';
    const response = await this.get(id);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'products';
    const response = await this.put(id, data);
    return response;
  }
}
