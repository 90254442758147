import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import AdomiCategoryCard from './AdomiCategoryCard';
import AdomiCategoryService from '../../../services/AdomiCategoryService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  brandCard: {
    height: '100%'
  }
}));

const AdomiCategoryList = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [categories, setCategories] = useState([]);

  const foo = async () => {
    const fetchData = typeof id !== 'undefined'
      ? await AdomiCategoryService.getById(id)
      : await AdomiCategoryService.getAll();

    if (fetchData?.code === 200) {
      if (id) {
        setCategories(fetchData.data.sub_categories);
      } else {
        setCategories(fetchData.data);
      }
    }
  };

  useEffect(() => {
    foo();
  }, []);

  useEffect(() => {
    if (id && id?.length > 0) {
      foo();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Categorías de Adomi"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            {categories.map((category) => (
              <Grid
                item
                key={category.id}
                lg={4}
                md={6}
                xs={12}
              >
                <AdomiCategoryCard
                  className={classes.categoryCard}
                  category={category}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            color="primary"
            count={3}
            size="small"
          />
        </Box>
      </Container>
    </Page>
  );
};

export default AdomiCategoryList;
