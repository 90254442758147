import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

// Services
import TemplateService from 'src/services/TemplateService';

// Hooks
import useModal from 'src/hooks/useModal';
import useErrors from 'src/hooks/useErrors';

import { Add } from '@material-ui/icons';

// Components
import {
  Box,
  Card,
  Grid,
  IconButton,
  Switch,
  Typography,
  makeStyles
} from '@material-ui/core';
import useTemplateListContext from 'src/hooks/useTemplateListContext';
import ModalTemplates from './ModalTemplates';

// Icons

const useStyles = makeStyles(() => ({
  card: {
    width: '49%'
  }
}));

const TemplateItem = ({ activeTemplate }) => {
  const classes = useStyles();
  const {
    selectedTemplates,
    onChangeSelectedTemplates
  } = useTemplateListContext();

  const handleChange = (event) => {
    const { checked } = event.target;
    const newTemplates = selectedTemplates.map((template) => {
      if (template.id === activeTemplate.id)
        return { ...template, is_active: checked };
      return { ...template, is_active: false };
    });
    onChangeSelectedTemplates(newTemplates);
  };

  return (
    <Card className={classes.card}>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        p={2}
        justifyContent="space-between"
      >
        <Typography>{activeTemplate.name}</Typography>
        <Switch
          color="primary"
          checked={activeTemplate.is_active}
          onChange={(e) => handleChange(e)}
        />
      </Box>
    </Card>
  );
};

const TemplateList = () => {
  const { id } = useParams();
  const { setErrors } = useErrors();
  const { isOpen, toggle } = useModal();
  const {
    selectedTemplates,
    onChangeSelectedTemplates
  } = useTemplateListContext();

  const getTemplates = async () => {
    const response = await TemplateService.getByBrand(id);
    if (response.code !== 200 && response.code !== 201) {
      setErrors(response.errors);
      return;
    }
    const newTemplates = response.data.map((newTemplate) => ({
      id: newTemplate.template.id,
      name: newTemplate.template.name,
      is_active: newTemplate.is_active
    }));
    const newSelectedTemplates = Array.from(
      new Set([...newTemplates, ...selectedTemplates])
    );
    onChangeSelectedTemplates(newSelectedTemplates);
  };

  useEffect(() => {
    if (id) {
      getTemplates();
    }
  }, [id]);

  return (
    <>
      <Grid item xs={12}>
        <Box width="100%" display="flex" flexDirection="column" gridGap={8}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Templates</Typography>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                toggle();
              }}
            >
              <Add />
            </IconButton>
          </Box>
          {selectedTemplates.length > 0 && (
            <Box display="flex" alignItems="center" flexWrap="wrap" gridGap={8}>
              {selectedTemplates.map((template) => (
                <TemplateItem key={template.id} activeTemplate={template} />
              ))}
            </Box>
          )}
        </Box>
      </Grid>
      {isOpen && <ModalTemplates isOpen={isOpen} toggle={toggle} />}
    </>
  );
};

TemplateItem.propTypes = {
  activeTemplate: PropTypes.shape({
    id: PropTypes.string,
    is_active: PropTypes.bool,
    name: PropTypes.string
  }).isRequired
};

export default TemplateList;
