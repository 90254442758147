import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import LoadingBox from 'src/components/LoadingBox';
import TemplateService from '../../../services/TemplateService';
import CreateTemplateForm from './CreateTemplateForm';
import TemplateCardPreview from './TemplateCardPreview';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateTemplateView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(false);

  const handleTemplateAttributes = (values) => {
    setTemplate({
      ...template,
      ...values
    });
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await TemplateService.getById(id);
        if (response.code === 200) {
          setTemplate({
            id: response.data.id,
            name: response.data.name
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page className={classes.root} title="Crear template">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <TemplateCardPreview template={template} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <CreateTemplateForm
                edit={edit}
                template={template}
                onChange={handleTemplateAttributes}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateTemplateView.propTypes = {
  edit: PropTypes.bool
};

CreateTemplateView.defaultProps = {
  edit: false
};

export default CreateTemplateView;
