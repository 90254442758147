import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  // Chip,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
  Typography,
  // Table,
  // TableRow,
  // TableCell,
  // TableBody,
  // TableHead,
  makeStyles,
  useMediaQuery,
  withStyles,
  InputBase
} from '@material-ui/core';
import { CancelRounded, Error } from '@material-ui/icons';
import DriverService from 'src/services/DriverService';
import OrderService from 'src/services/OrderService';

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 15,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Poppins"',
      'sans-serif',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      boxShadow: '0 0 0 0.2rem rgba(67,160,71,.25)',
    },
  },
}))(InputBase);

const StatusModalSent = ({
  onClose,
  order,
  refresh,
}) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');
  const [drivers, setDrivers] = useState([]);
  const [driverSelected, setDriverSelected] = useState('');
  const [error, setError] = useState(false);

  const useStyles = makeStyles(() => ({
    buttons: {
      display: 'inline-block',
    },
    overlay: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '450px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      padding: '15px 10px',
      borderBottom: '1px solid #e6ebf1',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > h3': {
        fontWeight: 'bold',
        fontSize: '22px'
      }
    },
    icon: {
      animation: '$shake 0.2s ease-in-out infinite alternate',
    },
    line: {
      transformOrigin: 'center right',
      strokeDasharray: 22,
      animation: '$line 0.8s ease-in-out infinite',
      animationFillMode: 'both',
      MozAnimationFillMode: 'both',
      WebkitAnimationFillMode: 'both',
    },
    lineTop: {
      animationDelay: '0s'
    },
    lineMiddle: {
      animationDelay: '0.2s'
    },
    lineBottom: {
      animationDelay: '0.4s'
    },
    btnConfirm: {
      textTransform: 'capitalize',
      borderRadius: 6,
      width: 125,
      fontWeight: 700,
      fontSize: '14px'
    },

    '@keyframes shake': {
      '0%': {
        transform: 'translateY(-1%)',
      },
      '100%': {
        transform: 'translateY(2%)',
      }
    },

    '@keyframes line': {
      '0%': {
        strokeDashoffset: 22,
      },
      '25%': {
        strokeDashoffset: 22,
      },
      '50%': {
        strokeDashoffset: 0,
      },
      '51%': {
        strokeDashoffsett: 0,
      },
      '80%': {
        strokeDashoffset: -22,
      },
      '100%': {
        strokeDashoffset: -22,
      }
    }
  }));
  const classes = useStyles();

  const handleDriver = (e) => {
    setDriverSelected(e.target.value);
  };

  const onPush = async () => {
    setError(false);
    if (driverSelected.length === 0) {
      setError(true);
      return;
    }
    const response = await DriverService.getById(driverSelected);
    if (response?.code === 200) {
      const dataAssign = {
        driver_id: driverSelected,
        order_id: order?.id,
        data_driver: response?.data,
        status: 'sent',
      };
      OrderService.assignDriver(dataAssign)
        .then(() => {
          refresh();
          onClose();
        }).catch((err) => {
          console.log(`Este es el error: ${err}`);
        });
    }
  };

  const getDrivers = async () => {
    const response = await DriverService.getAll();

    if (response?.code === 200) {
      const d = Array.from(response?.data)?.reduce((acc, item) => {
        return acc.concat({
          id: item?.id,
          country_id: item?.country_id,
          city_id: item?.city_id,
          first_name: item?.first_name,
          last_name: item?.last_name,
          avatar: item?.avatar,
          email: item?.email,
          birthdate: item?.birthdate,
          phone: item?.phone,
          whatsapp: item?.whatsapp,
          is_free: item?.is_free,
          has_dataphone: item?.has_dataphone,
          is_dual: item?.is_dual,
          branch_offices: item?.branch_offices,
          is_enabled: item?.is_enabled,
          is_develop: item?.is_develop,
        });
      }, []);

      setDrivers(d.filter((item) => item?.is_enabled && !item?.is_develop
          && order?.data_branch_office?.city_id === item?.city_id
          && item?.is_free));
    }
  };

  useEffect(() => {
    getDrivers();
  }, []);

  return (
    <div className={classes?.overlay}>
      <div className={classes.pane} onClick={() => onClose('sent', false)} />
      <div className={classes?.modal}>
        <div className={classes.header}>
          <div className={classes.title}>
            <h3 style={{ fontFamily: '"Poppins", sans-serif' }}>Asignación de driver</h3>
            <CancelRounded color="primary" style={{ cursor: 'pointer' }} onClick={() => onClose()} />
          </div>
        </div>
        <Box>
          <Box p={3} pt={1}>
            <Box display="flex" alignItems="center">
              <FormControl style={{ width: '100%' }} error={error}>
                <InputLabel htmlFor="demo-customized-select-native">Drivers</InputLabel>
                <NativeSelect
                  id="demo-customized-select-native"
                  value={driverSelected}
                  onChange={handleDriver}
                  input={<BootstrapInput />}
                >
                  <option aria-label="None" value="">Seleccione un driver...</option>
                  {drivers.map((item) => (
                    <option value={item?.id}>{`${item?.first_name} ${item?.last_name}`}</option>
                  ))}
                </NativeSelect>
                {error && (
                  <FormHelperText>
                    <Box display="flex" alignItems="center" mt={0.5}>
                      <Error size="small" style={{ fontSize: '23px', marginRight: '4px' }} />
                      <Typography style={{ fontWeight: 500, fontSize: '13px', fontFamily: '"Poppins", sans-serif' }} variant="body2">
                        Debe seleccionar un driver
                      </Typography>
                    </Box>
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box mt={2.5} textAlign="center">
              <Button className={classes.btnConfirm} size="small" variant="contained" color="primary" onClick={onPush}>Asignar</Button>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

StatusModalSent.propTypes = {
  onClose: PropTypes.func,
  refresh: PropTypes.func,
  order: PropTypes.object,
};

export default StatusModalSent;
