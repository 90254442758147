import React from 'react';
import clsx from 'clsx';
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Swal from '@sweetalert/with-react';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import NotificationService from '../../../services/NotificationService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateNotificationForm = ({
  className, notification, onChange, ...rest
}) => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const { setErrors } = useErrors();

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const translateApp = (app) => {
    switch (app) {
      case 'clients':
        return 'Pedidos';
      case 'partners':
        return 'Aliados';
      case 'drivers':
        return 'Repartidores';
      default: return '';
    }
  };

  const handleSubmit = async () => {
    const errors = [];
    const body = {
      topic: notification?.topic,
      data: {
        click_action: 'FLUTTER_NOTIFICATION_CLICK'
      },
      notification: {
        title: notification?.title,
        body: notification?.body
      },
      android: {
        notification: {
          sound: 'adomi.mp3'
        }
      }
    };

    const app = notification?.app;

    if (body?.notification?.title?.length === 0) {
      errors.push('El titulo es obligatorio');
    }

    if (body?.notification?.body?.length === 0) {
      errors.push('El cuerpo es obligatorio');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const response = body?.topic?.length === 0
      ? await NotificationService.sendToAppInGeneral(app, body)
      : await NotificationService.sendByTopic(body);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      await Swal({
        content: (
          <p>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              La notificación fue enviada correctamente
            </Typography>
          </p>
        ),
        icon: 'success',
      });
      // navigate('/app/notificaciones/enviar', { replace: true });
      onChange({
        title: '',
        body: '',
        app: '',
        topic: ''
      });
    } else {
      setErrors(response?.errors);
    }
  };

  const alertForHandleSubmit = async () => {
    await Swal({
      content: (
        <div>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Enviar notificación
          </Typography>
          <p>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              ¿Está seguro que desea enviar la notificación?
            </Typography>
          </p>
        </div>
      ),
      icon: 'warning',
      buttons: ['No', 'Si']
    }).then(async (answer) => {
      if (answer) {
        await handleSubmit();
      }
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La notificación puede ser enviada a una app específica o por un topic"
          title="Notificación"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el titulo"
                label="Titulo"
                name="title"
                onChange={handleChange}
                required
                value={notification?.title}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={2}
                placeholder="Escriba el cuerpo"
                label="Cuerpo"
                name="body"
                onChange={handleChange}
                required
                value={notification?.body}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes?.formControl}>
                <InputLabel id="app">Aplicación</InputLabel>
                <Select
                  name="app"
                  labelId="app"
                  label="Aplicación"
                  value={notification?.app}
                  onChange={handleChange}
                  disabled={notification?.topic?.length > 0}
                >
                  {
                    ['', 'clients', 'partners', 'drivers'].map((item) => (
                      <MenuItem key={item} value={item}>
                        { translateApp(item) }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el topic"
                label="Topic"
                name="topic"
                onChange={handleChange}
                value={notification?.topic}
                variant="outlined"
                disabled={notification?.app?.length > 0}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={alertForHandleSubmit}
          >
            Enviar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

CreateNotificationForm.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  notification: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    app: PropTypes.string,
    topic: PropTypes.string
  })
};

CreateNotificationForm.defaultProps = {
  className: '',
  onChange: () => {},
  notification: {
    title: '',
    body: '',
    app: '',
    topic: ''
  },
};

export default CreateNotificationForm;
