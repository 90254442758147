import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import store from 'src/redux/store';
import './ListPolygons.css';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '18px',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: '20px 10px'
  },
  prefix: {
    color: '#546e7a',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    flexGrow: 1,
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgba(68, 162, 73, 0.5)',
    },
  }
}));

const ListPolygons = ({
  polygons, setEditable, showWarning, handleDelete
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const showAside = () => {
    setShow(!show);
    if (!show) {
      document.body.className += 'overflow-hidden';
      document.querySelector('.btn-list').className += ' show-btn';
    } else {
      document.body.className = '';
      document.querySelector('.btn-list').className = 'btn-list';
    }
  };

  return (
    <>
      <div className="polygons aside" style={{ right: show ? '0' : '-100%' }}>
        <div
          className="btn-list"
          onClick={() => showAside()}
        >
          <ArrowBackIosRoundedIcon fontSize="small" style={{ transition: 'transform 0.3s ease', transform: show ? 'rotate(180deg)' : 'rotate(0deg)', color: '#ffffff' }} />
        </div>
        <div className={classes.title}>
          <h2>Poligonos</h2>
        </div>
        <ul className="list">
          {polygons.length > 0
            && polygons.map((item, index) => (
              <li key={item.id} className="item-list">
                <div
                  className="polygon"
                >
                  <div className="info-item">
                    <span className={classes.prefix}>{item.prefix}</span>
                    <div className="buttons">
                      <IconButton
                        className={classes.button}
                        onClick={() => {
                          if (store.getState()?.app?.city === 'general') {
                            showWarning(true, 'Debe seleccionar una ciudad para poder editar el poligono');
                            return;
                          }
                          showAside();
                          setEditable(item.id, item.points[0]);
                        }}
                        size="medium"
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        className={classes.button}
                        size="medium"
                        onClick={() => {
                          if (store.getState()?.app?.city === 'general') {
                            showWarning(true, 'Debe seleccionar una ciudad para poder eliminar el poligono');
                            return;
                          }
                          handleDelete(item.id, index);
                        }}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

ListPolygons.propTypes = {
  polygons: PropTypes.array,
  setEditable: PropTypes.func,
  showWarning: PropTypes.func,
  handleDelete: PropTypes.func
};

export default ListPolygons;
