import React, { useState } from 'react';
import ChatSupportService from 'src/services/ChatSupportService';
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Card
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FcOnlineSupport } from 'react-icons/fc';
import Page from 'src/components/Page';
import {
  updateChat,
} from '../../../redux/ducks/chat';
import ListChats from './ListChats';
import Chat from './Chat';
import Welcome from './components/Welcome';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f6f6fa',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  cardChat: {
    height: '100%',
    borderRadius: 20,
    backgroundColor: '#f4f7fa',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
  }
}));

const ChatSupportView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedChat, setSelectedChat] = useState(null);

  const handleChat = async (id, color, event = 'set') => {
    if (id) {
      const response = await ChatSupportService.getById(id);
      if (response.code === 200) {
        setSelectedChat({
          ...response?.data,
          color
        });
        if (event === 'new' && response.data.support_messages[0]?.sent_by !== 'user') {
          const data = {
            id,
            unanswered_messages_by_admin: 0
          };
          dispatch(updateChat(data));
        }
      }
    } else {
      setSelectedChat(null);
    }
  };

  const addMessage = (newMsg) => {
    selectedChat?.support_messages.splice(0, 0, newMsg);
    setSelectedChat({
      ...selectedChat
    });
  };

  return (
    <Page
      title="Chat soporte"
      className={classes.root}
    >
      <Container style={{ height: '100%' }}>
        <Card className={classes.cardChat}>
          <Box
            display="flex"
            alignItems="center"
            bgcolor="#fff"
            height="10%"
            p={2}
          >
            <FcOnlineSupport
              size={30}
            />
            <Typography
              variant="h4"
              className={classes.title}
            >
              Soporte
            </Typography>
          </Box>
          <Box
            display="flex"
            p={2}
            height="90%"
          >
            <ListChats
              setChat={handleChat}
            />
            {!selectedChat && <Welcome />}
            {selectedChat && (
              <Chat
                selectedChat={selectedChat}
                messages={selectedChat?.support_messages?.slice()}
                setChat={handleChat}
                addMessage={addMessage}
              />
            )}
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default ChatSupportView;
