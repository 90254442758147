import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import adminsPhone from 'src/utils/adminsPhone';
import { selector as UserSelector } from 'src/redux/ducks/user';
import TemplateService from 'src/services/TemplateService';
import useTemplateListContext from 'src/hooks/useTemplateListContext';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import BrandService from '../../../services/BrandService';
import useConfirmation from '../../../hooks/useConfirmation';
import ConfirmationModal from '../../../components/ConfirmationModal';
import TemplateList from './TemplateList';

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  }
}));

const CreateBrandForm = forwardRef(
  (
    { className, brand, edit, onChange, hasImage, setLoading, ...rest },
    ref
  ) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user } = useSelector(UserSelector);
    const { setErrors } = useErrors();
    const { toggleModal } = useConfirmation();
    const [values, setValues] = useState([]);
    const { selectedTemplates } = useTemplateListContext();

    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value
      });

      onChange({
        ...values,
        [event.target.name]: event.target.value
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const errors = [];
      const file = ref.current.files[0];
      const data = {
        name: e.target?.name?.value,
        description: e.target?.description?.value,
        slug: e?.target?.slug?.value,
        google_analytics_id:
          e?.target?.google_analytics_id.value.length !== 0
            ? e?.target?.google_analytics_id.value
            : null,
        facebook_pixel_id:
          e?.target?.facebook_pixel_id.value.length !== 0
            ? e?.target?.facebook_pixel_id.value
            : null,
        hotjar_id:
          e?.target?.hotjar_id.value.length !== 0
            ? e?.target?.hotjar_id.value
            : null
      };

      if (data.name.length === 0) {
        errors.push('El nombre de la marca es obligatoria');
      }
      if (data.description.length === 0) {
        errors.push('La descripción de la marca es obligatoria');
      }

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }

      // setLoading(true);

      const response = edit
        ? await BrandService.update(brand?.id, data)
        : await BrandService.create(data);

      if (response?.code === 201 || response?.code === 200) {
        setErrors([]);
        /*
      if (response?.data?.logo !== null && file !== undefined) {
        await BrandService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await BrandService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/marcas');
          }, 500);
        }
      }
      if (response?.data?.logo === null && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await BrandService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/marcas');
          }, 500);
        }
      }
       */
        await TemplateService.addTemplates(
          response.data.id,
          selectedTemplates.map((template) => ({
            id: template.id,
            is_active: template.is_active
          }))
        );

        if (file !== undefined) {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('id', response.data.id);
          const r = await BrandService.uploadImage(formData);

          if (r?.code === 200 || r?.code === 201) {
            setTimeout(() => {
              return navigate('/app/marcas');
            }, 500);
          }
        }
        navigate('/app/marcas', { replace: true });
      } else {
        setErrors(response.errors);
      }
    };

    const deleteBrand = async () => {
      if (brand?.id) {
        await BrandService.deleteImage(brand.id);
        const response = await BrandService.remove(brand.id);
        if (!response) {
          navigate('/app/marcas', { replace: true });
        }
      }
    };

    useEffect(() => {
      if (edit) {
        setValues(brand);
      }
    }, [edit, brand]);

    return (
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Card>
          <CardHeader
            subheader="La información puede ser editada"
            title="Marca"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escribe el nombre"
                  label="Nombre"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Slug"
                  label="Slug"
                  name="slug"
                  onChange={handleChange}
                  required
                  value={values?.slug}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el ID de Google Analytics"
                  label="ID de Google Analytics"
                  name="google_analytics_id"
                  onChange={handleChange}
                  value={values?.google_analytics_id}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el ID de Facebook Pixel"
                  label="ID de Facebook Pixel"
                  name="facebook_pixel_id"
                  onChange={handleChange}
                  value={values?.facebook_pixel_id}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba el ID de hotjar"
                  label="ID de hotjar"
                  name="hotjar_id"
                  onChange={handleChange}
                  value={values?.hotjar_id}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="Escriba la descripción"
                  label="Descripción"
                  name="description"
                  onChange={handleChange}
                  required
                  value={values.description}
                  variant="outlined"
                />
              </Grid>
              <TemplateList />
              {hasImage && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Logo"
                    name="logo"
                    onChange={handleChange}
                    required
                    value={values.logo}
                    variant="outlined"
                  />
                </Grid>
              )}
              <input
                name="logo[]"
                type="file"
                ref={ref}
                style={{ display: 'none' }}
              />
            </Grid>
          </CardContent>
          <Errors time={0} />
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            {edit && adminsPhone.includes(user?.phone.toString()) && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )}
            <Button type="submit" color="primary" variant="contained">
              {edit ? 'Editar' : 'Guardar'}
            </Button>
          </Box>
        </Card>
        <ConfirmationModal onAccept={deleteBrand} />
      </form>
    );
  }
);

CreateBrandForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  brand: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
    slug: PropTypes.string,
    facebook_pixel_id: PropTypes.string,
    hotjar_id: PropTypes.string
  })
};

CreateBrandForm.defaultProps = {
  className: '',
  hasImage: false,
  onChange: () => {},
  setLoading: () => {}
};

export default CreateBrandForm;
