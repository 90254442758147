import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
// utils

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd'
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0
  },
  link: {
    color: '#000',
    display: 'block',
    padding: '.75rem 1rem',
    cursor: 'pointer',
    fontFamily: 'sans-serif',
    fontSize: '.9rem',
    '&:hover': {
      backgroundColor: '#eee'
    }
  }
}));

const Results = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [templates, setTemplates] = useState([]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onClick = (e, template) => {
    setPanel(panel ? null : template.id);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setTemplates(data);
    }
  }, [data]);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={600}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {templates
                  .slice(page * limit, limit * (page + 1))
                  .map((template) => (
                    <TableRow hover key={template.id}>
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Typography color="textPrimary" variant="body2">
                            {template.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell className={classes?.statusCell}>
                        <MoreVertIcon
                          className={classes?.moreVert}
                          onClick={(e) => onClick(e, template)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={templates.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {panel !== null && (
        <div
          className={classes?.statusPanel}
          style={{
            top: panelPosition?.y,
            left: panelPosition?.x - 120
          }}
        >
          <ul className={classes?.statusPanelList}>
            <li className={classes?.statusPanelItem}>
              <Link
                className={classes.link}
                to={`/app/templates/${panel}/editar`}
              >
                Editar
              </Link>
              <Link
                className={classes.link}
                to={`/app/page-templates/${panel}`}
              >
                Ver paginas
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired
};

export default Results;
