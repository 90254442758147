import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClientService from 'src/services/ClientService';
import {
  TextField,
  Grid,
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import useConfirmation from 'src/hooks/useConfirmation';

const PhoneNumberModal = ({ toggleModalPhone, handleClientId }) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');

  const useStyles = makeStyles(() => ({
    input: {
      '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: '0'
      },
      '& input[type=number]': {
        MozAppearance: 'textfield'
      }
    },
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '600px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative'
    },
    PhoneNumberModal: {
      backgroundColor: 'rgba(46, 44, 54, 0.2)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      bottom: '0',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null
    }
  }));

  const classes = useStyles();
  const { toggleModal } = useConfirmation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [option, setOption] = useState(1);
  const [error, setError] = useState('');

  const confirm = async () => {
    if (phoneNumber.length === 0) {
      setError('Debe ingresar el telefono del cliente');
      return;
    }

    let response = null;

    try {
      response = await ClientService.getByPhone(phoneNumber);
      if (response?.code === 200 && Object.keys(response?.data)?.length > 0) {
        handleClientId(response?.data?.id, option);
        setError('');
        toggleModal();
      } else if (response?.code === 404) {
        setError('No existe ningun cliente con este numero de telefono');
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <div className={classes.PhoneNumberModal}>
      <div className="pane" onClick={() => toggleModalPhone()} />
      <div className={classes.modal}>
        <div className="header">
          <div className="title">
            <h3>Eliminar cliente</h3>
            <CancelRoundedIcon
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => toggleModalPhone()}
            />
          </div>
        </div>
        <div className="body">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                focused
                className={classes.input}
                fullWidth
                placeholder="Escriba el teléfono"
                label="Teléfono"
                name="phone"
                error={error.length !== 0}
                helperText={error}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                variant="outlined"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Elija que desea eliminar del cliente
                </FormLabel>
                <RadioGroup
                  value={option}
                  onChange={(e) => setOption(Number(e.target.value))}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="Ordenes del cliente"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio color="primary" />}
                    label="Cuenta del cliente"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" paddingTop={3}>
            <Box mr={2}>
              <Button onClick={() => toggleModalPhone()}>Cancelar</Button>
            </Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => confirm()}
            >
              Aceptar
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

PhoneNumberModal.propTypes = {
  toggleModalPhone: PropTypes.func,
  handleClientId: PropTypes.func
};

export default PhoneNumberModal;
