import HttpRequest from './HttpRequest';

export default class ManagerService extends HttpRequest {
  static endpoint = 'managers';

  static async getByPhone(phone) {
    this.endpoint = `managers/get-by-phone/${phone}`;
    const response = await this.get();
    return response;
  }

  static async create(data) {
    this.endpoint = 'managers';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'managers';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'managers';
    const response = await this.get(id);
    return response;
  }
}
