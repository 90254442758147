import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import pusher from 'src/utils/pusher';
import dayjs from 'dayjs';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import OrderModal from 'src/components/OrderModal';
import StatusModalSent from 'src/components/StatusModalSent';
import StatusModalInProgress from 'src/components/StatusModalInProgress';
import OrderService from '../../../services/OrderService';
import Results from './Results';
import Toolbar from './Toolbar';

import orderBy from '../../../utils/arrays';

import { selector as UserSelector } from '../../../redux/ducks/user';
import { actions as AlertActions } from '../../../redux/ducks/alert';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const OrderListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [sent, setSent] = useState(false);
  const [detail, setDetail] = useState(false);
  const [orderSelected, setOrderSelected] = useState(null);
  const { user } = useSelector(UserSelector);
  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState(null);
  const [isDev, setIsDev] = useState(false);

  const translate = (status, ship) => {
    switch (status) {
      case 'in_progress':
        return 'En proceso';
      case 'finished':
        return 'Finalizado';
      case 'rejected':
        return 'Rechazado';
      case 'cancelled':
        return 'Cancelado';
      case 'pending_to_be_sent':
        if (ship === 'adomi') return 'Pendiente por enviar';
        return 'Pendiente por enviar local';
      case 'sent_local':
        return 'Enviado por local';
      case 'sent':
        return 'Enviado';
      case 'created':
        return 'Pendiente';
      default:
        return status;
    }
  };

  const getStatus = (status, ship) => {
    switch (status) {
      case 'CANCELLED':
        return 'in_process';
      case 'IN_PROGRESS':
        return 'accepted';
      case 'PENDING_TO_BE_SENT':
        if (ship === 'adomi') return 'accepted';
        return 'sent';
      case 'REJECTED':
        return 'rejected';
      case 'FINISHED':
        return 'finished';
      case 'SENT':
        return 'sent';
      case 'SENT_LOCAL':
        return 'sent';
      default:
        return 'pending';
    }
  };

  const handleOrder = (value) => {
    setOrderSelected(value);
  };

  const toggleSentModal = () => {
    setSent(!sent);
  };

  const toggleInProgressModal = () => {
    setInProgress(!inProgress);
  };

  const getCreatAt = (createAt, etp) => {
    const createdAt = new Date(createAt);
    createdAt.setMinutes(createdAt.getMinutes() + etp);
    return etp !== 0 ? Date.parse(createdAt) - Date.parse(new Date())
      : Date.parse(new Date()) - Date.parse(createdAt);
  };

  const toggleOrderModal = () => {
    setDetail(!detail);
  };

  const getOrderStatus = (order, findStatus) => {
    const status = order?.order_status?.filter((item) => item?.status === findStatus);
    const orderStatus = status[status?.length - 1];
    return orderStatus;
  };

  const orderByEtpDec = (data) => {
    return data.sort((a, b) => {
      const valA = getCreatAt(getOrderStatus(a, 'etp')?.created_at, getOrderStatus(a, 'etp')?.information?.etp);
      const valB = getCreatAt(getOrderStatus(b, 'etp')?.created_at, getOrderStatus(b, 'etp')?.information?.etp);
      if (valA < valB) return -1;
      if (valA > valB) return 1;
      return 0;
    });
  };
  const orderByEtpInc = (data) => {
    return data.sort((a, b) => {
      const valA = getCreatAt(getOrderStatus(a, 'pending_to_be_sent')?.created_at, 0);
      const valB = getCreatAt(getOrderStatus(b, 'pending_to_be_sent')?.created_at, 0);
      if (valA > valB) return -1;
      if (valA < valB) return 1;
      return 0;
    });
  };

  const getOrders = async () => {
    const response = await OrderService.getAllByDate(date || null);

    if (response?.data) {
      const pendingSentOrders = orderByEtpInc(response?.data?.orders?.filter((item) => item?.status === 'pending_to_be_sent'));
      const processOrders = orderByEtpDec(response?.data?.orders?.filter((item) => item?.status === 'in_progress'));
      const auxOrders = response?.data?.orders?.filter((item) => item?.status !== 'in_progress' && item?.status !== 'pending_to_be_sent');

      const data = auxOrders.concat(pendingSentOrders, processOrders).reduce((array, item) => {
        let statusOrder = 0;
        switch (item?.status) {
          case 'pending_to_be_sent':
            if (item.ship_type === 'adomi') statusOrder = 0;
            else statusOrder = 3;
            break;
          case 'created':
            statusOrder = 2;
            break;
          case 'in_progress':
            if (item.ship_type === 'adomi') statusOrder = 1;
            else statusOrder = 4;
            break;
          case 'finished':
            statusOrder = 6;
            break;
          case 'rejected':
            statusOrder = 7;
            break;
          case 'cancelled':
            statusOrder = 8;
            break;
          default:
            statusOrder = 5;
        }

        return array.concat({ ...item, statusOrder });
      }, []);

      setOrders(orderBy(data, 'statusOrder'));
    }
  };

  const refresh = async () => {
    if (user?.id) {
      getOrders();
    }
  };

  const handleFilter = (value) => setFilter(value);

  const handleSearch = (value) => setSearch(value);

  const handleDev = (e) => {
    setIsDev(e?.target?.checked);
  };

  const filterDev = (ors) => {
    if (isDev) return ors;

    return ors?.filter((order) => (
      !order?.data_branch_office?.is_develop && !order?.data_branch_office?.is_demo
    ));
  };

  const filterData = (ors) => {
    if (!filter) return ors;

    return ors?.filter((order) => {
      if (filter === 'pending_to_be_sent') {
        return (order?.status === filter && order?.ship_type === 'adomi') || order?.payment_method === filter;
      }
      return order?.status === filter || order?.payment_method === filter;
    });
  };

  const searchData = (ors) => {
    if (!search) return ors;

    return ors?.reduce((array, order) => {
      if (order?.reference?.toString()?.search(search) !== -1) {
        return array.concat(order);
      }
      return array;
    }, []);
  };

  const handleDate = (d) => setDate(d);

  useEffect(() => {
    if (user?.id) {
      getOrders();
      const channel = pusher.subscribe('adomi-dashboard');

      ['create-order', 'changes-orders', 'new-order', 'create-order-product', 'create-order-status', 'command-order', 'finish-order', 'client-cancel-order', 'manager-cancel-order']
        .map((item) => {
          channel.bind(item, () => {
            refresh();
            if (['new-order', 'create-order-product']?.some((event) => event === item)) {
              refresh();
              dispatch(AlertActions.set());
            }
            if (['changes-orders']?.some((event) => event === item)) {
              refresh();
            }
          });
          return item;
        });
    }
  }, [user]);

  useEffect(() => {
    refresh();
  }, [date]);

  useEffect(() => {
    if (!sent && detail) handleOrder(orders.find((item) => item?.id === orderSelected?.id));
  }, [refresh]);

  return (
    <Page
      className={classes.root}
      title="Ordenes en progreso"
    >
      <Container maxWidth={false}>
        <Toolbar
          date={date}
          refresh={refresh}
          onSearch={handleSearch}
          onChange={handleFilter}
          onChangeDate={handleDate}
          onDev={handleDev}
        />
        <Box mt={3}>
          <Results
            data={searchData(filterData(filterDev(orders)))}
            toggleSentModal={toggleSentModal}
            toggleDetailModal={toggleOrderModal}
            toggleInProgressModal={toggleInProgressModal}
            onRefresh={refresh}
            setOrder={handleOrder}
            statusOrder={getStatus}
            translate={translate}
          />
        </Box>
      </Container>
      {
        detail && (
          <OrderModal
            order={orderSelected}
            toggleOrderModal={toggleOrderModal}
            toggleSentModal={toggleSentModal}
            statusOrder={getStatus}
            statusTranslate={translate}
            onRefresh={refresh}
            setOrder={handleOrder}
          />
        )
      }
      {
        inProgress && (
          <StatusModalInProgress
            onClose={toggleInProgressModal}
            order={orderSelected}
          />
        )
      }
      {
        sent && (
          <StatusModalSent
            onClose={toggleSentModal}
            order={orderSelected}
            refresh={refresh}
            setOrder={handleOrder}
            detail={detail}
          />
        )
      }
    </Page>
  );
};

export default OrderListView;
