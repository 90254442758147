import HttpRequest from './HttpRequest';

export default class BrandService extends HttpRequest {
  static endpoint = 'brands';

  static async getAll() {
    this.endpoint = 'brands/branch-offices';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'brands';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'brands';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'brands';
    const response = await this.put(id, data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'brands';
    const response = await this.delete(id);
    return response;
  }

  static async uploadImage(data) {
    this.endpoint = 'brands/create-logo';
    const response = await this.post(data);
    return response;
  }

  static async deleteImage(data) {
    this.endpoint = 'brands/delete-logo';
    const response = await this.delete(data);
    return response;
  }

  static async toggleMaintenance(data) {
    this.endpoint = 'brands/all/toggle-maintenance';
    const response = await this.putNew(data);
    return response;
  }
}
