import HttpRequest from './HttpRequest';
import generateEndpoint from '../utils/generateEndpoint';

export default class DriverService extends HttpRequest {
  static endpoint = 'drivers';

  static async getAll() {
    this.endpoint = generateEndpoint('drivers');
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'drivers';
    const response = await this.get(id);
    response.data.birthdate = response.data.birthdate.substring(0, 10);
    return response;
  }

  static async getByBranchOffice(branchOfficeId) {
    this.endpoint = generateEndpoint(`drivers?branch_office=${branchOfficeId}`);
    const response = await this.get();
    return response;
  }

  static async getBranchOffices(id) {
    this.endpoint = 'drivers/branch-offices';
    const response = await this.get(id);
    return response;
  }

  static async alertToActivate(id) {
    this.endpoint = 'drivers/alert-to-activate';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'drivers';
    const response = await this.post(data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'drivers';
    const response = await this.delete(id);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'drivers';
    const response = await this.put(id, data);
    return response;
  }

  static async updateSchedule(id, data) {
    this.endpoint = 'drivers/schedule';
    const response = await this.put(id, data);
    return response;
  }

  static async uploadImage(data) {
    this.endpoint = 'drivers/create-avatar';
    const response = await this.post(data);
    return response;
  }

  static async deleteImage(data) {
    this.endpoint = 'drivers/delete-avatar';
    const response = await this.delete(data);
    return response;
  }

  static async register(data) {
    this.endpoint = 'auth/drivers/register/phone';
    const response = await this.post(data);
    return response;
  }

  static async changeIfEnabled(id) {
    this.endpoint = 'drivers/change-if-enabled';
    const response = await this.get(id);
    return response;
  }

  static async getAvailableByCity(cityId, branchOfficeId = null) {
    this.endpoint = `drivers/active-by-city/${cityId}`;
    const response = await this.get(branchOfficeId ? `?branch_office_id=${branchOfficeId}` : '');
    return response;
  }

  static async assignOrder(driverId, orderId) {
    this.endpoint = `drivers/${driverId}/link-to-order`;
    const response = await this.get(orderId);
    return response;
  }

  static async toggleIsDevelop(id) {
    this.endpoint = 'drivers/toggle/is-develop';
    const response = await this.get(id);
    return response;
  }
}
