import React, { useEffect, useState } from 'react';
import mapStyles from 'src/utils/mapStyles';
import { makeStyles } from '@material-ui/core';
import GoogleMapWithToggle from 'src/components/GoogleMapWithToggle';
import MarketShop from 'src/assets/images/marker_shop.png';
import EnvironmentService from 'src/services/EnvironmentService';
import BranchOfficeService from 'src/services/BranchOfficeService';
import CenterCityMaps from 'src/utils/CenterCityMaps';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 64px)'
  }
}));

const monitorMapStyles = null;

const MapSucursalView = () => {
  const classes = useStyles();
  const [center, setCenter] = useState({
    lat: 4.5319550262015955,
    lng: -76.10316395139068
  });
  const [markers, setMarkers] = useState([]);
  const [googleMapStyles, setGoogleMapStyles] = useState(monitorMapStyles);

  const toggleInfoWindow = (id, _markers) => {
    if (_markers && Array.isArray(_markers) && _markers?.length > 0) {
      setMarkers(
        _markers?.reduce((array, marker) => {
          if (marker?.uuid === id) {
            return array.concat({
              ...marker,
              infoWindow: !marker?.infoWindow
            });
          }

          return array.concat({
            ...marker,
            infoWindow: false
          });
        }, [])
      );
    }
  };

  const fetchData = async () => {
    try {
      const response = await BranchOfficeService.getAll();

      const cityCenter = await CenterCityMaps();
      setCenter(cityCenter);

      if (response?.code === 200) {
        setMarkers(
          response?.data?.map((item) => {
            const uuid = item?.id;
            const position = { lat: item?.latitude, lng: item?.longitude };
            const icon = MarketShop;
            const radius = item?.coverage_radio;

            return {
              ...item,
              uuid,
              icon,
              position,
              radius,
              toggle: toggleInfoWindow,
              infoWindow: false
            };
          }) || []
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const getMonitorGoogleMapStyles = async () => {
    const response = await EnvironmentService.getMonitorGoogleMapStyles();

    if (response?.code === 200) {
      setGoogleMapStyles(JSON.parse(response?.data?.value));
    }
  };

  useEffect(() => {
    getMonitorGoogleMapStyles();
    fetchData();
  }, []);

  return (
    <div className={classes?.root}>
      {googleMapStyles !== null && (
        <GoogleMapWithToggle
          center={center}
          markers={markers}
          centerMarker={false}
          styles={googleMapStyles}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp`}
          loadingElement={<div style={mapStyles} />}
          containerElement={<div style={mapStyles} />}
          mapElement={<div style={mapStyles} />}
        />
      )}
    </div>
  );
};

export default MapSucursalView;
