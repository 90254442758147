import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useQueryParams from 'src/hooks/useQueryParams';
import LoadingBox from 'src/components/LoadingBox';
import AdminCardPreview from './AdminCardPreview';
import CreateAdminForm from './CreateAdminForm';
import AdminService from '../../../services/AdminService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateAdminView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    role: '',
    password: '',
    status: ''
  });
  const queryParams = useQueryParams();
  const typeForm = queryParams.get('type');

  const inputRef = useRef();

  const handleAdminAttributes = (values) => {
    setAdmin({
      ...admin,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await AdminService.getById(id);
        if (response?.code === 200) {
          setAdmin({
            ...response.data,
            id: response.data.id,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            avatar: response.data.avatar,
            email: response.data.email,
            password: response.data.password,
            phone: response.data.phone,
            role: response.data.role
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page
        className={classes.root}
        title="Crear administrador"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            {
            typeForm !== 'password'
          && (
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <AdminCardPreview
                ref={inputRef}
                admin={admin}
                hasImage={hasImage}
                onUpload={handleUploadImage}
              />
            </Grid>
          )
          }
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <CreateAdminForm
                ref={inputRef}
                edit={edit}
                hasImage={hasImage}
                admin={admin}
                onChange={handleAdminAttributes}
                setLoading={setLoading}
                typeForm={typeForm}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateAdminView.propTypes = {
  edit: PropTypes.bool,
};

CreateAdminView.defaultProps = {
  edit: false
};

export default CreateAdminView;
