import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useErrors from '../hooks/useErrors';

const Errors = ({ className, time }) => {
  const { errors, clearErrors } = useErrors(time);

  useEffect(() => {
    if (errors.length > 0 && time > 0) {
      setTimeout(() => {
        clearErrors();
      }, time);
    }
  }, [errors]);

  useEffect(() => {
    return () => clearErrors();
  }, []);

  return (
    errors.length > 0 && (
      <Box p={2} className={className}>
        <Alert full onClose={clearErrors} severity="error">
          {
            errors.map((error) => (
              <Typography color="error">
                {`- ${error}`}
              </Typography>
            ))
          }
        </Alert>
      </Box>
    )
  );
};

Errors.propTypes = {
  time: PropTypes.number,
  className: PropTypes.string,
};

Errors.defaultProps = {
  time: 6000,
  className: ''
};

export default Errors;
