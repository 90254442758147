import HttpRequest from './HttpRequest';

export default class TransferService extends HttpRequest {
  static endpoint = 'transfer';

  static async getAll(driver) {
    this.setMicroservice('adomi-wallet');
    this.endpoint = `transfer?page=1&perPage=100&userId=${driver}&userType=DRIVER&conceptId=362ed203-2606-422f-a0c9-38bbe5129630`;
    const response = await this.get();
    return response;
  }

  static async create(data) {
    this.setMicroservice('adomi-wallet');
    this.endpoint = 'transfer';
    const response = await this.post(data);
    return response;
  }
}
