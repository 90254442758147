import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Chip,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles, TablePagination
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';

import DriverLeadService from 'src/services/DriverLeadService';
// import DriverService from 'src/services/DriverService';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  pending: {
    backgroundColor: '#FBC02D',
    color: 'white',
  },
  rejected: {
    backgroundColor: 'red',
    color: 'white'
  },
  accepted: {
    backgroundColor: '#43a047',
    color: 'white',
  },
  in_process: {
    backgroundColor: '#eee'
  }
}));

const Results = ({
  className, data, onRefresh, ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [leads, setLeads] = useState([]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onClick = (e, lead) => {
    setPanel(panel ? null : lead?.id);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  const updateStatus = async (status, d = null) => {
    if (!panel) return;

    console.log(d);

    // if (status === 'accepted') {
    //   const drv = await DriverService.register({
    //     first_name: d?.first_name,
    //     last_name: d?.last_name,
    //     phone: d?.phone,
    //     country_id: 'be8cc509-a489-4ec9-99d7-76b7f48258dd'
    //   });

    //   console.log(drv);
    // }

    const response = await DriverLeadService.update(panel, { status });

    if (response?.code === 200 || response?.code === 201) {
      onRefresh();
    }
  };

  const removeLead = async (lead) => {
    if (['in_process']?.includes(lead?.status)) {
      // eslint-disable-next-line
      alert('No es posible eliminar un registro que se encuentre en proceso')
      return;
    }

    const response = await DriverLeadService.remove(lead?.id);

    if (!response?.code) {
      onRefresh();
    }
  };

  const translate = (status) => {
    switch (status) {
      case 'IN_PROCESS':
        return 'En proceso';
      case 'ACCEPTED':
        return 'Aceptado';
      case 'REJECTED':
        return 'Rechazado';
      default:
        return 'Pendiente';
    }
  };

  const statusLead = (status) => {
    switch (status) {
      case 'IN_PROCESS':
        return 'in_process';
      case 'ACCEPTED':
        return 'accepted';
      case 'REJECTED':
        return 'rejected';
      default:
        return 'pending';
    }
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setLeads(data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1150}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="150">
                    Nombre
                  </TableCell>
                  <TableCell width="200">
                    Correo
                  </TableCell>
                  <TableCell width="150">
                    Teléfono
                  </TableCell>
                  <TableCell width="250">
                    Dirección
                  </TableCell>
                  <TableCell width="200">
                    Ciudad
                  </TableCell>
                  <TableCell width="50">
                    Tipo de vehiculo
                  </TableCell>
                  <TableCell width="50">
                    Estado
                  </TableCell>
                  <TableCell width="5" />
                  <TableCell width="5" />
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.slice(0, limit).map((lead) => (
                  <TableRow
                    hover
                    key={lead?.id}
                  >
                    <TableCell width="150">
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {lead?.name}
                      </Typography>
                    </TableCell>
                    <TableCell width="200">
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {lead?.email}
                      </Typography>
                    </TableCell>
                    <TableCell width="150">
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {lead?.phone}
                      </Typography>
                    </TableCell>
                    <TableCell width="200">
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {lead?.address || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell width="200">
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {lead?.city2?.name}
                      </Typography>
                    </TableCell>
                    <TableCell width="70">
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {lead?.vehicle_type}
                      </Typography>
                    </TableCell>
                    <TableCell width="70">
                      <Chip
                        label={translate(lead?.status?.toString()?.toUpperCase())}
                        className={classes?.[statusLead(lead?.status?.toString()?.toUpperCase())]}
                      />
                    </TableCell>
                    <TableCell className={classes?.statusCell} width="5">
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, lead)}
                      />
                    </TableCell>
                    <TableCell className={classes?.statusCell} width="5">
                      <DeleteIcon
                        className={classes?.moreVert}
                        onClick={() => removeLead(lead)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={leads.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {
      panel !== null && (
        <div
          className={classes?.statusPanel}
          style={{
            top: panelPosition?.y,
            left: panelPosition?.x - 120
          }}
        >
          <ul className={classes?.statusPanelList}>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => updateStatus('pending')}>
                Pendiente
              </a>
            </li>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => updateStatus('in_process')}>
                En proceso
              </a>
            </li>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => updateStatus('rejected')}>
                Rechazado
              </a>
            </li>
            <li className={classes?.statusPanelItem}>
              <a onClick={() => updateStatus('accepted')}>
                Aceptado
              </a>
            </li>
          </ul>
        </div>
      )
    }
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  onRefresh: PropTypes.func,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  className: '',
  onRefresh: () => null,
};

export default Results;
