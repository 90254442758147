import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import TutorialCard from './TutorialCard';
import TutorialsService from '../../../services/TutorialsService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tutorialCard: {
    height: '100%'
  }
}));

const TutorialList = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [tutoriales, setTutoriales] = useState([]);

  useEffect(() => {
    const foo = async () => {
      const fetchData = await TutorialsService.getAll();
      console.log(fetchData);
      if (fetchData?.code === 200) {
        if (id) {
          setTutoriales([fetchData.data]);
        } else {
          setTutoriales(fetchData.data);
        }
      }
    };

    foo();
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Tutoriales"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            {tutoriales.map((tutorial) => (
              <Grid
                item
                key={tutorial.id}
                lg={4}
                md={6}
                xs={12}
              >
                <TutorialCard
                  className={classes.tutorialCard}
                  tutorial={tutorial}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            color="primary"
            count={3}
            size="small"
          />
        </Box>
      </Container>
    </Page>
  );
};

export default TutorialList;
