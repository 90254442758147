import React, { useState, useEffect } from 'react';
import mapStyles from 'src/utils/mapStyles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useConfirmation from 'src/hooks/useConfirmation';
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  Button,
  Modal,
  makeStyles
} from '@material-ui/core';
import {
  LockOpenOutlined,
  LockOutlined
} from '@material-ui/icons';
import GoogleMap from 'src/components/GoogleMap';
import ConfirmationModal from 'src/components/ConfirmationModal';

const GoogleMapsPolygonModal = ({
  position, isOpen, onAccept, polygon, savePolygon, // onLocate
}) => {
  const { toggleModal } = useConfirmation();
  const [center, setCenter] = useState(null);
  const [poly, setPoly] = useState([]);
  const [lock, setLock] = useState(false);

  const useStyles = makeStyles((theme) => ({
    modalContent: {
      minWidth: '100%',
      width: '100%',
    },
    buttons: {
      display: 'inline-block',
    },
    ml: {
      marginLeft: '5px'
    },
    box: {
      position: 'relative',
      display: 'flex',
      width: '100%'
    },
    lockButton: {
      position: 'fixed',
      zIndex: 1,
      borderRadius: '50px',
      fontSize: '13px'
    },
    icon: {
      cursor: 'pointer',
      display: 'flex',
      backgroundColor: lock ? '#2e7d32' : theme.palette.primary.main,
      padding: '5px',
      right: '47%',
      borderRadius: '50%',
      margin: '10px 8px'
    },
    btn: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#2e7d32'
      },
      transition: 'background 0.2s ease 0s'
    },
  }));

  const classes = useStyles();

  const confirm = () => {
    savePolygon(center, poly);
    onAccept();
  };

  const toggleLock = () => {
    setLock(!lock);
  };

  const handleMarkerPosition = (latitude, longitude) => {
    setCenter({ lat: latitude, lng: longitude });
  };

  const handlePolygon = (points) => {
    setPoly(points);
  };

  const onAcceptConfirmation = () => {
    setPoly([]);
  };

  useEffect(() => {
    setCenter(position);
    setPoly(polygon);
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onAccept}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Dialog
          open={isOpen}
          onClose={onAccept}
          aria-labelledby="simple-dialog-title"
        >
          <Box display="flex" justifyContent="space-between">
            <DialogTitle id="simple-dialog-title">Area de cobertura</DialogTitle>
            <Box pr={2} display="flex" alignItems="center" justifyContent="center">
              <Button
                disabled={Object.keys(poly).length === 0}
                size="small"
                color="primary"
                variant="contained"
                onClick={() => toggleModal()}
              >
                Eliminar poligono
              </Button>
            </Box>
          </Box>
          <Box p={2} pt={0} className={classes?.modalContent}>
            <Grid container>
              <Grid className={classes.box}>
                {poly.length > 0 && (
                  <Box
                    onClick={() => toggleLock()}
                    className={clsx(classes.icon, classes.btn, classes.lockButton)}
                  >
                    {lock && (
                      <LockOutlined style={{ color: '#fff', padding: '1px' }} />
                    )}
                    {!lock && (
                      <LockOpenOutlined style={{ color: '#fff', padding: '1px' }} />
                    )}
                  </Box>
                )}
                <GoogleMap
                  draggable
                  lockDrag={lock}
                  center={center}
                  setPosition={handleMarkerPosition}
                  drawPolygon="true"
                  polygon={poly}
                  setPolygon={handlePolygon}
                  isBranchOffice
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={mapStyles} />}
                  containerElement={<div style={{ width: '568px', height: '400px' }} />}
                  mapElement={<div style={mapStyles} />}
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Box mt={1} className={classes.buttons}>
                  {
                    /*
                      <Button onClick={onLocate}>
                        Mi Ubicacion
                      </Button>
                    */
                  }
                  <Button onClick={onAccept}>
                    Cancelar
                  </Button>
                  <Button
                    className={classes.ml}
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={confirm}
                  >
                    Guardar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </Modal>
      <ConfirmationModal onAccept={onAcceptConfirmation} />
    </>
  );
};

GoogleMapsPolygonModal.propTypes = {
  isOpen: PropTypes.bool,
  onAccept: PropTypes.func,
  // onLocate: PropTypes.func,
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  polygon: PropTypes.array,
  savePolygon: PropTypes.func
};

export default GoogleMapsPolygonModal;
