import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import orderBy from 'src/utils/arrays';
// utils

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      color: 'inherit'
    }
  },
  moreVert: {
    cursor: 'pointer',
    color: 'inherit'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  }
}));

const Results = ({
  className, data, onRefresh, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [branchOffices, setBranchOffices] = useState([]);
  const [adminToken, setAdminToken] = useState('');

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleMenu = (e) => setBranchOffices(!branchOffices ? e.currentTarget : null);

  const redirect = () => {
    navigate(`/app/aliados/${panel}/editar`, { replace: true });
    toggleMenu();
  };

  const editPassword = () => {
    navigate(`/app/aliados/${panel}/editar?type=password`, { replace: true });
    toggleMenu();
  };

  const onClick = (e, coupon) => {
    setPanel(panel ? null : coupon?.id);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setBranchOffices(data);
      const month = dayjs().get('month');
      const date = dayjs().get('date');
      const hour = dayjs().get('hour');
      const t = window.btoa(`${process.env.REACT_APP_SECRET_DASHBOARD_PASSWORD}_${month}/${date}:${hour}`);

      setAdminToken(t);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1200}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nombre
                  </TableCell>
                  <TableCell>
                    Marca
                  </TableCell>
                  <TableCell>
                    Telefono
                  </TableCell>
                  <TableCell width="350">
                    Correo
                  </TableCell>
                  <TableCell width="210">
                    NIT
                  </TableCell>
                  <TableCell width="400">
                    Cuenta Bancaria
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) && orderBy(data, 'name').slice(page * limit, (limit * (page + 1))).map((item) => (
                  <TableRow
                    hover
                    key={item?.id}
                  >
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={item?.avatar}
                        />
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          {item?.first_name}
                          {' '}
                          {item?.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {item?.brand?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {item?.phone}
                      </Typography>
                    </TableCell>
                    <TableCell width="350">
                      <Typography
                        style={{ wordBreak: 'break-word' }}
                        color="textPrimary"
                        variant="body2"
                      >
                        {item?.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {item?.nit || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell width="400">
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {<b>Banco:</b>}
                        {item?.bank_account_details?.bank_name || ''}
                        <br />
                        {<b>Tipo de cuenta:</b>}
                        {item?.bank_account_details?.account_type || ''}
                        <br />
                        {<b>Número de cuenta:</b>}
                        {item?.bank_account_details?.account_number || ''}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={`${process.env.REACT_APP_DASHBOARD_ALIADOS}/login/admin/${adminToken}?email=${item?.email}`}
                      >
                        <ExternalLinkIcon className={classes?.moreVert} />
                      </a>
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, item)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={branchOffices.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {
        panel !== null && (
          <div
            className={classes?.statusPanel}
            style={{
              top: panelPosition?.y,
              left: panelPosition?.x - 120
            }}
          >
            <ul className={classes?.statusPanelList}>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => redirect('edit')}>
                  Editar
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => editPassword('edit')}>
                  Editar contraseña
                </a>
              </li>
            </ul>
          </div>
        )
      }
    </>
  );
};

Results.propTypes = {
  className: PropTypes.func,
  onRefresh: PropTypes.func,
  data: PropTypes.array.isRequired
};

Results.propTypes = {
  className: () => { },
  onRefresh: () => null,
};

export default Results;
