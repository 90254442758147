import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles, Grid, Chip
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
// utils

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  active: {
    backgroundColor: '#43a047',
    color: 'white'
  },
  inactive: {
    backgroundColor: '#f44336',
    color: 'white'
  }
}));

const Results = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [admins, setAdmins] = useState([]);

  const translateRole = (role) => {
    switch (role) {
      case 'ADMINISTRATOR':
        return 'Administrador';
      case 'SELLER':
        return 'Vendedor';
      default: return '';
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case 'active':
        return 'Activo';
      case 'inactive':
        return 'Inactivo';
      default:
        return 'Activo';
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleMenu = (e) => setAdmins(!admins ? e.currentTarget : null);

  const redirect = () => {
    navigate(`/app/administradores/${panel}/editar`, { replace: true });
    toggleMenu();
  };

  const redirectEditPassword = () => {
    navigate(`/app/administradores/${panel}/editar?type=password`, { replace: true });
    toggleMenu();
  };

  const redirectToBranchOfficesReport = (id) => {
    navigate(`/app/sucursales?asesor=${id}`, { replace: true });
  };

  const onClick = (e, driver) => {
    setPanel(panel ? null : driver?.id);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setAdmins(data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1000}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    Nombre
                  </TableCell>
                  <TableCell>
                    Teléfono
                  </TableCell>
                  <TableCell>
                    Correo
                  </TableCell>
                  <TableCell>
                    Rol
                  </TableCell>
                  <TableCell>
                    País
                  </TableCell>
                  <TableCell>
                    Reporte de sucursales
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.slice(page * limit, (limit * (page + 1))).map((admin) => (
                  <TableRow
                    hover
                    key={admin?.id}
                  >
                    <TableCell width="60">
                      <Chip
                        label={translateStatus(admin?.status)}
                        className={classes?.[admin?.status]}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={admin?.avatar}
                        />
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          {admin?.first_name}
                          {' '}
                          {admin?.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {admin?.phone || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {admin?.email || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {translateRole(admin?.role)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {admin?.country?.name || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <Grid item container justify="center" alignItems="center">
                        <ExternalLinkIcon
                          className={classes?.moreVert}
                          onClick={() => redirectToBranchOfficesReport(admin?.id)}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, admin)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={admins.length}
          onChangePage={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {
        panel !== null && (
          <div
            className={classes?.statusPanel}
            style={{
              top: panelPosition?.y,
              left: panelPosition?.x - 120
            }}
          >
            <ul className={classes?.statusPanelList}>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => redirect('edit')}>
                  Editar
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => redirectEditPassword('edit')}>
                  Editar contraseña
                </a>
              </li>
            </ul>
          </div>
        )
      }
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired
};

export default Results;
