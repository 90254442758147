import HttpRequest from './HttpRequest';

export default class AddressService extends HttpRequest {
  static endpoint = 'addresses';

  static async getById(id) {
    this.endpoint = 'addresses';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'addresses';
    const response = await this.post(data);
    return response;
  }

  static async remove(id, clientId) {
    this.endpoint = 'addresses';
    const response = await this.delete(`${id}?clientId=${clientId}`);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'addresses';
    const response = await this.put(id, data);
    return response;
  }
}
