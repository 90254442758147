import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useSearch from 'src/hooks/useSearch';
import PhoneNumberModal from 'src/components/PhoneNumberModal';
import ConfirmationModal from 'src/components/ConfirmationModal';
import OrderService from 'src/services/OrderService';
import ClientService from '../../../services/ClientService';
import Results from './Results';
import Toolbar from './Toolbar';
import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ClientView = () => {
  const classes = useStyles();
  const { user } = useSelector(UserSelector);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState('');
  const [option, setOption] = useState(0);
  const [filter, setFilter] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { dataFilterd, handleSearch, handleInitialData } = useSearch({ keys: ['phone', 'first_name', 'last_name'] });

  const toggleModalPhone = () => {
    setShowModal(!showModal);
  };

  const handleClientId = (id, op) => {
    setClientId(id);
    setOption(op);
  };

  const getClients = async () => {
    const response = await ClientService.getAll();

    if (response?.code === 200 && response?.data?.length > 0) {
      setClients(response?.data);
      handleInitialData(response?.data);
    }
  };

  const handleFilter = (value) => setFilter(value);

  const filterData = (cs) => {
    if (!filter) return cs;

    return cs?.filter((c) => c?.is_develop.toString() === filter);
  };

  const refresh = async () => {
    if (user?.id) {
      await getClients();
    }
  };

  const deleteClient = async () => {
    try {
      if (option === 1) {
        await OrderService.deleteByClient(clientId);
      } else {
        await ClientService.remove(clientId);
        refresh();
      }
    } catch (e) {
      console.log(e.message);
      return;
    }
    toggleModalPhone();
  };

  const onAccept = async () => {
    deleteClient();
  };

  useEffect(() => {
    if (user?.id) {
      getClients();
    }
  }, [user]);

  return (
    <Page
      className={classes.root}
      title="Clientes"
    >
      <Container maxWidth={false}>
        {clients?.length > 0
        && (
          <Toolbar
            toggleModal={toggleModalPhone}
            onChange={handleFilter}
            onSearch={handleSearch}
          />
        )}
        <Box mt={3}>
          <Results data={filterData(dataFilterd)} onRefresh={refresh} />
        </Box>
      </Container>
      { showModal && (
        <PhoneNumberModal
          toggleModalPhone={toggleModalPhone}
          handleClientId={handleClientId}
        />
      )}
      <ConfirmationModal onAccept={onAccept} />
    </Page>
  );
};

export default ClientView;
