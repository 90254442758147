import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import AdminService from '../../../services/AdminService';
import CountryService from '../../../services/CountryService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateAdminForm = forwardRef(({
  className, admin, edit, onChange, hasImage, setLoading, typeForm, ...rest
}, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const [countrySelected, setCountrySelected] = useState('');
  const [countries, setCountries] = useState([]);
  

  const handleChange = (event) => {
    onChange({
      [event?.target?.name]: event?.target?.value
    });
  };

  const handleCountry = (event) => {
    setCountrySelected(event.target.value);
    handleChange(event);
  };

  const translateRole = (role) => {
    switch (role) {
      case 'ADMINISTRATOR':
        return 'Administrador';
      case 'SELLER':
        return 'Vendedor';
      default: return '';
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case 'active':
        return 'Activo';
      case 'inactive':
        return 'Inactivo';
      default:
        return 'Activo';
    }
  };

  const getValuesForm = (type, e) => {
    if (type === 'password') {
      return {
        first_name: admin?.first_name,
        last_name: admin?.last_name,
        avatar: admin?.avatar || '',
        email: admin?.email,
        phone: Number(admin?.phone),
        role: admin?.role,
        password: e.target?.password?.value,
        country_id: admin?.country_id
      };
    }
    return {
      first_name: e.target?.first_name?.value,
      last_name: e.target?.last_name?.value,
      avatar: e.target?.avatar?.value,
      email: e.target?.email.value,
      phone: Number(e.target?.phone?.value),
      role: e.target?.role?.value,
      password: e.target?.password?.value,
      country_id: e.target?.country_id?.value,
      status: e.target?.status?.value
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const file = ref.current && ref.current.files[0];
    const data = getValuesForm(typeForm, e);

    if (typeForm !== 'password') {
      if (data.first_name.length === 0) {
        errors.push('El nombre del administrador es obligatorio');
      }
      if (data.last_name.length === 0) {
        errors.push('Los apellidos del administrador son obligatorios');
      }
      if (data.country_id.length === 0) {
        errors.push('El país es obligatorio');
      }
      if (data.phone.length === 0) {
        errors.push('El teléfono del administrador es obligatorio');
      }
      if (data.email.length === 0) {
        errors.push('El email del administrador es obligatorio');
      }
      if (data.role.length === 0) {
        errors.push('El rol del administrador es obligatorio');
      }
      if (data.status.length === 0) {
        errors.push('El estado del administrador es obligatorio');
      }
    }

    if (data.password) {
      if (data.password.length < 8) {
        errors.push('La contraseña debe tener 8 caracteres como mínimo');
      }
    }
    if (typeForm === 'password' && data.password.length === 0) {
      errors.push('La contraseña es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    // setLoading(true);

    const response = edit
      ? await AdminService.update(admin?.id, data)
      : await AdminService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (response?.data?.avatar !== null && file !== undefined) {
        await AdminService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await AdminService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/administradores');
          }, 500);
        }
      }
      if (response?.data?.avatar === null && file !== undefined) {
        const formData = new FormData();

        // The third parameter is required for server
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await AdminService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/administradores');
          }, 500);
        }
      }
      navigate('/app/administradores');
    } else {
      setErrors(response.errors);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await CountryService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar los países']);
        return;
      }

      setCountries(response.data);
    })();
  }, []);

  useEffect(() => {
    setCountrySelected(admin.country_id);
  }, [admin.country_id]);

  return (
    <>
      {
        typeForm === 'password'
          ? (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader
                  subheader="La información puede ser editada"
                  title="Administrador"
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba la contraseña"
                        label="Contraseña"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={admin?.password}
                        variant="outlined"
                        min={8}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Errors time={0} />
                <Divider />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {edit ? 'Editar' : 'Guardar'}
                  </Button>
                </Box>
              </Card>
            </form>
          )
          : (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader
                  subheader="La información puede ser editada"
                  title="Administrador"
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba el nombre"
                        label="Nombre"
                        name="first_name"
                        onChange={handleChange}
                        required
                        value={admin?.first_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba los apellidos"
                        label="Apellidos"
                        name="last_name"
                        onChange={handleChange}
                        required
                        value={admin?.last_name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl required variant="outlined" className={classes.formControl}>
                        <InputLabel id="pais">Seleccione un país</InputLabel>
                        <Select
                          required
                          name="country_id"
                          labelId="pais"
                          label="Selecciona un país"
                          value={countrySelected}
                          onChange={handleCountry}
                        >
                          <MenuItem value="0" />
                          {
                            countries.map((country) => (
                              <MenuItem key={country.id} value={country.id}>
                                { country.name }
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba el teléfono"
                        label="Teléfono"
                        name="phone"
                        onChange={handleChange}
                        required
                        value={admin?.phone}
                        variant="outlined"
                        type="tel"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl required variant="outlined" className={classes.formControl}>
                        <InputLabel id="role">Rol</InputLabel>
                        <Select
                          name="role"
                          labelId="role"
                          label="Rol"
                          value={admin?.role}
                          onChange={handleChange}
                        >
                          {
                            ['ADMINISTRATOR', 'SELLER'].map((item) => (
                              <MenuItem key={item} value={item}>
                                { translateRole(item)}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <FormControl required variant="outlined" className={classes.formControl}>
                        <InputLabel id="role">Estado</InputLabel>
                        <Select
                          name="status"
                          labelId="status"
                          label="Estado"
                          value={admin?.status}
                          onChange={handleChange}
                        >
                          {
                            ['active', 'inactive'].map((item) => (
                              <MenuItem key={item} value={item}>
                                { translateStatus(item)}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Escriba el correo electrónico"
                        label="Correo electrónico"
                        name="email"
                        onChange={handleChange}
                        required
                        value={admin?.email}
                        variant="outlined"
                        type="email"
                      />
                    </Grid>
                    {
                      !edit && (
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="Escriba la contraseña"
                            label="Contraseña"
                            name="password"
                            onChange={handleChange}
                            type="password"
                            value={admin?.password}
                            variant="outlined"
                            min={8}
                          />
                        </Grid>
                      )
                    }
                    {
                      hasImage && (
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            fullWidth
                            label="Perfil"
                            name="avatar"
                            onChange={handleChange}
                            required
                            value={admin?.avatar}
                            variant="outlined"
                          />
                        </Grid>
                      )
                    }
                    <input name="perfil[]" type="file" ref={ref} style={{ display: 'none' }} />
                  </Grid>
                </CardContent>
                <Errors time={0} />
                <Divider />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    {edit ? 'Editar' : 'Guardar'}
                  </Button>
                </Box>
              </Card>
            </form>
          )
      }
    </>
  );
});

CreateAdminForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  typeForm: PropTypes.string,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  admin: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.number,
    role: PropTypes.string,
    password: PropTypes.string,
    country_id: PropTypes.string,
    status: PropTypes.string
  })
};

CreateAdminForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  typeForm: '',
  setLoading: () => { },
  onChange: () => { },
  admin: {
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    role: '',
    password: '',
    country_id: '',
    status: ''
  },
};

export default CreateAdminForm;
