import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  Switch,
  Tooltip,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles, Button
} from '@material-ui/core';
import {
  CheckCircle as ActiveIcon,
  HighlightOff as InactiveIcon,
  MoreVert as MoreVertIcon
} from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Swal from '@sweetalert/with-react';
import Swal2 from 'sweetalert2';
import DriverModal from 'src/components/DriverModal';
import DriverService from '../../../services/DriverService';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreVert: {
    cursor: 'pointer'
  },
  active: {
    color: 'green'
  },
  inactive: {
    color: 'red'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
    }
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    '&:hover': {
      backgroundColor: '#eee'
    },
    cursor: 'pointer'
  }
}));

const Results = ({
  className, data, onRefresh, ...rest
}) => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState('');
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [drivers, setDrivers] = useState([]);
  const [modal, setModal] = useState(false);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleIsDevelopDriver = async (driver) => {
    const response = await DriverService.toggleIsDevelop(driver?.id);
    setTooltipOpen('');
    setTooltipTitle('');

    if (response?.code === 200) {
      const newDrivers = drivers.reduce((result, item) => {
        if (item?.id === driver?.id) {
          return result.concat({ ...item, is_develop: !item?.is_develop });
        }
        return result.concat(item);
      }, []);

      setDrivers(newDrivers);
    }

    if (response?.code === 409) {
      setTooltipTitle(response?.errors[0]);
      setTooltipOpen(driver?.id);

      setTimeout(() => {
        setTooltipOpen('');
        setTooltipTitle('');
      }, 5000);
    }

    onRefresh();
  };
  // const toggleMenu = (e) => setDrivers(!drivers ? e.currentTarget : null);
  const alertToActivate = async (id, firstName, lastName) => {
    await DriverService.alertToActivate(id);
    await Swal({
      content: (
        <div>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            MENSAJE ENVIADO
          </Typography>
          <p>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {`La alerta fue enviada al WhatsApp del repartidor ${firstName} ${lastName}`}
            </Typography>
          </p>
        </div>
      ),
      icon: 'success',
      button: 'Aceptar'
    });
  };

  const changeIfEnabled = async (id, isEnabled) => {
    const response = await DriverService.changeIfEnabled(id);
    if (response?.code === 201 || response?.code === 200) {
      const action = isEnabled ? 'inhabilitado' : 'habilitado';
      await Swal2.fire({
        text: `El repartidor fue ${action} exitosamente`,
        icon: 'success',
      });
      onRefresh();
    }
  };

  const enabledOrDisabledDriverAlert = async (id, isEnabled, firstName, lastName) => {
    const title = isEnabled ? 'Inhabilitar repartidor temporalmente' : 'Habilitar repartidor';
    const text = `¿Está seguro que desea ${isEnabled ? 'inhabilitar' : 'habilitar'} al repartidor ${firstName} ${lastName}?`;
    await Swal2.fire({
      title,
      text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#05B40DBF',
      cancelButtonColor: '#b7b4b4',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar'
    }).then(async (answer) => {
      if (answer.isConfirmed) {
        await changeIfEnabled(id, isEnabled);
      }
    });
  };

  const onClick = (e, driver) => {
    setPanel(panel ? null : driver);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setDrivers(data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nombre
                  </TableCell>
                  <TableCell>
                    Teléfono
                  </TableCell>
                  <TableCell>
                    Ciudad
                  </TableCell>
                  <TableCell>
                    Adomi
                  </TableCell>
                  <TableCell>
                    Activo
                  </TableCell>
                  <TableCell>
                    Desarrollo
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {drivers.slice(page * limit, (limit * (page + 1))).map((driver) => (
                  <TableRow
                    hover
                    key={driver?.id}
                  >
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={driver?.avatar}
                        />
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          {driver?.first_name}
                          <br />
                          {driver?.last_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {driver?.phone || 'Sin registrar'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {driver?.city?.name || 'Ninguna'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      { driver?.is_adomi ? 'Si' : 'No' }
                    </TableCell>
                    <TableCell>
                      {
                        driver?.is_free
                          ? (
                            <ActiveIcon className={classes.active} />
                          ) : (
                            <InactiveIcon className={classes.inactive} />
                          )
                      }
                    </TableCell>
                    <TableCell>
                      <Tooltip title={tooltipTitle} open={tooltipOpen === driver?.id}>
                        <Switch
                          color="primary"
                          checked={driver.is_develop}
                          onChange={() => driver && toggleIsDevelopDriver(driver)}
                          name="driver_is_develop"
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={driver?.is_free}
                        size="small"
                        color="default"
                        variant="contained"
                        onClick={
                          () => alertToActivate(driver?.id, driver?.first_name, driver?.last_name)
                        }
                      >
                        Alertar
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        color="default"
                        variant="contained"
                        onClick={
                          () => enabledOrDisabledDriverAlert(driver?.id, driver?.is_enabled,
                            driver?.first_name, driver?.last_name)
                        }
                      >
                        {driver.is_enabled ? 'Inhabilitar' : 'Habilitar'}
                      </Button>
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, driver)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={drivers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {
        panel !== null && (
          <div
            className={classes?.statusPanel}
            style={{
              top: panelPosition?.y,
              left: panelPosition?.x - 120
            }}
          >
            <ul className={classes?.statusPanelList}>
              <li className={classes?.statusPanelItem}>
                <Link className={classes?.link} to={`/app/repartidores/${panel?.id}/editar`}>
                  <Typography
                    variant="body2"
                  >
                    Editar
                  </Typography>
                </Link>
                <Link className={classes?.link} to={`/app/sucursales?driver=${panel?.id}`}>
                  <Typography
                    variant="body2"
                  >
                    Ver sucursales
                  </Typography>
                </Link>
                <Link className={classes?.link} to={`/app/repartidores/${panel?.id}/editar?type=password`}>
                  <Typography
                    variant="body2"
                  >
                    Editar contraseña
                  </Typography>
                </Link>
                <Box
                  className={classes?.link}
                  p={2}
                  onClick={() => toggleModal()}
                >
                  <Typography
                    variant="body2"
                  >
                    Pagar deuda
                  </Typography>
                </Box>
              </li>
            </ul>
          </div>
        )
      }
      {modal && (<DriverModal toggleModal={toggleModal} driver={panel} />)}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  onRefresh: PropTypes.func
};

export default Results;
