import React, { useState, useEffect } from 'react';
import {
  CardContent,
  Card,
  CardHeader,
  Box,
  Container,
  makeStyles,
  Grid,
  FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import Results from './Results';
import ZonesService from '../../../services/ZonesService';
import useErrors from '../../../hooks/useErrors';
import CityService from '../../../services/CityService';
import { selector as UserSelector } from '../../../redux/ducks/user';
import { selector as AppSelector } from '../../../redux/ducks/app';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    width: '100%',
  },
}));

const ZonaReportView = () => {
  const classes = useStyles();
  const { setErrors } = useErrors();
  const [zonasData, setZonasData] = useState([]);
  const { user } = useSelector(UserSelector);
  const [cities, setCity] = useState([]);
  const [citySelected, setCitySelected] = useState('');
  const { globalCitySelected: citySelectedApp } = useSelector(AppSelector);
  const [zonaName, setZonaName] = useState(null);
  const [zonaSelected, setZonaSelected] = useState('');
  const [comidas, setComidas] = useState({});
  const [activeView, setActiveView] = useState(false);

  const [total, setTotal] = useState(0);

  const getZones = async (id) => {
    const fetchData = await ZonesService.getReport(id);

    if (fetchData?.code === 200) {
      setZonasData(fetchData?.data);
      setActiveView(false);
    } else if (fetchData?.code === 404) {
      setZonaName(null);
      setActiveView(false);
    }
  };

  const handleCity = (event) => {
    const cityId = event.target.value;
    setCitySelected(cityId);
    setTotal(0);
  };
  const handleZone = (event) => {
    const zoneId = event.target.value;
    setZonaSelected(zoneId);
    const zonaItemSelected = zonasData[zoneId];
    setComidas(zonaItemSelected?.Comida);
    setActiveView(true);
  };

  const refresh = async () => {
    if (user?.id) {
      await getZones();
    }
  };

  useEffect(() => {
    getZones();

    (async () => {
      const response = await CityService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
        return;
      }

      setCity(response.data);
    })();
  }, []);

  useEffect(() => {
    getZones(citySelected);
  }, [citySelected]);

  useEffect(() => {
    const zonasResult = Object.keys(zonasData);
    // for (let i = 0; i < zonasResult.length; i++) {
    //   const clave = zonasResult[i];
    //   console.log(zonasData[clave]);
    // }
    setZonaName(zonasResult);
  }, [zonasData]);

  useEffect(() => {
    const initialValue = 0;
    const sumaTotal = Object.values(comidas)?.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      initialValue
    );
    setTotal(sumaTotal);
  }, [comidas]);

  return (
    <Page
      className={classes.root}
      title="Zonas"
    >

      <Container maxWidth={false}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                md={3}
                xs={12}
              >
                <FormControl
                  // error={localErrors.some((e) => e.field === 'city_id')}
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="adomiCategory">Seleccione una ciudad</InputLabel>
                  <Select
                    required
                    name="city_id"
                    labelId="ciudad"
                    label="Selecciona una ciudad"
                    value={citySelected || citySelectedApp}
                    onChange={handleCity}
                  >
                    {
                      cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <FormControl
                  // error={localErrors.some((e) => e.field === 'city_id')}
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="adomiCategory">Seleccione una zona</InputLabel>
                  <Select
                    required
                    name="zone"
                    labelId="ciudad"
                    label="Selecciona una ciudad"
                    value={zonaSelected}
                    onChange={handleZone}
                  >
                    {
                      zonaName ? (
                        zonaName?.map((zone) => (
                          <MenuItem key={zone} value={zone}>
                            {zone}
                          </MenuItem>
                        ))
                      ) : <MenuItem disabled> No hay zonas registradas </MenuItem>
                    }
                  </Select>
                </FormControl>
              </Grid>
              <CardHeader
                subheader={total}
                title="Total de Zonas"
              />
            </Grid>
          </CardContent>
        </Card>
        <Box mt={3}>
          <Results
            data={zonasData}
            onRefresh={refresh}
            city={citySelected}
            zonaNames={zonaName}
            foods={comidas}
            activeView={activeView}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ZonaReportView;
