import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import TutorialsService from '../../../services/TutorialsService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateTutorialForm = forwardRef(({
  className, tutorial, edit, onChange, hasImage, ...rest
}, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const translateApp = (app) => {
    switch (app) {
      case 'orders':
        return 'Pedidos';
      case 'partners':
        return 'Aliados';
      case 'drivers':
        return 'Repartidores';
      default: return '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const file = ref.current.files[0];
    const data = {
      preview_image: e.target?.preview_image?.value,
      title: e.target?.title?.value,
      description: e.target?.description?.value,
      url: e.target?.url?.value,
      app: e.target?.app?.value,
      is_available: e.target?.is_available?.value
    };

    if (data?.title?.length === 0) {
      errors.push('El titulo  es obligatorio');
    }

    if (data?.description?.length === 0) {
      errors.push('La descripción es obligatoria');
    }

    if (data?.url?.length === 0) {
      errors.push('La url es obligatoria');
    }

    if (data?.app?.length === 0) {
      errors.push('La aplicación es obligatoria');
    }

    if (!edit && file === undefined) {
      errors.push('La imagen es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await TutorialsService.update(tutorial.id, data)
      : await TutorialsService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (response?.data?.preview_image !== null && file !== undefined) {
        await TutorialsService.deleteImage(response?.data?.id);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await TutorialsService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/tutoriales');
          }, 500);
        }
      }
      if (response?.data?.preview_image === null && file !== undefined) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', response.data.id);
        const r = await TutorialsService.uploadImage(formData);

        if (r?.code === 200 || r?.code === 201) {
          setTimeout(() => {
            return navigate('/app/tutoriales');
          }, 500);
        }
      }
      navigate('/app/tutoriales');
    } else {
      setErrors(response.errors);
    }
  };

  const deleteTutorial = async () => {
    if (tutorial?.id) {
      await TutorialsService.deleteImage(tutorial?.id);
      const response = await TutorialsService.remove(tutorial?.id);
      if (!response) {
        navigate('/app/tutoriales', { replace: true });
      }
    }
  };

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Tutorial"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el Titulo"
                label="Titulo"
                name="title"
                onChange={handleChange}
                required
                value={tutorial?.title}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={2}
                placeholder="Escriba Descripción"
                label="Descripción"
                name="description"
                onChange={handleChange}
                required
                value={tutorial?.description}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={2}
                placeholder="Escriba la url"
                label="url"
                name="url"
                onChange={handleChange}
                required
                value={tutorial?.url}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="route">Seleccione la aplicación</InputLabel>
                <Select
                  required
                  name="app"
                  labelId="aplicación"
                  label="Selecciona una aplicación"
                  value={tutorial?.app}
                  onChange={handleChange}
                >
                  <MenuItem value="0" />
                  {
                    ['orders', 'partners', 'drivers'].map((app) => (
                      <MenuItem key={app} value={app}>
                        {translateApp(app)}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="is_available">Está disponible</InputLabel>
                <Select
                  required
                  name="is_available"
                  labelId="Está disponible"
                  label="Selecciona una opción"
                  value={tutorial?.is_available}
                  onChange={handleChange}
                >
                  {
                    [true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        {answer.toString() === 'true' ? 'Si' : 'No'}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              hasImage && (
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="preview_image"
                    name="preview_image"
                    onChange={handleChange}
                    required
                    value={tutorial?.preview_image}
                    variant="outlined"
                  />
                </Grid>
              )
            }
            <input name="image[]" type="file" ref={ref} style={{ display: 'none' }} />
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteTutorial} />
    </form>
  );
});

CreateTutorialForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  tutorial: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    app: PropTypes.string,
    is_available: PropTypes.bool,
    preview_image: PropTypes.string,
  })
};

CreateTutorialForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  onChange: () => { },
  tutorial: {
    id: null,
    title: '',
    description: '',
    url: '',
    app: '',
    is_available: false,
    preview_image: ''
  },
};

export default CreateTutorialForm;
