import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  Grid,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  }
}));

const PageTemplateCardPreview = ({
  className,
  pageTemplate,
  onUpload,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid
            container
            item
            xs={3}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Avatar
              className={classes.avatar}
              alt="brand"
              src={null}
              variant="square"
            />
          </Grid>
          <Grid item xs={9}>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {pageTemplate?.title}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {pageTemplate?.path_name}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {pageTemplate?.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PageTemplateCardPreview.propTypes = {
  className: PropTypes.string,
  pageTemplate: PropTypes.object,
  onUpload: PropTypes.func
};

PageTemplateCardPreview.defaultProps = {
  className: '',
  pageTemplate: {},
  onUpload: () => {}
};

export default PageTemplateCardPreview;
