import HttpRequest from './HttpRequest';
import generateEndpoint from '../utils/generateEndpoint';

export default class faqsService extends HttpRequest {
  static endpoint = 'faqs';

  static async getAll() {
    this.endpoint = generateEndpoint('faqs');
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'faqs';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'faqs';
    const response = await this.post(data);
    return response;
  }

  static async remove(id) {
    this.endpoint = 'faqs';
    const response = await this.delete(id);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'faqs';
    const response = await this.put(id, data);
    return response;
  }
}
