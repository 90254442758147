import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import adminsPhone from 'src/utils/adminsPhone';
import { selector as UserSelector } from 'src/redux/ducks/user';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import PartnerService from '../../../services/PartnerService';
import BrandService from '../../../services/BrandService';
import CountryService from '../../../services/CountryService';
import Selector from '../../../components/Selector';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%'
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  }
}));

const selectorStyles = {
  container: {
    height: '100%',
    minHeight: '56px'
  },
  control: {
    height: '100%',
    padding: '9px 0'
  }
};

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const CreatePartnerForm = forwardRef(
  (
    {
      className,
      partner,
      edit,
      onChange,
      hasImage,
      typeForm,
      setLoading,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user } = useSelector(UserSelector);
    const { setErrors } = useErrors();
    const { toggleModal } = useConfirmation();
    const [countrySelected, setCountrySelected] = useState('');
    const [countries, setCountries] = useState([]);
    const [brandSelected, setBrandSelected] = useState('');
    const [brands, setBrands] = useState([]);

    const handleChange = (event) => {
      onChange({
        [event.target.name]: event.target.value,
        bank_account_details: {
          ...partner.bank_account_details,
          [event.target.name]: event.target.value
        }
      });
    };

    const handleCountry = (event) => {
      setCountrySelected(event.target.value);
      handleChange(event);
    };

    const handleBrand = (value) => {
      setBrandSelected(value?.value);
      handleChange({
        target: {
          name: 'brand_id',
          value: value?.value
        }
      });
    };

    const getValuesForm = (dataType, e) => {
      if (dataType === 'password') {
        return {
          first_name: partner?.first_name,
          last_name: partner?.last_name,
          avatar: partner?.avatar || null,
          email: partner?.email,
          phone: Number(partner?.phone),
          brand_id: partner?.brand_id,
          country_id: partner?.country_id,
          bank_account_details: partner?.bank_account_details,
          nit: partner?.nit,
          password: e.target?.password?.value
        };
      }

      return {
        first_name: e.target?.first_name?.value,
        last_name: e.target?.last_name?.value,
        avatar: e.target?.avatar?.value,
        email: e.target?.email.value,
        phone: Number(e.target?.phone?.value),
        brand_id: e.target?.brand_id?.value,
        country_id: e.target?.country_id?.value,
        bank_account_details: {
          bank_name: e.target?.bank_name?.value,
          account_type: e.target?.account_type?.value,
          account_number: e.target?.account_number?.value
        },
        nit: e.target?.nit?.value,
        password: e.target?.password?.value
      };
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const errors = [];
      const file = ref.current && ref.current.files[0];
      const data = getValuesForm(typeForm, e);
      console.log(data);
      if (data.first_name.length === 0) {
        errors.push('El nombre del aliado es obligatorio');
      }
      if (data.last_name.length === 0) {
        errors.push('Los apellidos del aliado son obligatorios');
      }
      if (data.phone.length === 0) {
        errors.push('El teléfono del aliado es obligatorio');
      }
      if (data.email.length === 0) {
        errors.push('El email del aliado es obligatorio');
      }
      if (data.bank_account_details.bank_name.length === 0) {
        errors.push('El nombre del banco es obligatorio');
      }
      if (data.bank_account_details.account_type.length === 0) {
        errors.push('El tipo de cuenta es obligatorio');
      }
      if (data.bank_account_details.account_number.length === 0) {
        errors.push('El número de la cuenta es obligatorio');
      }
      if (emailRegex.test(data.email) === false) {
        errors.push('El email esta mal escrito');
      }
      if (data.password) {
        if (data.password.length < 8) {
          errors.push('La contraseña debe tener 8 caracteres como mínimo');
        }
      }
      if (typeForm === 'password' && data.password.length === 0) {
        errors.push('La nueva contraseña es obligatoria');
      }

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }

      // setLoading(true);

      const response = edit
        ? await PartnerService.update(partner.id, data)
        : await PartnerService.create(data);

      if (response?.code === 201 || response?.code === 200) {
        setErrors([]);
        if (response?.data?.avatar !== null && file !== undefined) {
          await PartnerService.deleteImage(response?.data?.id);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('id', response.data.id);
          const r = await PartnerService.uploadImage(formData);

          if (r?.code === 200 || r?.code === 201) {
            setTimeout(() => {
              return navigate('/app/aliados');
            }, 500);
          }
        }
        if (response?.data?.avatar === null && file !== undefined) {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('id', response.data.id);
          const r = await PartnerService.uploadImage(formData);

          if (r?.code === 200 || r?.code === 201) {
            setTimeout(() => {
              return navigate('/app/aliados');
            }, 500);
          }
        }
        navigate('/app/aliados');
      } else {
        setErrors(response.errors);
      }
    };

    const deletePartner = async () => {
      if (partner?.id) {
        await PartnerService.deleteImage(partner.id);
        const response = await PartnerService.remove(partner.id);
        if (!response) {
          navigate('/app/aliados', { replace: true });
        }
      }
    };

    const banks = [
      'BBVA Colombia',
      'Bancamia S.A',
      'Banco AV Villas',
      'Banco Agrario',
      'Banco BTG Pactual',
      'Banco Caja Social BCSC SA',
      'Banco Cooperativo Coopcentral',
      'Banco Credifinanciera S.A',
      'Banco Davivienda SA',
      'Banco Falabella SA',
      'Banco GNB Sudameris',
      'Banco Pichincha',
      'Banco Popular',
      'Banco Santander',
      'Banco Serfinanza',
      'Banco W S.A.',
      'Banco de Bogotá',
      'Banco de Occidente',
      'Bancoldex',
      'Bancolombia',
      'Bancoomeva',
      'COLTEFINANCIERA S.A',
      'Confiar Cooperativa Financiera',
      'Daviplata',
      'Giros y Finanzas CF',
      'IRIS',
      'Itau',
      'Itau antes Corpbanca',
      'J.P. Morgan Colombia S.A',
      'Lulo Bank S.A',
      'Movii',
      'Scotiabank Colpatria S.A.',
      'Uala'
    ];

    useEffect(() => {
      (async () => {
        const response = await CountryService.getAll();

        if (response?.code !== 200) {
          setErrors(['Ocurrió un error al intentar mostrar los países']);
          return;
        }

        setCountries(response.data);
      })();
    }, []);

    useEffect(() => {
      setCountrySelected(partner.country_id);
    }, [partner.country_id]);

    useEffect(() => {
      (async () => {
        const response = await BrandService.getAll();

        if (response?.code !== 200) {
          setErrors(['Ocurrió un error al intentar mostrar las marcas']);
          return;
        }

        setBrands(response.data);
      })();
    }, []);

    useEffect(() => {
      setBrandSelected(partner.brand_id);
    }, [partner.brand_id]);

    return (
      <>
        {typeForm === 'password' ? (
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader="La información puede ser editada"
                title="Aliado"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba la contraseña"
                      label="Contraseña"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={partner?.password}
                      variant="outlined"
                      min={8}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Errors time={0} />
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button type="submit" color="primary" variant="contained">
                  {edit ? 'Editar' : 'Guardar'}
                </Button>
              </Box>
            </Card>
            <ConfirmationModal onAccept={deletePartner} />
          </form>
        ) : (
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader
                subheader="La información puede ser editada"
                title="Aliado"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="pais">Seleccione un país</InputLabel>
                      <Select
                        required
                        name="country_id"
                        labelId="pais"
                        label="Selecciona un país"
                        value={countrySelected}
                        onChange={handleCountry}
                      >
                        <MenuItem value="0" />
                        {countries.map((country) => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      // error={localErrors.some((e) => e.field === 'brand_id')}
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Selector
                        required
                        options={brands?.map((brand) => ({
                          value: brand?.id,
                          label: brand?.name
                        }))}
                        name="brand_id"
                        labelId="marca"
                        label="Selecciona una marca"
                        placeholder="Selecciona una marca"
                        value={brands
                          ?.filter((brand) => brand?.id === brandSelected)
                          ?.map((brand) => ({
                            value: brand?.id,
                            label: brand?.name
                          }))
                          ?.find((brand) => brand?.value === brandSelected)}
                        onChange={handleBrand}
                        styles={selectorStyles}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba el nombre"
                      label="Nombre"
                      name="first_name"
                      onChange={handleChange}
                      required
                      value={partner?.first_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba los apellidos"
                      label="Apellidos"
                      name="last_name"
                      onChange={handleChange}
                      required
                      value={partner?.last_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba el NIT"
                      label="NIT"
                      name="nit"
                      onChange={handleChange}
                      required
                      value={partner?.nit}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba el teléfono"
                      label="Teléfono"
                      name="phone"
                      onChange={handleChange}
                      required
                      value={partner?.phone}
                      variant="outlined"
                      type="tel"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba el correo electrónico"
                      label="Correo electrónico"
                      name="email"
                      onChange={handleChange}
                      required
                      value={partner?.email}
                      variant="outlined"
                    />
                  </Grid>
                  {!edit && (
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder="Escriba la contraseña"
                        label="Contraseña"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={partner?.password}
                        variant="outlined"
                        min={8}
                      />
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      required
                      className={classes?.formControl}
                    >
                      <InputLabel id="bank_name">Banco</InputLabel>
                      <Select
                        name="bank_name"
                        labelId="bank"
                        label="Banco"
                        value={partner?.bank_account_details?.bank_name}
                        onChange={handleChange}
                      >
                        {banks.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      required
                      className={classes?.formControl}
                    >
                      <InputLabel id="account_type">Tipo de cuenta</InputLabel>
                      <Select
                        name="account_type"
                        labelId="account_type"
                        label="Tipo de cuenta"
                        value={partner?.bank_account_details?.account_type}
                        onChange={handleChange}
                      >
                        {['Cuenta de ahorros', 'Cuenta corriente'].map(
                          (item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Escriba el número de la cuenta"
                      label="Número de cuenta"
                      name="account_number"
                      onChange={handleChange}
                      required
                      type="number"
                      value={partner?.bank_account_details?.account_number}
                      variant="outlined"
                    />
                  </Grid>
                  {hasImage && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Perfil"
                        name="avatar"
                        onChange={handleChange}
                        required
                        value={partner.avatar}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <input
                    name="perfil[]"
                    type="file"
                    ref={ref}
                    style={{ display: 'none' }}
                  />
                </Grid>
              </CardContent>
              <Errors time={0} />
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {edit && adminsPhone.includes(user?.phone.toString()) && (
                  <Box mr={2}>
                    <Button
                      type="button"
                      onClick={() => toggleModal()}
                      className={classes.error}
                      variant="contained"
                    >
                      Eliminar
                    </Button>
                  </Box>
                )}
                <Button type="submit" color="primary" variant="contained">
                  {edit ? 'Editar' : 'Guardar'}
                </Button>
              </Box>
            </Card>
            <ConfirmationModal onAccept={deletePartner} />
          </form>
        )}
      </>
    );
  }
);

CreatePartnerForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  hasImage: PropTypes.bool,
  onChange: PropTypes.func,
  typeForm: PropTypes.string,
  setLoading: PropTypes.func,
  partner: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
    country_id: PropTypes.string,
    brand_id: PropTypes.string,
    phone: PropTypes.number,
    password: PropTypes.string,
    nit: PropTypes.string,
    bank_account_details: PropTypes.object
  })
};

CreatePartnerForm.defaultProps = {
  className: '',
  edit: false,
  hasImage: false,
  typeForm: '',
  onChange: () => {},
  setLoading: () => {},
  partner: {
    id: null,
    first_name: '',
    last_name: '',
    avatar: '',
    email: '',
    phone: '',
    country_id: '',
    brand_id: '',
    password: '',
    nit: '',
    bank_account_details: {}
  }
};

export default CreatePartnerForm;
