import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useQueryParams from 'src/hooks/useQueryParams';
import LoadingBox from 'src/components/LoadingBox';
import DriverCardPreview from './DriverCardPreview';
import CreateDriverForm from './CreateDriverForm';
import DriverService from '../../../services/DriverService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateDriverView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [driver, setDriver] = useState({
    id: null,
    first_name: '',
    last_name: '',
    branch_office_id: '',
    avatar: '',
    email: '',
    phone: '',
    whatsapp: '',
    password: '',
    city_id: '',
    is_develop: false,
    has_dataphone: false,
    is_coordinator: false,
    is_adomi: false
  });
  const queryParams = useQueryParams();
  const typeForm = queryParams.get('type');

  const inputRef = useRef();

  const handleDriverAttributes = (values) => {
    setDriver({
      ...driver,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current?.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await DriverService.getById(id);
        if (response?.code === 200) {
          setDriver({
            ...response.data,
            id: response.data.id,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            avatar: response.data.avatar,
            email: response.data.email,
            password: response.data.password,
            city_id: response.data.city_id,
            is_coordinator: response.data.is_coordinator,
            birthdate: response.data.birthdate,
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page
        className={classes.root}
        title="Crear repartidor"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            {
              typeForm !== 'password'
              && (
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <DriverCardPreview
                    ref={inputRef}
                    driver={driver}
                    hasImage={hasImage}
                    onUpload={handleUploadImage}
                  />
                </Grid>
              )
            }
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <CreateDriverForm
                ref={inputRef}
                edit={edit}
                hasImage={hasImage}
                driver={driver}
                typeForm={typeForm}
                onChange={handleDriverAttributes}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateDriverView.propTypes = {
  edit: PropTypes.bool,
};

CreateDriverView.defaultProps = {
  edit: false
};

export default CreateDriverView;
