import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import BannerCardPreview from './BannerCardPreview';
import CreateBannerForm from './CreateBannerForm';
import BannerService from '../../../services/BannerService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateBannerView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [banner, setBanner] = useState({
    id: null,
    image: '',
    branch_office_id: '',
    brand_id: '',
    description: '',
    route: '',
    order: '',
    is_available: false,
    city_id: '',
    adomi_category_id: '',
  });

  const inputRef = useRef();

  const handleBannerAttributes = (values) => {
    setBanner({
      ...banner,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await BannerService.getById(id);
        if (response?.code === 200) {
          setBanner({
            ...response.data,
            id: response.data.id,
            description: response.data.description,
            notification: response.data.image,
          });
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear Anuncio"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <BannerCardPreview
              ref={inputRef}
              banner={banner}
              hasImage={hasImage}
              onUpload={handleUploadImage}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateBannerForm
              ref={inputRef}
              edit={edit}
              hasImage={hasImage}
              banner={banner}
              onChange={handleBannerAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateBannerView.propTypes = {
  edit: PropTypes.bool,
};

CreateBannerView.defaultProps = {
  edit: false
};

export default CreateBannerView;
