import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Grid,
  Box,
  Button,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import useErrors from 'src/hooks/useErrors';
import TermsAndConditionsService from 'src/services/TermsAndConditionsService';

const CreateTermsModal = ({
  edit,
  term,
  onSave,
  onEdit,
  toggleModal
}) => {
  const matches = useMediaQuery('screen and (min-width: 769px)');
  const [newTerm, setNewTerm] = useState({ title: '', description: '' });
  const { setErrors } = useErrors();
  const [localErrors, setLocalErrors] = useState([]);

  const useStyles = makeStyles(() => ({
    modal: {
      border: '1px solid #e6ebf1',
      borderRadius: '8px',
      width: !matches ? '100%' : '600px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    CreateTermsModal: {
      backgroundColor: 'rgba(46, 44, 54, 0.2)',
      position: 'fixed',
      top: 0,
      height: '100%',
      bottom: '0',
      width: '100%',
      zIndex: '3',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
      right: 0
    }
  }));

  const classes = useStyles();

  const handleChange = (e) => {
    setNewTerm({
      ...newTerm,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];

    const data = {
      title: newTerm.title,
      description: newTerm.description
    };

    if (data?.title.length === 0) {
      errors.push({ field: 'title', error: 'El titulo es obligatorio' });
    }

    if (data?.description.length === 0) {
      errors.push({ field: 'description', error: 'La descripción es obligatoria' });
    }

    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalErrors(errors);
      return;
    }

    try {
      const response = edit
        ? await TermsAndConditionsService.update(newTerm?.id, data)
        : await TermsAndConditionsService.create(data);

      if (response?.code === 200 || response?.code === 201) {
        setErrors([]);
        if (edit) {
          onEdit(newTerm);
        } else {
          onSave({ ...newTerm, id: response?.data?.id });
        }
        toggleModal();
      } else {
        setErrors(Array.isArray(response.errors) ? response.errors : []);
      }
      console.log(response);
    } catch (err) {
      setErrors([err.message]);
    }
  };

  useEffect(() => {
    if (edit) setNewTerm(term);
  }, []);

  return (
    <div className={classes.CreateTermsModal}>
      <div className="pane" onClick={() => toggleModal(false)} />
      <div className={classes.modal}>
        <div className="header">
          <div className="title">
            <h3>
              {!edit ? 'Crear ' : 'Editar '}
              Terminos y condiciones
            </h3>
            <CancelRoundedIcon style={{ cursor: 'pointer' }} color="primary" onClick={() => toggleModal(false)} />
          </div>
        </div>
        <div className="body">
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  placeholder="Escriba el titulo"
                  label="Titulo"
                  name="title"
                  error={localErrors.some((e) => e.field === 'title')}
                  helperText={localErrors.find((e) => e.field === 'title')?.error || ''}
                  value={newTerm.title}
                  onChange={handleChange}
                  variant="outlined"
                  type="text"
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Descripción"
                  name="description"
                  error={localErrors.some((e) => e.field === 'description')}
                  helperText={localErrors.find((e) => e.field === 'description')?.error || ''}
                  value={newTerm.description}
                  onChange={handleChange}
                  multiline
                  rows={5}
                  variant="outlined"
                  type="text"
                  required
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              paddingTop={3}
            >
              <Box mr={2}>
                <Button
                  onClick={() => toggleModal()}
                >
                  Cancelar
                </Button>
              </Box>
              <Button
                type="submit"
                color="primary"
                variant="contained"
              >
                {!edit ? 'Guardar' : 'Editar'}
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

CreateTermsModal.propTypes = {
  edit: PropTypes.bool,
  toggleModal: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  term: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  })
};

export default CreateTermsModal;
