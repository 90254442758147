/* eslint-disable max-len */
import React, { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import {
  Box,
  Chip,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  FaPhone, FaPhoneSlash
} from 'react-icons/fa';
import { AiOutlineClose, AiOutlineFullscreen } from 'react-icons/ai';
import './OrderDetailModal.css';
import { DirectionsBike } from '@material-ui/icons';
// import WhatsappIcon from '../assets/icons/whatsapp.svg';

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'inline-block',
  },
  overlay: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,.5)',
    zIndex: 10000
  },
  modal: {
    position: 'relative',
    width: '90%',
    maxWidth: '600px',
    // maxHeight: '70vh',
    // overflow: 'auto',
    backgroundColor: 'white',
    borderRadius: '.3rem',
    padding: '0 1rem 1rem 1rem',
    transition: '1s'
  },
  modalFullscreen: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',
    // borderRadius: '.3rem',
    padding: '0 1rem 1rem 1rem',
    transition: '1s'
  },
  modalHeader: {
    position: 'sticky',
    top: 0,
    padding: '1rem 0',
    width: '100%',
    backgroundColor: 'white',
    borderBottom: '1px solid #ddd'
  },
  logo: {
    width: '48px',
    borderRadius: '.25rem',
    marginRight: '1rem'
  },
  hide: {
    display: 'none',
    width: 0,
    height: 0,
  },
  whatsapp: {
    width: '1.5rem',
    marginRight: '.5rem'
  },
  icosns: {
    marginRight: '.5rem'
  },
  icon: {
    cursor: 'pointer',
    marginLeft: '.5rem'
  },
  pending: {
    backgroundColor: '#FBC02D',
    color: 'white',
  },
  rejected: {
    backgroundColor: 'red',
    color: 'white'
  },
  accepted: {
    backgroundColor: '#43a047',
    color: 'white',
  },
  finished: {
    backgroundColor: '#1565C0',
    color: 'white',
  },
  in_process: {
    backgroundColor: '#eee'
  },
  sent: {
    color: 'white',
    backgroundColor: '#7B1FA2',
  },
  blackLink: {
    color: '#111',
  },
  button: {
    backgroundColor: '#43a047',
    border: 'none',
    display: 'flex',
    padding: '.5rem 2rem',
    borderRadius: '20px',
    color: 'white',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#226125',
    }
  },
  buttonTwo: {
    backgroundColor: 'white',
    color: '#43a047',
    padding: '.5rem 2rem',
    borderRadius: '20px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid ##43a047'
    }
  }
}));

const ConfirmationModal = ({
  onClose,
  order,
  setOrder,
  toggleSentModal
}) => {
  const classes = useStyles();
  const [fullScreenActived, setFullScreenActived] = useState(false);

  const translate = (status) => {
    switch (status) {
      case 'in_progress':
        return 'En proceso';
      case 'finished':
        return 'Finalizado';
      case 'rejected':
        return 'Rechazado';
      case 'cancelled':
        return 'Cancelado';
      case 'pending_to_be_sent':
        return 'Pendiente por enviar';
      case 'sent_local':
        return 'Enviado por local';
      case 'sent':
        return 'Enviado';
      case 'created':
        return 'Pendiente';
      default:
        return status;
    }
  };

  const statusOrder = (status) => {
    switch (status) {
      case 'CANCELLED':
        return 'in_process';
      case 'IN_PROGRESS':
        return 'accepted';
      case 'REJECTED':
        return 'rejected';
      case 'FINISHED':
        return 'finished';
      case 'SENT':
        return 'sent';
      default:
        return 'pending';
    }
  };

  const getHour = () => {
    const hh = dayjs(order?.created_at).get('hour');
    const mm = dayjs(order?.created_at).get('minute');

    return `${hh}:${mm < 10 ? `0${mm}` : mm}`;
  };

  const getTotalProducts = (products) => {
    return products?.reduce((amount, product) => (
      amount + product?.total
    ), 0);
  };

  const getCurrency = (value) => {
    return value?.toLocaleString('en-US')?.replace(/,/g, '.');
  };

  const getProductList = (products) => { // eslint-disable-line
    const response = products?.reduce((text, product, index) => ( // eslint-disable-line
      index > 0 // eslint-disable-line
        ? text + '(' + product?.amount + 'x) ' + product?.name + ' $' + (product?.has_discount ? getCurrency(product?.discount?.discount_price) : getCurrency(product?.price)) + '%0A' // eslint-disable-line
        : '(' + product?.amount + 'x) ' + product?.name + ' $' + (product?.has_discount ? getCurrency(product?.discount?.discount_price) : getCurrency(product?.price)) + '%0A' // eslint-disable-line
    ), '') // eslint-disable-line

    return response // eslint-disable-line
  };

  const getPaymentMethod = (o) => {
    let response;

    switch (o?.payment_method) {
      case 'cash':
        response = 'Efectivo';
        break;
      case 'card':
        response = 'Tarjeta';
        break;
      case 'datafono':
        response = 'Datafono';
        break;
      default:
        response = o?.payment_method;
        break;
    }

    return response;
  };

  const getTotalPartner = (o) => {
    const couponValue = (o?.coupon_data ? o?.coupon_value : 0);

    if (o?.status === 'sent' && o?.free_delivery) {
      return getTotalProducts(o?.order_products) - o?.delivery_cost - couponValue;
    }
    return getTotalProducts(o?.order_products) - couponValue;
  };

  const getDelivery = (o) => {
    if (o?.status === 'sent') {
      return o?.free_delivery ? `-$ ${getCurrency(o?.delivery_cost)} (Paga el Aliado)` : '$0 (Paga el Cliente)';
    }

    if (o?.status === 'sent_local') {
      return o?.free_delivery ? '(Paga el Aliado)' : '$0 (Paga el Cliente)';
    }

    return '';
  };

  const now = (hour) => {
    const hh = dayjs(hour).get('hour');
    const mm = dayjs(hour).get('minute');

    return `${hh < 13 ? hh : hh - 12}:${mm < 10 ? `0${mm}` : mm} ${hh < 12 ? 'AM' : 'PM'}`;
  };

  // eslint-disable-next-line no-unused-vars
  const newBuildWhatsappText = (o) => {
    return (
      `*HOLA, LLEGÓ UN ADOMI*

*PEDIDO:* #${o?.reference} | ${now(o?.created_at)}

*TIPO DE ENVÍO:* ${o?.status === 'sent' ? 'Adomi' : 'Local'}

*FORMA DE PAGO:* ${getPaymentMethod(o)}

*INFORMACIÓN DEL COMERCIO:*

  *_Nombre_*
  ${o?.data_branch_office?.brand?.name} ${o?.branch_office_name}

  *_Dirección_*
  ${o?.data_branch_office?.address.replace(/#/g, 'N°')}
  https://maps.google.com/?q=${o?.data_branch_office?.latitude},${o?.data_branch_office?.longitude}
  
  *_Teléfono_* 
  ${o?.data_branch_office?.phone1}
  https://wa.me/57${o?.data_branch_office?.whatsapp1}

  _*Código para retirar el pedido:* ${o?.delivery_token}_


*RESUMEN DE PRODUCTOS*
${getProductList(o?.order_products)}
----------------------------------------
  *Total: $${getCurrency(getTotalProducts(o?.order_products))}*


*💲 VALOR A PAGAR AL ALIADO:*
  *- Productos:* $${getCurrency(getTotalProducts(o?.order_products))}
  *- Cupón:* ${o?.coupon_data ? `-$${getCurrency(o?.coupon_value)}` : '$0'}
  *- Domicilio:* ${getDelivery(o)}
----------------------------------------
  *Total: $${getCurrency(getTotalPartner(o))}*


*💲 VALOR A COBRAR AL USUARIO:*
  *- Productos:* $${getCurrency(getTotalProducts(o?.order_products))}
  *- Domicilio:* ${o?.free_delivery ? `GRATIS ~($${getCurrency(o?.delivery_cost)})~` : `$${getCurrency(o?.delivery_cost)}`}
  *- Propina:* $${o?.tip ? getCurrency(o?.tip) : 0}
  *- Plataforma:* $0
  *- Cupón:* -$${o?.coupon_data ? getCurrency(o?.coupon_value) : 0}
----------------------------------------
  *Total: $${getCurrency(o?.total)}*


*INFORMACIÓN DE ENTREGA*

  *Efectivo:* $${getCurrency(o?.cash_paid)}

  *_Nombre_*
  ${o?.data_client?.first_name} ${o?.data_client?.last_name}

  *_Dirección de entrega_*
  ${o?.address?.address1.replace(/#/g, 'N°')} ${o?.address?.reference && `(${o?.address?.reference.replace(/#/g, 'N°')})`}
  https://maps.google.com/?q=${o?.address?.latitude},${o?.address?.longitude}

  *_Teléfono_*
  ${o?.data_client?.phone}
  https://wa.me/57${o?.data_client?.phone}


_Una vez entregue la orden, complete la entrega escribiendo: *cerrar ${o?.reference}* [código de entrega]_
`
    );
  };

  // eslint-disable-next-line no-unused-vars
  const buildWhatsappText = (o) => {
    return (`*HOLA, LLEGÓ UN ADOMI*%0A%0A
*1. Pedido* N°${o?.reference} %20| %20 Hora de ingreso (${getHour()})%0A%0A
*2. Comercio:* ${o?.data_branch_office?.brand?.name} ${o?.branch_office_name}%0A
%20%20%20${o?.data_branch_office?.address.replace(/#/g, 'N°')}%0A%0A
*3. Productos*%0A${getProductList(o?.order_products)}%0A
*4. Valor a pagar al comercio:* $${getCurrency(getTotalProducts(o?.order_products))}%0A%0A
*5. Cupón de descuento:* $${o?.coupon_data ? getCurrency(o?.coupon_value) : 0}%0A%0A
*6. Propina:* $${o?.tip ? getCurrency(o?.tip) : 0}%0A%0A
*7. Costo Domicilio:* ${o?.free_delivery ? `GRATIS ~($${getCurrency(o?.delivery_cost)})~` : `$${getCurrency(o?.delivery_cost)}`}%0A%0A
*8. Valor a pagar al cliente:* $${getCurrency(o?.total)}%0A%0A
*9. Información del cliente*%0A
%20%20*- Dirección de entrega*%0A
%20%20%20${o?.address?.address1.replace(/#/g, 'N°')} ${o?.address?.reference && `(${o?.address?.reference.replace(/#/g, 'N°')})`}%0A
%20%20*- Nombre*%0A%20%20%20${o?.data_client?.first_name} ${o?.data_client?.last_name}%0A
%20%20*- Teléfono*%0A%20%20%20${o?.data_client?.phone}
`);
  };
  /*
  const getOrder = async () => {
    const response = await OrderService.getById(detail?.id);
    if (response?.success) {
      const text = encodeURIComponent(newBuildWhatsappText(response?.data));
      setOrder(response?.data);
      setWhatsappText(text);
      console.log(whatsappText);
      const response2 = await OrderService.getByBranchOffice(
        response?.data?.branch_office_id,
        response?.data?.created_at
      );
      if (response2?.success) {
        const k = response2?.data?.orders?.find((o) => o?.id === detail?.id);
        setStatuses(k?.order_status);
      }
    }
  };
  */

  const handleClose = (e) => {
    if (Array.from(e?.target?.classList).some((c) => c?.includes('css-') || c?.includes('MuiButton-label'))) return;
    onClose();
  };

  const handlesetFullScreen = () => {
    setFullScreenActived(!fullScreenActived);
  };

  return order && (
    <div className={classes?.overlay}>
      <div className={fullScreenActived ? classes?.modalFullscreen : classes?.modal}>
        <div className={classes?.modalHeader}>
          <Grid container>
            <Grid item xs={12} display="flex" mb={4}>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <AiOutlineFullscreen className={classes.icon} onClick={handlesetFullScreen} />
                <AiOutlineClose className={classes.icon} onClick={handleClose} />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h4" component="h5">
                Pedido #
                {order?.reference}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Box mr={2} pt={0.5}>
                <Typography variant="body2" align="right">
                  Hora:
                  {getHour(order)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Chip
                label={translate(order?.status?.toString())}
                className={classes?.[
                  statusOrder(order?.status?.toString()?.toUpperCase())
                ]}
              />
            </Grid>
          </Grid>
        </div>
        <Box mb={3} mt={fullScreenActived ? 10 : 2}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" component="h5">
                Comercio
              </Typography>
              <Box mt={1}>
                <Grid container spacing={2}>
                  <Grid item>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img
                      src={order?.data_branch_office?.brand?.logo}
                      alt={order?.data_branch_office?.brand?.name}
                      className={classes?.logo}
                    />
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography gutterBottom variant="subtitle1" component="div">
                          {order?.data_branch_office?.brand?.name}
                          {' '}
                          {order?.branch_office_name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {order?.data_branch_office?.phone1}
                          {order?.data_branch_office?.phone2 ? ` - ${order?.data_branch_office?.phone2}` : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <div className={fullScreenActived ? 'detailModal-info-full' : 'detailModal-info'}>
          <Box mb={3}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  Productos
                </Typography>
                <Table size="small" dense table border>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Nombre
                      </TableCell>
                      <TableCell width="20">
                        Cantidad
                      </TableCell>
                      <TableCell width="120" align="right">
                        Precio
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order?.order_products?.map((product) => (
                      <TableRow
                        hover
                        key={product?.id}
                      >
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="body2"
                          >
                            {product?.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            color="textPrimary"
                            variant="body2"
                          >
                            {product?.amount}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            color="textPrimary"
                            variant="body2"
                          >
                            $
                            <CurrencyFormat
                              value={product?.has_discount
                                ? product?.discount?.discount_price
                                : product?.price}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow hover>
                      <TableCell colSpan={3} align="right">
                        <Box mr={0}>
                          <Typography
                            color="textPrimary"
                            variant="caption"
                            align="right"
                            component="span"
                          >
                            <strong>SUB-TOTAL</strong>
                            {'   '}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body2"
                            align="right"
                            component="span"
                          >
                            $
                            <CurrencyFormat
                              value={getTotalProducts(order?.order_products)}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    {order?.coupon_data && (
                      <>
                        <TableRow hover>
                          <TableCell colspan={2}>
                            <Typography
                              color="textPrimary"
                              variant="body2"
                            >
                              Cupón
                              {' '}
                              {`(${order?.coupon_data?.key})`}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography
                              color="textPrimary"
                              variant="body2"
                            >
                              <Box mr={0}>
                                - $
                                <CurrencyFormat value={order?.coupon_value} displayType="text" thousandSeparator="." decimalSeparator="," />
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow hover>
                          <TableCell colSpan={3} align="right">
                            <Box mr={0}>
                              <Typography
                                color="textPrimary"
                                variant="caption"
                                align="right"
                                component="span"
                              >
                                <strong>SUB-TOTAL</strong>
                                {'   '}
                              </Typography>
                              <Typography
                                color="textPrimary"
                                variant="body2"
                                align="right"
                                component="span"
                              >
                                $
                                <CurrencyFormat value={order?.sub_total} displayType="text" thousandSeparator="." decimalSeparator="," />
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    <TableRow hover>
                      <TableCell colspan={2}>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Propina
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          <Box mr={0}>
                            $
                            <CurrencyFormat
                              value={order?.tip || 0}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </Box>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell colSpan={3} align="right">
                        <Box mr={0}>
                          <Typography
                            color="textPrimary"
                            variant="caption"
                            align="right"
                            component="span"
                          >
                            <strong>SUB-TOTAL</strong>
                            {'   '}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body2"
                            align="right"
                            component="span"
                          >
                            $
                            <CurrencyFormat
                              value={order?.sub_total + (order?.tip || 0)}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell colSpan={1}>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          Costo de domicilio
                        </Typography>
                      </TableCell>
                      <TableCell align="right" colSpan={2}>
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          {
                            order?.free_delivery
                              ? (
                                <>
                                  GRATIS (
                                  <del>{order?.delivery_cost}</del>
                                  )
                                </>
                              )
                              : (
                                <>
                                  $
                                  <CurrencyFormat value={order?.delivery_cost} displayType="text" thousandSeparator="." decimalSeparator="," />
                                </>
                              )
                          }
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell colSpan={3} align="right">
                        <Box mr={0}>
                          <Typography
                            color="textPrimary"
                            variant="caption"
                            align="right"
                            component="span"
                          >
                            <strong>TOTAL</strong>
                            {'   '}
                          </Typography>
                          <Typography
                            color="textPrimary"
                            variant="body2"
                            align="right"
                            component="span"
                          >
                            $
                            <CurrencyFormat value={order?.total} displayType="text" thousandSeparator="." decimalSeparator="," />
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Box>
          <Box mb={3}>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant="h5" component="h5">
                    Datos de entrega
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  Nombre del cliente
                </Typography>
                <Typography variant="body2">
                  {order?.data_client?.first_name}
                  {' '}
                  {order?.data_client?.last_name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  Teléfono
                </Typography>
                <Typography variant="body2">
                  {order?.data_client?.phone}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6">
                  Dirección de entrega
                </Typography>
                <Typography variant="body2">
                  {order?.address?.address1}
                  {' '}
                  {order?.address?.reference && `(${order?.address?.reference})`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mb={3}>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant="h5" component="h5">
                    Driver asignado
                  </Typography>
                </Box>
              </Grid>
              {order?.order_status?.some((s) => s?.status.includes('sent', 'finished')) && order?.data_driver?.id ? (
                <>
                  <Grid container item>
                    <Grid item container>
                      <Typography variant="h6" component="h6">
                        Nombre
                      </Typography>
                    </Grid>
                    <Grid item container>
                      <Typography variant="body2">
                        {order?.data_driver?.first_name}
                        {' '}
                        {order?.data_driver?.last_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item container>
                      <Typography variant="h6" component="h6">
                        Teléfono
                      </Typography>
                    </Grid>
                    <Grid item container>
                      <a href={`tel:${order?.data_driver?.whatsapp}`} className={classes?.blackLink}>
                        <Typography variant="body2">
                          {order?.data_driver?.whatsapp}
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid container item>
                  <Grid item container>
                    <Typography variant="body2">
                      No asignado
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

          </Box>
          <Box mb={3}>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant="h5" component="h5">
                    Historial de la orden
                  </Typography>
                </Box>
              </Grid>
              {order?.order_status?.find((s) => s?.status === 'created')?.created_at && (
                <Grid container item>
                  <Grid item container xs={6} md={3}>
                    <Typography variant="h6" component="h6">
                      Recibido
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} md={2}>
                    <Typography variant="body2">
                      {dayjs(order?.order_status?.find((s) => s?.status === 'created')?.created_at)?.format('hh:mm A')}
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} md={7}>
                    <Typography variant="body2">
                      {order?.has_called
                        ? (
                          <>
                            Aliado llamado
                            <FaPhone className={classes.icosns} />
                          </>
                        )
                        : (
                          <>
                            Aliado no llamado
                            <FaPhoneSlash className={classes.icosns} />
                          </>
                        )}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {order?.order_status?.find((s) => s?.status === 'etp')?.created_at && (
                <Grid container item>
                  <Grid item container xs={6} md={3}>
                    <Typography variant="h6" component="h6">
                      Tiempo Estimado
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} md={2}>
                    <Typography variant="body2">
                      {order?.order_status?.find((s) => s?.status === 'etp')?.information?.etp}
                      min
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {order?.order_status?.find((s) => s?.status === 'in_progress')?.created_at && (
                <Grid container item>
                  <Grid item container xs={6} md={3}>
                    <Typography variant="h6" component="h6">
                      En proceso
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} md={2}>
                    <Typography variant="body2">
                      {dayjs(order?.order_status?.find((s) => s?.status === 'in_progress')?.created_at)?.format('hh:mm A')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {order?.order_status?.find((s) => s?.status.includes('sent'))?.created_at && (
                <Grid container item>
                  <Grid item container xs={6} md={3}>
                    <Typography variant="h6" component="h6">
                      En camino
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} md={2}>
                    <Typography variant="body2">
                      {dayjs(order?.order_status?.find((s) => s?.status.includes('sent'))?.created_at)?.format('hh:mm A')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {order?.order_status?.find((s) => s?.status === 'cancelled')?.created_at && (
                <Grid container item>
                  <Grid item container xs={6} md={3}>
                    <Typography variant="h6" component="h6">
                      Cancelado
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} md={2}>
                    <Typography variant="body2">
                      {dayjs(order?.order_status?.find((s) => s?.status === 'cancelled')?.created_at)?.format('hh:mm A')}
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} md={7}>
                    <Typography variant="body2">
                      {order?.order_status?.find((s) => s?.status === 'cancelled')?.information?.reason}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {order?.order_status?.find((s) => s?.status === 'rejected')?.created_at && (
                <Grid container item>
                  <Grid item container xs={6} md={3}>
                    <Typography variant="h6" component="h6">
                      Rechazado
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} md={2}>
                    <Typography variant="body2">
                      {dayjs(order?.order_status?.find((s) => s?.status === 'rejected')?.created_at)?.format('hh:mm A')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {order?.order_status.find((s) => s?.status === 'finished')?.created_at && (
                <Grid container item>
                  <Grid item container xs={6} md={3}>
                    <Typography variant="h6" component="h6">
                      Completado
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} md={2}>
                    <Typography variant="body2">
                      {dayjs(order?.order_status?.find((s) => s?.status === 'finished')?.created_at)?.format('hh:mm A')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </div>

        <div className={classes.root}>
          <Box mt={2} mb="10px" display="flex" justifyContent="center" text-align="center">
            {order?.status === 'pending_to_be_sent' && order?.ship_type === 'adomi' && (
              <Grid container item justify="center">
                <Button
                  className={classes.button}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setOrder(order);
                    toggleSentModal();
                  }}
                >
                  <DirectionsBike fontSize="small" className={classes.icosns} />
                  Asignar repartidor
                </Button>
              </Grid>
            )}
          </Box>
          {/* <Box mb={3}>
            <Grid container>
              <Grid container item xs={12} justify="center">
                <a href={`https://wa.me?text=${whatsappText}`} target="_blank" rel="noreferrer">
                  <Button className={classes.buttonTwo} color="primary" variant="contained" size="small">
                    Enviar por Whatsapp
                    <FaWhatsapp size={20} className={classes.icosns} />
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Box> */}
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  onClose: PropTypes.func,
  order: PropTypes.object,
  // onRefresh: PropTypes.array,
  setOrder: PropTypes.func,
  toggleSentModal: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  onClose: () => null,
};

export default ConfirmationModal;
