import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import NotificationCardPreview from './NotificationCardPreview';
import CreateNotificationForm from './CreateNotificationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateNotificationView = () => {
  const classes = useStyles();
  const [notification, setNotification] = useState({
    title: '',
    body: '',
    app: '',
    topic: ''
  });

  const handleNotificationAttributes = (values) => {
    setNotification({
      ...notification,
      ...values
    });
  };

  return (
    <Page
      className={classes.root}
      title="Enviar Notificación"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <NotificationCardPreview
              notification={notification}
              onChange={handleNotificationAttributes}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateNotificationForm
              notification={notification}
              onChange={handleNotificationAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateNotificationView;
