import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import LoadingBox from 'src/components/LoadingBox';
import AdomiComponentsCardPreview from './AdomiComponentsCardPreview';
import CreateAdomiComponentsForm from './CreateAdomiComponentsForm';
import AdomiComponentsService from '../../../services/AdomiComponentsService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateAdomiComponentsView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage] = useState(false);
  const [adomiComponent, setAdomiComponent] = useState({});
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();

  const handleAdomiComponentAttributes = (values) => {
    setAdomiComponent({
      ...adomiComponent,
      ...values
    });
  };

  const handleUploadImage = () => inputRef.current.click();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await AdomiComponentsService.getById(id);
        if (response?.code === 200) {
          setAdomiComponent({
            ...response.data,
            id: response.data.id,
            name: response.data.name,
            image: response.data.image,
          });
        }
      })();
    }
  }, [id]);

  return (
    <LoadingBox loading={loading}>
      <Page
        className={classes.root}
        title="Crear Componente de Adomi"
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <AdomiComponentsCardPreview
                ref={inputRef}
                adomiComponent={adomiComponent}
                hasImage={hasImage}
                onUpload={handleUploadImage}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <CreateAdomiComponentsForm
                ref={inputRef}
                edit={edit}
                hasImage={hasImage}
                adomiComponent={adomiComponent}
                onChange={handleAdomiComponentAttributes}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </LoadingBox>
  );
};

CreateAdomiComponentsView.propTypes = {
  edit: PropTypes.bool,
};

CreateAdomiComponentsView.defaultProps = {
  edit: false
};

export default CreateAdomiComponentsView;
