import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// MUI components
import { Box, makeStyles } from '@material-ui/core';

// Utils
import pusher from 'src/utils/pusher';
import colors from 'src/utils/colorsProfile';

// Components
import LoadingBox from 'src/components/LoadingBox';
import NavBar from './NavBar';
import TopBar from './TopBar';

// Redux
import { actions as AlertActions } from '../../redux/ducks/alert';
import { selector as UserSelector } from '../../redux/ducks/user';
import {
  getChats,
  newChat,
  updateChat,
  addUnansweredMsg
} from '../../redux/ducks/chat';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  content: {
    flexGrow: 1,
    width: '100%',
    marginTop: 64,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      marginLeft: '-256px',
    },
    overflow: 'auto'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const DashboardLayout = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user, token } = useSelector(UserSelector);

  useEffect(() => {
    if (!token) navigate('/login', { replace: true });
  }, []);

  useEffect(() => {
    if (user?.id) {
      setLoading(false);
      dispatch(getChats());
      const channel = pusher.subscribe('support-chats');
      ['changes-support-chats', 'new-support-chat', 'new-support-message']
        .map((item) => {
          channel.bind(item, (message) => {
            if (['changes-support-chats']?.some((event) => event === item)) {
              const data = {
                id: message.message.support_chat?.id,
                status: message.message.support_chat?.status
              };
              dispatch(updateChat(data));
            }

            if (['new-support-chat']?.some((event) => event === item)) {
              const color = colors[Math.floor(Math.random() * colors.length)];
              dispatch(newChat({
                ...message.message?.support_chat,
                color,
                unanswered_messages_by_admin: 0
              }));
              dispatch(AlertActions.setNewChat());
            }

            if (['new-support-message']?.some((event) => event === item)) {
              const data = {
                id: message.message.support_message?.support_chat_id,
                last_message: message.message.support_message
              };
              dispatch(updateChat(data));
              if (message.message.support_message?.sent_by === 'user') {
                dispatch(AlertActions.setNewMessage());
                dispatch(addUnansweredMsg(message.message.support_message?.support_chat_id));
              }
            }
          });
          return item;
        });
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <LoadingBox
        loading={loading}
        bgcolor="transparent"
      >
        <Box
          display="flex"
          width="100%"
        >
          <TopBar
            onMobileNavOpen={setMobileNavOpen}
            open={isMobileNavOpen}
          />
          <NavBar
            onMobileClose={setMobileNavOpen}
            openMobile={isMobileNavOpen}
          />
          <Box
            className={clsx(classes.content, {
              [classes.contentShift]: !isMobileNavOpen,
            })}
            bgcolor="#fff"
          >
            <Outlet />
          </Box>
        </Box>
      </LoadingBox>
    </div>
  );
};

export default DashboardLayout;
