import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  FormControl,
  TextField,
  InputLabel,
  makeStyles, MenuItem
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import GoogleMapsModal from '../../../components/GoogleMapsModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import AddressService from '../../../services/AddressService';
import CityService from '../../../services/CityService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateAddressForm = ({
  className, id, address, edit, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [citySelected, setCitySelected] = useState('');
  const [cities, setCities] = useState([]);
  const [googleMapsOpen, setGoogleMapsOpen] = useState(false);
  const [googleMapCenter, setGoogleMapCenter] = useState({
    lat: address.latitude,
    lng: address.longitude
  });

  id = edit === true ? address?.client_id : id;

  const handleChange = (event) => {
    onChange({
      ...address,
      client_id: id,
      [event.target.name]: event.target.value
    });
  };

  const handleCity = (event) => {
    setCitySelected(event.target.value);
    handleChange(event);
  };

  const savePosition = (position) => {
    onChange({
      latitude: position.lat,
      longitude: position.lng,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      client_id: id,
      address1: e.target?.address1?.value,
      city_id: e.target?.city_id?.value === '0' ? null : e.target?.city_id?.value,
      latitude: Number(e.target?.latitude?.value),
      longitude: Number(e.target?.longitude?.value),
      reference: e.target?.reference?.value,
      address_type: e.target?.address_type?.value,
    };

    if (data?.city_id?.value === '') {
      errors.push('El campo ciudad es obligatorio');
    }
    if (data?.address1?.length === 0) {
      errors.push('La dirección es obligatorio');
    }

    if (data?.name?.length === 0 || data?.city_id?.value === '') {
      setErrors(errors);
      return;
    }
    const response = edit
      ? await AddressService.update(address?.id, data)
      : await AddressService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      if (edit) {
        navigate(`/app/clientes/${address?.client_id}/direcciones`, { replace: true });
      } else {
        navigate(`/app/clientes/${id}/direcciones`, { replace: true });
      }
    } else {
      setErrors(response.errors);
    }
  };

  const deleteAddress = async () => {
    if (address?.id) {
      const response = await AddressService.remove(address?.id, id);
      if (!response) {
        navigate(`/app/clientes/${id}/direcciones`, { replace: true });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await CityService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
        return;
      }

      setCities(response?.data);
    })();
  }, []);

  useEffect(() => {
    if (edit && address?.id) {
      setGoogleMapCenter({ lat: address?.latitude, lng: address?.longitude });
      setCitySelected(address.city_id);
    }
  }, [edit, address]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Dirección"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="ciudad">Selecciona una ciudad</InputLabel>
                <Select
                  required
                  name="city_id"
                  labelId="ciudad"
                  label="Selecciona una ciudad"
                  value={citySelected}
                  onChange={handleCity}
                >
                  <MenuItem value="0" />
                  {
                  cities.map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                      { city.name }
                    </MenuItem>
                  ))
                }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba la dirección"
                label="Dirección"
                name="address1"
                onChange={handleChange}
                required
                value={address?.address1}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba las indicaciones"
                label="Indicaciones (opcional)"
                name="reference"
                onChange={handleChange}
                value={address?.reference}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="address_type">Tipo de dirección</InputLabel>
                <Select
                  name="address_type"
                  labelId="address_type"
                  label="Tipo de dirección"
                  value={address?.address_type}
                  onChange={handleChange}
                >
                  {
                    ['Casa', 'Trabajo', 'Otro'].map((item) => (
                      <MenuItem key={item} value={item}>
                        { item }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Vaya al mapa"
                type="number"
                label="Latitud"
                name="latitude"
                onChange={handleChange}
                required
                disabled
                value={address?.latitude}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Vaya al mapa"
                label="Longitud"
                name="longitude"
                onChange={handleChange}
                required
                disabled
                value={address?.longitude}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Box mr={2}>
            <Button
              disabled={citySelected === ''}
              onClick={() => setGoogleMapsOpen(true)}
            >
              Ver mapa
            </Button>
          </Box>
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                  disabled={address?.is_active}
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            { edit ? 'Editar' : 'Guardar' }
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteAddress} />
      {googleMapsOpen && (
        <GoogleMapsModal
          address={address?.address1}
          cityId={citySelected}
          position={googleMapCenter}
          isOpen={googleMapsOpen}
          onAccept={() => setGoogleMapsOpen(false)}
          savePosition={savePosition}
        />
      )}
    </form>
  );
};

CreateAddressForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  address: PropTypes.shape({
    id: PropTypes.string,
    client_id: PropTypes.string,
    address1: PropTypes.string,
    city_id: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    reference: PropTypes.string,
    address_type: PropTypes.string,
    is_active: PropTypes.bool,
  })
};

CreateAddressForm.defaultProps = {
  className: '',
  id: '',
  edit: false,
  onChange: () => {},
  address: {
    id: null,
    client_id: '',
    address1: '',
    city_id: '',
    latitude: '',
    longitude: '',
    address_type: '',
    reference: '',
  }
};

export default CreateAddressForm;
