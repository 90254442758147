import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-calendar/dist/Calendar.css';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRoutes, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { selector as UserSelector, actions as UserActions } from './redux/ducks/user';
import { actions as AppActions } from './redux/ducks/app';
import AuthService from './services/AuthService';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('America/Bogota');

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector(UserSelector);
  const routing = useRoutes(routes);

  useEffect(() => {
    const foo = async () => {
      if (token) {
        const authenticated = await AuthService.validate();

        if (authenticated?.code === 200) {
          const data = await AuthService?.refresh();

          if (data?.code === 200) {
            dispatch(UserActions?.set(data?.data?.data));
            dispatch(UserActions?.tokenize(data?.data?.token));
          }
        } else {
          dispatch(UserActions.clear());
          navigate('/login', { replace: true });
        }
      }
    };

    foo();
    dispatch(AppActions.getCity());
  }, []);

  useEffect(() => {
    // PWA Events
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      window.deferredPrompt = e;
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
