import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import ConfirmationModal from '../../../components/ConfirmationModal';
import PageTemplateService from '../../../services/PageTemplateService';

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  }
}));

const CreatePageTemplateForm = ({
  className,
  pageTemplate,
  edit,
  onChange,
  setLoading,
  ...rest
}) => {
  const classes = useStyles();
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [values, setValues] = useState([]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onChange({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      template_id: templateId,
      title: e.target?.title?.value,
      path_name: e.target?.path_name?.value,
      name: e.target?.name?.value,
      background: '#f5f2ed',
      primary_font: '#000000',
      is_dynamic: false,
      is_main: true,
      column_product_id: null,
      brand_category_id: null,
      parent_page_id: null
    };

    if (data.title.length === 0) {
      errors.push('El nombre de la pagina es obligatorio');
    }

    if (data.path_name.length === 0) {
      errors.push('La url de la pagina es obligatoria');
    }

    if (data.name.length === 0) {
      errors.push('El nombre del componente de la pagina es obligatorio');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    // setLoading(true);

    const response = edit
      ? await PageTemplateService.update(pageTemplate?.id, data)
      : await PageTemplateService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate(`/app/page-templates/${templateId}`, { replace: true });
    } else {
      setErrors(response.errors);
    }
  };

  const deletePageTemplate = async () => {
    if (pageTemplate?.id) {
      const response = await PageTemplateService.remove(pageTemplate.id);
      if (response?.code === 200) {
        navigate(`/app/page-templates/${templateId}`, { replace: true });
      } else {
        setErrors(response.errors);
      }
      toggleModal();
    }
  };

  useEffect(() => {
    if (edit) {
      setValues(pageTemplate);
    }
  }, [edit, pageTemplate]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Pagina"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Escribe el nombre de la pagina"
                label="Nombre"
                name="title"
                onChange={handleChange}
                required
                value={values.title}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Escribe la url de la pagina"
                label="Url"
                name="path_name"
                onChange={handleChange}
                required
                value={values.path_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Escribe el nombre del componente"
                label="Componente"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {edit && (
            <Box mr={2}>
              <Button
                type="button"
                onClick={() => toggleModal()}
                className={classes.error}
                variant="contained"
              >
                Eliminar
              </Button>
            </Box>
          )}
          <Button type="submit" color="primary" variant="contained">
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deletePageTemplate} />
    </form>
  );
};

CreatePageTemplateForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  pageTemplate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    path_name: PropTypes.string
  })
};

CreatePageTemplateForm.defaultProps = {
  className: '',
  onChange: () => {},
  setLoading: () => {}
};

export default CreatePageTemplateForm;
