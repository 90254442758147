import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import Errors from '../../../components/Errors';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import ConfirmationModal from '../../../components/ConfirmationModal';
import TemplateService from '../../../services/TemplateService';

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white'
    }
  }
}));

const CreateTemplateForm = ({
  className,
  template,
  edit,
  onChange,
  setLoading,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [values, setValues] = useState([]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });

    onChange({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: e.target?.name?.value
    };

    if (data.name.length === 0) {
      errors.push('El nombre del template es obligatorio');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    // setLoading(true);

    const response = edit
      ? await TemplateService.update(template?.id, data)
      : await TemplateService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/templates', { replace: true });
    } else {
      setErrors(response.errors);
    }
  };

  const deleteTemplate = async () => {
    if (template?.id) {
      const response = await TemplateService.remove(template.id);
      if (response?.code === 200) {
        navigate('/app/templates', { replace: true });
      } else {
        setErrors(response.errors);
      }
      toggleModal();
    }
  };

  useEffect(() => {
    if (edit) {
      setValues(template);
    }
  }, [edit, template]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Template"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Escribe el nombre"
                label="Nombre"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {edit && (
            <Box mr={2}>
              <Button
                type="button"
                onClick={() => toggleModal()}
                className={classes.error}
                variant="contained"
              >
                Eliminar
              </Button>
            </Box>
          )}
          <Button type="submit" color="primary" variant="contained">
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteTemplate} />
    </form>
  );
};

CreateTemplateForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  template: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })
};

CreateTemplateForm.defaultProps = {
  className: '',
  onChange: () => {},
  setLoading: () => {}
};

export default CreateTemplateForm;
