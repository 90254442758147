import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  Grid,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  }
}));

const AddressCardPreview = ({
  className, address, ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {address?.address1}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
            >
              {address?.reference}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="caption"
            >
              {address?.address_type}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="caption"
            >
              {address?.city?.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AddressCardPreview.propTypes = {
  className: PropTypes.string,
  address: PropTypes.object,
};

AddressCardPreview.defaultProps = {
  className: '',
  address: {},
};

export default AddressCardPreview;
