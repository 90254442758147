import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Errors from 'src/components/Errors';
import { isSeller } from 'src/helpers/roles';
import AuthService from '../../services/AuthService';
import { actions as UserActions, selector as UserSelector } from '../../redux/ducks/user';
import useErrors from '../../hooks/useErrors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginConfirmationView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { user } = useSelector(UserSelector);

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required('El codigo es obligatorio')
            })}
            onSubmit={async (value) => {
              const data = await AuthService.twoAuthenticate({
                phone: Number(user?.phone),
                sms_token: value?.password
              });

              if (data.code === 200) {
                dispatch(UserActions.set(data?.data?.data));
                dispatch(UserActions.tokenize(data?.data?.token));
                if (isSeller(data?.data?.data)) {
                  navigate('/app/sucursales', { replace: true });
                } else {
                  navigate('/app/ordenes/en-progreso', { replace: true });
                }
              } else {
                setErrors([
                  'Telefono es valido, o codigo expiró o no valido'
                ]);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Doble autenticación
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Ingresa a la plataforma de administración de Adomi Delivery
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Codigo de mensaje Whatsapp"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.password}
                  variant="outlined"
                />
                <Errors time={0} />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Iniciar Sesión
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginConfirmationView;
