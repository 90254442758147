import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import fileDownload from 'js-file-download';
import Swal from '@sweetalert/with-react';
// components
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  Switch,
  Tooltip,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import adminsPhone from 'src/utils/adminsPhone';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
// services
import ManagerService from '../../../services/ManagerService';
import BranchOfficeService from '../../../services/BranchOfficeService';
// utils
import { isSeller } from '../../../helpers/roles';
// redux
import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      color: 'inherit'
    }
  },
  moreVert: {
    cursor: 'pointer',
    color: 'inherit'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  avatarSwal: {
    width: '4rem',
    height: '4rem',
    borderRadius: '10rem',
    marginTop: '2rem',
  },
  dateAfter: {
    color: '#bbb'
  },
  true: {
    fill: theme?.palette?.primary?.main,
  },
  false: {
    fill: '#aaa'
  },
  statusCell: {
    position: 'relative'
  },
  statusPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 120,
    backgroundColor: 'white',
    borderRadius: '.2rem',
    border: '1px solid #ddd',
  },
  statusPanelList: {
    listStyle: 'none'
  },
  statusPanelItem: {
    padding: 0,
    margin: 0,
    '& > a': {
      display: 'block',
      padding: '.75rem 1rem',
      cursor: 'pointer',
      fontFamily: 'sans-serif',
      fontSize: '.9rem',
      '&:hover': {
        backgroundColor: '#eee'
      }
    }
  },
  openTab: {
    color: '#fff',
    padding: '5px 9px',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '50px'
  },
  point: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
  }
}));

const Results = ({
  setTotalBrancOffices,
  className,
  data,
  onRefresh,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useSelector(UserSelector);
  const [tooltipOpen, setTooltipOpen] = useState('');
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [panel, setPanel] = useState(null);
  const [panelPosition, setPanelPosition] = useState({ x: 0, y: 0 });
  const [branchOffices, setBranchOffices] = useState([]);
  const [branchOfficeName, setBranchOfficeName] = useState([]);
  const [billingCode, setBillingCode] = useState([]);
  setTotalBrancOffices(data?.length);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleBranchOffice = async (branchOffice, field) => {
    const query = {
      name: branchOffice?.name,
      partner_id: branchOffice?.partner_id,
      adomi_categories: branchOffice?.adomi_categories
    };
    query[field] = !branchOffice[field];
    const response = await BranchOfficeService.update(branchOffice?.id, query);
    setTooltipOpen('');
    setTooltipTitle('');

    if (response?.code === 200) {
      const newBranchOffices = branchOffices.reduce((result, item) => {
        if (item?.id === branchOffice?.id) {
          if (field === 'is_working') {
            return result.concat({ ...item, is_working: !item?.is_working });
          }
          if (field === 'is_available') {
            return result.concat({ ...item, is_available: !item?.is_available });
          }
          if (field === 'is_verified') {
            return result.concat({ ...item, is_verified: !item?.is_verified });
          }
        }
        return result.concat(item);
      }, []);

      setBranchOffices(newBranchOffices);
    }

    if (response?.code === 409) {
      setTooltipTitle(response?.errors[0]);
      setTooltipOpen(branchOffice?.id);

      setTimeout(() => {
        setTooltipOpen('');
        setTooltipTitle('');
      }, 5000);
    }
  };

  const toggleMenu = (e) => setBranchOffices(!branchOffices ? e.currentTarget : null);

  const redirect = (route) => {
    switch (route) {
      case 'drivers':
        navigate(`/app/repartidores?sucursal=${panel}`, { replace: true });
        toggleMenu();
        return;
      default:
        navigate(`/app/sucursales/${panel}/editar`, { replace: true });
        toggleMenu();
    }
  };

  const downloadReport = async () => {
    const CSVdata = await BranchOfficeService.downloadReport(panel);
    fileDownload(CSVdata, `Reporte_cupones_${branchOfficeName}.csv`);
  };

  const associateNotify = async (branchOffice) => {
    await Swal({
      content: (
        <Box my={4}>
          <div>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              Asociado Exitosamente
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              component="p"
            >
              Ahora puedes gestionar ordenes en
              {' '}
              {branchOffice?.brand?.name}
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              className={classes?.avatarSwal}
              src={branchOffice?.brand?.logo}
              alt={branchOffice?.brand?.name}
            />
          </div>
        </Box>
      ),
      icon: 'success',
      button: 'Aceptar'
    });
  };

  const associate = async () => {
    let association = null;
    const response = await ManagerService.getByPhone(user?.phone);
    if (response?.data?.exists) {
      const manager = response?.data?.manager;
      delete manager.branch_office;
      const payload = {
        ...manager,
        avatar: manager?.avatar || '',
        branch_office_id: panel,
        is_order_taker: true,
        is_partner: true,
      };
      association = await ManagerService.update(manager?.id, payload);
    } else {
      const payload = {
        ...user,
        avatar: '',
        phone: Number(user?.phone),
        country_id: 'be8cc509-a489-4ec9-99d7-76b7f48258dd',
        branch_office_id: panel,
        is_order_taker: true,
        is_partner: true,
      };
      association = await ManagerService.create(payload);
    }

    if (association?.code === 200) {
      const branchOffice = await BranchOfficeService.getById(panel);
      associateNotify(branchOffice?.data);
    }
  };

  const onClick = (e, branchOffice) => {
    setPanel(panel ? null : branchOffice?.id);
    setBillingCode(branchOffice?.billing_code);
    setPanelPosition(panel ? { x: 0, y: 0 } : { x: e.clientX, y: e.clientY });
    setBranchOfficeName(branchOffice?.name);
  };

  const closePanel = () => {
    setTimeout(() => {
      setPanel(null);
      setPanelPosition({ x: 0, y: 0 });
    }, 50);
  };

  useEffect(() => {
    if (panel) {
      document.addEventListener('click', closePanel);
    }

    return () => {
      document.removeEventListener('click', closePanel);
    };
  }, [panel]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setBranchOffices(data);
    }
  }, [data]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box minWidth={1500}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell width="210">
                    Marca
                  </TableCell>
                  <TableCell width="210">
                    Nombre
                  </TableCell>
                  <TableCell width="100">
                    Ciudad
                  </TableCell>
                  <TableCell>
                    Teléfonos
                  </TableCell>
                  <TableCell>
                    WhatsApps
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    Activa
                  </TableCell>
                  <TableCell>
                    Disponible
                  </TableCell>
                  <TableCell>
                    Destacada
                  </TableCell>
                  <TableCell>
                    Desarrollo
                  </TableCell>
                  <TableCell>
                    Demo
                  </TableCell>
                  {
                    adminsPhone.includes(user?.phone.toString()) && (
                      <TableCell>
                        Verificada
                      </TableCell>
                    )
                  }
                  <TableCell>
                    Creador
                  </TableCell>
                  <TableCell>
                    Asesor
                  </TableCell>
                  <TableCell width="10" />
                </TableRow>
              </TableHead>
              <TableBody>
                {branchOffices.slice(page * limit, (limit * (page + 1))).map((branchOffice) => (
                  <TableRow
                    hover
                    key={branchOffice?.id}
                  >
                    <TableCell style={{ paddingRight: '5px' }}>
                      <span className={classes.openTab} style={{ backgroundColor: branchOffice?.is_working ? '#43a047' : '#f44336' }}>
                        {branchOffice?.is_working ? 'Abierta' : 'Cerrada'}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems="center"
                        display="flex"
                      >
                        <Avatar
                          className={classes.avatar}
                          src={branchOffice?.brand?.logo}
                        />
                        <Typography
                          color="textPrimary"
                          variant="body2"
                        >
                          {branchOffice?.brand?.name}
                        </Typography>
                        <Box display="flex" justifyContent="flex-end" flexGrow="2">
                          <span className={classes.point} style={{ backgroundColor: branchOffice?.is_auto_accept ? '#43a047' : '#f44336' }} />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {branchOffice?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {branchOffice?.city?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {branchOffice?.phone1}
                        <br />
                        {branchOffice?.phone2}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {branchOffice?.whatsapp1}
                        <br />
                        {branchOffice?.whatsapp2}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={tooltipTitle} open={tooltipOpen === branchOffice?.id}>
                        <Switch
                          color="primary"
                          checked={branchOffice?.is_working}
                          onChange={() => branchOffice && toggleBranchOffice(branchOffice, 'is_working')}
                          name="branch_office_working"
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={tooltipTitle} open={tooltipOpen === branchOffice?.id}>
                        <Switch
                          color="primary"
                          checked={branchOffice.is_available}
                          onChange={() => branchOffice && toggleBranchOffice(branchOffice, 'is_available')}
                          name="branch_office_available"
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {branchOffice?.is_featured ? 'Si' : 'No'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {branchOffice?.is_develop ? 'Si' : 'No'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="body2"
                      >
                        {branchOffice?.is_demo ? 'Si' : 'No'}
                      </Typography>
                    </TableCell>
                    {
                      adminsPhone.includes(user?.phone.toString()) && (
                        <TableCell>
                          <Tooltip title={tooltipTitle} open={tooltipOpen === branchOffice?.id}>
                            <Switch
                              color="primary"
                              checked={branchOffice.is_verified}
                              onChange={() => branchOffice
                                && toggleBranchOffice(branchOffice, 'is_verified')}
                              name="branch_office_verified"
                            />
                          </Tooltip>
                        </TableCell>
                      )
                    }
                    <TableCell>
                      {
                        branchOffice?.creator?.first_name
                          ? (
                            <Typography
                              color="textPrimary"
                              variant="body2"
                            >
                              {`${branchOffice?.creator?.first_name} ${branchOffice?.creator?.last_name}`}
                            </Typography>
                          ) : (
                            <Typography
                              color="textPrimary"
                              variant="body2"
                            >
                              Sin registrar
                            </Typography>
                          )
                      }
                    </TableCell>
                    <TableCell>
                      {
                        branchOffice?.assessor?.first_name
                          ? (
                            <Typography
                              color="textPrimary"
                              variant="body2"
                            >
                              {`${branchOffice?.assessor?.first_name} ${branchOffice?.assessor?.last_name}`}
                            </Typography>
                          ) : (
                            <Typography
                              color="textPrimary"
                              variant="body2"
                            >
                              Sin registrar
                            </Typography>
                          )
                      }
                    </TableCell>
                    <TableCell className={classes?.statusCell}>
                      <MoreVertIcon
                        className={classes?.moreVert}
                        onClick={(e) => onClick(e, branchOffice)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={branchOffices.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          labelRowsPerPage="Filas por página"
        />
      </Card>
      {
        panel !== null && (
          <div
            className={classes?.statusPanel}
            style={{
              top: panelPosition?.y,
              left: panelPosition?.x - 120
            }}
          >
            <ul className={classes?.statusPanelList}>
              <li className={classes?.statusPanelItem}>
                <a onClick={() => redirect('edit')}>
                  Editar
                </a>
              </li>
              {
                isSeller(user) && (
                  <li className={classes?.statusPanelItem}>
                    <a onClick={associate}>
                      Asociar
                    </a>
                  </li>
                )
              }
              <li className={classes?.statusPanelItem}>
                <a onClick={() => redirect('drivers')}>
                  Ver repartidores
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a onClick={downloadReport}>
                  Descargar reporte
                </a>
              </li>
              <li className={classes?.statusPanelItem}>
                <a
                  href={`https://recaudo.adomi.app/checkout/${billingCode}`}
                >
                  Ir al recaudo
                </a>
              </li>
            </ul>
          </div>
        )
      }
    </>
  );
};

Results.propTypes = {
  className: PropTypes.func,
  onRefresh: PropTypes.func,
  setTotalBrancOffices: PropTypes.func,
  data: PropTypes.array.isRequired
};

Results.propTypes = {
  className: () => { },
  onRefresh: () => null,
};

export default Results;
