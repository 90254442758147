import React, { useState, useEffect } from 'react';
import {
  Box,
  Container, Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useParams } from 'react-router-dom';
import ClientService from '../../../services/ClientService';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem',
  },
  saveIcon: {
    color: 'white',
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddressesView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [addresses, setAddresses] = useState([]);
  const [client, setClient] = useState([]);

  const getAddresses = async () => {
    const response = await ClientService.getById(id);

    if (response?.code === 200) {
      setAddresses(response?.data?.addresses);
      setClient(response?.data);
    }
  };

  useEffect(() => {
    getAddresses();
  }, []);

  useEffect(() => {
    if (id && id?.length > 0) {
      getAddresses();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Direcciones"
    >
      <Container maxWidth={false}>
        <Toolbar id={id} />
        <Box mt={3}>
          <Box p={2}>
            <Grid item container alignItems="center">
              <Typography variant="h4" component="h2" className={classes?.categoryTitle}>
                { `Direcciones del cliente ${client?.first_name} ${client?.last_name}` }
              </Typography>
            </Grid>
          </Box>
          <Results data={addresses} />
        </Box>
      </Container>
    </Page>
  );
};

export default AddressesView;
