import React from 'react';
import 'react-tabs-scrollable/dist/rts.css';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box, makeStyles, Typography
} from '@material-ui/core';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons';
import { Tabs, Tab } from 'react-tabs-scrollable';

const useStyles = makeStyles(() => ({
  tab: {
    whiteSpace: 'normal',
    color: '#009739',
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    borderRadius: '10px',
    padding: '7px 15px',
    backgroundColor: '#e8f1f6',
    border: 'none',
    cursor: 'pointer',
    maxWidth: '160px',
    minWidth: '160px',
    height: 50,
    transition: '1s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;'
    }
  },
  tabActive: {
    backgroundColor: '#009739',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
    color: '#fff'
  },
  totalCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#009739',
    padding: '5px',
    borderRadius: '100px',
    minHeight: '30px',
    minWidth: '30px',
    color: 'white',
    transition: '1s',
  },
  totalCircleActive: {
    backgroundColor: '#e0336d',
  },
  bounce: {
    animation: '$bounce 1s infinite both'
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)'
    },
    '40%': {
      transform: 'translateY(-30px)'
    },
    '60%': {
      transform: 'translateY(-15px)'
    },
  },
}));

/**
  * TabFilters es un componente que me permite hacer todo el proceso de filtrado de datos,
  dependiendo del tab seleccionado, un ejemplo es que para cada Tab tenga como referencia un estado,
  y así me permita poder filtrar los datos a travez de su estado.
  * Para el buen funcionamiento del componente deben pasarle por las props el estado filters,
  selectedFilter y la función onChange.
  @Props
  * haveCountData: Si desea que cada Tab tenga un contador de cuantas existencias hay de esos
  datos por ese filtro asociado, envie un true.
  * haveAnimationEvent: Si desea que cada Tab realice una animación cada que se le alerte,
  envie un true
  * selectedFilter: Un objeto que me indica cual es el Tab actualmente seleccionado.
  su formato es el siguiente:
  {
    *key: Un valor unico de tipo number. Ej: 1.
    *value: Un valor de tipo string, el cual es el que cada uno de los Tabs tiene como referencia,
    para poder filtrar los datos. Ej: 'pending_to_be_sent'.
    *title: Un valor de tipo string, el cual simplemente muestra de forma grafica el titulo del Tab
    *total: El contador de cuantas existencias hay de esos datos con ese valor asociado.
    solo debe mandar este campo si el componente tiene la propiedad havenCountData en true
    *alert: Un booleano que me permite iniciar la animación del tab.
    solo debe mandar este campo si el componente tiene la propiedad haveEventAnimation en true
  }
  *onChange: Esta función detecta el filtro seleccionado, devolviendo su valor asociado.
  *filters: Es un array de objetos que contiene la información de cada uno de los tabs.
*/
const TabFilters = ({
  classTab, // Estilos del Tab
  classTabActive, // Estilos del Tab cuando esta seleccionado
  classAnimation, // Animation de alerta
  classCount, // Estilos del contador de existencias
  classCountActive, // Estilos del contador de existencias cuando esta seleccionado
  haveCountData,
  haveAnimationEvent,
  filters,
  selectedFilter,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      px={2}
    >
      <Tabs
        activeTab={0}
        onTabClick={() => {}}
        leftBtnIcon={(
          <ArrowBackIosRounded
            style={{
              fontSize: 18,
              color: '#009739',
            }}
          />
        )}
        rightBtnIcon={<ArrowForwardIosRounded style={{ fontSize: 18, color: '#009739' }} />}
      >
        {filters.map((filter) => {
          return (
            <Tab
              key={filter?.key}
              className={clsx(
                classes.tab,
                {
                  [classes.tabActive]: selectedFilter?.value === filter?.value
                  || (haveAnimationEvent && filter?.alert),
                  [classes.bounce]: haveAnimationEvent && filter?.alert
                },
                classTab,
                {
                  [classTabActive]: selectedFilter?.value === filter?.value
                  || (haveAnimationEvent && filter?.alert),
                  [classAnimation]: haveAnimationEvent && filter?.alert
                }
              )}
              mx={0.7}
              my={1}
              onClick={() => onChange(filter)}
            >
              {haveCountData && (
                <Box
                  className={clsx(
                    classes.totalCircle,
                    {
                      [classes.totalCircleActive]: selectedFilter?.value === filter?.value
                      || filter?.alert
                    },
                    classCount,
                    {
                      [classCountActive]: selectedFilter?.value === filter?.value || filter?.alert
                    }
                  )}
                >
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 13
                    }}
                    variant="body2"
                  >
                    {filter?.total}
                  </Typography>
                </Box>
              )}
              <Typography
                variant="body2"
                style={{
                  fontWeight: 500,
                  fontSize: 13
                }}
              >
                {filter.title}
              </Typography>
            </Tab>
          );
        })}
      </Tabs>
    </Box>
  );
};

TabFilters.propTypes = {
  classTab: PropTypes.string,
  classTabActive: PropTypes.string,
  classAnimation: PropTypes.string,
  classCount: PropTypes.string,
  classCountActive: PropTypes.string,
  haveCountData: PropTypes.bool,
  haveAnimationEvent: PropTypes.bool,
  filters: PropTypes.array,
  selectedFilter: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number,
    alert: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};

TabFilters.defaultProps = {
  classTab: '',
  classTabActive: '',
  classAnimation: '',
  classCount: '',
  classCountActive: '',
  haveCountData: false,
  haveAnimationEvent: false,
  filters: [],
  selectedFilter: {
    key: 0,
    value: '',
    title: '',
    total: 0,
    alert: false,
  },
  onChange: () => {},
};

export default TabFilters;
