import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles, FormControl, InputLabel, Select, MenuItem, Typography
} from '@material-ui/core';
import CurrencyFormat from 'react-currency-format';
import TermsAndConditionsService from 'src/services/TermsAndConditionsService';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import CouponService from '../../../services/CouponService';
import BrandService from '../../../services/BrandService';
import BranchOfficeService from '../../../services/BranchOfficeService';
import ClientService from '../../../services/ClientService';
import CityService from '../../../services/CityService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  },
  menuItem: {
    display: 'flex',
    flexFlow: 'column',
    whiteSpace: 'normal',
    paddingBottom: '.5rem',
    '& p': {
      width: '100%',
      display: 'block'
    }
  }
}));

const CreateCouponForm = ({
  className, coupon, edit, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [brandSelected, setBrandSelected] = useState('');
  const [brands, setBrands] = useState([]);
  const [branchOfficeSelected, setBranchOfficeSelected] = useState('');
  const [branchOffices, setBranchOffices] = useState([]);
  const [clientSelected, setClientSelected] = useState('');
  const [clients, setClients] = useState([]);
  const [termSelected, setTermSelected] = useState('');
  const [terms, setTerms] = useState([]);
  const [citySelected, setCitySelected] = useState('');
  const [cities, setCities] = useState([]);

  const handleChange = (event) => {
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const translateType = (type) => {
    switch (type) {
      case 'amount':
        return 'Monto';
      case 'percentage':
        return 'Porcentaje';
      case 'delivery':
        return 'Domicilio gratis';
      default: return '';
    }
  };

  const handleBrand = (event) => {
    setBrandSelected(event.target.value);
    handleChange(event);
  };

  const handleClient = (event) => {
    setClientSelected(event.target.value);
    handleChange(event);
  };

  const handleBranchOffice = (event) => {
    setBranchOfficeSelected(event.target.value);
    handleChange(event);
  };

  const handleTerm = (event) => {
    setTermSelected(event.target.value);
    handleChange(event);
  };

  const handleCity = (event) => {
    setCitySelected(event.target.value);
    handleChange(event);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      branch_office_id: e.target?.branch_office_id?.value || null,
      brand_id: e.target?.brand_id?.value || null,
      client_id: e.target?.client_id?.value || null,
      description: e.target?.description?.value,
      key: e.target?.key?.value,
      title: e.target?.title?.value || e.target?.key?.value,
      type: e.target?.type?.value,
      value: Number((e.target?.value?.value).replace('.', '')),
      max: Number(e.target?.max?.value),
      started_at: e.target?.started_at?.value,
      expired_at: e.target?.expired_at?.value,
      term_and_conditions_id: e.target?.term_and_conditions_id?.value || null,
      city_id: e.target?.city_id?.value || null
    };

    if (data?.description?.length === 0) {
      errors.push('La descripción es obligatoria');
    }

    if (data?.key?.length === 0) {
      errors.push('El código es obligatorio');
    }

    if (data?.type?.length === 0) {
      errors.push('El tipo del cupón es obligatorio');
    }

    if (data?.value?.length === 0) {
      errors.push('El valor es obligatorio');
    }
    if (data?.value < 0) {
      errors.push('El valor no pueder ser menor que 0');
    }

    if (data?.value === 0 && data?.type !== 'delivery') {
      errors.push('El valor del cupón no puede ser 0');
    }

    if (data?.max === 0) {
      errors.push('El maximo de usos es obligatorio');
    }
    if (data?.max < 0) {
      errors.push('Maximo usado, no puede ser menor a 0');
    }

    if (data?.started_at?.length === 0) {
      errors.push('La fecha de inicio es obligatoria');
    }

    if (data?.expired_at?.length === 0) {
      errors.push('La fecha de expiración es obligatoria');
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await CouponService.update(coupon?.id, data)
      : await CouponService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/cupones');
    } else {
      setErrors(response.errors);
    }
  };

  const deleteCoupon = async () => {
    if (coupon?.id) {
      const response = await CouponService.remove(coupon?.id);
      if (!response) {
        navigate('/app/cupones', { replace: true });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await BrandService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las marcas']);
        return;
      }

      setBrands(response.data);
    })();
  }, []);

  useEffect(() => {
    setBrandSelected(coupon?.brand_id);
  }, [coupon?.brand_id]);

  useEffect(() => {
    (async () => {
      const response = await BranchOfficeService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las sucursales']);
        return;
      }

      setBranchOffices(response?.data);
    })();
  }, []);

  useEffect(() => {
    setBranchOfficeSelected(coupon?.branch_office_id);
  }, [coupon?.branch_office_id]);

  useEffect(() => {
    (async () => {
      const response = await ClientService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar los clientes']);
        return;
      }

      setClients(response?.data);
    })();
  }, []);

  useEffect(() => {
    setClientSelected(coupon?.client_id);
  }, [coupon?.client_id]);

  useEffect(() => {
    (async () => {
      const response = await TermsAndConditionsService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar los terminos y condiciones ']);
        return;
      }

      setTerms(response?.data);
    })();
  }, []);

  useEffect(() => {
    setTermSelected(coupon?.term_and_conditions_id);
  }, [coupon?.term_and_conditions_id]);

  useEffect(() => {
    (async () => {
      const response = await CityService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar las ciudades']);
        return;
      }

      setCities(response?.data);
    })();
  }, []);

  useEffect(() => {
    setCitySelected(coupon?.city_id);
  }, [coupon?.city_id]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Cupón"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el titulo"
                label="Titulo"
                name="title"
                onChange={handleChange}
                required
                value={coupon?.title}
                variant="outlined"
              />
            </Grid>
            {
              edit ? <div /> : (
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Escriba la descripción"
                    label="Descripción"
                    name="description"
                    onChange={handleChange}
                    required
                    value={coupon?.description}
                    variant="outlined"
                  />
                </Grid>
              )
            }
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el código"
                label="Código"
                name="key"
                onChange={handleChange}
                required
                value={coupon?.key}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="route">Seleccione el tipo</InputLabel>
                <Select
                  required
                  name="type"
                  labelId="tipo"
                  label="Selecciona el tipo"
                  value={coupon?.type}
                  onChange={handleChange}
                >
                  <MenuItem value="0" />
                  {
                    ['amount', 'percentage', 'delivery'].map((type) => (
                      <MenuItem key={type} value={type}>
                        {translateType(type)}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <CurrencyFormat
                thousandSeparator="."
                decimalSeparator=","
                customInput={TextField}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el valor"
                label="Valor"
                name="value"
                required
                value={coupon?.type === 'delivery' ? 0 : coupon?.value}
                variant="outlined"
                disabled={coupon?.type === 'delivery'}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el máximo de usos"
                label="Máximo de usos"
                name="max"
                onChange={handleChange}
                required
                value={coupon?.max}
                variant="outlined"
                type="Number"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="Fecha de inicio"
                name="started_at"
                onChange={handleChange}
                required
                value={coupon?.started_at.substring(0, 10)}
                variant="outlined"
                type="date"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="Fecha de expiración"
                name="expired_at"
                onChange={handleChange}
                required
                value={coupon?.expired_at.substring(0, 10)}
                variant="outlined"
                type="date"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="ciudad">Seleccione la ciudad</InputLabel>
                <Select
                  name="city_id"
                  labelId="ciudad"
                  label="Seleccione una ciudad"
                  value={citySelected}
                  onChange={handleCity}
                >
                  <MenuItem value="0" />
                  {
                    cities?.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="marca">Seleccione una marca</InputLabel>
                <Select
                  name="brand_id"
                  labelId="marca"
                  label="Selecciona una marca"
                  value={brandSelected}
                  onChange={handleBrand}
                >
                  <MenuItem value="0" />
                  {
                    brands.map((brand) => (
                      <MenuItem key={brand?.id} value={brand?.id}>
                        {brand?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="sucursal">Seleccione una sucursal</InputLabel>
                <Select
                  name="branch_office_id"
                  labelId="sucursal"
                  label="Selecciona una sucursal"
                  value={branchOfficeSelected}
                  onChange={handleBranchOffice}
                >
                  <MenuItem value="0" />
                  {
                    branchOffices?.map((branchOffice) => (
                      <MenuItem key={branchOffice?.id} value={branchOffice?.id}>
                        {branchOffice?.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="cliente">Seleccione un cliente</InputLabel>
                <Select
                  name="client_id"
                  labelId="cliente"
                  label="Selecciona un cliente"
                  value={clientSelected}
                  onChange={handleClient}
                >
                  {
                    clients.map((client) => (
                      <MenuItem key={client?.id} value={client?.id} className={classes?.menuItem}>
                        <Typography variant="caption" component="p">
                          {`${client?.phone}`}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {`${client?.first_name} ${client?.last_name}`}
                        </Typography>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="terminos-y-condiciones">Seleccione terminos y condiciones</InputLabel>
                <Select
                  name="term_and_conditions_id"
                  labelId="termino-y-condicion"
                  label="Seleccione terminos y condiciones"
                  value={termSelected}
                  onChange={handleTerm}
                >
                  <MenuItem value="0" />
                  {
                    terms?.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.title}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          {
            edit && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            {edit ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteCoupon} />
    </form>
  );
};

CreateCouponForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  coupon: PropTypes.shape({
    id: PropTypes.string,
    branch_office_id: PropTypes.string,
    brand_id: PropTypes.string,
    client_id: PropTypes.string,
    description: PropTypes.string,
    key: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.number,
    started_at: PropTypes.string,
    expired_at: PropTypes.string,
    max: PropTypes.number,
    term_and_conditions_id: PropTypes.string,
    city_id: PropTypes.string
  })
};

CreateCouponForm.defaultProps = {
  className: '',
  edit: false,
  onChange: () => { },
  coupon: {
    id: null,
    branch_office_id: '',
    brand_id: '',
    client_id: '',
    description: '',
    key: '',
    title: '',
    type: '',
    value: '',
    started_at: '',
    expired_at: '',
    max: '',
    term_and_conditions_id: '',
    city_id: ''
  },
};

export default CreateCouponForm;
