import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: '3rem',
    maxHeight: '3rem',
    borderRadius: '10rem'
  }
}));

const Logo = ({ className }) => {
  const classes = useStyles();
  return (
    <img
      alt="Logo"
      src="/static/images/logo.jpg"
      className={clsx(classes.logo, className)}
    />
  );
};

Logo.defaultProps = {
  className: '',
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
