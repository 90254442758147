import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import Toolbar from './Toolbar';
import Results from './Results';
import ZonesService from '../../../services/ZonesService';
import { selector as UserSelector } from '../../../redux/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ZonaList = () => {
  const classes = useStyles();
  const [zonas, setZonas] = useState([]);
  const { user } = useSelector(UserSelector);

  const getZones = async () => {
    const fetchData = await ZonesService.getAll();

    if (fetchData?.code === 200) {
      setZonas(fetchData.data.filter((zone) => zone.name !== 'NO DEFINIDA'));
    }
  };

  const refresh = async () => {
    if (user?.id) {
      await getZones();
    }
  };

  useEffect(() => {
    getZones();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Zonas"
    >
      <Container maxWidth={false}>
        <Toolbar refresh={refresh} />
        <Box mt={3}>
          <Results data={zonas} onRefresh={refresh} />
        </Box>
      </Container>
    </Page>
  );
};

export default ZonaList;
