import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  Typography,
  Container,
  makeStyles,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Divider,
  // Button
} from '@material-ui/core';
// import MaterialTable from 'material-table';
// import dayjs from 'dayjs';
import './index.css';
import Papa from 'papaparse';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import Page from 'src/components/Page';

import { selector as UserSelector } from '../../../redux/ducks/user';
import OrderService from '../../../services/OrderService';
import CityService from '../../../services/CityService';

registerLocale('es', es);

const useStyles = makeStyles((theme) => ({
  categoryTitle: {
    display: 'inline-block',
    marginRight: '1rem'
  },
  titleEditable: {
    fontSize: '2.18rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.167,
    letterSpacing: '-0.24px',
    color: '#888',
    marginBottom: '.5rem'
  },
  saveIcon: {
    color: 'white'
  },
  editButton: {
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  select: {
    width: '100%'
  }
}));

const GeneralReportView = () => {
  const classes = useStyles();
  const [downloading, setDownloading] = useState(false);
  const [seeReport, setSeeReport] = useState(false);
  const { user } = useSelector(UserSelector);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [citySelected, setCitySelected] = useState('');
  const [cities, setCity] = useState([]);

  const [dataReport, setDataReport] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [rejectedList, setRejectedList] = useState(0);
  const [cancelledList, setCancelledList] = useState(0);
  const [finishedList, setFinishedList] = useState(0);

  const handleCity = (event) => {
    const cityId = event.target.value;
    setCitySelected(cityId);
  };

  // date to yyyy-mm-dd
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) { month = `0${month}`; }
    if (day.length < 2) { day = `0${day}`; }

    return [year, month, day].join('-');
  };

  const translateStatus = (status) => {
    switch (status) {
      case 'cancelled':
        return 'Cancelada';
      case 'rejected':
        return 'Rechazada';
      case 'finished':
        return 'Finalizada';
      default:
        return status;
    }
  };

  const getStatistics = (data) => {
    let subTotalSum = 0;
    data.forEach((row) => {
      row.forEach((column) => {
        if (row[4] === column) {
          subTotalSum += Number(column);
        }
      });
    });
    const subTotalSumDeci = new Intl.NumberFormat(es).format(subTotalSum);
    setSubTotal(subTotalSumDeci);
  };

  const estados = (data) => {
    const status = {
      cancelled: 0,
      finished: 0,
      rejected: 0,
    };
    data.forEach((row) => {
      row.forEach((column) => {
        switch (column) {
          case 'cancelled':
            status.cancelled++;
            break;
          case 'rejected':
            status.rejected++;
            break;
          case 'finished':
            status.finished++;
            break;
          default:
            status.finished += 0;
        }
      });
    });
    setCancelledList(status.cancelled);
    setFinishedList(status.finished);
    setRejectedList(status.rejected);
  };

  const getTotal = (data) => {
    let totalSum = 0;
    data.forEach((row) => {
      row.forEach((column) => {
        if (row[2] === column) {
          totalSum += Number(column);
        }
      });
    });
    const totalSumDeci = new Intl.NumberFormat(es).format(totalSum);
    setTotal(totalSumDeci);
  };

  const onDownload = async () => {
    setDownloading(true);
    const fromDate = formatDate(startDate);
    const toDate = formatDate(endDate);
    const body = {
      branch_office_id: null,
      city_id: (citySelected === '') ? null : citySelected,
      brand_id: null,
      from_date: fromDate,
      to_date: toDate,
      is_develop: false,
      is_demo: false,
    };
    const CSVdata = await OrderService.generalReport(body);
    fileDownload(CSVdata, `General_report_${fromDate}_${toDate}.csv`);
    setDownloading(false);
  };

  const onSee = async () => {
    setSeeReport(true);
    const fromDate = formatDate(startDate);
    const toDate = formatDate(endDate);
    const body = {
      branch_office_id: null,
      city_id: (citySelected === '') ? null : citySelected,
      brand_id: null,
      from_date: fromDate,
      to_date: toDate,
      is_develop: false,
      is_demo: false,
    };
    const CSVdata = await OrderService.generalReport(body);
    const csvToJson = Papa.parse(CSVdata);
    const dataFinalReport = csvToJson.data;
    dataFinalReport.shift();
    setDataReport(dataFinalReport);
    getStatistics(dataFinalReport);
    estados(dataFinalReport);
    getTotal(dataFinalReport);
    setSeeReport(false);
  };

  useEffect(() => {
    setCitySelected('');

    (async () => {
      const response = await CityService.getAll();

      if (response?.code !== 200) {
        console.error(['Ocurrió un error al intentar mostrar las ciudades']);
        return;
      }

      setCity(response.data);
    })();
  }, [user]);

  return (
    <Page className={classes.root} title="Reportes operacionales">
      <Container maxWidth={false}>
        <Card>
          <Box p={3}>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Typography variant="h4">
                  Reportes operacionales
                  {' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12} md={3}>
                <Box my={5}>
                  <FormControl
                    required
                    variant="outlined"
                    className={classes?.select}
                  >
                    <InputLabel id="ciudad">Seleccione una ciudad</InputLabel>
                    <Select
                      required
                      name="city_id"
                      labelId="ciudad"
                      label="Selecciona una ciudad"
                      value={citySelected}
                      onChange={handleCity}
                    >
                      {
                        cities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid xs={12} md={12}>
                <div className="date-container">
                  <div className="date-1">
                    <h3>Fecha de inicio:</h3>
                    <DatePicker className="pickers" selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yyyy/MM/dd" />
                  </div>
                  <div className="date-2">
                    <h3>Fecha final:</h3>
                    <DatePicker className="pickers" selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="yyyy/MM/dd" />
                  </div>
                </div>
                <div className="buttonrepo-container">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={seeReport}
                    onClick={onSee}
                  >
                    Ver Reporte
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={downloading}
                    onClick={onDownload}
                  >
                    {downloading ? 'Descargando...' : 'Descargar'}
                  </Button>
                </div>
              </Grid>
              <Divider />
              <Grid item xs={12} md={6}>
                <Box minWidth={1020}>
                  <div className="info-container">
                    <div className="total-container">
                      <div className="total-box">
                        <h3>Total:</h3>
                        <p>{`$${total}`}</p>
                      </div>
                      <div className="subtotal-box">
                        <h3>Subtotal:</h3>
                        <p>{`$${subTotal}`}</p>
                      </div>
                    </div>
                    <div className="order-container">
                      <div className="finished-box order-box">
                        <h3>Ordenes finalizadas:</h3>
                        <p>{finishedList}</p>
                      </div>
                      <div className="rejected-box order-box">
                        <h3>Ordenes rechazadas:</h3>
                        <p>{rejectedList}</p>
                      </div>
                      <div className="cancelled-box order-box">
                        <h3>Ordenes canceladas:</h3>
                        <p>{cancelledList}</p>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box minWidth={1020}>
                  <Table className="table">
                    <TableHead>
                      <TableRow>
                        <TableCell width="150">
                          Creado
                        </TableCell>
                        <TableCell>
                          Referencia
                        </TableCell>
                        <TableCell>
                          Total
                        </TableCell>
                        <TableCell>
                          Cupón
                        </TableCell>
                        <TableCell>
                          Subtotal
                        </TableCell>
                        <TableCell>
                          Costo Delivery
                        </TableCell>
                        <TableCell>
                          Estado
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataReport.map((row) => (
                        <TableRow>
                          <TableCell key={[row[0]]}>
                            <Typography>
                              {formatDate(row[0])}
                            </Typography>
                          </TableCell>
                          {row.slice(1, row.length - 1).map((column) => (
                            <TableCell key={[column]}>
                              <Typography>
                                {translateStatus(column)}
                              </Typography>
                            </TableCell>
                          ))}
                          <TableCell key={[row[row.length - 1]]}>
                            <Typography>
                              {translateStatus(row[row.length - 1])}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default GeneralReportView;
