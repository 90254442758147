import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CountryCardPreview from './CountryCardPreview';
import CreateCountryForm from './CreateCountryForm';
import CountryService from '../../../services/CountryService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateCountryView = ({ edit }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [hasImage, setHasImage] = useState(false);
  const [country, setCountry] = useState({
    id: null,
    first_name: '',
    flag: '',
    code: '',
    acronym: '',
  });

  const handleCountryAttributes = (values) => {
    setCountry(values);
  };

  const handleUploadImage = () => setHasImage(true);

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await CountryService.getById(id);
        if (response?.code === 200) {
          setCountry({
            id: response.data.id,
            name: response.data.name,
            flag: response.data.flag,
            code: Number(response.data.code),
            acronym: response.data.acronym,
          });
          setHasImage(true);
        }
      })();
    }
  }, [id]);

  return (
    <Page
      className={classes.root}
      title="Crear País"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <CountryCardPreview
              country={country}
              hasImage={hasImage}
              onUpload={handleUploadImage}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateCountryForm
              edit={edit}
              hasImage={hasImage}
              country={country}
              onChange={handleCountryAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

CreateCountryView.propTypes = {
  edit: PropTypes.bool,
};

CreateCountryView.defaultProps = {
  edit: false
};

export default CreateCountryView;
