import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  Grid,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ClientCardPreview = ({ className, client, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {client?.first_name}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {client?.last_name}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
            >
              {client?.email}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
            >
              {client?.phone}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ClientCardPreview.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object,
};

ClientCardPreview.defaultProps = {
  className: '',
  client: {},
};

export default ClientCardPreview;
