import HttpRequest from './HttpRequest';
import generateEndpoint from '../utils/generateEndpoint';

export default class AdomiBIService extends HttpRequest {
  static async countRegisteredUsers() {
    this.setMicroservice('adomi-bi');
    this.endpoint = generateEndpoint('users/count-registered', 'cityId');
    const response = await this.get();
    return response;
  }
}
