import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Box, Button } from '@material-ui/core';
import store from 'src/redux/store';
import CurrencyFormat from 'react-currency-format';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import './PolygonDetailModal.css';
import PolygonService from 'src/services/PolygonService';
import useErrors from '../hooks/useErrors';

const RadioComponent = ({
  id,
  value,
  label,
  name,
  checked,
  onChange,
  index,
  disabled
}) => {
  return (
    <div className="radioButton">
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e, index)}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const PolygonDetailModal = ({
  polygon,
  toogleModal,
  onChange,
  onChangeSchedule,
  onEditInfo,
  showWarning
}) => {
  const incrementOptions = [0, 0.1, 0.2, 0.3];
  const cityId = store.getState()?.app?.city;
  const { setErrors } = useErrors();
  const [localErrors, setLocalErrors] = useState([]);

  const handleChange = (e) => {
    onChange({
      [e.target.name]: e.target.value
    });
  };

  const handleIncrement = (e, index) => {
    onChangeSchedule(e.target.value, index);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (cityId === 'general') {
      showWarning(
        true,
        'Debe seleccionar una ciudad para poder editar el poligono'
      );
      return;
    }

    const errors = [];
    const data = {
      id: e.target.id.value,
      city_id: polygon.city_id,
      base_cost: Number(e.target.base_cost.value.replace('.', '')),
      prefix: e.target.prefix.value,
      alias: e.target.alias.value,
      points: polygon.points,
      schedule: JSON.stringify(polygon.schedule),
      is_available: polygon.is_available
    };

    if (data.base_cost === 0) {
      errors.push({
        field: 'base_cost',
        error: 'El costo base es obligatorio'
      });
    }

    if (data.prefix.length === 0) {
      errors.push({ field: 'prefix', error: 'El prefix es obligatorio' });
    }

    if (data.alias.length === 0) {
      errors.push({ field: 'alias', error: 'El alias es obligatorio' });
    }

    if (errors.length > 0) {
      setErrors(errors.map((err) => err.error));
      setLocalErrors(errors);
      return;
    }

    try {
      const response = await PolygonService.update(polygon.id, data);
      console.log(response);
    } catch (err) {
      setErrors([err.message]);
      return;
    }
    setErrors([]);
    onEditInfo({ ...data, schedule: polygon.schedule });
    toogleModal();
  };

  return (
    <div className="PolygonDetailModal">
      <div className="pane" onClick={() => toogleModal()} />
      <div className="modal">
        <div className="header">
          <div className="title">
            <h3>Detalles del poligono</h3>
            <CancelRoundedIcon
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => toogleModal()}
            />
          </div>
        </div>

        <div className="body">
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Id poligono"
                  label="Id poligono"
                  name="id"
                  disabled
                  onChange={handleChange}
                  value={polygon.id}
                  variant="outlined"
                />
              </Grid>

              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={cityId === 'general'}
                  error={localErrors.some((e) => e.field === 'prefix')}
                  helperText={
                    localErrors.find((e) => e.field === 'prefix')?.error || ''
                  }
                  placeholder="Prefix"
                  label="Prefix"
                  name="prefix"
                  onChange={handleChange}
                  value={polygon.prefix}
                  variant="outlined"
                  required
                />
              </Grid>

              <Grid item sm={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={cityId === 'general'}
                  error={localErrors.some((e) => e.field === 'alias')}
                  helperText={
                    localErrors.find((e) => e.field === 'alias')?.error || ''
                  }
                  placeholder="Alias"
                  label="Alias"
                  name="alias"
                  onChange={handleChange}
                  value={polygon.alias}
                  variant="outlined"
                  required
                />
              </Grid>

              <Grid item sm={4} xs={12}>
                <CurrencyFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  customInput={TextField}
                  fullWidth
                  disabled={cityId === 'general'}
                  error={localErrors.some((e) => e.field === 'base_cost')}
                  helperText={
                    localErrors.find((e) => e.field === 'base_cost')?.error ||
                    ''
                  }
                  placeholder="Costo base"
                  label="Costo base"
                  name="base_cost"
                  onChange={handleChange}
                  value={polygon.base_cost === null ? '' : polygon.base_cost}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <h4
                    style={{
                      fontSize: '20px',
                      fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
                    }}
                  >
                    Horario
                  </h4>
                  {polygon.schedule.map((item, index) => (
                    <div className="container-schedule">
                      <div className="schedule">
                        <p style={{ marginRight: '10px' }}>
                          <span>{item.start}</span>
                        </p>
                        -
                        <p style={{ margin: '0 10px' }}>
                          <span>{item.end}</span>
                        </p>
                      </div>
                      <div className="radioGroup">
                        {incrementOptions.map((option, i) => (
                          <RadioComponent
                            disabled={cityId === 'general'}
                            index={index}
                            onChange={handleIncrement}
                            name={`increment_${index}`}
                            id={`${index}_${i}`}
                            value={option}
                            label={`${option * 100}%`}
                            checked={Number(item.increment) === option}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" paddingTop={3}>
              <Box mr={2}>
                <Button onClick={() => toogleModal()}>Cancelar</Button>
              </Box>
              <Button type="submit" color="primary" variant="contained">
                Guardar
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

PolygonDetailModal.propTypes = {
  polygon: PropTypes.shape({
    id: PropTypes.string,
    city_id: PropTypes.string,
    base_cost: PropTypes.number,
    prefix: PropTypes.string,
    alias: PropTypes.string,
    points: PropTypes.array,
    schedule: PropTypes.array,
    is_available: PropTypes.bool
  }),
  toogleModal: PropTypes.func,
  onChange: PropTypes.func,
  onChangeSchedule: PropTypes.func,
  onEditInfo: PropTypes.func,
  showWarning: PropTypes.func
};

RadioComponent.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  index: PropTypes.number,
  disabled: PropTypes.bool
};

export default PolygonDetailModal;
