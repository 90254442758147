import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ClientCardPreview from './ClientCardPreview';
import CreateClientForm from './CreateClientForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CreateClientView = () => {
  const classes = useStyles();
  const [client, setClient] = useState({
    id: null,
    country_id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    birthdate: '',
    is_develop: '',
    gender: '',
  });

  const handleClientAttributes = (values) => {
    setClient({
      ...client,
      ...values
    });
  };

  return (
    <Page
      className={classes.root}
      title="Crear Cliente"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <ClientCardPreview
              client={client}
            />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <CreateClientForm
              client={client}
              onChange={handleClientAttributes}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateClientView;
