import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  Grid,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles, InputLabel, Select, MenuItem, FormControl
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  moreIcon: {
    cursor: 'pointer',
    marginLeft: '1rem'
  },
  categoryButton: {
    marginRight: theme.spacing(1)
  },
  formControl: {
    flex: 1,
    width: '100%',
  }
}));

const Toolbar = ({
  className,
  onChange,
  handleSearch,
  ...rest
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange(e?.target?.value);
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container justify="space-between">
              <Grid item xs={12} md={6}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Buscar aliado prospecto"
                    variant="outlined"
                    onChange={(event) => handleSearch(event?.target?.value)}
                  />
                </Box>
              </Grid>
              <Grid container item xs={12} md={5} justify="flex-end" alignItems="center">
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="status">Estado</InputLabel>
                  <Select
                    required
                    name="status"
                    labelId="Status"
                    label="Selecciona una estado"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      Todos
                    </MenuItem>
                    <MenuItem value="pending">
                      Pendiente
                    </MenuItem>
                    <MenuItem value="in_process">
                      En proceso
                    </MenuItem>
                    <MenuItem value="rejected">
                      Rechazado
                    </MenuItem>
                    <MenuItem value="accepted">
                      Aceptado
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  handleSearch: PropTypes.func,
};

Toolbar.defaultProps = {
  className: '',
  onChange: () => null
};

export default Toolbar;
