import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  FormControl,
  TextField,
  InputLabel,
  makeStyles, MenuItem
} from '@material-ui/core';
import adminsPhone from 'src/utils/adminsPhone';
import { selector as UserSelector } from 'src/redux/ducks/user';
import CurrencyFormat from 'react-currency-format';
import Errors from '../../../components/Errors';
import ConfirmationModal from '../../../components/ConfirmationModal';
import GoogleMapsModal from '../../../components/GoogleMapsModal';
import useErrors from '../../../hooks/useErrors';
import useConfirmation from '../../../hooks/useConfirmation';
import CityService from '../../../services/CityService';
import StateService from '../../../services/StateService';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    }
  }
}));

const CreateCityForm = ({
  className, city, edit, onChange, ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useSelector(UserSelector);
  const { setErrors } = useErrors();
  const { toggleModal } = useConfirmation();
  const [stateSelected, setStateSelected] = useState('');
  const [states, setStates] = useState([]);
  const [googleMapsOpen, setGoogleMapsOpen] = useState(false);
  const [googleMapCenter, setGoogleMapCenter] = useState({ lat: 3.4188976, lng: -76.5444095 });

  const handleChange = (event) => {
    onChange({
      ...city,
      [event.target.name]: event.target.value
    });
  };

  const handleState = (event) => {
    setStateSelected(event.target.value);
    handleChange(event);
  };

  const handleMarkerPosition = (latitude, longitude) => {
    onChange({
      latitude,
      longitude
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    const data = {
      name: e.target?.name?.value,
      state_id: e.target?.state_id?.value === '0' ? null : e.target?.state_id?.value,
      latitude: Number(e.target?.latitude?.value),
      longitude: Number(e.target?.longitude?.value),
      cost_additional_km: Number((e.target?.cost_additional_km?.value)?.replace('.', '')),
      base_km: Number((e.target?.base_km?.value)?.replace('.', '')),
      is_available: e.target?.is_available?.value,
      is_coming_soon: e.target?.is_coming_soon?.value,
      slug: e?.target?.slug?.value || '',
    };

    if (data.state_id.value === '') {
      errors.push('El campo estado es obligatorio');
    }
    if (data.name.length === 0) {
      errors.push('El nombre de la ciudad es obligatorio');
    }
    if (data.is_available.length === 0) {
      errors.push('El campo disponible es obligatorio');
    }

    if (data.name.length === 0 || data.state_id.value === '' || data.is_available.length === 0) {
      setErrors(errors);
      return;
    }

    const response = edit
      ? await CityService.update(city.id, data)
      : await CityService.create(data);

    if (response?.code === 201 || response?.code === 200) {
      setErrors([]);
      navigate('/app/ciudades', { replace: true });
    } else {
      setErrors(response?.errors);
    }
  };

  const deleteCity = async () => {
    if (city?.id) {
      const response = await CityService.delete(city.id);
      if (!response) {
        navigate('/app/ciudades', { replace: true });
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await StateService.getAll();

      if (response?.code !== 200) {
        setErrors(['Ocurrió un error al intentar mostrar los estados']);
        return;
      }

      setStates(response.data);
    })();
  }, []);

  useEffect(() => {
    if (edit && city?.id) {
      setStateSelected(city.state_id);
      setGoogleMapCenter({ lat: city?.latitude, lng: city?.longitude });
    }
  }, [edit, city]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader="La información puede ser editada"
          title="Ciudad"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="estado">Selecciona una estado</InputLabel>
                <Select
                  required
                  name="state_id"
                  labelId="estado"
                  label="Selecciona un estado"
                  value={stateSelected}
                  onChange={handleState}
                >
                  <MenuItem value="0" />
                  {
                  states.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      { state.name }
                    </MenuItem>
                  ))
                }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el nombre"
                label="Nombre"
                name="name"
                onChange={handleChange}
                required
                value={city.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Vaya al mapa"
                type="number"
                label="Latitud"
                name="latitude"
                onChange={handleChange}
                required
                disabled
                value={city?.latitude?.toString() ?? ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Vaya al mapa"
                label="Longitud"
                name="longitude"
                onChange={handleChange}
                required
                disabled
                value={city?.longitude?.toString() ?? ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <CurrencyFormat
                thousandSeparator="."
                decimalSeparator=","
                customInput={TextField}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el km base"
                label="Base km"
                name="base_km"
                onChange={handleChange}
                required
                value={city?.base_km?.toString() ?? ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <CurrencyFormat
                thousandSeparator="."
                decimalSeparator=","
                customInput={TextField}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Escriba el costo adicional por km"
                label="Costo adicional por km"
                name="cost_additional_km"
                onChange={handleChange}
                required
                value={city?.cost_additional_km?.toString() ?? ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="is_available">Está disponible</InputLabel>
                <Select
                  required
                  name="is_available"
                  labelId="Está disponible"
                  label="Selecciona una opción"
                  value={city?.is_available}
                  onChange={handleChange}
                >
                  {
                    [true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        { answer.toString() === 'true' ? 'Si' : 'No' }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <InputLabel id="is_coming_soon">Próximamente</InputLabel>
                <Select
                  required
                  name="is_coming_soon"
                  labelId="Próximamente"
                  label="Selecciona una opción"
                  value={city?.is_coming_soon}
                  onChange={handleChange}
                >
                  {
                    [true, false].map((answer) => (
                      <MenuItem key={answer} value={answer}>
                        { answer.toString() === 'true' ? 'Si' : 'No' }
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Slug"
                label="Slug"
                name="slug"
                onChange={handleChange}
                required
                value={city?.slug}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FormControl required variant="outlined" className={classes.formControl}>
                <TextField
                  rows={8}
                  fullWidth
                  multiline
                  name="polygon"
                  label="Poligono"
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Errors time={0} />
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Box mr={2}>
            <Button
              disabled={stateSelected === ''}
              onClick={() => setGoogleMapsOpen(true)}
            >
              Ver mapa
            </Button>
          </Box>
          {
            edit && adminsPhone.includes(user?.phone.toString()) && (
              <Box mr={2}>
                <Button
                  type="button"
                  onClick={() => toggleModal()}
                  className={classes.error}
                  variant="contained"
                >
                  Eliminar
                </Button>
              </Box>
            )
          }
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            { edit ? 'Editar' : 'Guardar' }
          </Button>
        </Box>
      </Card>
      <ConfirmationModal onAccept={deleteCity} />
      <GoogleMapsModal
        center={googleMapCenter}
        isOpen={googleMapsOpen}
        onAccept={() => setGoogleMapsOpen(false)}
        setPosition={handleMarkerPosition}
      />
    </form>
  );
};

CreateCityForm.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  onChange: PropTypes.func,
  city: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    state_id: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    cost_additional_km: PropTypes.number,
    base_km: PropTypes.number,
    is_available: PropTypes.bool,
    is_coming_soon: PropTypes.bool,
    slug: PropTypes.string,
  })
};

CreateCityForm.defaultProps = {
  className: '',
  edit: false,
  onChange: () => {},
  city: {
    id: null,
    name: '',
    state_id: '',
    latitude: '',
    longitude: '',
    cost_additional_km: '',
    base_km: '',
    is_available: false,
    is_coming_soon: false,
    slug: '',
  }
};

export default CreateCityForm;
