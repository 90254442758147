import HttpRequest from './HttpRequest';

export default class StateService extends HttpRequest {
  static endpoint = 'states';

  static async getAll() {
    this.endpoint = 'states?is_available=false';
    const response = await this.get();
    return response;
  }

  static async getById(id) {
    this.endpoint = 'states';
    const response = await this.get(id);
    return response;
  }

  static async create(data) {
    this.endpoint = 'states';
    const response = await this.post(data);
    return response;
  }

  static async update(id, data) {
    this.endpoint = 'states';
    const response = await this.put(id, data);
    return response;
  }
}
