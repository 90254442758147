import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Typography,
  Chip,
  Card,
  Button,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import {
  ArrowBackIosRounded,
  DirectionsBike,
  Restaurant,
  SwapHoriz
} from '@material-ui/icons';
import { getDate, getHour } from 'src/utils/formatDate';
import CurrencyFormat from 'react-currency-format';
import { FaPhone, FaPhoneSlash } from 'react-icons/fa';

const statusDrivers = [
  {
    key: 'driver_data',
    title: 'Asignado'
  },
  {
    key: 'delivering',
    title: 'Recogido'
  },
  {
    key: 'returning',
    title: 'Entregado'
  },
];

const OrderModal = ({
  toggleOrderModal,
  toggleSentModal,
  order,
  statusOrder,
  statusTranslate,
  setOrder,
}) => {
  const matchesSm = useMediaQuery('screen and (min-width: 100%)');
  const matches = useMediaQuery('screen and (min-width: 70%)');
  const [products, setProducts] = useState([]);
  const useStyles = makeStyles((theme) => ({
    modal: {
      border: '1px solid #e6ebf1',
      width: !matches ? '100%' : '600px',
      height: !matches ? '100%' : 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.grey[100],
      bottom: '0',
      animation: 'showPopUp 0.5s',
      position: !matches ? 'absolute' : 'relative',
    },
    CreateCustomizationModal: {
      backgroundColor: 'rgba(46, 44, 54, 0.4)',
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: '4',
      display: matches ? 'flex' : null,
      justifyContent: matches ? 'center' : null,
      alignItems: matches ? 'center' : null,
    },
    pane: {
      height: '100%',
      width: '100%',
      position: 'fixed'
    },
    header: {
      padding: '15px 10px',
      borderBottom: '1px solid #e6ebf1',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > h3': {
        fontWeight: 'bold',
        fontSize: '22px'
      }
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      padding: matchesSm ? '20px 0' : '15px 0',
      height: '100%',
    },
    branchOffice: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    thumbail: {
      position: 'relative',
      display: 'flex',
      minWidth: '50px',
      maxWidth: '50px',
      '& > img': {
        objectFit: 'contain',
        width: '100%',
        borderRadius: '50%',
      }
    },
    btn: {
      width: '125px',
      fontSize: '13px',
    },
    pending: {
      backgroundColor: '#FBC02D',
      color: 'white',
    },
    rejected: {
      backgroundColor: 'red',
      color: 'white'
    },
    accepted: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    finished: {
      backgroundColor: '#1565C0',
      color: 'white',
    },
    sent: {
      color: 'white',
      backgroundColor: '#7B1FA2',
    },
    containerOrders:{
      width: '88%',
      boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
      marginTop: '-15px',
      marginBottom: '10px',
      backgroundColor: '#fff',
      padding: '25px',
      margin: 'auto',
      flexDirection: "column",
      paddingRight: theme.spacing(4.0),
      paddingLeft: theme.spacing(4.0),
      '&::-webkit-scrollbar': {
        width: '7px'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '20px',
        backgroundColor: theme.palette.grey[400]
      }
    },
    card: {
      overflow: 'unset',
      backgroundColor: '#fff',
      marginBottom: theme.spacing(2.5),
      '&:last-child': {
        marginBottom: 0
      },
      border: '1px solid #transparent',
      padding: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    buttonIcon: {
      borderRadius: '50%',
      minWidth: 10,
      padding: 6,
      margin: '0 7px'
    },
    assignDriver: {
      with: 145,
      textTransform: 'none',
      borderRadius: 6,
      fontWeight: 700,
      fontSize: '13px'
    },
    icons: {
      marginRight: '.5rem'
    },
    iconFood: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
      fontSize: '17px'
    },
    primary: {
      color: theme.palette.primary.main
    },
    iconSwap: {
      fontSize: 15,
      color: '#fff'
    },
    containerProduct: {
      padding: '15px',
      width: '55%',
      backgroundColor: '#fff',
      boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
    },
    containerInfo: {
      padding: '15px',
      width: '45%',
      backgroundColor: '#fff',
      marginLeft: '25px',
      boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
    },
    back:{
      width: '50px',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));
  const classes = useStyles();

  const haveToppings = (sections) => {
    return sections.filter((section) => Object.keys(section)?.length > 0)?.length === 0;
  };

  const detailProduct = (product) => {
    return !haveToppings(product?.customize?.sections) || product.comment?.length > 0;
  };

  const getDetailPrice = (prices) => {
    return prices.find((price) => price.is_default);
  };

  const getTotalProducts = () => {
    return products?.reduce((amount, product) => (
      amount + product?.total
    ), 0);
  };

  const getCouponValue = (subtotal, coupon) => {
    if (coupon !== null) {
      const { type, value } = coupon;
      if (type === 'amount') return value;
      if (type === 'percentage') return subtotal * (value / 100);
    }
    return 0;
  };

  const optionTranslate = (type) => {
    switch (type) {
      case 'radio':
        return '(Opción unica)';
      case 'checkbox':
        return '(Si/No)';
      case 'quantity':
        return '(Opción multiple)';
      default:
        return '';
    }
  };

  const getProducts = () => {
    return order?.order_products?.map((product) => {
      let sections = [];
      if (product?.customize?.sections?.length > 0) {
        sections = product?.customize?.sections?.map((section) => {
          let items = [];
          if (section?.type === 'radio') {
            items = section?.items.filter((item) => item.value);
          } else if (section?.type === 'checkbox') {
            items = section?.items.filter((item) => !item.value);
          } else {
            items = section?.items.filter((item) => item.value > 0);
          }

          if (items?.length === 0) return {};
          return {
            ...section,
            items
          };
        });
      }
      return {
        ...product,
        customize: {
          sections
        }
      };
    });
  };

  useEffect(() => {
    setProducts(getProducts());
  }, []);

  return order && (
    <div className={classes.CreateCustomizationModal}>
      <div className={classes.pane} onClick={() => toggleOrderModal()} />
      <div className={classes.modal}>
        <div className={classes.title}>
          <Box display="flex" alignItems="center">
            <Box m={3} className={classes.back} style={{ cursor: 'pointer', fontSize: '20px', color: '#252525' }} onClick={() => toggleOrderModal()}>
              <ArrowBackIosRounded />
            </Box>
            {order?.roundtrip && (
              <Box
                mt={-20}
                borderRadius="50%"
                border="3px solid #97D4AE"
                display="flex"
                flexGrow="2"
                justifyContent="center"
                alignItems="center"
                width={26}
                height={26}
                bgcolor="#009739"
                ml={1.5}
              >
                <SwapHoriz className={classes.iconSwap} />
              </Box>
            )}
          </Box>            
        </div>       
          
        <div className={classes.body}>
          <Box display="flex" alignItems="center" justifyContent="center" px={2.5} pb={1.5} mt={-8} mb={2}>
            <Box className={classes.branchOffice}>
              <Box className={classes.thumbail}>
                <img
                  src={order?.data_branch_office?.brand?.logo}
                  alt={order?.data_branch_office?.brand?.name}
                />
              </Box>
              <Box ml={1.2} display="flex" flexDirection="column">
                <Typography style={{ fontSize: '15px' }} variant="h4">{`${order?.data_branch_office?.brand?.name} ${order?.branch_office_name}`}</Typography>
                <Box mt={0.5}>
                  <Typography style={{ fontSize: '13px', fontWeight: 400 }} variant="body2">
                    {order?.data_branch_office?.phone1}
                    {order?.data_branch_office?.phone2 ? ` - ${order?.data_branch_office?.phone2}` : ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.containerOrders} maxHeight="90vh" height="80vh" display="flex" flexDirection="column" overflow="auto">
            <Box display="flex" justifyContent="space-between" flexDirection="row" mb={-3}>
              <Box mb={4}>
                <Typography style={{ fontWeight: 600 }} variant="h4">
                  Pedido #
                  {order?.reference}
                </Typography>
                <Box display="flex">
                  <Typography style={{ color: '#A5A5A5', fontWeight: 700, marginRight: '5px' }} variant="body2">{getDate(order?.created_at)}</Typography>
                  <Typography style={{ color: '#A5A5A5', fontWeight: 700 }} variant="body2">{getHour(order?.created_at)}</Typography>
                </Box>
              </Box>
              <Box>
                <Chip
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                  className={classes?.[
                    statusOrder(order?.status?.toString()?.toUpperCase(), order?.ship_type)
                  ]}
                  label={(
                    <Box display="flex" alignItems="center">
                      {order?.status?.toString() === 'pending_to_be_sent' && order?.ship_type === 'adomi' && (
                        <DirectionsBike style={{ fontSize: '16px', color: '#fff', marginRight: '5px' }} />
                      )}
                      {statusTranslate(order?.status?.toString(), order?.ship_type)}
                    </Box>
                  )}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" width="100%" height="100%">
              <Box className={classes.containerProduct} maxHeight="100vh" display="flex" flexDirection="column" overflow="auto">
                <Box mb={1.5}>
                  <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="body2">
                    Productos
                  </Typography>
                </Box>
                {products.map((product) => {
                  const detailPrice = getDetailPrice(product?.prices);
                  return (
                    <Card className={classes.card}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" py={1.5} borderBottom="2px dashed #B5B5B5">
                        <Box display="flex">
                          <Restaurant className={classes.iconFood} />
                          <Box display="flex" flexDirection="column">
                            <Typography style={{ fontWeight: 600 }} variant="body2">
                              {product?.amount}
                              {' x '}
                              {haveToppings(product?.customize?.sections) ? '(S) ' : '(M) '}
                              {product?.name}
                            </Typography>
                            <Typography style={{ fontSize: '12px', marginTop: '2px' }} variant="body2">
                              <span style={{ fontWeight: 600 }}>{detailPrice?.name}</span>
                              {detailPrice?.description?.length > 0 && (
                                <>
                                  {` (${detailPrice?.description})`}
                                </>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography style={{ fontWeight: 600 }} variant="body2">
                          {'$ '}
                          <CurrencyFormat
                            value={product?.has_discount
                              ? product?.discount?.discount_price
                              : product?.price}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                          />
                        </Typography>
                      </Box>
                      {detailProduct(product) && (
                        <Box borderBottom="2px dashed #B5B5B5">
                          {!haveToppings(product?.customize?.sections) && (
                            <Box py={1.5}>
                              <Typography style={{ fontWeight: 700 }} variant="h6">Modificaciones y adicionales:</Typography>
                              {product?.customize?.sections?.map((section) => (
                                <>
                                  {Object.keys(section)?.length > 0 && (
                                    <Box pl={1} pt={1}>
                                      <Typography style={{ fontWeight: 600 }} variant="h6">
                                        {section.title}
                                        {' '}
                                        {optionTranslate(section?.type)}
                                      </Typography>
                                      {section?.items?.map((item) => (
                                        <Box pl={1} display="flex" alignItems="center">
                                          <Typography variant="body2">
                                            {'* '}
                                            {section?.type === 'quantity' && (
                                              <span style={{ fontWeight: 600 }}>
                                                {item?.value}
                                                {' x '}
                                              </span>
                                            )}
                                            {item?.name}
                                            {section?.type === 'checkbox' && (
                                              <span style={{ fontWeight: 600 }}>
                                                {' (NO) '}
                                              </span>
                                            )}
                                          </Typography>
                                          {item?.price > 0 && (
                                            <Box ml={1.5}>
                                              <Typography variant="body2">
                                                +
                                                $
                                                <CurrencyFormat
                                                  value={item?.price}
                                                  displayType="text"
                                                  thousandSeparator="."
                                                  decimalSeparator=","
                                                />
                                              </Typography>
                                            </Box>
                                          )}
                                        </Box>
                                      ))}
                                    </Box>
                                  )}
                                </>
                              ))}
                            </Box>
                          )}
                          {product?.comment?.length > 0 && (
                            <Box pb={1.5}>
                              <Typography style={{ fontWeight: 700 }} variant="h6">Comentarios</Typography>
                              <Box pl={1}>
                                <Typography variant="body2">{product?.comment}</Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      )}
                      <Box textAlign="right" pt={1.5} pb={0.5}>
                        <Typography style={{ fontWeight: 600 }} variant="h5">
                          {'Subtotal: '}
                          $
                          <CurrencyFormat
                            value={product?.total}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                          />
                        </Typography>
                      </Box>
                    </Card>
                  );
                })}
              </Box>          
              <Box className={classes.containerInfo} maxHeight="100vh" display="flex" flexDirection="column" overflow="auto">
                <Card className={classes.card}>
                  <Box mt={0.5} mb={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="body2">Resumen orden</Typography>
                  </Box>
                  <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2">Total productos:</Typography>
                    <Typography variant="body2">
                      $
                      <CurrencyFormat
                        value={getTotalProducts(products)}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Box>
                  <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2">
                      Cupon
                      {order?.coupon_data !== null && (
                        <>
                          {order?.coupon_data?.type === 'percentage' && (
                            <span style={{ marginLeft: '5px' }}>{`(${order?.coupon_value}%)`}</span>
                          )}
                        </>
                      )}
                      :
                    </Typography>
                    <Typography variant="body2">
                      {order?.coupon_data !== null && (
                        <>
                          {order?.coupon_data?.type !== 'delivery' && (
                            <span style={{ marginRight: '2px' }}>-</span>
                          )}
                        </>
                      )}
                      {order?.coupon_data?.type === 'delivery' && (
                        <>
                          Domicilio gratis
                        </>
                      )}
                      {order?.coupon_data?.type !== 'delivery' && (
                        <>
                          $
                          <CurrencyFormat
                            value={
                              getCouponValue(
                                getTotalProducts(order?.order_products), order?.coupon_data
                              )
                            }
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                          />
                        </>
                      )}
                    </Typography>
                  </Box>
                  <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2">Descuento Adomi:</Typography>
                    <Typography variant="body2">
                      {'- '}
                      $
                      <CurrencyFormat
                        value={order?.adomi_bonus}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Box>
                  <Box mt={0.5} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="h5">Subtotal:</Typography>
                    <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="h5">
                      $
                      <CurrencyFormat
                        value={order?.sub_total}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Box>
                  <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2">Costo domicilio:</Typography>
                    <Typography variant="body2">
                      $
                      <CurrencyFormat
                        value={order?.coupon_data?.type !== 'delivery' ? order.delivery_cost : 0}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Box>
                  <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2">Propina:</Typography>
                    <Typography variant="body2">
                      $
                      <CurrencyFormat
                        value={order?.tip}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Box>
                  <Box mt={0.7} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="h5">Total:</Typography>
                    <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="h5">
                      $
                      <CurrencyFormat
                        value={order?.coupon_data?.type !== 'delivery' ? order?.total : order?.total - order.delivery_cost}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Box>
                </Card>
                <Card className={classes.card}>
                  <Box mt={0.5} mb={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="body2">Datos de Entrega</Typography>
                  </Box>
                  {order?.data_client?.first_name && (
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Nombre del cliente:</Typography>
                      <Typography variant="body2">
                        {`${order?.data_client?.first_name} ${order?.data_client?.last_name}`}
                      </Typography>
                    </Box>
                  )}
                  {order?.data_client?.phone && (
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Teléfono:</Typography>
                      <Typography variant="body2">
                        {order?.data_client?.phone}
                      </Typography>
                    </Box>
                  )}
                  <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2">Dirección de entrega:</Typography>
                    <Typography variant="body2">
                      {`${order?.address?.address1} (${order?.address?.neighborhood}, ${order?.address?.reference})`}
                    </Typography>
                  </Box>
                </Card>
                {order?.data_driver && order?.ship_type === 'adomi' && (
                  <Card className={classes.card}>
                    <Box mt={0.5} mb={1} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="body2">Driver asignado</Typography>
                    </Box>
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Nombre:</Typography>
                      <Typography variant="body2">
                        {`${order?.data_driver?.first_name} ${order?.data_driver?.last_name}`}
                      </Typography>
                    </Box>
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Teléfono:</Typography>
                      <Typography variant="body2">
                        {order?.data_driver?.whatsapp}
                      </Typography>
                    </Box>
                  </Card>
                )}
                <Card className={classes.card}>
                  <Box mt={0.5} mb={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography style={{ fontWeight: 600, fontSize: '16px' }} variant="body2">Historial de la orden</Typography>
                  </Box>
                  {order?.order_status?.find((s) => s?.status === 'created')?.created_at && (
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Recibido:</Typography>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body2" style={{ textAlign: 'right' }}>
                          {getHour(order?.order_status?.find((s) => s?.status === 'created')?.created_at)}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          {order?.has_called
                            ? (
                              <FaPhone className={clsx(classes.icons, classes.primary)} />
                            )
                            : (
                              <FaPhoneSlash className={clsx(classes.icons, classes.primary)} />
                            )}
                          <Typography variant="body2">
                            {order?.has_called ? 'Aliado llamado' : 'Aliado no llamado'}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {order?.order_status?.filter((s) => s?.status === 'etp').slice(-1)[0]?.created_at && (
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Tiempo estimado:</Typography>
                      <Typography variant="body2">
                        {order?.order_status?.filter((s) => s?.status === 'etp').slice(-1)[0]?.information?.etp}
                        {' minutos'}
                      </Typography>
                    </Box>
                  )}
                  {order?.order_status?.filter((s) => s?.status === 'in_progress').slice(-1)[0]?.created_at && (
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">En proceso:</Typography>
                      <Typography variant="body2">
                        {getHour(order?.order_status?.filter((s) => s?.status === 'in_progress').slice(-1)[0]?.created_at)}
                      </Typography>
                    </Box>
                  )}
                  {order?.order_status?.filter((s) => s?.status === 'sent').slice(-1)[0]?.created_at && (
                    <Box
                      display="flex"
                      flexDirection="column"
                    >
                      <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="body2">En camino:</Typography>
                        <Typography variant="body2">
                          {getHour(order?.order_status?.filter((s) => s?.status === 'sent').slice(-1)[0]?.created_at)}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        py={1}
                      >
                        {statusDrivers.map((item, index) => (
                          <>
                            {order?.order_status?.filter((s) => s?.status === item?.key)
                              .slice(-1)[0]?.created_at && (
                              <Box
                                display="flex"
                                pl={1}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                >
                                  {index !== 0 && (
                                    <Box
                                      display="flex"
                                      flexDirection="column-reverse"
                                    >
                                      {[0, 1].map(() => (
                                        <Box
                                          my={0.3}
                                          width={7}
                                          height={7}
                                          borderRadius="50%"
                                          bgcolor="#97D4AE"
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="50%"
                                    border="3px solid #97D4AE"
                                    width={17}
                                    height={17}
                                    my={0.3}
                                    bgcolor="#009739"
                                  />
                                </Box>
                                <Box
                                  ml={1}
                                  display="flex"
                                  justifyContent="space-between"
                                  alignSelf="end"
                                  height="fit-content"
                                >
                                  <Typography
                                    color="primary"
                                    variant="body2"
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500
                                    }}
                                  >
                                    {`${item?.title}:`}
                                  </Typography>
                                  <Typography
                                    color="primary"
                                    variant="body2"
                                    style={{
                                      fontSize: 13,
                                      marginLeft: 7,
                                      fontWeight: 500
                                    }}
                                  >
                                    {getHour(order?.order_status?.filter((s) => s?.status === item?.key)
                                      .slice(-1)[0]?.created_at)}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {order?.order_status?.filter((s) => s?.status === 'cancelled').slice(-1)[0]?.created_at && (
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Cancelado:</Typography>
                      <Typography variant="body2">
                        {getHour(order?.order_status?.filter((s) => s?.status === 'cancelled').slice(-1)[0]?.created_at)}
                      </Typography>
                    </Box>
                  )}
                  {order?.order_status?.filter((s) => s?.status === 'rejected').slice(-1)[0]?.created_at && (
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">Rechazado:</Typography>
                      <Typography variant="body2">
                        {getHour(order?.order_status?.filter((s) => s?.status === 'rejected').slice(-1)[0]?.created_at)}
                      </Typography>
                    </Box>
                  )}
                  {order?.order_status?.filter((s) => s?.status === 'finished'
                  || s?.status === 'driver_delivered').slice(-1)[0]?.created_at && (
                    <Box mt={0.3} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2">
                        Completado
                        {order?.roundtrip ? ' (Ida y vuelta)' : ' (Entregado)'}
                        :
                      </Typography>
                      <Typography variant="body2">
                        {getHour(order?.order_status?.filter((s) => s?.status === 'finished'
                        || s?.status === 'driver_delivered').slice(-1)[0]?.created_at)}
                      </Typography>
                    </Box>
                  )}
                </Card>  
              </Box>              
            </Box>
          </Box>              
          {(order?.status === 'in_progress' || order?.status === 'pending_to_be_sent') && order?.ship_type === 'adomi' && (
            <Box mt={3} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.assignDriver}
                onClick={() => {
                  setOrder(order);
                  toggleSentModal();
                }}
              >
                <DirectionsBike fontSize="small" className={classes.icons} />
                Asignar repartidor
              </Button>
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

OrderModal.propTypes = {
  toggleOrderModal: PropTypes.func,
  toggleSentModal: PropTypes.func,
  order: PropTypes.object,
  statusOrder: PropTypes.func,
  statusTranslate: PropTypes.func,
  setOrder: PropTypes.func,
};

export default OrderModal;
