import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Card,
  Grid,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  avatar: {
    width: 'auto',
    height: 'auto'
  }
}));

const CityCardPreview = ({
  className, city, ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={3} direction="column" justify="center" alignItems="center">
            <Avatar
              className={classes.avatar}
              alt="city"
              src={city?.state?.country?.flag}
              variant="square"
            />
          </Grid>
          <Grid item xs={9}>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {city?.name}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
            >
              {city?.state?.name}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              gutterBottom
              variant="caption"
            >
              {city?.state?.country?.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CityCardPreview.propTypes = {
  className: PropTypes.string,
  city: PropTypes.object,
};

CityCardPreview.defaultProps = {
  className: '',
  city: {},
};

export default CityCardPreview;
